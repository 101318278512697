import DefaultText from '@/components/atoms/Text/DefaultText';
import style from './style.module.sass';

type DropdownSettingChartProps = {
  onAreaEditClick?: () => void;
  modalModalDeleteClick: () => void;
};

function DropdownSettingChartNone({ onAreaEditClick, modalModalDeleteClick }: DropdownSettingChartProps) {
  return (
    <>
      <div className={`${style.setting_box} left-[-130px] h-24 lg:left-[-170px]`}>
        <div className={style.setting_list_item}>
          <div className={style.setting_item} onClick={onAreaEditClick}>
            <DefaultText variant="title-children" className="text-on-surface">
              編集
            </DefaultText>
          </div>
          <div className={style.setting_item} onClick={modalModalDeleteClick}>
            <DefaultText variant="title-children" className="text-error">
              削除
            </DefaultText>
          </div>
        </div>
      </div>
    </>
  );
}

export default DropdownSettingChartNone;
