import { useState } from 'react';

type Props = {
  listImagesPath: string[] | undefined;
};

export const useService = ({ listImagesPath }: Props) => {
  const [img, setImg] = useState<number | undefined>();

  const handleClose = () => {
    setImg(undefined);
  };

  const handleOnClick = (img: string) => {
    const index = listImagesPath?.findIndex((path) => path === img);
    if (index !== -1 && index !== undefined) {
      setImg(index);
    }
  };

  return {
    handleOnClick,
    img,
    handleClose,
  };
};
