import { FilledButton, TextButton } from '@/components/molecules/ButtonIcon';
import React, { useRef, useState } from 'react';
import { useService } from './service';
import AddImageDialog from '@/components/atoms/AddImageDialog';
import IconCameraAlt from '@/components/icon/IconCameraAlt';
import IconImage from '@/components/icon/IconImage';
import IconLabel from '@/components/icon/IconLabel';
import ListTagsWrap from '@/components/atoms/ListTags';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { ITag } from '@/types/tag';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { uploadFileQuestion, setQFDCData } from '@/stores/common';
import { Link } from 'react-router-dom';
import { replacePathParams } from '@/utils/helpers';
import { FileRes } from '@/types/common';

type Props = {
  selected: Array<ITag>;
  setSelected: (tag: Array<ITag>) => void;
  handleCreateUpdate: () => void;
  isEdit?: boolean;
  ePathDetail: string;
  ePathOrganization: string;
  filesImageRes?: string[];
  filesRes?: FileRes[];
};

const FooterQFD = (props: Props) => {
  const dispatch = useDispatch();
  const {
    selected,
    setSelected,
    handleCreateUpdate,
    isEdit = false,
    ePathDetail,
    ePathOrganization,
    filesImageRes = [],
    filesRes = [],
  } = props;
  const { QFDCData } = useSelector((state: RootState) => state.common);
  const { handleToggleImageDialog, isShow, setIsShow } = useService();
  const [isShowListTags, setIsShowListTags] = useState<boolean>(false);
  const refList = useRef(null);

  const handleListShow = () => {
    setIsShowListTags(false);

    dispatch(
      setQFDCData({
        ...QFDCData,
        listTags: selected,
      }),
    );
  };

  const uploadFile = (filesImage: File[], files: File[], imagesPath: string[], filesInit: FileRes[]) => {
    setIsShow(false);
    dispatch(
      setQFDCData({
        ...QFDCData,
        files: filesInit,
        imagesDisplay: filesImage,
        imagesPath: imagesPath,
        filesPath: filesInit.map((item) => item.path),
      }),
    );
    if (filesImage.length > 0 || files.length > 0) {
      dispatch(uploadFileQuestion([...filesImage, ...files]));
    }
  };

  useOutsideClick(refList, handleListShow);

  const refFile = useRef<HTMLDivElement>(null);

  useOutsideClick(refFile, () => {
    setIsShow(false);
  });

  return (
    <>
      <div className="flex flex-row">
        <button className="my-3 hidden p-2 md:m-2 lg:flex" onClick={handleToggleImageDialog}>
          <i className="icon-add icon-24px h-6 w-6 bg-on-surface-variant" />
        </button>
        <button className="my-3 p-2 md:my-2 lg:hidden">
          <IconCameraAlt className="w-6 text-on-surface-variant" />
        </button>
        <button className="my-3 p-2 md:my-2 lg:hidden" onClick={handleToggleImageDialog}>
          <IconImage className="w-6 text-on-surface-variant" />
        </button>
        <button className="relative my-2 p-2">
          <div onClick={() => setIsShowListTags(!isShowListTags)}>
            <IconLabel className="w-6 text-on-surface-variant" />
          </div>
          <div>
            {isShowListTags && (
              <div className="z-9 absolute bottom-[50px] left-[-85px] lg:left-0" ref={refList}>
                <ListTagsWrap selected={selected} setSelected={setSelected} />
              </div>
            )}
          </div>
        </button>
      </div>
      <div className="flex items-center gap-2">
        <Link to={isEdit ? ePathDetail : replacePathParams(ePathOrganization, { id: 1 })} className="hidden sm:block">
          <TextButton className="text-on-primary">キャンセル</TextButton>
        </Link>
        <FilledButton className="text-on-primary" onClick={handleCreateUpdate}>
          投稿
        </FilledButton>
      </div>
      {isShow && (
        <div ref={refFile} className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
          <AddImageDialog
            setIsShow={setIsShow}
            uploadFile={uploadFile}
            filesImageRes={filesImageRes}
            filesRes={filesRes}
          />
        </div>
      )}
    </>
  );
};

export default FooterQFD;
