import BodyText from '@/components/atoms/AText/BodyText';
import Label from '@/components/atoms/AText/Label';
import Chip from '@/components/atoms/Chips';
import React from 'react';
import PublicChip from '@/components/atoms/PublicChip';
import InputChip from '@/components/atoms/InputChips';
import { ITag } from '@/types/tag';
import { shareType } from '@/types/enum';
import Linkify from '@/components/atoms/Linkify';
import { nanoid } from 'nanoid';

type PublishOptions = {
  option: number;
  organization_id: number[];
};

type CardProps = {
  id: number;
  organizationId?: number;
  content: string;
  image: string | undefined;
  publish?: PublishOptions;
  posted_date?: string;
  posted_by?: number;
  statusCd?: number | null;
  commentsTop?: string | null;
  commentCount?: number;
  title: string;
  organizationName: string;
  tags: ITag[];
  optionPublic: number;
  handleLinkDetail?: (id: number) => void;
};

const Card = ({
  organizationName,
  title,
  commentsTop,
  statusCd = null,
  image,
  id,
  handleLinkDetail,
  commentCount,
  tags,
  optionPublic,
}: CardProps) => {
  return (
    <div
      className="m-auto flex w-[320px] cursor-pointer flex-col overflow-hidden rounded-xl border border-outline-variant bg-white md:m-0"
      onClick={() => handleLinkDetail?.(id)}
    >
      <div className="items-center overflow-hidden">
        <img
          src={image ? `${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(image)}` : '/media.png'}
          className="max-h-[150px] w-full object-cover object-center"
          alt=""
        />
      </div>

      <div className="flex flex-col items-start justify-start gap-1 p-4">
        <Label tag="p" className="truncate-text text-[11px] text-on-surface-variant">
          {organizationName}
        </Label>
        <div className="flex max-w-full flex-col">
          <BodyText tag="h4" size="large" className="text-ellipsis-2 flex">
            <div className="text-on-surface">
              <Linkify>{title}</Linkify>
            </div>
          </BodyText>
          <BodyText tag="h6" size="medium" className="text-ellipsis-2 text-on-surface-variant">
            {commentsTop}
          </BodyText>
        </div>

        <div className="inline-flex max-w-full flex-row gap-3">
          {statusCd !== null && <Chip comment={commentCount} />}
          <PublicChip variant={optionPublic === shareType.public ? 'public' : 'private'} />
        </div>
        <div className="flex max-w-full flex-wrap gap-1">
          {tags?.map((item) => (
            <InputChip key={nanoid(5)} className="icon-button" type="square" variant="unselected" text={item?.title} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
