import cn from '@/utils/general';
import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type TextFieldOwnProps = {
  name: string;
  className?: string;
  iconLeft?: string;
  iconRight?: string;
  supportText?: string;
  hasError?: boolean;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  id: string;
  register?: UseFormRegister<FieldValues>;
  error?: string;
};

type TextFieldProps = TextFieldOwnProps & Omit<React.HTMLProps<HTMLInputElement>, keyof TextFieldOwnProps>;

const InputFormText = ({
  name,
  id,
  register,
  error,
  className,
  iconLeft,
  iconRight,
  onClickLeft,
  onClickRight,
  ...rest
}: TextFieldProps) => {
  return (
    <div>
      <div className="flex flex-row">
        {iconLeft && (
          <button onClick={onClickLeft} className={cn('icon-input m-1 -mr-12 flex items-center p-2', iconLeft)}>
            <i className="h-6 w-6 before:bg-on-surface-variant" />
          </button>
        )}
        <input
          id={id}
          className={cn(
            'input-text-field regular-text lg',
            'px-4 py-2 text-on-surface',
            iconLeft ? 'pl-12rem' : '',
            iconRight ? 'pr-12rem' : '',
            error ? 'border-2 border-error hover:border-error focus:border-error' : '',
            className,
          )}
          {...register?.(name)}
          {...rest}
        />
        {iconRight && (
          <button
            onClick={onClickRight}
            className={cn('icon-input m-1 -ml-12 flex items-center p-2 hover:cursor-pointer', iconRight)}
          >
            <i className="h-6 w-6 before:bg-on-surface-variant" />
          </button>
        )}
      </div>
      {error && (
        <div className="relative mb-6">
          <p className="label sm absolute max-w-full truncate whitespace-nowrap px-4 pt-1 text-error">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputFormText;
