import React from 'react';

interface Props {
  className?: string;
  errorMessage: string | undefined;
  hasError?: boolean;
}

export const ErrorTooltip: React.FC<Props> = (props) => {
  const { className, errorMessage, hasError } = props;
  return (
    <>
      {hasError && (
        <p className={`mt-1 pl-4 font-noto text-[11px] font-medium text-error ${className}`}>{errorMessage}</p>
      )}
    </>
  );
};
