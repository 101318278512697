import cn from '@/utils/general';
import React from 'react';

type IconProps = {
  className?: string;
  width?: number;
  height?: number;
};

const IconTrash = ({ className, width = 24, height = 24 }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" className={cn(className)}>
      <path
        fill="#BA1A1A"
        d="M15 3v1h5v2h-1v13c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6H4V4h5V3h6ZM7 19h10V6H7v13ZM9 8h2v9H9V8Zm6 0h-2v9h2V8Z"
      />
    </svg>
  );
};

export default IconTrash;
