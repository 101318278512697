import Label from '@/components/atoms/AText/Label';
import Heading from '@/components/atoms/Heading';
import { RootState } from '@/stores';
import { updateEditDepreciationExpense } from '@/stores/business/services';
import { DialogBusinessPlanForm4 } from '@/types/business';
import { MESSAGE_VALIDATE_MIN } from '@/utils/constants/messageValidate';
import { handleOnChangeInputNumberFormat } from '@/utils/helpers';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInputRow from '../FormInputRow';
import FormEditWraper from './FormEditWraper';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';

interface Props {
  data: DialogBusinessPlanForm4[];
}

const FormEdit4 = (props: Props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const { sheetActive } = useSelector((state: RootState) => state.business);

  const { control, handleSubmit, setValue } = useForm<FieldValues>();

  useEffect(() => {
    if (data) {
      data.forEach((item) => {
        item.data.forEach((item2) => {
          setValue(item2.name, item2.defaultValue ? String(item2.defaultValue) : '');
        });
      });
    }
  }, [data]);

  const onSubmit = async (data: FieldValues) => {
    if (businessId) {
      setIsDisable(true);
      const dataUpdate = {
        newBuildings: +data.newBuildings,
        usedBuildings: +data.usedBuildings,
        newMechanicalEquipment: +data.newMechanicalEquipment,
        usedMechanicalEquipment: +data.usedMechanicalEquipment,
      };
      await dispatch(updateEditDepreciationExpense({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
    }
  };

  return (
    <form
      className="max-w-w-[calc(100vw-64px)] w-[calc(100vw-64px)] max-w-[479px] rounded-2xl bg-gray-background p-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormEditWraper isDisable={isDisable}>
        {data?.length > 0 &&
          data.map((item) => (
            <div key={item.title}>
              <Heading level={5} text={item.title} className="regular-text lg text-on-surface" />
              <div className="flex flex-col flex-wrap sm:flex-row">
                {item.data.length > 0 &&
                  item.data.map((item2) => (
                    <div key={item2.name} className="flex basis-1/2 flex-col">
                      <Label className="label lg">{item2.labelLeft}</Label>
                      <FormInputRow labelRight={item2.labelRight} classNames="sm:w-[180px]">
                        <Controller
                          name={item2.name}
                          control={control}
                          rules={{
                            min: {
                              value: item2.min ?? 0,
                              message: MESSAGE_VALIDATE_MIN(item2.min ?? 0),
                            },
                          }}
                          render={({ field }) => (
                            <StepperNumberFormat
                              {...field}
                              className="regular-text lg text-on-surface"
                              onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                              setValue={setValue}
                              min={item2.min}
                              max={item2.max}
                              step={item2.step}
                              name={item2.name}
                              isHiddenArrowUpDown={false}
                              isFiledNumberDialog={true}
                            />
                          )}
                        />
                      </FormInputRow>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </FormEditWraper>
    </form>
  );
};

export default FormEdit4;
