import React, { ReactNode } from 'react';
import Label from '@/components/atoms/AText/Label';

type FormInputRowProps = {
  readonly labelLeft?: string;
  readonly labelRight?: string;
  readonly children: ReactNode;
  readonly classNames?: string;
};

function FormInputRow({ labelLeft, labelRight, children, classNames }: FormInputRowProps) {
  return (
    <div className={`flex items-center gap-2 ${classNames ?? ''}`}>
      {labelLeft && <Label className="label lg w-[82px] font-medium">{labelLeft}</Label>}
      <div className="grow sm:max-w-[220px]">{children}</div>
      {labelRight && <Label className="label lg my-auto whitespace-nowrap font-medium">{labelRight}</Label>}
    </div>
  );
}

export default FormInputRow;
