import Breadcrumb, { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import IconMoreGrey from '@/components/icon/IconMore/IconMoreGrey';
import c from 'clsx';
import React, { useRef, useState } from 'react';
import Input from '../../../atoms/Input';
import DropdownSettingChartNone from '../../Dropdown/DropdownSettingChart/DropdownSettingChartNone';
import ModalDelete from '../../ModalComponent/ModalDelete';
import ModalSowingChart from '../../ModalComponent/ModalSowingChart';
import s from './../style.module.sass';
import { useService } from './service';
import { Chart } from '@/stores/chart/model';
import { useOutsideClick } from '@/hooks/useClickOutside';

type Props = {
  className?: string;
  area: string;
  id: string;
  label: string;
  circleData: Chart;
};

const ChartNameCellNoneAll: React.FC<Props> = (props) => {
  const { className, area, id, label, circleData } = props;
  const { isEdit, inputRef, handleSubmit } = useService(area, id);
  const [hoveredGroup, setHoveredGroup] = useState<boolean>(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showAreaManagement, setShowAreaManagement] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const refDiv = useRef<HTMLDivElement>(null);

  const handleIconMoreClick = () => {
    setShowSetting(!showSetting);
  };

  const handleAreaEditClick = () => {
    setShowAreaManagement(true);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleCloseModal = () => {
    setShowAreaManagement(false);
    setShowModalDelete(false);
  };

  const BREADCRUMBS_ROOT: TBreadcrumb[] = [
    {
      name: circleData.organization.title,
      to: '#',
    },
    {
      name: circleData.house.title,
      to: '#',
    },
    {
      name: circleData.businessPlan.title,
      to: '#',
    },
    {
      name: circleData.sheet.title,
      to: '#',
    },
    {
      name: circleData.area.title,
      to: '#',
    },
  ];

  useOutsideClick(refDiv, () => {
    setShowSetting(false);
  });

  return (
    <>
      <div
        className={`${c(s.root, className)} my-auto h-12 w-full`}
        onMouseEnter={() => setHoveredGroup(true)}
        onMouseLeave={() => setHoveredGroup(false)}
        ref={refDiv}
      >
        {isEdit ? (
          <form onSubmit={handleSubmit}>
            <Input ref={inputRef} />
          </form>
        ) : (
          <>
            <div
              className=""
              title={`${circleData.organization.title} > ${circleData.house.title} > ${circleData.businessPlan.title} > ${circleData.sheet.title} > ${circleData.area.title} `}
            >
              <Breadcrumb data={BREADCRUMBS_ROOT} className="h-[18px] whitespace-nowrap" />
              <DefaultText variant="text-link" className={`text-sm text-on-surface ${s.label}`}>
                {label}
              </DefaultText>
            </div>
            <div className="relative my-auto h-6 w-6" onClick={handleIconMoreClick}>
              {hoveredGroup && (
                <div className="pr-0.5" onClick={handleIconMoreClick}>
                  <IconMoreGrey />
                </div>
              )}
              <div className="mt-8">
                {showSetting && (
                  <DropdownSettingChartNone
                    onAreaEditClick={handleAreaEditClick}
                    modalModalDeleteClick={handleModalDeleteClick}
                  />
                )}{' '}
              </div>
            </div>
          </>
        )}
      </div>
      <CommonModal
        breadcrumb="エリアA"
        open={showAreaManagement}
        title={'播種'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[640px]'}
      >
        <ModalSowingChart circleData={circleData} handleClose={handleCloseModal} />
      </CommonModal>
      <CommonModal
        open={showModalDelete}
        title={'エリアの削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete contentDelete={'エリアの名前を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ChartNameCellNoneAll;
