import { useState } from 'react';

export const useService = () => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const handleEnter = () => {
    setDisplayTooltip(true);
  };

  const handleLeave = () => {
    setDisplayTooltip(false);
  };
  return {
    displayTooltip,
    handleEnter,
    handleLeave,
  };
};
