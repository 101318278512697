import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore, getDefaultMiddleware, Middleware, ThunkDispatch } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';

import chart from './chart';
import feedback from './feedback';
import business from './business';
import { IndexedObject } from '@/utils/types';
import authReducer from './auth';
import tagReducer from './tag';
import organizationReducer from './organization';
import notificationReducer from './notification';
import commonReducer, { setIsLoading } from './common';
import housesReducer from './houses';
import questionReducer from './question';
import documentReducer from './document';

const loadingMiddleware: Middleware = (store) => (next) => (action) => {
  if (action.type.startsWith('loading/') && action.type.endsWith('/pending')) {
    store.dispatch(setIsLoading(true));
  } else if (
    (action.type.startsWith('loading/') && action.type.endsWith('/fulfilled')) ||
    (action.type.startsWith('loading/') && action.type.endsWith('/rejected'))
  ) {
    store.dispatch(setIsLoading(false));
  }
  return next(action);
};

export default loadingMiddleware;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducer = combineReducers({
  chart: chart.reducer,
  feedback: feedback.reducer,
  business: business.reducer,
  auth: authReducer,
  questions: questionReducer,
  router: routerReducer,
  common: commonReducer,
  tag: tagReducer,
  houses: housesReducer,
  document: documentReducer,
  organization: organizationReducer,
  notification: notificationReducer,
});

const initializeStore = (preloadedState = undefined) => {
  const middleware = [...getDefaultMiddleware().concat(routerMiddleware), loadingMiddleware];

  return configureStore({
    reducer,
    middleware,
    preloadedState,
  });
};
export const store = initializeStore();
export type RootState = ReturnType<typeof reducer>;
export type AsyncAction<R = void> = ThunkAction<Promise<R>, RootState, undefined, Action<string>>;
export type Dispatch = ThunkDispatch<RootState, IndexedObject, Action>;
export const history = createReduxHistory(store);
