import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';
import styles from './Button.module.scss';

export interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  fontSize?: number;
  variant?: 'primary' | 'on-secondary' | 'error';
  color?: 'red' | 'white' | 'disabled' | 'black725';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  textClassNameContainer?: string;
}

export default function BaseButton({
  className = '',
  children,
  onClick,
  fontSize,
  variant = 'primary',
  type = 'button',
  color = 'black725',
  disabled = false,
  textClassNameContainer,
}: ButtonProps) {
  const buttonClass = useMemo(
    () => twMerge('h-[40px] flex justify-center items-center rounded whitespace-nowrap disabled:opacity-50', className),
    [className],
  );
  return (
    <button
      className={`${styles.baseButton} ${buttonClass}`}
      data-variant={variant}
      data-color={color}
      data-gradient={color === 'black725'}
      onClick={onClick}
      type={type}
      style={{ fontSize: fontSize }}
      disabled={disabled}
    >
      {<span className={`${textClassNameContainer}`}>{children}</span>}
    </button>
  );
}
