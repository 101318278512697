import { IconArrowSelect } from '@/components/icon/IconArrowDropUp/IconArrowSelect';
import c from 'clsx';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import s from './style.module.sass';

type Props = {
  className?: string;
  onChange?: () => void;
  data: { value: string; label: string }[];
};

const Select: ForwardRefRenderFunction<HTMLSelectElement, Props> = (props, ref) => {
  const { className, onChange, data } = props;

  return (
    <div className={c(s.selectContainer, className)}>
      <select ref={ref} className={c(s.select, className)} onChange={onChange}>
        {data.map((i) => (
          <option key={i.label} value={i.value} label={i.label} className={s.option}>
            {i.label}
          </option>
        ))}
      </select>
      <div className={s.arrow}>
        <IconArrowSelect className={s.arrowIcon} />
      </div>
    </div>
  );
};

const ForwardedSelect = forwardRef(Select);

export default ForwardedSelect;
