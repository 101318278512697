import cn from '@/utils/general';
import React from 'react';
import Label from '../AText/Label';

type ChipProps = {
  comment?: number;
  className?: string;
};

const Chip = ({ comment, className }: ChipProps) => {
  let chipBkg;

  if (!comment) {
    chipBkg = 'bg-error-container';
  } else {
    chipBkg = 'bg-tertiary-container';
  }

  return (
    <div
      className={cn(
        'flex w-fit items-center justify-center rounded-lg border border-outline px-3 py-[5px] hover:cursor-pointer',
        chipBkg,
        className,
      )}
    >
      <Label tag="span" size="large" className="items-baseline font-medium">
        コメント{comment}件
      </Label>
    </div>
  );
};

export default Chip;
