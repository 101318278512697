import React, { ForwardRefRenderFunction, forwardRef, ReactNode } from 'react';
import c from 'clsx';
import s from './style.module.sass';
import { IconArrowDropDown } from '@/components/icon/IconArrowDropDown';
import { IOptionFilter } from '@/types/common';

type Props = {
  className?: string;
  onChange?: (selected: IOptionFilter) => void;
  data: IOptionFilter[];
  children?: ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  classNameWrap?: string;
};

const SelectWithIcon: ForwardRefRenderFunction<HTMLSelectElement, Props> = (props, ref) => {
  const { className, onChange, data, children, defaultValue = '', disabled, classNameWrap } = props;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const result = data.find((item) => item.value === e.target.value);
    if (result) {
      onChange?.(result);
    }
  };

  return (
    <div className={`h-8 lg:h-12 lg:w-auto ${s.box_select} ${classNameWrap}`}>
      <select
        ref={ref}
        onChange={handleChange}
        className={c(s.root, className)}
        value={defaultValue}
        disabled={disabled}
      >
        {children}
        {data.map((i) => {
          return (
            <option className="max-w-[80%]" key={i.label} value={i.value} label={i.label}>
              {i.label}
            </option>
          );
        })}
      </select>
      <span className={`h-7 lg:h-11 ${s.icon_select} pointer-events-none bg-white`}>
        <IconArrowDropDown></IconArrowDropDown>
      </span>
    </div>
  );
};

const ForwardedSelect = forwardRef(SelectWithIcon);

export default ForwardedSelect;
