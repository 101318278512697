import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TFeedback } from './model';
import { IDataFilter, IFilter, IQuestionCreateApi, TQuestionUpdate } from '@/types/question';
import { FileRes, IPagination, TCallback } from '@/types/common';
import { DATA_OPTION_INIT, DEFAULT_PAGE_SIZE, typeFilterQuestion } from '@/utils/constants/consts';
import axiosInstance from '@/api/axios-client';
import { setTags } from '@/stores/tag';
import { CommonState, getListFeedback, setQFDCData, setShowToast, setTitleHeaderDetail } from '@/stores/common';
import { isArray } from '@craco/craco/dist/lib/utils';
import { REGEX } from '@/utils/constants/regex';
import { getAPIFeedbackList } from '@/stores/feedback/services';
import { messageNotification } from '@/utils/constants/messageNotification';
import { getCountNotification } from '@/stores/notification';

export type FeedbackState = {
  feedbacks: TFeedback[];
  isAdmin: boolean;
  keyword: string;
  page: number;
  limit: number;
  total: number;
  dataOption: IFilter<IPagination, IDataFilter>;
  feedbackDetail: TFeedback | null;
};
const initialState: FeedbackState = {
  page: 1,
  limit: 10,
  total: 0,
  feedbacks: [],
  isAdmin: false,
  keyword: '',
  dataOption: DATA_OPTION_INIT,
  feedbackDetail: null,
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    saveFeedbacks: (state, action) => {
      const { items, page, limit, total } = action.payload;
      state.feedbacks = items;
      state.page = page;
      state.limit = limit;
      state.total = total;
    },
    getFeedbacks: (state, action: PayloadAction<TFeedback[]>) => {
      state.feedbacks = action.payload;
    },
    setDataOption: (state, action) => {
      state.dataOption[action.payload.type].data = action.payload.data;
    },
    setFeedbackDetail: (state, action) => {
      state.feedbackDetail = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const getUserFilterAsync = createAsyncThunk('feedback/getUserFilter', async (param: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `feedback/users?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
  );
  thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.AUTHOR, data: response.data.items }));
});

export const getTagFilterAsync = createAsyncThunk('feedback/getTagFilter', async (param: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `feedback/tags?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
  );
  thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.TAG, data: response.data.items }));
});

export const getOrganizationFilterAsync = createAsyncThunk(
  'feedback/getOrganizationFilter',
  async (param: IPagination, thunkAPI) => {
    const response = await axiosInstance.get(
      `feedback/organizations?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
    );
    thunkAPI.dispatch(
      setDataOption({
        type: typeFilterQuestion.ORGANIZATION,
        data: response.data.items,
      }),
    );
  },
);

export const createFeedback = createAsyncThunk(
  'feedback/createFeedback',
  async (data: IQuestionCreateApi & TCallback, thunkAPI) => {
    const response = await axiosInstance.post('feedback', data);
    thunkAPI.dispatch(setTags(response.data.items));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(getCountNotification());
    data.callback?.();
  },
);

export const getFeedbackDetailAsync = createAsyncThunk(
  'feedback/getFeedbackDetailAsync',
  async (id: number, thunkAPI) => {
    const response = await axiosInstance.get(`feedback/${id}`);
    thunkAPI.dispatch(setFeedbackDetail(response.data));
    let imageName = [];
    let fileName = [];
    if (response.data.image && isArray(response.data.image)) {
      imageName = response.data.image
        ?.filter((file: FileRes) => REGEX.imageExtensionsRegex.test(file.name))
        .map((item: FileRes) => item.path);
      fileName = response.data.image.filter((file: FileRes) => !REGEX.imageExtensionsRegex.test(file.name));
    }
    const state = thunkAPI.getState() as { common: CommonState };
    thunkAPI.dispatch(
      setQFDCData({
        ...state.common.QFDCData,
        content: response.data.content,
        imagesPath: imageName ?? [],
        files: fileName,
        typeShare: response.data.publish,
        filesPath: response.data.image.map((item: FileRes) => item.path),
      }),
    );
    thunkAPI.dispatch(setTitleHeaderDetail(response.data.title));
  },
);

export const editFeedback = createAsyncThunk(
  'feedback/editFeedback',
  async (data: TQuestionUpdate & TCallback, thunkAPI) => {
    await axiosInstance.patch(`feedback/${data.id}`, data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    data.callback?.();
  },
);

export const deleteFeedback = createAsyncThunk(
  'feedback/deleteFeedback',
  async (params: { feedbackId: number; organizationId?: number | string } & TCallback, thunkAPI) => {
    if (params.organizationId) {
      await axiosInstance.delete(`feedback/${params.feedbackId}`);
      thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
      thunkAPI.dispatch(
        getAPIFeedbackList({
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          organizationId: [{ operation: 'in', value: [Number(params.organizationId)] }],
        }),
      );
      thunkAPI.dispatch(
        getListFeedback({
          organizationId: [
            {
              operation: 'in',
              value: [+params.organizationId],
            },
          ],
        }),
      );
      params.callback?.();
    }
  },
);

const { actions, reducer } = slice;
export default slice;
export const { setDataOption, setFeedbackDetail, setKeyword } = slice.actions;
export { actions, reducer };
