import { EPath } from '@/route/route';
import { comparePath } from '@/utils/helpers';
import { TITLE_WEB } from '@/utils/constants/consts';

export const titleMapping: Record<string, string> = {
  houses: 'すべてのハウス',
  questions: 'すべての質問',
  questionDetail: 'ハウスの名前の設定',
  organization: TITLE_WEB ?? '',
  organizationDetail: '組織の名前',
  instructions: 'すべての指導',
  literatures: 'すべての文献',
  account: 'アカウント',
  notification: '通知',
  business: '事業計画の名前',
  organizationHouse: 'グリーンハウスA',
  houseDetail: 'ハウスの名前の情報',
  settingOrganization: '組織の名前の情報',
  instructionsDetail: 'すべての指導',
  literaturesDetail: 'すべての文献',
  organizationQuestion: `${TITLE_WEB}の質問`,
  organizationDocument: `${TITLE_WEB}の文献`,
  organizationFeedback: `${TITLE_WEB}の指導`,
  listHouse: `${TITLE_WEB}のハウス`,
};

const listShowMore = [
  EPath.organization,
  EPath.organizationHouseList,
  EPath.organizationHouse,
  EPath.housesDetailAll,
  EPath.organizationHouseBusiness,
];

export const listShowTitleHouse = [
  EPath.organizationHouseList,
  EPath.housesDetailAll,
  EPath.editHousesDetailAll,
  EPath.organizationHouse,
  EPath.settingHousesDetailAll,
  EPath.houseDetail,
  EPath.houseDetailListHouse,
];
export const listShowTitleQuestion = [EPath.organizationQuestion, EPath.questionDetailAll];
export const listShowTitleFeedback = [EPath.organizationFeedback, EPath.feedbackDetailAll];
export const listShowTitleDocument = [EPath.organizationDocument, EPath.documentDetailAll];

export const checkShowMore = (path: string) => {
  return !!listShowMore.find((ePath) => comparePath(path, ePath));
};

export const checkShowByPath = (path: string, listShow: Array<string>) => {
  return !!listShow.find((ePath) => comparePath(path, ePath));
};
