import { DefaultCellTypes, CellStyle, NumberCell, TextCell } from '@silevis/reactgrid';
import { FlagCell } from './IconCellTemplate';

export const emptyTextCell: TextCell = { type: 'text', text: '' };

const numberFormat = new Intl.NumberFormat('ja-JP', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const textCell = (text: string, className = '', style?: CellStyle): TextCell => ({
  type: 'text',
  text,
  className,
  style: {
    ...style,
    border: {
      left: { color: 'rgb(var(--color-outline-variant))' },
      top: { color: 'rgb(var(--color-outline-variant))' },
      right: { color: 'rgb(var(--color-outline-variant))' },
      bottom: { color: 'rgb(var(--color-outline-variant))' },
    },
  },
});

export const iconCell = (text: string, className = '', variant?: string): FlagCell => ({
  type: 'flag',
  text,
  className,
  variant,
});

export const textCellNoSideBorder = (text: string, className = '', style?: CellStyle): TextCell => ({
  type: 'text',
  text,
  className,
  style: {
    ...style,
    border: {
      ...style?.border,
      bottom: {
        width: '1px',
        color: 'rgb(var(--color-outline-variant))',
        style: 'solid',
      },
      left: {
        style: 'none',
      },
      top: {
        width: '1px',
        color: 'rgb(var(--color-outline-variant))',
        style: 'solid',
      },
    },
  },
});

export const numberCell = (value: number, className = '', style?: CellStyle): NumberCell => ({
  type: 'number',
  value,
  className,
  style,
  format: numberFormat,
});

export const nonEditable = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  nonEditable: true,
});

export const showZero = (cell: NumberCell): NumberCell => ({
  ...cell,
  nanToZero: true,
  hideZero: false,
});

export function monthHeaderCell(month: string, additionalClassNames = ''): DefaultCellTypes {
  return nonEditable(
    textCell(month, `text-lg font-bold ${additionalClassNames}`, {
      background: '#ffffff',
      color: 'rgb(var(--color-on-surface-variant))',
      border: {
        left: { color: 'rgb(var(--color-outline-variant))' },
        top: { color: 'rgb(var(--color-outline-variant))' },
        right: { color: 'rgb(var(--color-outline-variant))' },
        bottom: { color: 'rgb(var(--color-outline-variant))' },
      },
    }),
  );
}

export function headerCell(title: string, additionalClassNames = ''): DefaultCellTypes {
  return nonEditable(
    textCell(title, `text-lg font-bold ${additionalClassNames}`, {
      background: '#ffffff',
      color: 'rgb(var(--color-on-surface))',
      border: {
        left: { color: 'rgb(var(--color-outline-variant))' },
        top: { color: 'rgb(var(--color-outline-variant))' },
        right: { color: 'rgb(var(--color-outline-variant))' },
        bottom: { color: 'rgb(var(--color-outline-variant))' },
      },
    }),
  );
}
