import { IconArrowDropUp } from '@/components/icon/IconArrowDropUp/IconArrowDropUp';
import FilterBlock from '@/components/molecules/FilterBlock';
import ListAllHouseComponent from '@/components/molecules/ListAllHouseComponent/ListAllHouseComponent';
import { RootState } from '@/stores';
import { getHousesAsync, getOrganizationFilterAsync, getUserFilterAsync, setKeyword } from '@/stores/houses';
import { ITypeFilter } from '@/types/question';
import { CONSTANTS, DEFAULT_PAGE_SIZE, QUANTITY_TYPE_FILTER } from '@/utils/constants/consts';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitleHeader } from '@/stores/common';
import { titleMapping } from '@/layout/header/header.Config';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';

import { IParamsFilter } from '@/types/common';
import LoadMore from '@/components/atoms/LoadMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilterHouses } from '@/types/houses';

const dataInit: ITypeFilter[] = [];

Array(QUANTITY_TYPE_FILTER.HOUSE)
  .fill('')
  .forEach((value, index) => {
    dataInit.push({
      id: index,
      type: '',
      operator: '',
      value: [],
    });
  });

const HouseComponent = () => {
  const tableHeading = ['ハウス名', '事業計画更新日', '面積(a)', '栽培株数(株)', '栽植密度(株/10a)', ''];
  const [dataFilter, setDataFilter] = useState<ITypeFilter[]>(dataInit);

  const dispatch = useDispatch();
  const { houses, keyword, total, page } = useSelector((state: RootState) => state.houses);
  const [sortDate, setSortDate] = useState(CONSTANTS.SORT_DATE_BUSINESS_PLAN_DESC);
  const { dataOption } = useSelector((state: RootState) => state.houses);
  const [dataFilterSelected, setDataFilterSelected] = useState<FilterHouses>({});
  const [hasMore, setHasMore] = useState<boolean>(true);

  const handleSortDateBusiness = () => {
    setSortDate(!sortDate);
  };

  const rotateData = {
    normal: 0,
    rotate: 180,
  };

  useEffect(() => {
    dispatch(
      getUserFilterAsync({
        page: 0,
        limit: 0,
      }),
    );
    dispatch(
      getOrganizationFilterAsync({
        page: 0,
        limit: 0,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      getHousesAsync({
        ...dataFilterSelected,
        page: 1,
        limit: DEFAULT_PAGE_SIZE,
        businessPlan: sortDate ? 'desc' : 'asc',
        keyword,
      }),
    );
    dispatch(setTitleHeader(titleMapping.houses));
  }, [sortDate, keyword]);

  const handleSubmitFilter = (searchKey?: string, clearFilter?: boolean) => {
    if (searchKey || searchKey === '') {
      dispatch(setKeyword(searchKey));
    }
    const dataFilterApi: IParamsFilter = {};
    if (!clearFilter) {
      dataFilter.forEach((item) => {
        if (item.type) {
          dataFilterApi[item.type] = [
            {
              operation: item.operator,
              value: item.value,
            },
          ];
        }
      });
    }
    const data = {
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      businessPlan: sortDate ? 'desc' : 'asc',
      keyword: searchKey ?? keyword,
      ...dataFilterApi,
    };

    if (dataFilterApi.manager) {
      const listManagerSelected = dataFilterApi?.manager[0].value;
      const filterManager = dataOption.manager.data
        .filter((item) => listManagerSelected.includes(item.id))
        .map((item) => item.title);
      const formData = {
        ...data,
        manager: [{ ...dataFilterApi.manager[0], value: filterManager }],
      };
      setDataFilterSelected(formData);
      dispatch(getHousesAsync(formData));
    } else {
      setDataFilterSelected(data);
      dispatch(getHousesAsync(data));
    }
  };

  const fetchMoreData = () => {
    if (houses.length >= (total ?? 0)) {
      setHasMore(false);
      return;
    }

    dispatch(
      getHousesAsync({
        ...dataFilterSelected,
        page: (page ?? 1) + 1,
        limit: DEFAULT_PAGE_SIZE,
        businessPlan: sortDate ? 'desc' : 'asc',
        keyword,
      }),
    );
  };

  useEffect(() => {
    setHasMore(houses.length < (total ?? 0));
  }, [total]);

  return (
    <div className="container_HouseComponent bg-on-error">
      <div className="absolute left-4 top-[-58px] w-full rounded-2xl lg:static lg:w-auto lg:px-8 lg:pt-4">
        <div className="relative z-10 flex w-[calc(100%-16px)] py-1 md:mb-4 md:py-0 lg:w-auto lg:justify-between">
          <FilterBlock
            data={dataFilter}
            setData={setDataFilter}
            handleSubmitFilter={handleSubmitFilter}
            dataOption={dataOption}
          />
        </div>
      </div>
      <div className="overflow-x-auto lg:overflow-hidden">
        <div className="flex h-[calc(100vh-200px)] min-w-[800px] flex-1 flex-col pt-[16px] md:h-[calc(100vh-208px)]">
          {houses?.length > 0 ? (
            <div className="houseComponentListColumn h-[28px] 2xl:h-10">
              {tableHeading?.map((e, i) => (
                <div
                  key={nanoid(5)}
                  className={`${
                    i === 0 ? 'justify-start pl-[80px]' : 'justify-center pl-2'
                  } 2xl:text-text-[11px] flex items-center whitespace-nowrap font-noto text-[11px] text-on-surface`}
                >
                  {i === 1 ? (
                    <React.Fragment>
                      {e}
                      <div className="ml-[8px] h-[18px] w-[18px] rounded-full bg-primary lg:ml-[24px] lg:h-[24px] lg:w-[24px]">
                        <span
                          className="flex justify-center pt-[1px] lg:pt-[4px]"
                          onClick={handleSortDateBusiness}
                          onKeyDown={() => {}}
                        >
                          <IconArrowDropUp
                            className={`${
                              sortDate ? 'before-rotate-' + rotateData.normal : 'rotate' + rotateData.rotate
                            }`}
                          />
                        </span>
                      </div>
                    </React.Fragment>
                  ) : (
                    e
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="w-[100vw] text-center text-lg text-on-surface-variant md:w-[calc(100vw-240px)]">
              {MESSAGE_NO_DATA}
            </div>
          )}
          <div className="relative flex-1">
            <InfiniteScroll
              dataLength={houses.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<LoadMore />}
              height={'calc(100vh - 250px)'}
            >
              <ul>
                {houses.map((houses) => (
                  <ListAllHouseComponent dataFilter={dataFilterSelected} key={nanoid()} data={houses} />
                ))}
              </ul>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseComponent;
