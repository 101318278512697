import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { IComment } from '@/types/question';
import CardComment from '@/components/molecules/CardComment';
import IconPlus from '@/components/icon/IconPlus';
import { useDispatch, useSelector } from 'react-redux';
import { commentQuestion } from '@/stores/question';
import { RootState } from '@/stores';
import FilesComment from '@/components/molecules/FilesComment';
import { replaceListFilesComment, resetListFilesComment, uploadFileComment } from '@/stores/common';
import AddImageDialog from '@/components/atoms/AddImageDialog';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { FileRes } from '@/types/common';

type Props = {
  listComment: IComment[];
  questionId: number | null;
};

const Comments = (props: Props) => {
  const { listComment, questionId } = props;
  const dispatch = useDispatch();
  const [content, setContent] = useState<string>('');
  const { user } = useSelector((state: RootState) => state.auth);
  const { listFilesComment } = useSelector((state: RootState) => state.common);
  const [isShow, setIsShow] = useState(false);
  const [listFileImage, setListFileImage] = useState<string[]>([]);
  const [listFile, setListFile] = useState<FileRes[]>([]);
  const sendComment = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      if (questionId && (content || listFilesComment.length > 0)) {
        dispatch(
          commentQuestion({
            questionId: questionId,
            name: user?.name || '',
            content: content,
            image: listFilesComment.map((item) => item.path),
          }),
        );
        setContent('');
        dispatch(resetListFilesComment());
      }
    }
  };

  const uploadFile = (filesImage: File[], files: File[], imagesPath: string[], filesInit: FileRes[]) => {
    setIsShow(false);
    const fileChoose = [...imagesPath, ...filesInit.map((item) => item.path)];
    const filesUpdate = listFilesComment.filter((item) => fileChoose.includes(item.path));
    dispatch(replaceListFilesComment([...filesUpdate]));
    dispatch(uploadFileComment([...filesImage, ...files]));
  };

  const refFile = useRef<HTMLDivElement>(null);

  useOutsideClick(refFile, () => {
    setIsShow(false);
  });

  const handleToggleImageDialog = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    setListFileImage(
      listFilesComment.filter((file) => file.path?.split('/')[0] === 'images').map((image) => image.path),
    );
    setListFile(listFilesComment.filter((item) => item?.path?.split('/')[0] === 'files'));
  }, [listFilesComment]);

  return (
    <>
      <div className="rounded-2xl bg-surface-at-1">
        <div className="p-4 pb-0 text-sm">
          <div className="mb-4 flex gap-2">
            <span>コメント</span>
            <span>{listComment.length}件</span>
          </div>
          <div className="flex flex-col gap-6">
            {listComment.map((comment) => (
              <CardComment key={comment.id} comment={comment} />
            ))}
          </div>
        </div>
        <FilesComment />
        <div className="flex h-[72px] w-full items-center gap-2 px-4 py-3">
          <div className="mx-4" onClick={handleToggleImageDialog} onKeyDown={() => {}}>
            <IconPlus />
          </div>
          <div className="h-12 grow">
            <input
              className="h-full w-full rounded-3xl border border-outline px-4 py-2 text-base focus:outline-none"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onKeyDown={(e) => sendComment(e)}
            />
          </div>
        </div>
      </div>
      {isShow && (
        <div ref={refFile} className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
          <AddImageDialog
            setIsShow={setIsShow}
            uploadFile={uploadFile}
            filesImageRes={listFileImage}
            filesRes={listFile}
          />
        </div>
      )}
    </>
  );
};

export default Comments;
