import IconArrowBack from '@/components/icon/IconBackArrow';
import IconModeEdit from '@/components/icon/IconModeEdit';
import IconMore from '@/components/icon/IconMore/IconMore';
import cn from '@/utils/general';
import React from 'react';
import { Link } from 'react-router-dom';
import Heading from '@/components/atoms/Heading';
type EditorProps = {
  isAdmin?: boolean;
  title: string;
  ePathBack: string;
};
const PageHeader = ({ isAdmin, title, ePathBack }: EditorProps) => {
  return (
    <div className="flex w-full justify-between">
      <div className="flex w-full items-center gap-2">
        <button className="m-1 p-2">
          <Link to={ePathBack}>
            <IconArrowBack className="w-6 text-on-surface-variant" />
          </Link>
        </button>
        <div className="w-[90%]">
          <Heading level={2} text={title} className="headline md:md max-w-screen truncate text-lg" />
        </div>
      </div>
      <div className="flex flex-row">
        <button className={cn('m-1 hidden p-2', isAdmin ? 'flex' : null)}>
          <IconModeEdit className="w-6 text-on-surface-variant" />
        </button>
        <button className={cn('m-1 hidden p-2', isAdmin ? 'flex' : null)}>
          <IconMore />
        </button>
      </div>
    </div>
  );
};

export default PageHeader;
