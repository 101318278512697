import React, { CSSProperties } from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

function IconRedo(props: Props) {
  const { className, onClick, disabled } = props;
  const svgStyle: CSSProperties = {
    pointerEvents: disabled ? 'none' : 'auto',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      onClick={onClick}
      className={className}
      style={svgStyle}
    >
      <path
        d="M18.63 11.1C16.78 9.49 14.38 8.5 11.73 8.5C7.08002 8.5 3.15002 11.53 1.77002 15.72L4.13002 16.5C5.18002 13.31 8.18002 11 11.73 11C13.68 11 15.46 11.72 16.85 12.88L13.23 16.5H22.23V7.5L18.63 11.1Z"
        fill="#615F50"
      />
    </svg>
  );
}

export default IconRedo;
