import CommonModal from '@/components/atoms/Modal/CommonModal';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import DefaultText from '@/components/atoms/Text/DefaultText';
import IconColonization from '@/components/icon/IconChart/IconColonization';
import IconHarvest from '@/components/icon/IconChart/IconHarvest';
import IconSowing from '@/components/icon/IconChart/IconSowing';
import ModalAreaManagement from '@/components/molecules/ModalComponent/ModalAreaManagement';
import React, { useEffect, useRef, useState } from 'react';
import Select from '../../atoms/Select';
import Chart from '../../organisms/Chart';
import '../../styles/common/index.sass';
import { useService } from './service';
import s from './style.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { useParams } from 'react-router-dom';
import { getDataChart, getScheduleCommentBusiness } from '@/stores/business/services';
import CommentsBusiness from '@/components/molecules/CommentsBusiness';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { setCharts } from '@/stores/chart/effects';
import { setDataChart } from '@/stores/business/slice';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import { reCalculatorApi } from '@/stores/chart/services';
import IconZoomOut from '@/components/icon/IconZoomOut';
import IconZoomIn from '@/components/icon/IconZoomIn';

const scaleValues = [
  {
    label: '年/月',
    value: 'month',
  },
  {
    label: '月/日',
    value: 'day',
  },
];

const groupByValues = [
  {
    label: 'エリア',
    value: 'true',
  },
  {
    label: 'なし',
    value: 'false',
  },
];

function Main() {
  const { scaleRef, scale, handleScaleChange, areaGroupingRef, isAreaGrouping, handleAreaGroupingChange } =
    useService();
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const refComment = useRef<HTMLDivElement>(null);
  const [showAreaManagement, setShowAreaManagement] = useState(false);
  const [zoom, setZoom] = useState<boolean>(false);
  const [confirmReflect, setConfirmReflect] = useState(false);
  const { scheduleComments, sheetActive, dataChart, countCommentChart } = useSelector(
    (state: RootState) => state.business,
  );
  const { listAreas } = useSelector((state: RootState) => state.chart);

  const handleAreaEditClick = () => {
    setShowAreaManagement(true);
  };

  const handleCloseModal = () => {
    setShowAreaManagement(false);
  };

  const [showComment, setshowComment] = useState(false);

  const showConfirmReflect = () => {
    setConfirmReflect(true);
  };

  const handleCloseConfirmReflect = () => {
    setConfirmReflect(false);
  };

  const handleConfirmReflect = () => {
    if (businessId) {
      dispatch(reCalculatorApi({ businessId: +businessId, sheetId: sheetActive, callback: handleCloseConfirmReflect }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setCharts({}));
      dispatch(setDataChart([]));
    };
  }, []);

  useEffect(() => {
    if (businessId && !isNaN(+businessId) && sheetActive) {
      dispatch(getScheduleCommentBusiness({ id: +businessId, idSheet: sheetActive }));
    }
  }, [businessId, sheetActive]);

  useEffect(() => {
    if (businessId && !isNaN(+businessId) && sheetActive) {
      dispatch(getDataChart({ id: +businessId, idSheet: sheetActive }));
    }
  }, [businessId, sheetActive]);

  useOutsideClick(refComment, () => setshowComment(false));

  const handleZoomIn = () => {
    setZoom(true);
  };

  const handleZoomOut = () => {
    setZoom(false);
  };

  return (
    <>
      <div className={`${s.root} ${zoom && s.zoom}`}>
        <div className="flex xl:justify-between">
          <div className=""></div>
          <div className="flex flex-wrap gap-4 p-4 lg:flex-nowrap">
            <div className="flex max-w-[296px] gap-[16px] rounded-lg border border-outline-variant px-[16px] py-[8px]">
              <DefaultText
                variant="text-status"
                className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
              >
                凡例
              </DefaultText>
              <div className="flex gap-1">
                <IconSowing />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  播種
                </DefaultText>
              </div>
              <div className="flex gap-1">
                <IconColonization />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  定植
                </DefaultText>
              </div>
              <div className="flex gap-1">
                <IconHarvest />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  収穫
                </DefaultText>
              </div>
            </div>
            <div className="flex gap-2">
              <DefaultText
                variant="text-tableBody"
                className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
              >
                GroupBy
              </DefaultText>
              <Select
                className="!min-w-[100px] lg:!min-w-[160px]"
                data={groupByValues}
                ref={areaGroupingRef}
                onChange={handleAreaGroupingChange}
              />
            </div>
            <div className="flex gap-2">
              <DefaultText
                variant="text-tableBody"
                className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
              >
                タイムスケール
              </DefaultText>
              <Select
                className="!min-w-[100px] lg:!min-w-[160px]"
                data={scaleValues}
                ref={scaleRef}
                onChange={handleScaleChange}
              />
            </div>
            <div className="relative flex gap-2">
              <PrimaryButton
                text={`コメント(${countCommentChart})`}
                variant="outlined"
                onClick={() => setshowComment((prev) => !prev)}
              />
              <div
                className="absolute left-0 top-[60px] z-[99] w-[calc(100vw-90px)] sm:left-0 lg:left-[auto] lg:right-0 lg:w-[360px]"
                ref={refComment}
              >
                {showComment && (
                  <CommentsBusiness
                    listComment={scheduleComments}
                    businessId={businessId ? +businessId : null}
                    idSheet={sheetActive ?? 0}
                    isShedule
                  />
                )}
              </div>
              {zoom ? (
                <div
                  className="flex items-center rounded-full border border-on-surface-variant p-3"
                  onClick={handleZoomOut}
                  onKeyDown={() => {}}
                >
                  <IconZoomOut />
                </div>
              ) : (
                <div
                  className="flex items-center rounded-full border border-on-surface-variant p-3"
                  onClick={handleZoomIn}
                  onKeyDown={() => {}}
                >
                  <IconZoomIn />
                </div>
              )}
            </div>
          </div>
        </div>
        {dataChart.length > 0 ? (
          <>
            <Chart scaleUnit={scale} grouping={isAreaGrouping === 'true'} isAreaGrouping={isAreaGrouping} />
            <div className="pb-2 pt-1">
              {isAreaGrouping === 'true' ? (
                <>
                  <div>
                    <PrimaryButton text="エリア管理" variant="outlined" onClick={handleAreaEditClick} />
                  </div>
                  <div className="mt-5">
                    <PrimaryButton text="収益計画に反映" variant="outlined" onClick={showConfirmReflect} />
                  </div>
                </>
              ) : (
                <PrimaryButton text="収益計画に反映" variant="outlined" onClick={showConfirmReflect} />
              )}
            </div>
          </>
        ) : (
          <div className="w-[100vw] text-center text-lg text-on-surface-variant md:w-[calc(100vw-240px)]">
            {MESSAGE_NO_DATA}
          </div>
        )}
      </div>
      <CommonModal
        open={showAreaManagement}
        title={'エリア管理'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100vw-64px)] !max-w-[640px]'}
        buttonHidden={true}
      >
        <ModalAreaManagement handleClose={handleCloseModal} listAreas={listAreas} />
      </CommonModal>
      <CommonModal
        open={confirmReflect}
        title={'収益計画に反映'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseConfirmReflect}
        handleClick={handleConfirmReflect}
        boxClassName={'!w-[calc(100vw-64px)] !max-w-[480px]'}
      >
        <div className="mt-4">
          栽培計画で収穫可能な売上数を損益計画に反映します。すでにある損益計画の数値が上書きされます。この操作は元に戻せません。
        </div>
      </CommonModal>
    </>
  );
}

export default Main;
