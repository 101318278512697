import cn from '@/utils/general';
import React from 'react';
import BodyText from '../AText/BodyText';
import { defaultFormat } from '@/utils/formatTime';
import PublicChipDropDown from '@/components/molecules/PublicChipDropDown';
import { IUser } from '@/types/auth';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';
import Chip from '@/components/atoms/Chips';

type AutharProps = {
  size?: 'medium' | 'large';
  data?: IUser;
  typeShare?: string;
  setTypeShare?: (value: string) => void;
  postedDate?: string;
  isShare?: boolean;
  isUpdate?: boolean;
  createBy?: IUser;
  showDetailUser?: boolean;
  commentQuantity?: number;
};

const Authar = (props: AutharProps) => {
  const {
    size = 'medium',
    typeShare,
    setTypeShare,
    postedDate,
    isShare = true,
    isUpdate,
    createBy,
    showDetailUser = true,
    commentQuantity,
  } = props;
  let avatarCn;
  if (size === 'large') {
    avatarCn = 'w-16 h-16';
  } else {
    avatarCn = 'w-10 h-10';
  }

  return (
    <div className={cn(`relative flex flex-wrap gap-3 ${commentQuantity === undefined ? '' : 'flex-col md:flex-row'}`)}>
      <div className="flex gap-2">
        <div className={cn('group flex-none overflow-hidden rounded-full border border-outline', avatarCn)}>
          <DisplayAvatar
            path={createBy?.file?.length ? createBy?.file[0]?.path : ''}
            nameUser={createBy?.name ?? ''}
            className="h-10 w-10 leading-10"
          />

          {showDetailUser && (
            <div className="hover:bg-red z-[999] hidden group-hover:block">
              <div className="w-[calc(100% + 64px)] absolute left-4 top-[40px] z-[999] w-[360px] rounded-2xl bg-gray-footer p-6 md:top-full">
                <div className="flex items-center gap-4">
                  <div className={cn('overflow-hidden rounded-full border border-outline', avatarCn)}>
                    <DisplayAvatar
                      path={createBy?.file?.length ? createBy?.file[0]?.path : ''}
                      nameUser={createBy?.name ?? ''}
                      className="h-10 w-10 leading-10"
                    />
                  </div>
                  <span className="flex flex-col">
                    <BodyText tag="span" size="medium" className="font-medium text-black">
                      {createBy?.name ?? ''}
                    </BodyText>
                    <span className="text-xs font-medium leading-3 text-on-surface-variant">
                      {postedDate && defaultFormat(postedDate)}
                    </span>
                  </span>
                </div>
                <div className="mt-4 text-base">{createBy?.describe}</div>
              </div>
            </div>
          )}
        </div>
        <span className="my-auto flex flex-col">
          <BodyText tag="span" size="medium" className="truncate-text !line-clamp-3 font-medium text-black">
            {createBy?.name ?? ''}
          </BodyText>
          <span className="text-xs font-medium leading-3 text-on-surface-variant">
            {postedDate && defaultFormat(postedDate)}
          </span>
        </span>
      </div>
      <div className="flex gap-2">
        {isShare && <PublicChipDropDown typeShare={typeShare ?? ''} setTypeShare={setTypeShare} isUpdate={isUpdate} />}
        {commentQuantity !== undefined && (
          <div className="my-auto">
            <Chip comment={commentQuantity} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Authar;
