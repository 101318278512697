import React from 'react';

type TitleProps = {
  className?: string;
  text?: string;
};
function Title(props: TitleProps) {
  const { className, text } = props;
  return <div className={className}>{text}</div>;
}

export default Title;
