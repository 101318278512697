const IconExplain = () => {
  return (
    <>
      <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.666992 0.666016L4.00033 3.99935L7.33366 0.666016H0.666992Z" fill="#615F50" />
      </svg>
    </>
  );
};

export default IconExplain;
