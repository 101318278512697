import FilterBlock from '@/components/molecules/FilterBlock';
import Card from '@/components/molecules/Card';
import { nanoid } from 'nanoid';
import { TDocument, TQuestion } from '@/api/types/questionTypes';
import { TFeedback } from '@/stores/feedback/model';
import { IDataFilter, IFilter, ITypeFilter } from '@/types/question';
import { FileRes, IPagination } from '@/types/common';
import { checkRoleAdd, replacePathParams } from '@/utils/helpers';
import BaseButton from '@/components/atoms/Button/Button';
import { Link, useParams } from 'react-router-dom';
import IconPlus from '@/components/icon/IconPlus';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { REGEX } from '@/utils/constants/regex';
import { isArray } from '@craco/craco/dist/lib/utils';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadMore from '@/components/atoms/LoadMore';
import IconEdit from '@/components/icon/IconEdit';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores';

type QuestionGuidanceProps = {
  contents: TQuestion[] | TFeedback[] | TDocument[];
  dataFilter: ITypeFilter[];
  setDataFilter: (value: ITypeFilter[]) => void;
  dataOption: IFilter<IPagination, IDataFilter>;
  handleSubmitFilter: (value?: string, clearFilter?: boolean) => void;
  handleLinkDetail?: (id: number) => void;
  isCreate?: boolean;
  ePath: string;
  fetchMoreData: () => void;
  hasMore?: boolean;
};
const Guidance = ({
  contents,
  handleLinkDetail,
  dataFilter,
  setDataFilter,
  handleSubmitFilter,
  dataOption,
  isCreate = false,
  ePath,
  fetchMoreData,
  hasMore,
}: QuestionGuidanceProps) => {
  const { organizationId } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const { organizationInfo } = useSelector((state: RootState) => state.organization);
  const renderList = (contents: TQuestion[] | TFeedback[] | TDocument[]) => {
    const getImage = (image?: FileRes[]) => {
      if (image && isArray(image)) {
        const images = image
          ?.filter((file: FileRes) => REGEX.imageExtensionsRegex.test(file.name))
          .map((item: FileRes) => item.path);
        return images[0];
      }
      return undefined;
    };

    return contents.map((content) => (
      <Card
        key={nanoid(5)}
        id={content.id}
        content={content.content}
        title={content.title}
        organizationName={content?.organization?.title ?? ''}
        image={getImage(content.image)}
        statusCd={content.statusCd}
        handleLinkDetail={handleLinkDetail}
        commentCount={content?.commentCount}
        tags={content.tagRelation}
        optionPublic={content.publish}
      />
    ));
  };

  const checkRole = useMemo(() => {
    return user?.id && organizationInfo?.members && isCreate ? checkRoleAdd(user?.id, organizationInfo) : false;
  }, [organizationInfo]);

  return (
    <div className="md:overflow-scroll-y m-auto h-full overflow-auto bg-surface-at-1 font-noto lg:p-4">
      <div className="rounded-2xl lg:bg-white lg:p-4">
        <div className="flex flex-wrap justify-between gap-2 bg-white px-4 py-1 sm:pb-5 md:mb-4 md:px-4 lg:py-0 lg:pb-0">
          <div className="relative flex flex-row gap-2">
            <FilterBlock
              data={dataFilter}
              setData={setDataFilter}
              handleSubmitFilter={handleSubmitFilter}
              dataOption={dataOption}
            />
          </div>
          {checkRole ? (
            <Link to={`${replacePathParams(ePath, { organizationId: organizationId ?? '' })}`} className="my-auto">
              <BaseButton variant="primary" type="submit" className="!border-0 md:!border-[1px] md:!border-bor-default">
                <DefaultText variant="length" className="hidden px-4 text-sm text-on-primary md:flex">
                  <IconPlus />
                  <span className="ml-2">新規作成</span>
                </DefaultText>
                <div
                  onClick={() => replacePathParams(ePath, { organizationId: organizationId ?? '' })}
                  onKeyDown={() => {}}
                  className="absolute bottom-4 right-8 z-10 inline-block cursor-pointer rounded-2xl bg-primary p-4 transition-all duration-200 ease-in hover:scale-[0.98] active:scale-[0.9] active:transition-all active:duration-200 active:ease-in md:hidden"
                >
                  <IconEdit color={'#1F1C00'} />
                </div>
              </BaseButton>
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div className="h-[calc(100vh-115px)] md:h-[calc(100vh-196px)]">
          <div id="scrollableDiv" className="h-full overflow-y-scroll">
            <InfiniteScroll
              dataLength={contents.length}
              next={fetchMoreData}
              hasMore={hasMore ?? false}
              loader={<LoadMore />}
              scrollableTarget="scrollableDiv"
              height={'calc(100vh - 250px)'}
            >
              <div className="flex flex-wrap gap-6 sm:px-2 sm:pb-4 sm:pt-8 md:px-4 md:py-6 lg:px-8 lg:py-4 xl:px-16 2xl:gap-10">
                {renderList(contents)}
              </div>
              {!contents.length && (
                <div className="text-center text-base text-on-surface-variant">{MESSAGE_NO_DATA}</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guidance;
