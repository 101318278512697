import CommonModal from '@/components/atoms/Modal/CommonModal';
import PublicChip from '@/components/atoms/PublicChip';
import TagsClose from '@/components/atoms/Tags/TagsClose';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { useState } from 'react';
import ModalDelete from '../ModalComponent/ModalDelete';
import { TQuestion } from '@/api/types/questionTypes';
import { defaultFormat } from '@/utils/formatTime';
import { Link, useParams } from 'react-router-dom';
import { deleteQuestionAsync } from '@/stores/question';
import { useDispatch } from 'react-redux';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { shareType } from '@/types/enum';
import { nanoid } from 'nanoid';

interface ListItemProps {
  question: TQuestion;
}

function ListQuestionComponent(props: ListItemProps) {
  const { question } = props;
  const { organizationId } = useParams();
  const dispatchListQuestion = useDispatch();
  const [showModalDeleteQuestion, setShowModalDeleteQuestion] = useState(false);
  const handleCloseModalQuestion = () => {
    setShowModalDeleteQuestion(false);
  };

  const handleDeleteQuestion = () => {
    handleCloseModalQuestion();
    dispatchListQuestion(deleteQuestionAsync({ questionId: question.id, organizationId }));
  };

  return (
    <>
      <Link
        to={replacePathParams(EPath.questionDetail, { organizationId: organizationId ?? '', id: question.id ?? '' })}
      >
        <li
          key={nanoid(5)}
          className="questionComponentListColumn flex min-h-[64px] items-center gap-1 border-b border-outline-variant"
        >
          <div className="grid gap-[4px] whitespace-pre-wrap py-2 pl-[16px]">
            <DefaultText
              variant="text-status"
              className="text-primary-dark truncate-text my-auto break-all text-[11px] text-on-surface-variant 2xl:text-[11px]"
            >
              {question.organization?.title}
            </DefaultText>
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark my-auto break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {question?.title}
            </DefaultText>
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark my-auto break-all text-[14px] text-on-surface-variant 2xl:text-[14px]"
            >
              {question.commentsTop?.title}
            </DefaultText>
            <div className="flex gap-[4px]">
              {question.tagRelation.map((tag) => (
                <TagsClose text={tag?.title} key={tag?.id} />
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <div
              className={`flex h-[33px] w-auto rounded-lg ${
                question.commentCount > 0 ? 'w-[81px] bg-tertiary-container' : 'bg-error-container'
              } border border-outline`}
            >
              <DefaultText variant="length" className={'px-[12px] py-[6px] text-[14px] text-on-surface'}>
                {question.commentCount > 0 ? '回答済み' : '未回答'}
              </DefaultText>
            </div>
          </div>
          <div className="flex justify-center">
            <PublicChip variant={question.publish === shareType.public ? 'public' : 'private'} />
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {question?.user?.name}
            </DefaultText>
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {defaultFormat(question.createdAt)}
            </DefaultText>
          </div>
        </li>
      </Link>

      <CommonModal
        open={showModalDeleteQuestion}
        title={'質問の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModalQuestion}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        handleClick={handleDeleteQuestion}
      >
        <ModalDelete contentDelete={'質問を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
}

export default ListQuestionComponent;
