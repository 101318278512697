import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import {
  IChangeOwner,
  IChangeRoleMember,
  IFilterOrganization,
  IFormCreateOrganization,
  IOrganization,
  IOrganizationInfo,
  IRemoveMember,
  IRestoreMember,
  IUserInfo,
} from '@/types/organization';
import { setIsLoading, setShowToast, setTitleHeader } from '@/stores/common';
import { TCallback } from '@/types/common';
import { messageNotification } from '@/utils/constants/messageNotification';

export interface CommonState {
  filter: IFilterOrganization;
  listOrganization: IOrganization[];
  titleOrganization: string;
  organizationInfo: IOrganizationInfo | null;
}

const initialState: CommonState = {
  filter: {
    page: 1,
    limit: 10,
    keyword: '',
    id: null,
  },
  listOrganization: [],
  titleOrganization: '',
  organizationInfo: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setListOrganization: (state, action) => {
      state.listOrganization = action.payload;
    },
    setOrganizationTitle: (state, action) => {
      state.titleOrganization = action.payload;
    },
    setOrganizationInfo: (state, action) => {
      state.organizationInfo = action.payload;
    },
  },
});

export const createOrganizationAsync = createAsyncThunk(
  'organization/createOrganization',
  async (data: IFormCreateOrganization, thunkAPI) => {
    await axiosInstance.post('organizations', data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(getOrganizationAsync({}));
  },
);

export const getOrganizationAsync = createAsyncThunk(
  'organization/getOrganization',
  async (dataFilter: IFilterOrganization, thunkAPI) => {
    const data = await axiosInstance.get(`organizations?page=${dataFilter.page ?? 0}&limit=${dataFilter.limit ?? 0}`);
    thunkAPI.dispatch(setListOrganization(data.data.items));
  },
);

export const getOrganizationMember = createAsyncThunk('organization/getOrganization', async (id: number, thunkAPI) => {
  const data = await axiosInstance.get(`organizations/${id}/members`);
  const { organization } = thunkAPI.getState() as { organization: CommonState };
  data &&
    thunkAPI.dispatch(
      setOrganizationInfo({
        ...organization.organizationInfo,
        ...data.data,
      }),
    );
});

export const updateOrganizationAsync = createAsyncThunk(
  'organization/updateOrganization',
  async (data: IOrganization, thunkAPI) => {
    const res = await axiosInstance.patch(`organizations/${data.id}`, data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setOrganizationTitle(res.data.title));
  },
);

export const getOrganizationDetailAsync = createAsyncThunk(
  'organization/getOrganizationDetail',
  async (id: number, thunkAPI) => {
    const res = await axiosInstance.get(`organizations/${id}`);
    thunkAPI.dispatch(setOrganizationTitle(res.data.title));
    thunkAPI.dispatch(setTitleHeader(res.data.title));
  },
);

export const changeOwnerOrganizationAsync = createAsyncThunk(
  'organization/changeOwner',
  async (data: IChangeOwner, thunkAPI) => {
    const body = {
      userId: data.userId,
    };
    await axiosInstance.patch(`organizations/${data.organizationId}/owner/change`, body);
    await thunkAPI.dispatch(getOrganizationMember(data.organizationId));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
  },
);

export const changeRoleMemberAsync = createAsyncThunk(
  'organization/changeRoleMember',
  async (data: IChangeRoleMember, thunkAPI) => {
    const body = {
      roleCd: data.roleCd,
    };
    await axiosInstance.patch(`organizations/${data.organizationId}/members/${data.memberId}`, body);
    await thunkAPI.dispatch(getOrganizationMember(data.organizationId));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
  },
);

export const inviteMemberAsync = createAsyncThunk(
  'loading/organization/inviteMember',
  async (data: IUserInfo & TCallback, thunkAPI) => {
    const body = {
      name: data.name,
      email: data.email,
      roleCd: data.roleCd,
    };
    await axiosInstance.post(`organizations/${data.organizationId}/members/invite`, body);
    await thunkAPI.dispatch(getOrganizationMember(data.organizationId));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.inviteSuccess, type: 'success' }));
    data.callback?.();
  },
);

export const removeMemberAsync = createAsyncThunk(
  'organization/inviteMember',
  async (data: IRemoveMember, thunkAPI) => {
    await axiosInstance.delete(`organizations/${data.organizationId}/members/${data.memberId}`);
    await thunkAPI.dispatch(getOrganizationMember(data.organizationId));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
  },
);

export const restoreMemberAsync = createAsyncThunk(
  'organization/restoreMember',
  async (data: IRestoreMember, thunkAPI) => {
    const body = {
      roleCd: data.roleCd,
    };
    await axiosInstance.post(`organizations/${data.organizationId}/members/${data.userId}/restore`, body);
    await thunkAPI.dispatch(getOrganizationMember(data.organizationId));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
  },
);

export const deleteOrganizationsAsync = createAsyncThunk(
  'organization/inviteMember',
  async (data: { id: number } & TCallback, thunkAPI) => {
    try {
      thunkAPI.dispatch(setIsLoading(true));
      await axiosInstance.delete(`organizations/${data.id}`).then(() => {
        thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
        thunkAPI.dispatch(getOrganizationAsync({}));
        thunkAPI.dispatch(setIsLoading(false));
        data.callback?.();
      });
    } catch (error) {
      console.error('Error delete organizations:', error);
      thunkAPI.dispatch(setIsLoading(false));
      throw error;
    }
  },
);

export const { setListOrganization, setOrganizationTitle, setOrganizationInfo } = organizationSlice.actions;
export default organizationSlice.reducer;
