import React from 'react';
import c from 'clsx';
import s from './style.module.sass';
import ItemBar from '../../atoms/ItemBar';

type Props = {
  className?: string;
  left: number;
  width: number;
  term: number;
  isEdit: boolean;
  handleDClick: () => void;
};

const Term: React.FC<Props> = (props) => {
  const { className, left, isEdit, width, term, handleDClick } = props;

  return (
    <div
      className={c(s.root, className)}
      style={{
        left: `${left}px`,
        zIndex: `${isEdit ? 2 : 0}`,
      }}
      onDoubleClick={handleDClick}
    >
      <ItemBar width={width} term={term} />
    </div>
  );
};

export default Term;
