import type { PayloadAction } from '@reduxjs/toolkit';
import type { Charts, EditType } from './model';
import { createSlice } from '@reduxjs/toolkit';
import { IArea } from './model';

type State = {
  charts: Charts;
  closeGroups: string[];
  listAreas: IArea[];
};
const initialState: State = {
  charts: {},
  closeGroups: [],
  listAreas: [],
};

const slice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    setCharts: (state, action: PayloadAction<Charts>) => {
      state.charts = action.payload;
    },
    moveChartDate: (_state, _action: PayloadAction<{ id: string; area: string; value: number }>) => {
      return;
    },
    renameChart: (state, action: PayloadAction<{ name: string; area: string; id: string }>) => {
      const area = action.payload.area;
      const i = state.charts[area].findIndex((c) => c.id === action.payload.id);
      state.charts[area][i].name = action.payload.name;
    },
    editChart: (
      state,
      action: PayloadAction<{
        area: string;
        id: string;
        type: EditType;
        date: string[];
      }>,
    ) => {
      const type = action.payload.type;
      const area = action.payload.area;
      const i = state.charts[area].findIndex((c) => c.id === action.payload.id);
      switch (type) {
        case 'sowing':
          state.charts[area][i].sowing = action.payload.date[0];
          break;
        case 'planting':
          state.charts[area][i].planting = action.payload.date[0];
          break;
        case 'harvest':
          state.charts[area][i].harvest.start = action.payload.date[0];
          state.charts[area][i].harvest.end = action.payload.date[1];
          break;
      }
    },
    openGroup: (state, action: PayloadAction<string>) => {
      state.closeGroups = state.closeGroups.filter((g) => g !== action.payload);
    },
    closeGroup: (state, action: PayloadAction<string>) => {
      state.closeGroups = [...state.closeGroups, action.payload];
    },
    setListAreas: (state, action: PayloadAction<IArea[]>) => {
      state.listAreas = action.payload;
    },
  },
});

const { actions, reducer } = slice;
export default slice;
export { actions, reducer };
