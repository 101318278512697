import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import BodyText from '../AText/BodyText';
import { nanoid } from 'nanoid';
import cn from '@/utils/general';

type InputDropDownProps = {
  data: { time: string }[];
  selected: { time: string };
  setSelected: React.Dispatch<
    React.SetStateAction<{
      time: string;
    }>
  >;
};

const InputDropDown = ({ data, selected, setSelected }: InputDropDownProps) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative mt-1 rounded border border-on-surface-variant md:w-[210px]">
        <Listbox.Button className="relative flex h-12 w-full cursor-pointer items-center justify-between rounded-lg border border-M3-outline bg-white py-1 pl-4 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <BodyText size="large" tag="span" className="font-normal">
            {selected.time}
          </BodyText>
          <span className="leading-12 pointer-events-none m-1 flex items-center p-2">
            <i className="icon-24px icon-arrow_drop_down h-6 w-6 bg-M3-on-surface-variant"></i>
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-elevation-M3-light-2 ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {data.map((type) => (
              <Listbox.Option
                key={nanoid(5)}
                className={({ active }) =>
                  `relative flex h-10 cursor-pointer select-none items-center pl-4 pr-6 ${
                    active ? 'bg-gray-background ' : ''
                  }`
                }
                value={type}
              >
                {({ selected }) => (
                  <>
                    <BodyText
                      size="large"
                      tag="span"
                      className={cn('flex truncate ', selected ? 'font-medium' : 'font-normal')}
                    >
                      {type.time}
                    </BodyText>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default InputDropDown;
