import { useState } from 'react';

interface ServiceState {
  displayFilter: boolean;
  displayFilterMobile: boolean;
}

type ServiceActions = {
  handleToggleFilterBlock: () => void;
  handleCloseFilterBlock: () => void;
  handleOpenFilterBlock: () => void;
  setDisplayFilterMobile: (value: boolean) => void;
};

export const useService = (): ServiceState & ServiceActions => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const [displayFilterMobile, setDisplayFilterMobile] = useState(false);
  const handleToggleFilterBlock = () => {
    setDisplayFilter(!displayFilter);
  };
  const handleCloseFilterBlock = () => {
    setDisplayFilter(false);
  };

  const handleOpenFilterBlock = () => {
    setDisplayFilter(true);
  };
  return {
    displayFilter,
    displayFilterMobile,
    setDisplayFilterMobile,
    handleToggleFilterBlock,
    handleCloseFilterBlock,
    handleOpenFilterBlock,
  };
};
