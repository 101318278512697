import React from 'react';
import LinkContent from 'linkify-react';

const Linkify: React.FC<{ children?: string }> = ({ children }) => {
  return (
    <LinkContent
      options={{
        target: '_blank',
        nl2br: true,
        className: 'text-teal-800',
      }}
    >
      {children ?? ''}
    </LinkContent>
  );
};

export default Linkify;
