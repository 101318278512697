import React, { ForwardRefRenderFunction } from 'react';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  className?: string;
  value: string;
  name?: string;
  setValue?: (name: string, value: string) => void;
};

const FieldDateCustom: ForwardRefRenderFunction<HTMLInputElement, Props> = (props) => {
  return (
    <div className={`relative ${props.className ?? ''}`}>
      <DatePicker
        className="h-12 w-full rounded border border-outline px-[10px]"
        value={props.value}
        onChange={(date) => props.name && props.setValue?.(props.name, moment(date).format('YYYY/MM/DD') ?? '')}
        dateFormat="YYYY/MM/DD"
      />
      <div className="pointer-events-none absolute right-[12px] top-[13px] bg-white">
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0 2.9 0 4V18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM3.5 11C3.5 9.62 4.62 8.5 6 8.5C7.38 8.5 8.5 9.62 8.5 11C8.5 12.38 7.38 13.5 6 13.5C4.62 13.5 3.5 12.38 3.5 11Z"
            fill="#615F50"
          />
        </svg>
      </div>
    </div>
  );
};

export default FieldDateCustom;
