import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { RootState } from '@/stores';
import { ICircleMultiple } from '@/stores/business/model';
import { updateProductSales } from '@/stores/business/services';
import { getValidateMsg, MESSAGE_VALIDATE_MAX, MESSAGE_VALIDATE_MIN } from '@/utils/constants/messageValidate';
import { handleOnChangeInputNumberFormat } from '@/utils/helpers';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInputRow from '../FormInputRow';
import FormEditWraper from './FormEditWraper';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';

const FormEdit1 = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICircleMultiple>();
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const { dataBusinessPlanResult, sheetActive } = useSelector((state: RootState) => state.business);

  useEffect(() => {
    if (dataBusinessPlanResult) {
      setValue(
        'transplantAreaQuantity',
        dataBusinessPlanResult.transplantAreaQuantity ? String(dataBusinessPlanResult.transplantAreaQuantity) : '',
      );
      setValue('weightFruit', dataBusinessPlanResult.weightFruit ? String(dataBusinessPlanResult.weightFruit) : '');
      setValue('stem', dataBusinessPlanResult.stem ? String(dataBusinessPlanResult.stem) : '');
      setValue(
        'fruitsQuantityStem',
        dataBusinessPlanResult.fruitsQuantityStem ? String(dataBusinessPlanResult.fruitsQuantityStem) : '',
      );
      setValue(
        'transplantSellingPrice',
        dataBusinessPlanResult.transplantSellingPrice ? String(dataBusinessPlanResult.transplantSellingPrice) : '',
      );
    }
  }, [dataBusinessPlanResult]);

  const onSubmit = async (data: ICircleMultiple) => {
    setIsDisable(true);
    const dataUpdate = {
      transplantAreaQuantity: +data.transplantAreaQuantity,
      weightFruit: +data.weightFruit,
      stem: +data.stem,
      fruitsQuantityStem: +data.fruitsQuantityStem,
      transplantSellingPrice: +data.transplantSellingPrice,
    };

    if (businessId && sheetActive)
      await dispatch(updateProductSales({ businessId: +businessId, sheetId: sheetActive, dataUpdate: dataUpdate }));
    setIsDisable(false);
  };

  return (
    <form
      className="w-[calc(100vw-64px)] max-w-[479px] rounded-2xl bg-gray-background p-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormEditWraper isDisable={isDisable}>
        <div>
          <FormInputRow
            labelLeft="株数"
            labelRight="株/1m2あたり"
            classNames="flex-col !items-start sm:flex-row sm:min-w-[410px]"
          >
            <Controller
              name="transplantAreaQuantity"
              control={control}
              defaultValue=""
              rules={{
                min: {
                  value: 4,
                  message: MESSAGE_VALIDATE_MIN(4),
                },
                max: {
                  value: 5,
                  message: MESSAGE_VALIDATE_MAX(5),
                },
                validate: (value) => {
                  return getValidateMsg(value, 0.5);
                },
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  step={0.5}
                  min={4}
                  max={5}
                  name="transplantAreaQuantity"
                  decimalScale={1}
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.transplantAreaQuantity?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip
            className="ml-24"
            errorMessage={errors?.transplantAreaQuantity?.message ?? ''}
            hasError={!!errors?.transplantAreaQuantity?.message}
          />
        </div>

        <div>
          <FormInputRow
            labelLeft="重さ"
            labelRight="g/1果あたり"
            classNames="flex-col !items-start sm:flex-row sm:min-w-[410px]"
          >
            <Controller
              name="weightFruit"
              control={control}
              defaultValue=""
              rules={{
                min: {
                  value: 10,
                  message: MESSAGE_VALIDATE_MIN(10),
                },
                max: {
                  value: 80,
                  message: MESSAGE_VALIDATE_MAX(80),
                },
                validate: (value) => {
                  return getValidateMsg(value, 5);
                },
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  min={10}
                  max={80}
                  step={5}
                  name="weightFruit"
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.weightFruit?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip
            className="ml-24"
            errorMessage={errors?.weightFruit?.message ?? ''}
            hasError={!!errors?.weightFruit?.message}
          />
        </div>

        <div>
          <FormInputRow
            labelLeft="段数"
            labelRight="段/1株あたり"
            classNames="flex-col !items-start sm:flex-row sm:min-w-[410px]"
          >
            <Controller
              name="stem"
              control={control}
              defaultValue=""
              rules={{
                min: {
                  value: 1,
                  message: MESSAGE_VALIDATE_MIN(1),
                },
                max: {
                  value: 5,
                  message: MESSAGE_VALIDATE_MAX(5),
                },
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  min={1}
                  max={5}
                  step={1}
                  name="stem"
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.stem?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip
            className="ml-24"
            errorMessage={errors?.stem?.message ?? ''}
            hasError={!!errors?.stem?.message}
          />
        </div>

        <div>
          <FormInputRow
            labelLeft="果実数"
            labelRight="個/1段あたり"
            classNames="flex-col !items-start sm:flex-row sm:min-w-[410px]"
          >
            <Controller
              name="fruitsQuantityStem"
              control={control}
              defaultValue=""
              rules={{
                min: {
                  value: 2,
                  message: MESSAGE_VALIDATE_MIN(2), // Custom error message for min
                },
                max: {
                  value: 20,
                  message: MESSAGE_VALIDATE_MAX(20), // Custom error message for max
                },
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  min={2}
                  max={20}
                  step={1}
                  name="fruitsQuantityStem"
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.fruitsQuantityStem?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip
            className="ml-24"
            errorMessage={errors?.fruitsQuantityStem?.message ?? ''}
            hasError={!!errors?.fruitsQuantityStem?.message}
          />
        </div>
        <div>
          <FormInputRow
            labelLeft="単価"
            labelRight="円/1kgあたり"
            classNames="flex-col !items-start sm:flex-row sm:min-w-[410px]"
          >
            <Controller
              name="transplantSellingPrice"
              control={control}
              defaultValue=""
              rules={{
                min: {
                  value: 300,
                  message: MESSAGE_VALIDATE_MIN(300),
                },
                max: {
                  value: 3000,
                  message: MESSAGE_VALIDATE_MAX(3000),
                },
                validate: (value) => {
                  return getValidateMsg(value, 10);
                },
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  min={300}
                  max={3000}
                  step={10}
                  name="transplantSellingPrice"
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.transplantSellingPrice?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip
            className="ml-24"
            errorMessage={errors?.transplantSellingPrice?.message ?? ''}
            hasError={!!errors?.transplantSellingPrice?.message}
          />
        </div>
      </FormEditWraper>
    </form>
  );
};

export default FormEdit1;
