import React, { useEffect, useRef } from 'react';

import c from 'clsx';
import s from './style.module.sass';
import ChartTable from '../../molecules/ChartTable/ChartTable';
import ChartNameArea from '../../molecules/ChartNameArea';
import { useService } from './service';
import ChartNameAreaNone from '@/components/molecules/ChartNameArea/ChartNameAreaNone';
import { useDispatch, useSelector } from 'react-redux';
import { getListAreas } from '@/stores/chart/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';

type Props = {
  className?: string;
  scaleUnit: 'day' | 'month';
  startAt?: string;
  grouping: boolean;
};

const Chart: React.FC<Props & { isAreaGrouping: string }> = (props) => {
  const { className, scaleUnit, grouping, isAreaGrouping } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const { charts, startAt, endAt, autoStartAt, autoEndAt } = useService(grouping);

  const areaRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (!areaRef.current || !chartRef.current) return;
      if (e.target === areaRef.current && !isDiv2Scrolling) {
        isDiv1Scrolling = true;
        chartRef.current.scrollTop = areaRef.current.scrollTop;
      } else if (e.target === chartRef.current && !isDiv1Scrolling) {
        isDiv2Scrolling = true;
        areaRef.current.scrollTop = chartRef.current.scrollTop;
      }

      isDiv1Scrolling = false;
      isDiv2Scrolling = false;
    };

    let isDiv1Scrolling = false;
    let isDiv2Scrolling = false;

    if (areaRef.current && chartRef.current) {
      areaRef.current.addEventListener('scroll', handleScroll);
      chartRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (areaRef.current && chartRef.current) {
        areaRef.current.removeEventListener('scroll', handleScroll);
        chartRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [areaRef.current, chartRef.current]);

  useEffect(() => {
    if (businessId && sheetActive) {
      dispatch(getListAreas({ businessId: +businessId, sheetId: sheetActive }));
    }
  }, []);

  return (
    <>
      <div className={c(s.root, className)}>
        {isAreaGrouping === 'true' ? (
          <ChartNameArea ref={areaRef} className={s.chartNameArea} data={charts} />
        ) : (
          <ChartNameAreaNone ref={areaRef} className={s.chartNameArea} data={charts} />
        )}
        <ChartTable
          ref={chartRef}
          className={s.chartTable}
          scaleUnit={scaleUnit}
          startAt={startAt ? startAt : autoStartAt ?? ''}
          endAt={endAt ? endAt : autoEndAt ?? ''}
          charts={charts}
          isAreaGrouping={isAreaGrouping}
          checkScroll={true}
        />
      </div>
    </>
  );
};

export default Chart;
