import { formatNumberWithoutDecimals } from '../helpers';
import { isDivisible } from '../number';

export const MESSAGE_REQUIRED_EMAIL = 'メールアドレスを入力してください';
export const MESSAGE_REQUIRED_PASSWORD = 'パスワードを入力してください';
export const MESSAGE_REQUIRED_NAME = '名前を入力してください';

export const MESSAGE_VALIDATE_EMAIL = 'メールアドレスが間違っています';
export const MESSAGE_VALIDATE_EMAIL_EXISTS = 'メールは既に存在します';
export const MESSAGE_VALIDATE_PASSWORD = 'パスワードが間違っています';
export const MAX_5_FILE = '選択できるファイルは最大 5 つまでです';
export const MAX_5_IMAGE = '写真は最大 5 枚まで選択できます';

export const MESSAGE_REQUIRED_ORGANIZATION_NAME = '組織名を入力してください';
export const MESSAGE_REQUIRED_DATE = '時間を入力してください';

export const MESSAGE_REQUIRED_HOUSE_NAME = 'ハウスの名前を入力してください';
export const MESSAGE_REQUIRED_DEFAULT = '必須項目です';
export const MESSAGE_REQUIRED_DIALOG = '入力してください。';
export const MESSAGE_NO_DATA = '該当データがありません。';

export const MESSAGE_VALIDATE_DAY = '正しく入力してください';

export const MESSAGE_VALIDATE_ADDRESS_HOUSE = '場所が間違っています';

export const MESSAGE_VALIDATE_MAX = (value: number) => {
  return `${formatNumberWithoutDecimals(value)}まで入力してください。`;
};

export const MESSAGE_VALIDATE_MIN = (value: number) => {
  return `${formatNumberWithoutDecimals(value)}から入力してください。`;
};

export const MESSAGE_ERROR_STEP = (step: number) => {
  return `刻みが${formatNumberWithoutDecimals(step)}で入力してください。`;
};

export const getValidateMsg = (num: string, step: number): string | undefined => {
  return isDivisible(num, step) ? undefined : MESSAGE_ERROR_STEP(step);
};
