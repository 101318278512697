import { AsyncAction } from '..';
import { Charts, EditType } from './model';
import { actions } from './slice';

export const setCharts =
  (charts: Charts): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(actions.setCharts(charts));
  };

export const renameChart =
  (props: { name: string; area: string; id: string }): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(actions.renameChart({ name: props.name, area: props.area, id: props.id }));
  };

export const editChart =
  (props: { area: string; id: string; type: EditType; date: string[] }): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(
      actions.editChart({
        area: props.area,
        id: props.id,
        type: props.type,
        date: props.date,
      }),
    );
  };

export const moveChartDate =
  (props: { id: string; area: string; value: number }): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(
      actions.moveChartDate({
        id: props.id,
        area: props.area,
        value: props.value,
      }),
    );
  };

export const openGroup =
  (props: { area: string }): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(actions.openGroup(props.area));
  };

export const closeGroup =
  (props: { area: string }): AsyncAction<void> =>
  async (dispatch) => {
    dispatch(actions.closeGroup(props.area));
  };
