import React, { ChangeEvent, useEffect } from 'react';

type TextAreaProps = {
  name?: string;
  className?: string;
  id?: string;
  placeholder?: string;
  rows?: number;
  value: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  btnRef?: React.RefObject<HTMLTextAreaElement>;
  readOnly?: boolean;
};
const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const { onChange, btnRef, id, name, placeholder, rows = 1, className, value, readOnly = false } = props;
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
    adjustTextareaHeight(event.target);
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    if (btnRef?.current) {
      btnRef.current.style.height = 'auto';
      btnRef.current.style.height = `${btnRef?.current.scrollHeight}px`;
    }
  }, [btnRef?.current?.scrollHeight]);

  return (
    <div className={className}>
      <textarea
        ref={btnRef}
        value={value}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={handleTextareaChange}
        style={{ resize: 'none', overflow: 'hidden', width: '100%' }}
        rows={rows}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TextArea;
