import { IconNavigateNext } from '@/components/icon/IconNavigateNext/IconNavigateNext';
import React from 'react';
import { Link } from 'react-router-dom';
import DefaultText from '../Text/DefaultText';
import { nanoid } from 'nanoid';

export interface TBreadcrumb {
  name: string;
  to: string;
}

interface Props {
  data: TBreadcrumb[];
  className?: string;
}

const Breadcrumb: React.FC<Props> = ({ data, className }: Props) => {
  return (
    <div className={`flex ${className}`}>
      {data.map((e, i) => (
        <React.Fragment key={nanoid(5)}>
          <Link to={e.to} className="!leading-[14px]" title={e.name}>
            <DefaultText
              variant="breadcrumbs"
              className={`${
                i < 2 && i < data.length - 1 && 'w-max max-w-[200px]'
              } truncate-text text-[12px] text-on-surface-variant`}
            >
              {e.name}
            </DefaultText>
          </Link>
          {i < data.length - 1 && <IconNavigateNext width={18} height={18} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
