import cn from '@/utils/general';
import React from 'react';

type PublicChipVariant = 'private' | 'public';

const publicChipVariantText: Record<PublicChipVariant, string> = {
  private: '組織内',
  public: '組織横断',
};

type PublicChipOwnProps = {
  className?: string;
  variant?: PublicChipVariant;
  text?: string;
};

type PublicChipProps = PublicChipOwnProps & Omit<React.HTMLProps<HTMLDivElement>, keyof PublicChipOwnProps>;

const PublicChip = ({ className, variant, text, ...rest }: PublicChipProps) => {
  return (
    <div
      className={cn(
        'label lg icon-button-share icon-button flex items-center justify-center gap-2 truncate rounded-lg border border-outline bg-surface px-3 py-[5px] text-on-surface-variant',
        className,
      )}
      {...rest}
    >
      <i className="hidden h-[18px] w-[18px] before:bg-outline" />
      <span className="truncate">{text ?? publicChipVariantText[variant ?? 'public']}</span>
    </div>
  );
};

export default PublicChip;
