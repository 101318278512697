import React from 'react';
import c from 'clsx';
import { useService } from './service';
import { chartConsts } from '../../organisms/Chart/consts';
import s from './style.module.sass';

type Props = {
  className?: string;
  width: number;
  term: number;
};

const ItemBar: React.FC<Props> = (props) => {
  const { className, width } = props;
  const { handleEnter, handleLeave } = useService();

  return (
    <div
      className={c(s.root, className)}
      style={{
        width: `${width}px`,
        height: `${chartConsts.mileStoneSize}px`,
        borderRadius: `${chartConsts.mileStoneSize / 2}px`,
      }}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    ></div>
  );
};

export default ItemBar;
