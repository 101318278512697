import React from 'react';
import c from 'clsx';
import s from './style.module.sass';

type Props = {
  className?: string;
  type: 'sowing' | 'planting';
  size: number;
};

const ItemCube: React.FC<Props> = (props) => {
  const { className, type, size } = props;

  return (
    <div
      className={c(s.root, className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        padding: `${size / 8}px`,
      }}
    >
      <div
        className={c(s.mark, s[type])}
        style={{
          width: `${size * 0.75}px`,
          height: `${size * 0.75}px`,
        }}
      />
    </div>
  );
};

export default ItemCube;
