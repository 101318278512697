import { useDraggable } from '@dnd-kit/core';

export const useService = (id: string) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

  const translate = transform ? `translate3d(${transform.x}px, 0, 0)` : undefined;

  return {
    attributes,
    listeners,
    setNodeRef,
    translate,
  };
};
