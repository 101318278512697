import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { MESSAGE_REQUIRED_EMAIL, MESSAGE_VALIDATE_EMAIL } from '@/utils/constants/messageValidate';
import { checkExistAsync } from '@/stores/auth';
import { REGEX } from '@/utils/constants/regex';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import BaseButton from '../../atoms/Button/Button';
import ForwardedInput from '../../atoms/Input';
import DefaultText from '../../atoms/Text/DefaultText';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { TITLE_WEB } from '@/utils/constants/consts';

interface IFormInputs {
  email: string;
  password: string;
  isRegister: boolean;
}

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitEmail = (data: IFormInputs) => {
    dispatch(checkExistAsync({ ...data, callback: () => navigate(replacePathParams(EPath.password, {})) }));
  };

  return (
    <div className="container-login-email m-auto h-588 max-w-480 bg-white px-6 lg:px-0">
      <div className="flex h-12 w-full pt-16 2xl:pt-40">
        <DefaultText variant="title" className="block h-12 w-full text-center text-[32px] text-on-surface">
          {TITLE_WEB} App ログイン
        </DefaultText>
      </div>

      <form onSubmit={handleSubmit(handleSubmitEmail)}>
        <div className="w-full pt-20">
          <DefaultText variant="length" className="flex pb-2 text-sm text-on-surface">
            メールアドレス
          </DefaultText>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_EMAIL,
              pattern: {
                value: REGEX.VALIDATE_MAIL,
                message: MESSAGE_VALIDATE_EMAIL,
              },
            }}
            render={({ field }) => (
              <ForwardedInput
                {...field}
                type="text"
                className={'border-1 h-12 w-full rounded border-on-surface-variant px-4 py-1'}
                hasError={!!errors?.email?.message}
              />
            )}
          />
          <ErrorTooltip errorMessage={errors?.email?.message} hasError={!!errors?.email?.message} />
        </div>
        <div className="flex pt-6">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            上記のメールアドレスにワンタイムパスワードを送信します。
          </DefaultText>
        </div>
        <div className="flex">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            次の画面で、ワンタイムパスワードを入力して、ログインしてください。
          </DefaultText>
        </div>
        <div className="pt-6">
          <BaseButton variant="primary" type="submit" className="w-full md:w-[77px]">
            <DefaultText
              variant="length"
              className="text-sm text-on-primary transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in"
            >
              送信
            </DefaultText>
          </BaseButton>
        </div>
        <div className="mt-2 flex">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            新しくアカウントを作成する場合は
            <Link className="text-[-webkit-link] underline" to={EPath.register}>
              こちら
            </Link>
          </DefaultText>
        </div>
      </form>
    </div>
  );
};

export default Login;
