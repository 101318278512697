import axiosInstance from '@/api/axios-client';
import IconAccount2 from '@/components/icon/IconAccount2';
import IconAccountInfo from '@/components/icon/IconAccountInfo';
import IconEdit from '@/components/icon/IconEdit';
import IconSetting from '@/components/icon/IconSetting';
import IconTrash from '@/components/icon/IconTrash';
import IconLogout from '@/components/icon/IconlLogout';
import {
  checkShowByPath,
  listShowTitleDocument,
  listShowTitleFeedback,
  listShowTitleHouse,
  listShowTitleQuestion,
} from '@/layout/header/header.Config';
import { EPath } from '@/route/route';
import { IParamUrl, TMenuItem } from '@/types/common';
import { CONSTANTS, ROLE } from '@/utils/constants/consts';
import { REGEX } from '@/utils/constants/regex';
import { Key, pathToRegexp } from '@/utils/pathToRegex';
import { ChangeEvent } from 'react';
import { IOrganizationInfo } from '@/types/organization';

export const sideBarTree = {
  houses: {
    index: 0,
  },
  questions: {
    index: 1,
  },
  instructions: {
    index: 2,
  },
  literatures: {
    index: 3,
  },
  organization: {
    index: 4,
    house: {
      index: 0,
    },
    questions: {
      index: 1,
    },
    guidance: {
      index: 2,
    },
    literature: {
      index: 3,
    },
  },
  allHouses: {
    index: 4,
    house: {
      index: 0,
    },
    cultivationPlan: {
      index: 1,
    },
  },
  'detail-question': {
    index: 4,
  },
  organization1: {
    index: 5,
  },
};

export type DataDropDown = {
  icon: string;
  label: string;
  link?: string;
  color: string;
};

export const dataDropDownSetting: DataDropDown[] = [
  {
    icon: 'setting',
    link: 'setting-organization/1',
    label: '組織情報',
    color: '#1D1C16',
  },
  {
    icon: 'trash',
    label: '組織を削除',
    color: '#BA1A1A',
  },
];

export const dataDropDownAccount: DataDropDown[] = [
  {
    icon: 'account',
    label: 'アカウント',
    color: '#1D1C16',
  },
  {
    icon: 'logout',
    label: 'ログアウト',
    color: '#BA1A1A',
  },
];

export const dataDropDownCommon: DataDropDown[] = [
  {
    icon: 'edit',
    label: '編集',
    color: '#1D1C16',
  },
  {
    icon: 'trash',
    label: '削除',
    color: '#BA1A1A',
  },
];

export const dataDropDownPrivilegesMember: DataDropDown[] = [
  {
    icon: 'setting',
    label: 'ハウス情報',
    color: '#1D1C16',
  },
  {
    icon: 'trash',
    label: 'ハウスを削除',
    color: '#BA1A1A',
  },
];

export function matchRouterWithSideBar(currentUrl: string) {
  currentUrl = !currentUrl.startsWith('/') ? currentUrl.substring(1) : currentUrl;
  currentUrl =
    currentUrl.indexOf('/') === currentUrl.length - 1 ? currentUrl.substring(currentUrl.length - 1, 1) : currentUrl;
  if (!currentUrl) {
    return [CONSTANTS.INDEX_HOME_PAGE];
  }

  if (currentUrl === CONSTANTS.SLUG_ACCOUNT_PAGE) {
    return [CONSTANTS.INDEX_ACCOUNT_PAGE];
  }
  if (currentUrl === CONSTANTS.SLUG_NOTIFY_PAGE) {
    return [CONSTANTS.INDEX_NOTIFY_PAGE];
  }
  const arrUrl = currentUrl.split('/');
  const arrIndex: number[] = [];
  arrUrl.forEach((item) => {
    arrIndex.push(sideBarTree[item as keyof typeof sideBarTree]?.index);
  });

  return arrIndex;
}

export function getDataDropdown(typeDropdown: number) {
  switch (typeDropdown) {
    case CONSTANTS.TYPE_DROPDOWN_SETTING:
      return dataDropDownSetting;
    case CONSTANTS.TYPE_DROPDOWN_PRIVILEGES_MEMBER:
      return dataDropDownPrivilegesMember;
    case CONSTANTS.TYPE_DROPDOWN_ACCOUNT:
      return dataDropDownAccount;
    case CONSTANTS.TYPE_DROPDOWN_TABLE:
      return dataDropDownCommon;
    default:
      return dataDropDownSetting;
  }
}

export function getIcon(nameIcon?: string) {
  switch (nameIcon) {
    case 'edit':
      return IconEdit({ width: 18, height: 18, className: '!w-[18px] !h-[18px]' });
    case 'setting':
      return IconSetting({});
    case 'trash':
      return IconTrash({});
    case 'account':
      return IconAccountInfo({});
    case 'account2':
      return IconAccount2();
    case 'logout':
      return IconLogout({});
    default:
      return IconSetting({});
  }
}

export function getFirstLetterName(name: string) {
  return name.trim().substring(0, 1);
}

export async function uploadFile(data: File[]) {
  const formData = new FormData();
  data.forEach((file) => {
    formData.append('files', file);
  });
  return await axiosInstance.post('files/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function downloadFile(path: string) {
  const filePath = encodeURIComponent(`${path}`);
  const data = axiosInstance.get(`files/${filePath}`, {
    method: 'GET',
    responseType: 'blob',
  });

  return data as unknown as Blob;
}

export const changeListSubItems = (dataCopy: TMenuItem[], path: string, subItems: TMenuItem[]) => {
  for (const item of dataCopy) {
    if (item.path === path) {
      item.subItems = subItems;
      return;
    }

    if (item.subItems && item.subItems.length > 0) {
      changeListSubItems(item.subItems, path, subItems);
    }
  }
};

export const replacePathParams = (path: string, params: IParamUrl): string =>
  path.replace(/:([^/]+)/g, (_, p1) => encodeURIComponent(params[p1] ? params[p1] : ''));

export const comparePath = (pathName: string, path: string) => {
  const keys: Key[] = [];
  const regex = pathToRegexp(path, keys);

  const [pathWithoutQuery] = pathName.split('?');

  const match = regex.exec(pathWithoutQuery);
  return !!match;
};

export const getTypeFile = (name: string) => {
  return name?.split('.').pop();
};

export const getNumberByStr = (str: string | number) => {
  return str !== 'NaN' && str !== undefined ? +str : 0;
};

export const convertNumberFormThousands = (number: number) => {
  return number.toString().replace(REGEX.convertNumberFormThousandsRegex, ',');
};

export const formatNumberWithCommas = (number: string) => {
  if (!number || number === '0' || number === '00') {
    return '0';
  }
  const numString = String(number);
  const hasLeadingZeroAndDecimal = numString.startsWith('0.');
  const trimmedNumber = hasLeadingZeroAndDecimal ? numString : numString.replace(/^0+/, '');
  const [integerPart, decimalPart] = trimmedNumber.split('.');
  let result = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (decimalPart !== undefined) {
    result += `.${decimalPart.replace(/0+$/, '')}`;
  }
  return result;
};

export const formatNumberWithoutDecimals = (number: number | string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getTitleHeader = (titleHeader: string, isMobile?: boolean) => {
  switch (true) {
    case checkShowByPath(window.location.pathname, listShowTitleHouse):
      return `${titleHeader}のハウス`;
    case checkShowByPath(window.location.pathname, listShowTitleQuestion):
      return `${titleHeader}の質問`;
    case checkShowByPath(window.location.pathname, listShowTitleFeedback):
      return `${titleHeader}の指導`;
    case checkShowByPath(window.location.pathname, listShowTitleDocument):
      return `${titleHeader}の文献`;
    case checkShowByPath(window.location.pathname, [EPath.newQuestion]):
      return isMobile ? '質問を投稿' : '質問の設定';
    case checkShowByPath(window.location.pathname, [EPath.newFeedback]):
      return isMobile ? '指導を投稿' : '指導の設定';
    case checkShowByPath(window.location.pathname, [EPath.newDocument]):
      return isMobile ? '文献を投稿' : '文献の設定';
    default:
      return titleHeader;
  }
};

export const calculateLeftLineNow = () => {
  const now = new Date();
  const day = now.getDate();

  return day * 2.58;
};

export const handleOnchangeDialog = (e: ChangeEvent<HTMLInputElement>, onchange: (value: string) => void) => {
  const sanitizedValue = e.target.value.replace(REGEX.REMOVE_NON_NUMERIC_CHARACTERS, '');
  onchange(sanitizedValue);
};

export const moveToPathHouse = (keyCode: string) => {
  if (keyCode === 'EA0025') {
    window.location.href = EPath.houses;
  }
};

export const handleOnChangeInputNumberFormat = (
  e: ChangeEvent<HTMLInputElement>,
  onchange: (value: string) => void,
  isFiledNumberDialog?: boolean,
) => {
  if (isFiledNumberDialog)
    onchange(e.target.value !== '' ? e.target.value.replace(REGEX.REMOVE_NON_NUMERIC_CHARACTERS, '') : '0');
  else onchange(e.target.value);
};

export const checkRoleAdd = (userIdCurrent: number, organizationInfo: IOrganizationInfo) => {
  const ownerId = organizationInfo.owner.members.id;
  const currentUser = organizationInfo.members.find((item) => item.members.id === userIdCurrent);
  return userIdCurrent === ownerId || (currentUser && currentUser.roleCd === ROLE.admin);
};

export const getDefaultTimeForDate = (time: string) => {
  const timeDefault = new Date(time);
  timeDefault.setHours(0);
  timeDefault.setMinutes(0);
  timeDefault.setSeconds(0);
  return timeDefault;
};
