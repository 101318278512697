import React from 'react';

type Props = {
  className?: string;
};

const IconAccount: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M28 18C22.48 18 18 22.48 18 28C18 33.52 22.48 38 28 38C33.52 38 38 33.52 38 28C38 22.48 33.52 18 28 18ZM34.36 32.83C32.93 31.09 29.46 30.5 28 30.5C26.54 30.5 23.07 31.09 21.64 32.83C20.62 31.49 20 29.82 20 28C20 23.59 23.59 20 28 20C32.41 20 36 23.59 36 28C36 29.82 35.38 31.49 34.36 32.83ZM24.5 25.5C24.5 23.56 26.06 22 28 22C29.94 22 31.5 23.56 31.5 25.5C31.5 27.44 29.94 29 28 29C26.06 29 24.5 27.44 24.5 25.5Z"
          fill="#615F50"
        />
      </g>
      <defs>
        <clipPath id="clip0_1364_7680">
          <rect width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAccount;
