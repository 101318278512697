import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { IComment } from '@/types/question';
import { fullTime } from '@/utils/formatTime';
import Medias from '@/components/molecules/Medias';
import { FileRes } from '@/types/common';
import { REGEX } from '@/utils/constants/regex';
import DisplayFiles from '@/components/atoms/DisplayFiles';
import Linkify from '@/components/atoms/Linkify';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';

type Props = {
  comment: IComment;
  isHidenFile?: boolean;
};

const CardComment = (props: Props) => {
  const { comment, isHidenFile } = props;
  const convertImage = () => {
    return (
      comment?.image
        ?.filter((file: FileRes) => REGEX.imageExtensionsRegex.test(file.name))
        .map((item: FileRes) => item.path) ?? []
    );
  };

  const convertFile = () => {
    return comment?.image?.filter((file: FileRes) => !REGEX.imageExtensionsRegex.test(file.name)) ?? [];
  };

  return (
    <div className="flex items-end !gap-2 rounded-2xl bg-surface-at-1 pb-0 text-sm sm:!gap-4">
      <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
        <DisplayAvatar
          path={comment.createdByName?.file?.length ? comment.createdByName?.file[0]?.path : ''}
          nameUser={comment.createdByName?.name ?? ''}
          className="h-10 w-10 leading-10"
        />
      </div>
      <div className="overlay-hidden flex w-[80%] grow flex-col rounded-2xl border border-outline-variant bg-white px-3 py-4 sm:px-6">
        <span className="text-[11px] leading-4 text-on-surface-variant">{fullTime(comment.createdAt)}</span>
        <span className="mb-2 text-xs font-medium leading-[18px] text-on-surface">
          {comment.createdByName?.name ?? ''}
        </span>
        {!isHidenFile && (
          <>
            {convertImage().length > 0 && <Medias listImagesPath={convertImage()} />}
            {convertFile().length > 0 && (
              <div className="mb-6">
                <DisplayFiles className="cursor-pointer" files={convertFile()} isRemove={false} />
              </div>
            )}
          </>
        )}
        <span className="text-xs font-medium">{comment.name}</span>
        <span className="regular-text md text-on-surface-variant">
          <div className="text-on-surface">
            <Linkify>{comment.content}</Linkify>
          </div>
        </span>
      </div>
    </div>
  );
};

export default CardComment;
