import axiosInstance from '@/api/axios-client';
import { THouses, THousesCreate, THousesUpdate } from '@/api/types/housesTypes';
import { setShowToast, setTitleHeaderDetail } from '@/stores/common';
import { setTags } from '@/stores/tag';
import { uploadFile } from '@/utils/helpers';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FilterHouses } from '@/types/houses';
import { IPagination, TCallback } from '@/types/common';
import { messageNotification } from '@/utils/constants/messageNotification';
import { IDataFilter, IFilter } from '@/types/question';
import { DATA_OPTION_INIT_HOUSE, DEFAULT_PAGE_SIZE, typeFilterQuestion } from '@/utils/constants/consts';

export interface HousesState {
  houses: THouses[];
  housesAll: THouses[];
  houseDetail: THouses | null;
  page?: number;
  limit?: number;
  total?: number;
  path?: string | null;
  keyword: string;
  dataOption: IFilter<IPagination, IDataFilter>;
}

const initialState: HousesState = {
  houses: [],
  housesAll: [],
  houseDetail: null,
  page: 1,
  limit: DEFAULT_PAGE_SIZE,
  total: 0,
  path: null,
  keyword: '',
  dataOption: DATA_OPTION_INIT_HOUSE,
};

interface UploadResponse {
  data: Array<{
    path: string;
    name: string;
    id: string;
  }>;
}

export const housesSlice = createSlice({
  name: 'houses',
  initialState,
  reducers: {
    saveHouses: (state, action) => {
      const { houses, page, limit, total } = action.payload;
      state.houses = houses;
      state.page = page;
      state.limit = limit;
      state.total = total;
    },
    setPath: (state, action) => {
      state.path = action.payload;
    },
    setHouseDetail: (state, action) => {
      state.houseDetail = action.payload;
    },
    setDataOption: (state, action) => {
      state.dataOption[action.payload.type].data = action.payload.data;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setHousesAll: (state, action) => {
      state.housesAll = action.payload;
    },
  },
});

export const getHousesALlAsync = createAsyncThunk('house/getHouses', async (param: FilterHouses, thunkAPI) => {
  const response = await axiosInstance.post('green-house/list', param);
  thunkAPI.dispatch(setHousesAll(response.data.items));
});

export const getHousesAsync = createAsyncThunk('house/getHouses', async (param: FilterHouses & TCallback, thunkAPI) => {
  const response = await axiosInstance.post('green-house/list', param);
  const { houses } = thunkAPI.getState() as { houses: HousesState };
  const housesClone = {
    ...response.data,
    total: response.data.total,
    houses: response.data.page === 1 ? [...response.data.items] : [...houses.houses, ...response.data.items],
  };
  thunkAPI.dispatch(saveHouses(housesClone));
  param.callback?.();
});

export const getHousesDetailAsync = createAsyncThunk(
  'house/getHousesDetail',
  async (params: { id: number; isSetTitleHeaderDetail?: boolean }, thunkAPI) => {
    const response = await axiosInstance.get(`green-house/${params.id}`);
    !params.isSetTitleHeaderDetail && thunkAPI.dispatch(setTitleHeaderDetail(response.data.title));
    thunkAPI.dispatch(setHouseDetail(response.data));
    thunkAPI.dispatch(setPath(response?.data.image?.[0].path));
  },
);

export const updateHousesAsync = createAsyncThunk(
  'house/updateHousesAsync',
  async (dataSend: { data: THousesUpdate; organizationId: number } & TCallback, thunkAPI) => {
    const dataRes = await axiosInstance.patch(`green-house/${dataSend.data.id}`, dataSend.data);
    thunkAPI.dispatch(getHousesDetailAsync({ id: dataSend.data.id }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    dataSend.callback?.();
    return dataRes;
  },
);

export const createHouses = createAsyncThunk('house/green-house', async (data: THousesCreate, thunkAPI) => {
  await axiosInstance.post('green-house', data).then((response) => {
    thunkAPI.dispatch(setTags(response.data));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(
      getHousesAsync({
        page: 1,
        limit: DEFAULT_PAGE_SIZE,
        organizationId: [
          {
            operation: 'in',
            value: [data.organizationId],
          },
        ],
      }),
    );
  });
});

export const uploadFileHouses = createAsyncThunk('loading/house/uploadFile', async (data: File[], thunkAPI) => {
  await uploadFile(data).then((res: UploadResponse) => {
    thunkAPI.dispatch(setPath(res.data[0].path));
  });
});

export const deleteHouse = createAsyncThunk(
  'houses/deleteHouse',
  async (params: { houseId: number; organizationId?: number | string; dataFilter?: FilterHouses }, thunkAPI) => {
    try {
      await axiosInstance.delete(`green-house/${params.houseId}`).then(() => {
        thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
        thunkAPI.dispatch(
          getHousesAsync({
            ...params.dataFilter,
            page: 1,
            limit: DEFAULT_PAGE_SIZE,
          }),
        );
      });
    } catch (error) {
      console.error('Error delete house:', error);
      throw error;
    }
  },
);

export const getUserFilterAsync = createAsyncThunk('house/getUserFilter', async (param: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `green-house/users/organizationId?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
  );
  const dataFilterUser = response.data.items.map((item: { manager: string }) => ({
    ...item,
    title: item.manager,
  }));
  thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.MANAGER, data: dataFilterUser }));
});

export const getOrganizationFilterAsync = createAsyncThunk(
  'house/getTagFilter',
  async (param: IPagination, thunkAPI) => {
    const response = await axiosInstance.get(
      `green-house/organizations/organizationId?page=${param.page}&limit=${param.limit}&id=${
        param.organizationId ?? ''
      }`,
    );
    thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.ORGANIZATION, data: response.data.items }));
  },
);

export const { saveHouses, setPath, setHouseDetail, setDataOption, setKeyword, setHousesAll } = housesSlice.actions;
export default housesSlice.reducer;
