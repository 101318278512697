import {
  IBusinessItem,
  IBusinessPlanDetail,
  IBusinessPlanList,
  IBusinessPostResponse,
  ICirclePlanArea,
  IDataBusinessPlanResult,
  ISheet,
} from './model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { THouses } from '@/api/types/housesTypes';
import { IComment } from '@/types/question';

export type State = {
  businessPostResponse: IBusinessPostResponse | null;
  businessList: IBusinessPlanList['data']['items'] | null;
  houseDetail: THouses | null;
  listSheet: ISheet[];
  listBusinessPlan: IBusinessPlanDetail[];
  comments: IComment[];
  scheduleComments: IComment[];
  dialogType: string;
  sheetActive: number;
  countComment: number;
  countCommentChart: number;
  dataChart: ICirclePlanArea[];
  dataBusinessPlanResult: IDataBusinessPlanResult;
  businessDetail: IBusinessItem | null;
  totalChartAll: number;
  page: 1;
};

const initialState: State = {
  businessPostResponse: null,
  businessList: null,
  houseDetail: null,
  listSheet: [],
  listBusinessPlan: [],
  comments: [],
  countComment: 0,
  countCommentChart: 0,
  scheduleComments: [],
  dialogType: '',
  sheetActive: 0,
  dataChart: [],
  dataBusinessPlanResult: {},
  businessDetail: null,
  totalChartAll: 0,
  page: 1,
};

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusinessPostResponse: (state, action: PayloadAction<IBusinessPostResponse>) => {
      state.businessPostResponse = action.payload;
    },
    getBusinessList: (state, action: PayloadAction<IBusinessPlanList['data']['items']>) => {
      state.businessList = action.payload;
    },
    setHouseDetail: (state, action) => {
      state.houseDetail = action.payload;
    },
    setBusinessDetail: (state, action) => {
      state.businessDetail = action.payload;
    },
    setListSheet: (state, action) => {
      state.listSheet = action.payload;
    },
    setListBusinessPlan: (state, action) => {
      state.listBusinessPlan = action.payload;
    },
    setCommentsList: (state, action) => {
      state.comments = action.payload;
    },
    setScheduleCommentsList: (state, action) => {
      state.scheduleComments = action.payload;
    },
    setDialogType: (state, action) => {
      state.dialogType = action.payload;
    },
    setSheetActive: (state, action) => {
      state.sheetActive = action.payload;
    },
    setDataChart: (state, action) => {
      state.dataChart = action.payload;
    },
    setDataBusinessPlanResult: (state, action) => {
      state.dataBusinessPlanResult = action.payload;
    },
    setCountComment: (state, action) => {
      state.countComment = action.payload;
    },
    setCountCommentChart: (state, action) => {
      state.countCommentChart = action.payload;
    },
    setTotalChartAll: (state, action) => {
      state.totalChartAll = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

const { actions, reducer } = slice;
export default slice;
export const {
  setCommentsList,
  setScheduleCommentsList,
  setDialogType,
  setSheetActive,
  setListSheet,
  setDataChart,
  setListBusinessPlan,
  setDataBusinessPlanResult,
  setBusinessDetail,
  setCountComment,
  setCountCommentChart,
  setTotalChartAll,
  setPage,
} = slice.actions;
export { actions, reducer };
