import React, { useEffect, useState } from 'react';

import ChartNameAreaAll from '@/components/molecules/ChartNameArea/ChartAll';
import ChartNameAreaNoneAll from '@/components/molecules/ChartNameArea/ChartAll/ChartNameAreaNoneAll';
import c from 'clsx';
import ChartTableAll from '../../molecules/ChartTable';
import { useService } from './service';
import s from './style.module.sass';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { setPage } from '@/stores/business/slice';

type Props = {
  className?: string;
  scaleUnit: 'day' | 'month';
  startAt?: string;
  grouping: boolean;
};

const ChartAll: React.FC<Props & { isAreaGrouping: string }> = (props) => {
  const { className, scaleUnit, grouping, isAreaGrouping } = props;
  const dispatch = useDispatch();
  const { page } = useSelector((state: RootState) => state.business);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [checkScroll, setCheckScroll] = useState<boolean>(false);

  const { charts, startAt, endAt, autoStartAt, autoEndAt } = useService(grouping);

  const handleScroll = (elementHandle: HTMLElement, elementListen: HTMLElement) => {
    if (elementHandle && elementListen) {
      elementHandle.scrollTop = elementListen.scrollTop;
    }
  };

  useEffect(() => {
    if (loadMore) {
      getData();
      setLoadMore(false);
    }
  }, [loadMore]);

  const getData = () => {
    dispatch(setPage(page + 1));
  };

  const autoScroll = (elementHandle: HTMLElement) => {
    if (elementHandle.clientHeight + elementHandle.scrollTop === elementHandle.scrollHeight && !loadMore) {
      setLoadMore(true);
    }
  };
  const handlePinChart = (div1: HTMLElement) => {
    if (div1.scrollTop < 5) {
      setCheckScroll(false);
    } else {
      setCheckScroll(true);
    }
  };

  useEffect(() => {
    const div1 = document.getElementById('chart-line');
    const div2 = document.getElementById('chart-name-area');
    const div3 = document.getElementById('chart-name-area-none');

    if (div1 && div2) {
      div1.addEventListener('scroll', () => autoScroll(div1));
      div1.addEventListener('scroll', () => handleScroll(div2, div1));
      div2.addEventListener('scroll', () => handleScroll(div1, div2));
      div1.addEventListener('scroll', () => handlePinChart(div1));
    }

    if (div1 && div3) {
      div1.addEventListener('scroll', () => handleScroll(div3, div1));
      div3.addEventListener('scroll', () => handleScroll(div1, div3));
    }

    return () => {
      if (div1 && div2) {
        div1.removeEventListener('scroll', () => handleScroll(div2, div1));
        div2.removeEventListener('scroll', () => handleScroll(div1, div2));
        div1.removeEventListener('scroll', () => handlePinChart(div1));
      }

      if (div1 && div3) {
        div1.removeEventListener('scroll', () => handleScroll(div3, div1));
        div3.removeEventListener('scroll', () => handleScroll(div1, div3));
      }
    };
  });

  return (
    <>
      {Object.keys(charts).length > 0 ? (
        <div className={c(s.root, className, 'grow')}>
          {isAreaGrouping === 'true' ? (
            <ChartNameAreaAll className={`${s.chartNameArea}`} data={charts} />
          ) : (
            <ChartNameAreaNoneAll className={s.chartNameArea} data={charts} />
          )}

          <ChartTableAll
            className={s.chartTable}
            scaleUnit={scaleUnit}
            startAt={startAt ? startAt : autoStartAt ?? ''}
            endAt={endAt ? endAt : autoEndAt ?? ''}
            charts={charts}
            isAreaGrouping={isAreaGrouping}
            checkScroll={checkScroll}
          />
        </div>
      ) : (
        <div className="w-[100vw] pt-4 text-center text-base text-on-surface-variant md:w-[calc(100vw-240px)]">
          {MESSAGE_NO_DATA}
        </div>
      )}
    </>
  );
};

export default ChartAll;
