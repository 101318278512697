import BusinessTable, { ChildRef } from '@/components/molecules/BusinessTable';
import BusinessFooter from '@/components/molecules/BusinessFooter';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Main from '@/pages/Main';
import { nanoid } from 'nanoid';
import Label from '@/components/atoms/AText/Label';
import BodyText from '@/components/atoms/AText/BodyText';
import InputDropDown from '@/components/atoms/InputDropDown';
import IconRedo from '@/components/icon/IconRedo/IconRedo';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentBusiness, getListSheet, getSheetBusiness } from '@/stores/business/services';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import s from './style.module.sass';
import CommentsBusiness from '@/components/molecules/CommentsBusiness';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { setListBusinessPlan, setSheetActive } from '@/stores/business/slice';
import DropdownExport from '@/components/molecules/Dropdown/DropdownExport/DropdownExport';
import IconZoomOut from '@/components/icon/IconZoomOut';
import IconZoomIn from '@/components/icon/IconZoomIn';
import { getHousesDetailAsync } from '@/stores/houses';
import { getOrganizationDetailAsync } from '@/stores/organization';

const tagsData = ['損益計画', '栽培計画'];
const BusinessPlan = () => {
  const dispatch = useDispatch();
  const { businessId, houseId, organizationId } = useParams();
  const refComment = useRef<HTMLDivElement>(null);
  const refModal = useRef<HTMLDivElement>(null);
  const childRef = useRef<ChildRef>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { titleOrganization } = useSelector((state: RootState) => state.organization);
  const { comments, listSheet, sheetActive, countComment } = useSelector((state: RootState) => state.business);
  const [zoom, setZoom] = useState<boolean>(false);
  const [showSetting, setShowSetting] = useState(false);

  const [iconVisibility, setIconVisibility] = useState(
    tagsData.map((_, index) => {
      if (index === 0) {
        return true;
      }
      return false;
    }),
  );
  const [showComment, setShowComment] = useState(false);
  const handleShow = () => {
    setShowComment((prev) => !prev);
  };
  const inputData = [{ time: '月' }, { time: '年' }];
  const [selected, setSelected] = useState({ time: '月' });
  const handleButtonClick: React.MouseEventHandler<HTMLElement> = (event) => {
    const clickedButton = (event.target as HTMLElement).closest('button');
    if (clickedButton?.getAttribute('data-index')) {
      const index = clickedButton?.getAttribute('data-index');
      if (index) {
        const updatedVisibility: boolean[] = tagsData.map(() => false);
        updatedVisibility[+index] = !updatedVisibility[+index];
        setIconVisibility(updatedVisibility);
      }
    }
  };

  useEffect(() => {
    if (organizationId && !titleOrganization) {
      dispatch(getOrganizationDetailAsync(Number(organizationId)));
    }
  });

  useEffect(() => {
    const tab = queryParams.get('tab');
    if (tab) {
      setIconVisibility([false, true]);
    }
  }, []);

  useEffect(() => {
    if (businessId && houseId) {
      dispatch(getListSheet(+businessId));
      dispatch(getHousesDetailAsync({ id: +houseId, isSetTitleHeaderDetail: true }));
    }
  }, [businessId]);

  useEffect(() => {
    if (businessId && !isNaN(+businessId) && sheetActive) {
      dispatch(getCommentBusiness({ id: +businessId, idSheet: sheetActive }));
    }
  }, [businessId, sheetActive]);

  useEffect(() => {
    if (businessId && !isNaN(+businessId)) {
      dispatch(getSheetBusiness({ id: +businessId }));
    }

    return () => {
      dispatch(setSheetActive(0));
      dispatch(setListBusinessPlan([]));
    };
  }, [businessId]);

  const canUndo = useMemo(() => {
    return childRef?.current?.canUndo;
  }, [childRef?.current?.canUndo, childRef?.current?.canRedo]);

  const canRedo = useMemo(() => {
    return childRef?.current?.canRedo;
  }, [childRef?.current?.canUndo, childRef?.current?.canRedo]);

  const undoChanges = () => {
    if (childRef.current) {
      childRef.current.handleUndoChanges();
    }
  };

  const redoChanges = () => {
    if (childRef.current) {
      childRef.current.handleRedoChanges();
    }
  };

  useOutsideClick(refComment, () => setShowComment(false));
  useOutsideClick(refModal, () => setShowSetting(false));

  const handleToggleShowSetting = () => {
    setShowSetting(!showSetting);
  };

  const handleZoomIn = () => {
    setZoom(true);
  };

  const handleZoomOut = () => {
    setZoom(false);
  };

  return (
    <div className="h-[calc(100vh-190px)] overflow-hidden font-noto">
      <div className="h-full bg-surface-at-1 p-4">
        <div className="flex h-full flex-col rounded-2xl bg-white pt-4">
          <div className="mb-4 border-b border-b-surface-variant">
            <button className="relative flex flex-row" onClick={handleButtonClick}>
              {tagsData.map((tag, index) => {
                return (
                  <div className="w-[120px]" key={nanoid(5)}>
                    <button
                      data-index={index}
                      className=" m-auto flex items-center justify-center px-4 py-[14px] hover:bg-black/[0.08]"
                    >
                      <Label size="large" tag="span" className="text-on-surface">
                        {tag}
                      </Label>
                      {iconVisibility[index] && (
                        <div className="absolute bottom-0 flex w-[58px] justify-center">
                          <i className="icon-indicator icon-24px flex h-[14px] w-[58px] justify-center bg-primary" />
                        </div>
                      )}
                    </button>
                  </div>
                );
              })}
            </button>
          </div>
          <div className={`${zoom && s.zoom} relative flex grow flex-col overflow-x-auto lg:overflow-hidden`}>
            {iconVisibility[0] && (
              <div className="mb-4 mt-2 flex grow flex-row flex-wrap items-center gap-4 px-4 lg:min-w-[742px] lg:flex-nowrap lg:justify-end">
                <div className="gap relative z-20 flex flex-row items-center gap-2">
                  <BodyText className="regular-text md w-fit whitespace-nowrap text-on-surface-variant">
                    タイムスケール
                  </BodyText>
                  <div>
                    <InputDropDown selected={selected} data={inputData} setSelected={setSelected} />
                  </div>
                </div>
                <div className="flex gap-1">
                  <div
                    className={`relative h-10 w-10 rounded-full border border-on-surface-variant ${
                      !canUndo ? 'bg-surface-at-2' : 'active:bg-[#efede6]'
                    }`}
                  >
                    <IconRedo
                      className={'m-auto h-10 scale-x-[-1] transform'}
                      disabled={!canUndo}
                      onClick={undoChanges}
                    />
                  </div>
                  <div
                    className={`h-10 w-10 rounded-full border border-on-surface-variant ${
                      !canRedo ? 'bg-surface-at-2' : 'active:bg-[#efede6]'
                    }`}
                  >
                    <IconRedo
                      className={`m-auto h-10 ${!canRedo ? 'bg-gray' : ''}`}
                      disabled={!canRedo}
                      onClick={redoChanges}
                    />
                  </div>
                </div>
                <div className="flex gap-2 lg:relative">
                  <PrimaryButton text={`コメント(${countComment})`} variant="outlined" onClick={handleShow} />
                  <div
                    className="absolute left-0 top-[140px] z-[99] w-[calc(100vw-64px)] sm:w-[360px] lg:top-[60px]"
                    ref={refComment}
                  >
                    {showComment && (
                      <CommentsBusiness
                        listComment={comments}
                        businessId={businessId ? +businessId : null}
                        idSheet={sheetActive ?? 0}
                      />
                    )}
                  </div>
                </div>
                <button className="relative flex" onClick={handleToggleShowSetting}>
                  <PrimaryButton text="エクスポート" variant="outlined" />
                  <div ref={refModal}>{showSetting && <DropdownExport />}</div>
                </button>
                {zoom ? (
                  <div
                    className="flex items-center rounded-full border border-on-surface-variant p-3"
                    onClick={handleZoomOut}
                    onKeyDown={() => {}}
                  >
                    <IconZoomOut />
                  </div>
                ) : (
                  <div
                    className="flex items-center rounded-full border border-on-surface-variant p-3"
                    onClick={handleZoomIn}
                    onKeyDown={() => {}}
                  >
                    <IconZoomIn />
                  </div>
                )}
              </div>
            )}
            <div
              className={`relative overflow-y-auto lg:min-w-[742px] ${
                iconVisibility[1] && 'h-[calc(100vh-300px)] xl:overflow-hidden'
              } grow px-4`}
            >
              {iconVisibility[0] && (
                <div className="h-[90%]">
                  <BusinessTable selectedGroupBy={selected} ref={childRef} />
                </div>
              )}
              {iconVisibility[1] && (
                <div className="h-full">
                  <Main />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className="fixed bottom-0 z-10 flex w-full justify-between bg-gray-background bg-gradient-to-r pl-8">
        <BusinessFooter data={listSheet} />
      </footer>
    </div>
  );
};

export default BusinessPlan;
