import { TSignUp } from '@/api/types/authTypes';
import { REGEX } from '@/utils/constants/regex';
import { Controller, useForm } from 'react-hook-form';
import BaseButton from '../../atoms/Button/Button';
import ForwardedInput from '../../atoms/Input';
import DefaultText from '../../atoms/Text/DefaultText';
import { registerAsync } from '@/stores/auth';
import { useDispatch } from 'react-redux';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import {
  MESSAGE_REQUIRED_EMAIL,
  MESSAGE_REQUIRED_NAME,
  MESSAGE_VALIDATE_EMAIL,
} from '@/utils/constants/messageValidate';
import React from 'react';
import { Link } from 'react-router-dom';
import { EPath } from '@/route/route';
import { TITLE_WEB } from '@/utils/constants/consts';

const SignUp = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TSignUp>();
  const dispatch = useDispatch();
  const register = (data: TSignUp) => {
    dispatch(registerAsync(data));
  };

  return (
    <form onSubmit={handleSubmit(register)}>
      <div className="container-login-email m-auto h-[588px] max-w-[480px] bg-white px-6 2xl:px-0">
        <div className="flex w-full pt-10 2xl:pt-[160px]">
          <DefaultText variant="title" className="text-3.5xl h-12 w-full text-center text-[32px] text-on-surface">
            {TITLE_WEB} App 新規登録
          </DefaultText>
        </div>
        <div className="mt-5 w-full">
          <DefaultText variant="length" className="flex pb-2 text-sm text-on-surface">
            名前
          </DefaultText>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
            }}
            render={({ field }) => (
              <ForwardedInput
                {...field}
                type="text"
                className={'h-12 w-full rounded px-4 py-1 text-base'}
                hasError={!!errors?.name?.message}
              />
            )}
          />
          <ErrorTooltip errorMessage={errors?.name?.message} hasError={!!errors?.name?.message} />
        </div>
        <div className="mt-6 w-full">
          <DefaultText variant="length" className="flex pb-2 text-sm text-on-surface">
            メールアドレス
          </DefaultText>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_EMAIL,
              pattern: {
                value: REGEX.VALIDATE_MAIL,
                message: MESSAGE_VALIDATE_EMAIL,
              },
            }}
            render={({ field }) => (
              <ForwardedInput
                {...field}
                type="text"
                className={'border-1 h-12 w-full rounded px-4 py-1 text-base'}
                hasError={!!errors?.email?.message}
              />
            )}
          />
          <ErrorTooltip errorMessage={errors?.email?.message} hasError={!!errors?.email?.message} />
        </div>
        <div className="flex pt-6">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            上記のメールアドレスにワンタイムパスワードを送信します。
          </DefaultText>
        </div>
        <div className="flex">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            次の画面で、ワンタイムパスワードを入力して、ログインしてください。
          </DefaultText>
        </div>
        <div className="pt-6">
          <BaseButton variant="primary" type="submit" className="w-full px-6 py-2.5 md:w-auto">
            <DefaultText
              variant="length"
              className="text-[14px] text-on-primary transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in"
            >
              送信
            </DefaultText>
          </BaseButton>
        </div>
        <div className="mt-2 flex">
          <DefaultText variant="text-link" className="text-sm text-on-surface">
            すでにアカウントをお持ちの方は
            <Link className="text-[-webkit-link] underline" to={EPath.login}>
              こちら
            </Link>
          </DefaultText>
        </div>
      </div>
    </form>
  );
};

export default SignUp;
