import React from 'react';
import './CheckBox.module.scss';

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const BaseCheckBox = ({ onChange }: Props) => {
  return (
    <div>
      <span className="my-auto h-[18px] w-[18px]">
        <input
          className="h-[18px] w-[18px]"
          type="checkbox"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e)}
        />
      </span>
    </div>
  );
};

export default BaseCheckBox;
