import CommonModal from '@/components/atoms/Modal/CommonModal';
import PublicChip from '@/components/atoms/PublicChip';
import TagsClose from '@/components/atoms/Tags/TagsClose';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useState } from 'react';
import ModalDelete from '../ModalComponent/ModalDelete';
import { defaultFormat } from '@/utils/formatTime';
import { TFeedback } from '@/stores/feedback/model';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteFeedback } from '@/stores/feedback/slice';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { shareType } from '@/types/enum';
import { nanoid } from 'nanoid';

interface ListItemProps {
  feedback: TFeedback;
}

const ListGuideComponent: React.FC<ListItemProps> = (props: ListItemProps) => {
  const dispatchListGuide = useDispatch();
  const { feedback } = props;
  const { organizationId: id } = useParams();
  const [showModalDeleteFeedback, setShowModalDeleteFeedback] = useState(false);

  const handleCloseModal = () => {
    setShowModalDeleteFeedback(false);
  };

  const handleDeleteFeedback = () => {
    handleCloseModal();
    dispatchListGuide(deleteFeedback({ feedbackId: feedback.id, organizationId: id }));
  };

  return (
    <>
      <Link to={replacePathParams(EPath.feedbackDetail, { organizationId: id ?? '', id: feedback.id ?? '' })}>
        <li
          key={nanoid(5)}
          className="questionComponentListColumn flex min-h-[64px] items-center gap-1 border-b border-outline-variant"
        >
          <div className="grid gap-[4px] whitespace-pre-wrap py-2 pl-[16px]">
            <DefaultText
              variant="text-status"
              className="truncate-text text-primary-dark my-auto h-[17px] break-all text-[11px] text-on-surface-variant 2xl:text-[11px]"
            >
              {feedback.organization?.title}
            </DefaultText>
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark my-auto break-all py-[4px] text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {feedback?.title}
            </DefaultText>
            <div className="flex gap-[4px]">
              {feedback.tagRelation.map((tag) => (
                <TagsClose text={tag?.title} key={tag?.id} />
              ))}
            </div>
          </div>
          <div />
          <div className="flex justify-center">
            <PublicChip variant={feedback.publish === shareType.public ? 'public' : 'private'} />
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {feedback?.user?.name}
            </DefaultText>
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {defaultFormat(feedback.createdAt)}
            </DefaultText>
          </div>
        </li>
      </Link>
      <CommonModal
        open={showModalDeleteFeedback}
        title={'指導の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        handleClick={handleDeleteFeedback}
      >
        <ModalDelete contentDelete={'指導を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ListGuideComponent;
