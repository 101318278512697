const IconZoomOut = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 7V1H5V3.59L1.91 0.5L0.5 1.91L3.59 5H1V7H7ZM19 7V5H16.41L19.5 1.91L18.09 0.5L15 3.59V1H13V7H19ZM1 13V15H3.59L0.5 18.09L1.91 19.5L5 16.41V19H7V13H1ZM13 13V19H15V16.41L18.09 19.5L19.5 18.09L16.41 15H19V13H13Z"
        fill="#615F50"
      />
    </svg>
  );
};

export default IconZoomOut;
