import Authar from '@/components/atoms/Authar';
import IconMore from '@/components/icon/IconMore/IconMore';
import PageHeader from '@/components/molecules/PageHeader';
import cn from '@/utils/general';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import FooterQFD from '../../molecules/FooterQFD';
import IconModeEdit from '@/components/icon/IconModeEdit';
import Medias from '@/components/molecules/Medias';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import DisplayFiles from '@/components/atoms/DisplayFiles';
import { ITag } from '@/types/tag';
import { editQuestion, getQuestionDetailAsync } from '@/stores/question';
import { editFeedback, getFeedbackDetailAsync } from '@/stores/feedback/slice';
import { setQFDCData } from '@/stores/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ListTags from '@/components/molecules/ListTags';
import { EPath } from '@/route/route';
import { replacePathParams, getTitleHeader } from '@/utils/helpers';
import TextArea from '@/components/atoms/TextArea';
import { editDocument, getDocumentDetailAsync } from '@/stores/document';

const autharData = {
  name: '梅津 由宇',
  tags: ['組織内'],
};

type Props = {
  typeEdit: string;
};

const EditQDF = (props: Props) => {
  const { typeEdit } = props;
  const isAdmin = false;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { questionsDetail } = useSelector((state: RootState) => state.questions);
  const { documentDetail } = useSelector((state: RootState) => state.document);
  const { feedbackDetail } = useSelector((state: RootState) => state.feedback);
  const { QFDCData, titleHeaderDetail } = useSelector((state: RootState) => state.common);
  const [selected, setSelected] = useState<Array<ITag>>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (id && !isNaN(+id)) {
      if (typeEdit === 'question') {
        dispatch(getQuestionDetailAsync(+id));
      } else if (typeEdit === 'document') {
        dispatch(getDocumentDetailAsync(+id));
      } else if (typeEdit === 'feedback') {
        dispatch(getFeedbackDetailAsync(+id));
      }
    } else {
      navigate(EPath.notFoundPage);
    }
  }, []);

  useEffect(() => {
    if (typeEdit === 'question') {
      setSelected(questionsDetail?.tagRelation ?? []);
    } else if (typeEdit === 'document') {
      setSelected(documentDetail?.tagRelation ?? []);
    } else if (typeEdit === 'feedback') {
      setSelected(feedbackDetail?.tagRelation ?? []);
    }
  }, [questionsDetail, documentDetail, feedbackDetail]);

  const handleEditQuestion = async () => {
    if (id) {
      const dataEdit = {
        id: +id,
        content: QFDCData.content.trim(),
        image: [...QFDCData.imagesPath, ...QFDCData.filesPath],
        publish: +QFDCData.typeShare,
        tags: selected.map((item) => item.id).join(','),
      };
      if (typeEdit === 'question' && questionsDetail) {
        dispatch(editQuestion({ ...dataEdit, callback: () => navigate(filterEPath()) }));
      } else if (typeEdit === 'document' && documentDetail) {
        dispatch(editDocument({ ...dataEdit, callback: () => navigate(filterEPath()) }));
      } else if (typeEdit === 'feedback' && feedbackDetail) {
        dispatch(editFeedback({ ...dataEdit, callback: () => navigate(filterEPath()) }));
      }
    }
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setQFDCData({ ...QFDCData, content: event.target.value }));
  };

  const unChoose = (id: number) => {
    const selectTag = [...selected].filter((item) => item.id !== id);
    setSelected(selectTag);
  };

  const setTypeShare = (value: string) => {
    dispatch(
      setQFDCData({
        ...QFDCData,
        typeShare: value,
      }),
    );
  };

  const filterEPath = () => {
    if (id) {
      switch (typeEdit) {
        case 'document':
          if (location.pathname.includes('organization'))
            return replacePathParams(EPath.documentDetail, {
              organizationId: Number(documentDetail?.organizationId),
              id: id,
            });
          else return replacePathParams(EPath.documentDetailAll, { id });
        case 'question':
          if (location.pathname.includes('organization'))
            return replacePathParams(EPath.questionDetail, {
              organizationId: Number(questionsDetail?.organizationId),
              id: id,
            });
          else return replacePathParams(EPath.questionDetailAll, { id });
        case 'feedback':
          if (location.pathname.includes('organization'))
            return replacePathParams(EPath.feedbackDetail, {
              organizationId: Number(feedbackDetail?.organizationId),
              id: id,
            });
          else return replacePathParams(EPath.feedbackDetailAll, { id });
        default:
          return EPath.organizationQuestion;
      }
    }

    return '';
  };

  const userDetail = useMemo(() => {
    if (typeEdit === 'document') {
      return documentDetail?.user;
    } else if (typeEdit === 'question') {
      return questionsDetail?.user;
    } else if (typeEdit === 'feedback') {
      return feedbackDetail?.user;
    }
  }, [typeEdit, questionsDetail, documentDetail, feedbackDetail]);

  return (
    <div className="flex flex-col justify-between font-noto lg:h-screen">
      <div className="w-full border-b border-b-[#E8E2D0] px-2 py-1 lg:px-8 lg:py-2">
        <PageHeader isAdmin={isAdmin} title={getTitleHeader(titleHeaderDetail)} ePathBack={filterEPath()} />
      </div>
      <div
        className="h-[calc(100vh-200px)] overflow-y-auto md:grow"
        onClick={() => {
          textareaRef.current?.focus();
        }}
      >
        <div className="mx-auto flex flex-col p-4 !pb-5 sm:w-[80%] lg:w-[calc(16/36*100%)] lg:p-0 lg:pt-2">
          <div className="mb-6 flex justify-between">
            <div className="flex items-center gap-2">
              <Authar
                data={autharData}
                setTypeShare={(value) => setTypeShare(value)}
                typeShare={QFDCData.typeShare}
                createBy={userDetail}
              />
            </div>
            <div className="flex flex-row">
              <button className={cn('m-1 hidden p-2', isAdmin ? 'flex' : null)}>
                <IconModeEdit className="w-6 text-on-surface-variant" />
              </button>
              <button className={cn('mflex -1 hidden items-center gap-3 p-2', isAdmin ? 'flex' : null)}>
                <IconMore />
              </button>
            </div>
          </div>
          <Medias listImagesPath={QFDCData.imagesPath} isShowDefault={true} />
          <br />
          <TextArea btnRef={textareaRef} value={QFDCData.content} onChange={handleTextareaChange} />
          <ListTags listTags={selected} isDelete deleteTag={unChoose} />
          <DisplayFiles files={QFDCData.files} isRemove={false} className="bg-white" />
        </div>
      </div>
      <footer className="flex w-full justify-between bg-gray-footer px-2 md:px-6">
        <FooterQFD
          selected={selected}
          setSelected={setSelected}
          handleCreateUpdate={handleEditQuestion}
          isEdit
          ePathDetail={filterEPath()}
          ePathOrganization=""
          filesImageRes={QFDCData.imagesPath}
          filesRes={QFDCData.files}
        />
      </footer>
    </div>
  );
};

export default EditQDF;
