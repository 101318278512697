import React from 'react';
import AllLiterature from '@/components/pages/AllLiterature';
import { useParams } from 'react-router-dom';

function AllDocumentWrap() {
  const { organizationId } = useParams();
  return <AllLiterature isCreate={true} organizationId={organizationId ? +organizationId : 0} />;
}

export default AllDocumentWrap;
