import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores';

export default function LoadingSpinner() {
  const { isLoading } = useSelector((state: RootState) => state.common);
  return (
    <>
      {isLoading && (
        <div className="fixed top-0 z-[999] flex h-screen w-screen items-center justify-center bg-gray-600 opacity-20">
          <div className="loading-spinner h-25 w-25 animate-spin rounded-[50%] border-8 border-t-8 border-white border-t-primary"></div>
        </div>
      )}
    </>
  );
}
