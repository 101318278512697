import PrimaryButton from '@/components/atoms/PrimaryButton';
import React from 'react';
import Label from '@/components/atoms/Label';
import Heading from '@/components/atoms/Heading';
import Text from '@/components/atoms/Text';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { defaultFormat } from '@/utils/formatTime';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { THouses } from '@/api/types/housesTypes';
import { MESSAGE_REQUIRED_DEFAULT } from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import FieldDateCustom from '@/components/atoms/FieldDateCustom';
import { formatNumberWithCommas, handleOnChangeInputNumberFormat } from '@/utils/helpers';
import { REGEX } from '@/utils/constants/regex';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';
import { useDispatch } from 'react-redux';
import { postAPIBusiness } from '@/stores/business/services';

type BusinessFormProps = {
  handleCloseForm: () => void;
  houseDetail: THouses | null;
};

const BusinessForm = ({ handleCloseForm, houseDetail }: BusinessFormProps) => {
  const dispatch = useDispatch();
  const { houseId } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: 'onBlur',
  });
  const onSubmit = (data: FieldValues) => {
    const _date = defaultFormat(data.date);
    if (houseId) {
      const request = {
        greenHouseId: +houseId,
        title: data.name,
        areaQuantity: Number(data.dividedAreas?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')),
        seedDate: _date,
        newBuildings: Number(data.newBuildings?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')) || 0,
        newMechanicalEquipment: Number(data.newEquipments?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')) || 0,
        usedBuildings: Number(data.oldBuildings?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')) || 0,
        usedMechanicalEquipment: Number(data.oldEquipments?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')) || 0,
      };
      reset();
      dispatch(postAPIBusiness({ ...request, callback: () => handleCloseForm() }));
    }
  };

  return (
    <form
      className="text-md flex flex-col overflow-hidden rounded border-none bg-gray-background text-on-surface"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="pt-4">
        <Label htmlFor="name" className="lg !text-md mb-2 text-on-surface lg:text-sm" text="事業計画の名前（必須）" />
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: MESSAGE_REQUIRED_DEFAULT,
          }}
          render={({ field }) => (
            <ForwardedInput
              {...field}
              type="text"
              className={'border-1 h-12 w-full rounded px-4 py-1'}
              hasError={!!errors?.name?.message}
            />
          )}
        />
        <ErrorTooltip errorMessage={errors['name']?.message?.toString()} hasError={!!errors?.name?.message} />
      </div>
      <div className="pt-4">
        <Heading level={5} className="regular-text lg mb-2" text="栽培" />
        <div className="flex items-center">
          <Text text="ハウス面積" className="label mr-2 inline-block text-sm" />
          <Text
            text={`${formatNumberWithCommas(houseDetail?.area.split('.00')[0] ?? '')} m`}
            className="label inline-block text-sm"
          />
          <sup className="regular">2</sup>
        </div>
        <div className="flex flex-row flex-wrap gap-2 pt-2.5 lg:gap-0">
          <div className="flex-1">
            <Label htmlFor="dividedAreas" className="lg mb-2 text-sm" text="分割エリア数（必須）" />
            <Controller
              name="dividedAreas"
              control={control}
              defaultValue="4"
              rules={{
                required: MESSAGE_REQUIRED_DEFAULT,
              }}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="w-[160px]"
                  step={1}
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                  setValue={setValue}
                  name="dividedAreas"
                  min={0}
                />
              )}
            />
            <ErrorTooltip
              errorMessage={errors['dividedAreas']?.message?.toString()}
              hasError={!!errors?.dividedAreas?.message}
            />
          </div>

          <div className="flex-1">
            <Label htmlFor="date" className="lg flex truncate text-sm " text="1回目の播種日（必須）" />
            <Controller
              name="date"
              control={control}
              defaultValue={moment().format('YYYY/MM/DD')}
              rules={{
                required: MESSAGE_REQUIRED_DEFAULT,
              }}
              render={({ field }) => (
                <FieldDateCustom className="max-w-[160px]" {...field} name={'date'} setValue={setValue} />
              )}
            />
            <ErrorTooltip errorMessage={errors['date']?.message?.toString()} hasError={!!errors?.date?.message} />
          </div>
        </div>
      </div>
      <div className="pt-4">
        <Heading level={5} className="regular-text lg mb-2" text="設備投資金額（対象がない場合は0と記載）" />
        <Text text="新品" className="label lg inline-block" />
        <div className="flex flex-row gap-2 lg:gap-0">
          <div className="basis-1/2">
            <Label htmlFor="buildings" className="lg mb-2 text-sm" text="建物・構築物" />
            <div className="flex items-center gap-2">
              <Controller
                name="newBuildings"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_DEFAULT,
                }}
                render={({ field }) => (
                  <StepperNumberFormat
                    {...field}
                    className="max-w-[160px]"
                    step={1}
                    onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                    setValue={setValue}
                    name="newBuildings"
                    min={0}
                  />
                )}
              />
              <span className="regular-text md text-sm">円</span>
            </div>
            <ErrorTooltip
              errorMessage={errors['newBuildings']?.message?.toString()}
              hasError={!!errors?.newBuildings?.message}
            />
          </div>
          <div className="relative basis-1/2">
            <Label htmlFor="newEquipments" className="lg mb-2 text-sm" text="機械設備" />
            <div className="flex items-center gap-2">
              <Controller
                name="newEquipments"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_DEFAULT,
                }}
                render={({ field }) => (
                  <StepperNumberFormat
                    {...field}
                    className="max-w-[160px]"
                    step={1}
                    onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                    setValue={setValue}
                    name="newEquipments"
                    min={0}
                  />
                )}
              />
              <span className="regular-text md text-sm">円</span>
            </div>
            <ErrorTooltip
              errorMessage={errors['newEquipments']?.message?.toString()}
              hasError={!!errors?.newEquipments?.message}
            />
          </div>
        </div>

        <Text text="中古" className="label lg mt-2 inline-block" />
        <div className="flex flex-row gap-2 lg:gap-0">
          <div className="basis-1/2">
            <Label htmlFor="oldBuildings" className="lg mb-2 text-sm" text="建物・構築物" />
            <div className="flex items-center gap-2">
              <Controller
                name="oldBuildings"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_DEFAULT,
                }}
                render={({ field }) => (
                  <StepperNumberFormat
                    {...field}
                    className="max-w-[160px]"
                    step={1}
                    onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                    setValue={setValue}
                    name="oldBuildings"
                    min={0}
                  />
                )}
              />
              <span className="regular-text md text-sm">円</span>
            </div>
            <ErrorTooltip
              errorMessage={errors['oldBuildings']?.message?.toString()}
              hasError={!!errors?.oldBuildings?.message}
            />
          </div>
          <div className="basis-1/2">
            <Label htmlFor="oldEquipments" className="lg mb-2 text-sm" text="機械設備" />
            <div className="flex items-center gap-2">
              <Controller
                name="oldEquipments"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_DEFAULT,
                }}
                render={({ field }) => (
                  <StepperNumberFormat
                    {...field}
                    className="max-w-[160px]"
                    step={1}
                    onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                    setValue={setValue}
                    name="oldEquipments"
                    min={0}
                  />
                )}
              />
              <span className="regular-text md text-sm">円</span>
            </div>
            <ErrorTooltip
              errorMessage={errors['oldEquipments']?.message?.toString()}
              hasError={!!errors?.oldEquipments?.message}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-2">
        <PrimaryButton variant="text" type="button" text="キャンセル" onClick={handleCloseForm} />
        <PrimaryButton variant="outlined" type="submit" text="作成" />
      </div>
    </form>
  );
};

export default BusinessForm;
