import ReactDOM from 'react-dom/client';

import './global.css';
import App from '@/route';
import { store } from '@/stores';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
