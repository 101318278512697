import Image from '@/components/atoms/Image/Image';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import IconMore from '@/components/icon/IconMore/IconMore';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { defaultFormat } from '@/utils/formatTime';
import { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DropdownSettingHouses from '../Dropdown/DropdownSetting/DropdownSettingHouses';
import ModalDelete from '../ModalComponent/ModalDelete';
import { EPath } from '@/route/route';
import { convertNumberFormThousands, replacePathParams } from '@/utils/helpers';
import { THouses } from '@/api/types/housesTypes';
import { useDispatch, useSelector } from 'react-redux';
import { deleteHouse } from '@/stores/houses';
import { nanoid } from 'nanoid';
import { RootState } from '@/stores';
import { FilterHouses } from '@/types/houses';

interface ListItemProps {
  data: THouses;
  dataFilter?: FilterHouses;
}

function ListHouseComponent(props: ListItemProps) {
  const { data, dataFilter } = props;
  const dispatch = useDispatch();
  const [showSetting, setShowSetting] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { organizationId } = useParams();
  const toggleSettingBox = () => {
    setShowSetting(!showSetting);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setShowSetting(false));

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleDeleteHouse = () => {
    handleCloseModal();
    dispatch(deleteHouse({ houseId: data.id, organizationId, dataFilter: dataFilter }));
  };

  return (
    <>
      <li key={nanoid(5)} className="flex min-h-[64px] items-center border-b border-outline-variant">
        <Link
          to={replacePathParams(EPath.organizationHouse, {
            organizationId: organizationId ?? '',
            houseId: data.id ?? '',
          })}
          className="houseComponentListColumn w-full"
        >
          <div className="flex gap-[16px] py-2 pl-[16px]">
            <div className="w-12 min-w-[48px]">
              <Image imagePath={data?.image?.[0]?.path ?? ''} />
            </div>
            <div className="my-auto">
              <DefaultText
                variant="text-tableBody"
                className="text-primary-dark truncate-text break-all text-left text-[14px] text-on-surface 2xl:text-[14px]"
              >
                {data.title}
              </DefaultText>
            </div>
          </div>
          <div className="my-auto pl-[16px]">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-center text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {data?.__businessPlan__[0]?.updatedAt ? defaultFormat(data?.__businessPlan__[0]?.updatedAt) : ''}
            </DefaultText>
          </div>
          <div className="my-auto pl-[16px] text-end">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {convertNumberFormThousands(data.unitConversion)}
            </DefaultText>
          </div>
          <div className="my-auto pl-[16px] text-end">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {convertNumberFormThousands(data.treeQuantity ?? 0)}
            </DefaultText>
          </div>
          <div className="my-auto pl-[16px] text-end">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {convertNumberFormThousands(Math.floor(data?.plantingDensity ?? 0))}
            </DefaultText>
          </div>
        </Link>
        {/* More */}
        <div
          className="flex w-[56px] cursor-pointer justify-end gap-4 pr-[16px]"
          onClick={(e) => {
            e.preventDefault();
          }}
          onKeyDown={() => {}}
        >
          {data.createdBy === user?.id && (
            <>
              <IconMore onClick={toggleSettingBox} />
              <div ref={ref} className="mt-8">
                {showSetting && (
                  <DropdownSettingHouses
                    modalModalDeleteClick={handleModalDeleteClick}
                    moveFormPath={replacePathParams(EPath.houseDetailListHouse, {
                      organizationId: Number(organizationId),
                      houseId: Number(data.id),
                    })}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </li>

      <CommonModal
        open={showModalDelete}
        title={'ハウスの削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        handleClick={handleDeleteHouse}
      >
        <ModalDelete
          contentDelete={'ハウスの名前を削除します。ハウス内にある事業計画も削除されます。この操作は元に戻せません。'}
        />
      </CommonModal>
    </>
  );
}

export default ListHouseComponent;
