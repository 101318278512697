import cn from '@/utils/general';
import React from 'react';

type LabelOwnProps = {
  text?: React.ReactNode;
  className?: string;
};

type LabelProps = LabelOwnProps & Omit<React.HTMLProps<HTMLLabelElement>, keyof LabelOwnProps>;

const Label = ({ className, text, ...rest }: LabelProps) => {
  return React.createElement(
    'label',
    {
      className: cn('break-words label', className),
      ...rest,
    },
    text,
  );
};

export default Label;
