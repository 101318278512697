import React, { ForwardRefRenderFunction, forwardRef, ChangeEvent } from 'react';
import c from 'clsx';
import s from './style.module.sass';
import { formatNumberWithoutDecimals } from '@/utils/helpers';

type Props = {
  className?: string;
  type?: 'text' | 'month' | 'date' | 'email' | 'number';
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  min?: number;
  step?: number;
  isStringPseudoNumber?: boolean;
};

const Input: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  const {
    className,
    type = 'text',
    onChange,
    placeholder,
    value,
    hasError,
    min,
    step,
    isStringPseudoNumber = false,
  } = props;

  const inputClassName = c(s.root, className, {
    '!border-2': hasError,
    '!border-error': hasError,
  });

  const getValueInput = () => {
    if (isStringPseudoNumber) {
      if (value) return formatNumberWithoutDecimals(+value);
      else return '';
    } else return value;
  };

  return (
    <input
      ref={ref}
      type={type}
      className={inputClassName}
      placeholder={placeholder}
      onChange={onChange}
      value={getValueInput()}
      min={min}
      step={step}
    />
  );
};

const ForwardedInput = forwardRef(Input);

export default ForwardedInput;
