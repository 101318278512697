import style from './style.module.sass';
import SelectWithIcon from '@/components/atoms/SelectWithIcon';
import { MultiSelect } from 'react-multi-select-component';
import React from 'react';
import { IDataFilter, IFilter, ITypeFilter } from '@/types/question';
import { IOptionFilter, IPagination } from '@/types/common';
import {
  DATA_OPERATOR_FILTER,
  DATA_TYPE_FILTER,
  DATA_TYPE_FILTER_HOUSE,
  typeFilterQuestion,
} from '@/utils/constants/consts';
import { IconArrowDropDown } from '@/components/icon/IconArrowDropDown';

type ModalBodyProps = {
  listFilter: ITypeFilter[];
  isMobile: boolean;
  dataOption: IFilter<IPagination, IDataFilter>;
  setData: (value: ITypeFilter[]) => void;
};
function CustomArrow() {
  return <IconArrowDropDown />;
}
const AllFilter: React.FC<ModalBodyProps> = ({ listFilter, isMobile, setData, dataOption }) => {
  const listOptionConvert = (row: string) => {
    return row
      ? dataOption[row].data?.map((item) => ({
          value: item.id ?? item,
          label: item.title ?? item.name ?? item,
        }))
      : [];
  };

  const handelChangeTypeFilter = (selected: IOptionFilter, index: number) => {
    const result = listFilter.find((item) => item.type === selected.value);
    if (!result) {
      const data = [...listFilter];
      data[index] = {
        id: data[index].id,
        type: selected.value ?? '',
        operator: listFilter[index].operator,
        value: [],
      };
      setData(data);
    } else {
      const dataUpdate = listFilter.map((item) =>
        item.type === selected.value ? { ...item, type: selected.value } : item,
      );
      setData(dataUpdate);
    }
  };

  const handelChangeOperatorFilter = (selected: IOptionFilter, row: string, index: number) => {
    if (row) {
      const result = [...listFilter].find((item) => item.type === row);
      if (!result) {
        const data = [...listFilter];
        data[index] = {
          id: data[index].id,
          type: '',
          operator: selected.value,
          value: [],
        };
        setData(data);
      } else {
        const dataUpdate = listFilter.map((item) => (item.type === row ? { ...item, operator: selected.value } : item));
        setData(dataUpdate);
      }
    }
  };

  const handelChangeValueFilter = (selected: IOptionFilter[], row: string, index: number) => {
    const result = [...listFilter].find((item) => item.type === row);
    const value = selected.map((item) => +item.value);
    if (!result) {
      const data = [...listFilter];
      data[index] = {
        id: data[index].id,
        type: '',
        operator: '',
        value: value,
      };
      setData(data);
    } else {
      const dataUpdate = listFilter.map((item) => (item.type === row ? { ...item, value: value } : item));
      setData(dataUpdate);
    }
  };
  const listFilterConvert = (index: number) => {
    const dataRowNotSelf = [...listFilter].map((item) => item.type);
    dataRowNotSelf.splice(index, 1);
    if (listFilter?.length === 5) {
      return DATA_TYPE_FILTER.filter((item) => !dataRowNotSelf.includes(item.value));
    } else if (listFilter?.length === 2) {
      return DATA_TYPE_FILTER_HOUSE.filter((item) => !dataRowNotSelf.includes(item.value));
    } else {
      return DATA_TYPE_FILTER.filter(
        (item) => !dataRowNotSelf.includes(item.value) && item.value !== typeFilterQuestion.COMMENT,
      );
    }
  };

  const checkValue = (row: string) => {
    const result = listFilter.find((item) => item.type === row)?.value;
    if (result && row) {
      return dataOption[row].data
        ?.filter((item) => result.includes(Number(row === typeFilterQuestion.COMMENT ? item : item.id)))
        .map((item) => ({
          value: row === typeFilterQuestion.COMMENT ? item : item.id,
          label: row === typeFilterQuestion.COMMENT ? String(item) : item.title ?? item.name,
        }));
    }
    return [];
  };

  return (
    <div className={isMobile ? style.modal_body_sp : style.modal_body}>
      {listFilter.map((row, index) => (
        <div
          key={row.id}
          className={isMobile ? style.filter_row_sp : style.filter_row}
          style={{
            background:
              'var(--surface-at-1, linear-gradient(0deg, rgba(54, 49, 0, 0.03) 0%, rgba(54, 49, 0, 0.03) 100%), #FDFBF7)',
          }}
        >
          {index !== 0 && <div className={`${style.label_row} hidden`}>かつ</div>}
          <div className="flex w-full gap-2 md:w-auto">
            <SelectWithIcon
              data={listFilterConvert(index)}
              defaultValue={listFilter[index].type}
              className="cursor-pointer text-base focus:outline-none sm:w-full md:w-[200px]"
              onChange={(item) => handelChangeTypeFilter(item, index)}
              classNameWrap="flex-1"
            >
              <option className="hidden" value="0">
                フィルターを選択
              </option>
            </SelectWithIcon>
            <SelectWithIcon
              data={DATA_OPERATOR_FILTER}
              defaultValue={
                listFilter[index].value.length > 0 && listFilter[index].operator === ''
                  ? DATA_OPERATOR_FILTER[0].value
                  : listFilter[index].operator
              }
              className="w-full cursor-pointer text-base focus:outline-none md:w-[160px]"
              classNameWrap="flex-1"
              onChange={(item) => handelChangeOperatorFilter(item, row.type, index)}
            >
              <option className="hidden" value="0"></option>
            </SelectWithIcon>
          </div>
          <MultiSelect
            hasSelectAll={false}
            options={listOptionConvert(row.type)}
            value={checkValue(row.type)}
            onChange={(item: IOptionFilter[]) => handelChangeValueFilter(item, row.type, index)}
            labelledBy=""
            ArrowRenderer={CustomArrow}
            className="mt-2 h-12 w-full cursor-pointer rounded text-base md:mt-0 md:w-[220px]"
            overrideStrings={{
              selectSomeItems: ' ',
              noOptions: '選択してください',
              allItemsAreSelected: 'すべての項目が選択されています',
            }}
            disableSearch={true}
          />
        </div>
      ))}
    </div>
  );
};

export default AllFilter;
