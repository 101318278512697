import {
  NOTIFICATION_TYPE_BUSINESS_PLAN,
  NOTIFICATION_TYPE_BUSINESS_PLAN_COMMENT,
  NOTIFICATION_TYPE_DOCUMENT,
  NOTIFICATION_TYPE_FEEDBACK,
  NOTIFICATION_TYPE_QUESTION,
  NOTIFICATION_TYPE_QUESTION_COMMENT,
  NOTIFICATION_TYPE_SCHEDULE_COMMENT,
} from '@/utils/constants/consts';
import { EPath } from '@/route/route';
import { replacePathParams } from '@/utils/helpers';
import { IndexedObject } from '@/utils/types';
import { TDataNotification } from '@/types/notification';

type TNotify = TDataNotification & {
  idNotification: number;
  type: number;
  createAt?: string;
  comment?: string;
  recipients?: number;
};

export type NotifyCardProps = {
  data?: TNotify;
};

type NotificationMapping = {
  [key: string]: {
    path: string;
    params: (data: TNotify) => IndexedObject | undefined;
  };
};

const notificationMap: NotificationMapping = {
  [NOTIFICATION_TYPE_QUESTION]: {
    path: EPath.questionDetail,
    params: (data: TNotify) => {
      if (data?.organization?.id) {
        return {
          organizationId: data?.organization?.id,
          id: data?.id,
        };
      }
      return undefined;
    },
  },
  [NOTIFICATION_TYPE_DOCUMENT]: {
    path: EPath.documentDetail,
    params: (data: TNotify) => {
      if (data?.organization?.id) {
        return {
          organizationId: data?.organization?.id,
          id: data?.id,
        };
      }
      return undefined;
    },
  },
  [NOTIFICATION_TYPE_FEEDBACK]: {
    path: EPath.feedbackDetail,
    params: (data: TNotify) => {
      if (data?.organization?.id) {
        return {
          organizationId: data?.organization?.id,
          id: data?.id,
        };
      }
      return undefined;
    },
  },
  [NOTIFICATION_TYPE_BUSINESS_PLAN]: {
    path: EPath.organizationHouseBusiness,
    params: (data: TNotify) => {
      if (data?.greenHouseId) {
        return {
          organizationId: data?.['__house__']?.organization.id,
          houseId: data?.greenHouseId,
          businessId: data?.id,
        };
      }
      return undefined;
    },
  },
  [NOTIFICATION_TYPE_QUESTION_COMMENT]: {
    path: EPath.questionDetail,
    params: (data: TNotify) => {
      if (data?.question?.organization?.id && data?.questionId) {
        return {
          organizationId: data?.question?.organization?.id,
          id: data?.questionId,
        };
      }
      return undefined;
    },
  },
  [NOTIFICATION_TYPE_BUSINESS_PLAN_COMMENT]: {
    path: EPath.organizationHouseBusiness,
    params: (data: TNotify) => {
      if (data?.sheetInfo) {
        return {
          organizationId: data?.sheetInfo?.businessPlanInfo?.['__house__'].organization.id,
          houseId: data?.sheetInfo?.businessPlanInfo?.['__house__'].id,
          businessId: data?.sheetInfo?.businessPlanInfo.id,
        };
      }
      return undefined;
    },
  },
};

export const getNavigatePath = (data?: TNotify) => {
  if (!data) return undefined;
  const notification = notificationMap[data.type];
  const params = notification.params(data);
  if (notification && params) {
    const path = `${replacePathParams(notification.path, params)}`;
    if (data.type === NOTIFICATION_TYPE_SCHEDULE_COMMENT) {
      return `${path}?tab=2`;
    }
    return path;
  }
  return undefined;
};
