import React from 'react';

type IconArrowProps = {
  className?: string;
};
export const IconArrowSelect = (props: IconArrowProps) => {
  const { className } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        className={className}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M7 10L12 15L17 10H7Z" fill="#615F50" />
      </svg>
    </>
  );
};
