import InputChip from '@/components/atoms/InputChips';
import cn from '@/utils/general';
import { Listbox, Transition } from '@headlessui/react';
import { nanoid } from 'nanoid';
import React, { Fragment } from 'react';
import { shareType } from '@/types/enum';
import BodyText from '@/components/atoms/AText/BodyText';

type PublicChipDropDownProps = {
  typeShare: string;
  setTypeShare?: (value: string) => void;
  isUpdate?: boolean;
};

const options = [
  { text: '組織内', value: shareType.private },
  { text: '組織横断', value: shareType.public },
];

const PublicChipDropDown = ({
  typeShare = String(shareType.public),
  setTypeShare,
  isUpdate = true,
}: PublicChipDropDownProps) => {
  return (
    <>
      <Listbox value={typeShare} onChange={setTypeShare}>
        <div className="relative my-auto">
          <Listbox.Button className="relative flex transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in">
            <InputChip
              variant="unselected"
              type="square"
              text={parseInt(typeShare) === shareType.public ? '組織横断' : '組織内'}
              className="icon-button"
              iconRight={isUpdate ? 'icon-button-arrow_drop_down' : ''}
              iconLeft="icon-button-share"
            />
          </Listbox.Button>
          {isUpdate && (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute max-h-60 w-full overflow-auto rounded bg-white text-base shadow-elevation-M3-light-2 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={nanoid(5)}
                    className={({ active }) =>
                      `relative flex h-10 cursor-default select-none items-center hover:cursor-pointer ${
                        active ? 'bg-gray-background ' : ''
                      }`
                    }
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <BodyText
                          size="large"
                          className={cn(
                            'label lg text flex truncate px-3 text-on-surface-variant',
                            selected && 'font-medium',
                          )}
                        >
                          {option.text}
                        </BodyText>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          )}
        </div>
      </Listbox>
    </>
  );
};

export default PublicChipDropDown;
