import style from './style.module.sass';
import Title from '@/components/atoms/Title';
import { IconClose } from '@/components/icon/IconClose/IconClose';
import ButtonNormal from '@/components/atoms/ButtonNormal';
type ModalHeaderProps = {
  text: string;
  isMobile: boolean;
  handleToggleFilterBlock?: () => void;
  handleSubmitFilter: () => void;
};
function ModalHeader(props: ModalHeaderProps) {
  const { text, isMobile, handleToggleFilterBlock, handleSubmitFilter } = props;
  return (
    <div className={`${style.modal_header} lg:mb-3`}>
      {isMobile ? (
        <div className={style.box_header}>
          <div className={style.box_left}>
            <span className={style.iconClose} onClick={handleToggleFilterBlock} onKeyDown={() => {}}>
              <IconClose></IconClose>
            </span>
            <span className={style.title_page}>
              <Title text={text} className={'text-[18px] font-normal leading-[27px]'}></Title>
            </span>
          </div>
          <div className={style.btn_event_cancel} onClick={handleSubmitFilter} onKeyDown={() => {}}>
            <ButtonNormal></ButtonNormal>
          </div>
        </div>
      ) : (
        <Title text={text} className={'text-[18px] font-normal leading-[27px]'}></Title>
      )}
    </div>
  );
}

export default ModalHeader;
