import { getIcon } from '@/utils/helpers';
import style from './style.module.sass';
import React from 'react';

type DropdownSettingProps = {
  modalModalDeleteClick?: () => void;
  modalModalBusinessPlanClick?: () => void;
};

const DropdownEditBusiness: React.FC<DropdownSettingProps> = ({
  modalModalDeleteClick,
  modalModalBusinessPlanClick,
}: DropdownSettingProps) => {
  return (
    <div className={style.setting_box}>
      <div className={style.setting_list_item}>
        <button className={style.setting_item} onClick={modalModalBusinessPlanClick}>
          {getIcon('edit')}
          <span style={{ color: '#1D1C16' }}>リネーム</span>
        </button>
        <button className={style.setting_item_trash} onClick={modalModalDeleteClick}>
          {getIcon('trash')}
          <span style={{ color: '#BA1A1A' }}>削除</span>
        </button>
      </div>
    </div>
  );
};

export default DropdownEditBusiness;
