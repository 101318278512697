import { DataDropDown, getIcon, replacePathParams } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import style from './style.module.sass';
import { EPath } from '@/route/route';

type DropdownSettingProps = {
  readonly modalModalDeleteClick?: () => void;
  readonly listDataDropdown?: DataDropDown[];
  readonly organizationId?: number;
  readonly isHidenDeleteIcon?: boolean;
};

function DropdownSetting(props: DropdownSettingProps) {
  const { modalModalDeleteClick, organizationId, isHidenDeleteIcon } = props;
  return (
    <>
      <div className={style.setting_box}>
        <div className={style.setting_list_item}>
          <Link to={replacePathParams(EPath.settingOrganization, { organizationId: organizationId ?? '' })}>
            <div className={style.setting_item}>
              {getIcon('setting')}

              <span style={{ color: '#1D1C16', fontFamily: 'Noto Sans JP, sans-serif' }}>組織設定</span>
            </div>
          </Link>
          {!isHidenDeleteIcon && (
            <div className={style.setting_item} onClick={modalModalDeleteClick}>
              {getIcon('trash')}
              <span style={{ color: '#BA1A1A', fontFamily: 'Noto Sans JP, sans-serif' }}>組織を削除</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DropdownSetting;
