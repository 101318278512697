import React from 'react';

type Props = {
  className?: string;
};
const IconColonization: React.FC<Props> = (props: Props) => {
  const { className } = props;

  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect
          x="12.272"
          y="-0.727905"
          width="18"
          height="18"
          rx="2"
          transform="rotate(45 12.272 -0.727905)"
          fill="#00A1A2"
        />
      </svg>
    </>
  );
};

export default IconColonization;
