import Breadcrumb, { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { IconArrowSelect } from '@/components/icon/IconArrowDropUp/IconArrowSelect';
import c from 'clsx';
import React, { useState } from 'react';
import ModalDelete from '../../ModalComponent/ModalDelete';
import s from './../style.module.sass';
import { Chart } from '@/stores/chart/model';

type ChartButtonProps = {
  label: string;
  onClick: () => void;
  isArrowClose: boolean;
  data: Chart;
};

const ChartButtonAll: React.FC<ChartButtonProps> = ({ label, onClick, isArrowClose, data }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const BREADCRUMBS_ROOT: TBreadcrumb[] = [
    {
      name: data.organization.title,
      to: '#',
    },
    {
      name: data.house.title,
      to: '#',
    },
    {
      name: data.businessPlan.title,
      to: '#',
    },
    {
      name: data.sheet.title,
      to: '#',
    },
  ];

  const customLabel = (str: string) => {
    return str.split('/')[0];
  };

  return (
    <>
      <button className="flex h-12 w-full py-2 pl-2 pr-3 hover:bg-gray-background ">
        <div className={s.groupButton} onClick={onClick}>
          <IconArrowSelect className={c(isArrowClose && s.arrowClose)} />
          <div className="w-full truncate">
            <div
              title={`${data.organization.title} > ${data.house.title} > ${data.businessPlan.title} > ${data.sheet.title} `}
            >
              <Breadcrumb data={BREADCRUMBS_ROOT} className="h-[18px] w-fit whitespace-nowrap" />
            </div>
            <DefaultText variant="text-link" className="text-left text-sm text-on-surface">
              {customLabel(label ?? '')}
            </DefaultText>
          </div>
        </div>
      </button>
      <CommonModal
        open={showModalDelete}
        title={'エリアの削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete contentDelete={'エリアの名前を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ChartButtonAll;
