import React from 'react';
import style from '@/components/molecules/Dropdown/DropdownExport/style.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { downloadCsv, downloadPdf } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import moment from 'moment';

const DropdownExport: React.FC = () => {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive, businessDetail, listSheet } = useSelector((state: RootState) => state.business);

  const downloadFile = (res: Blob, nameFile: string) => {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', nameFile);
    const href = URL.createObjectURL(res);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  };

  const handleDownloadCSV = async () => {
    if (businessId && sheetActive) {
      const sheet = listSheet.find((item) => item.id === sheetActive);
      const time = moment().format('YYYYMMDD');
      dispatch(
        downloadCsv({
          businessId: +businessId,
          sheetId: sheetActive,
          callback: (dataResCsv) =>
            downloadFile(dataResCsv, `事業計画_${businessDetail?.title}_${sheet?.title}_${time}`),
        }),
      );
    }
  };

  const handleDownloadPdf = async () => {
    if (businessId && sheetActive) {
      const sheet = listSheet.find((item) => item.id === sheetActive);
      const time = moment().format('YYYYMMDD');
      dispatch(
        downloadPdf({
          businessId: +businessId,
          sheetId: sheetActive,
          callback: (dataResCsv) =>
            downloadFile(dataResCsv, `事業計画_${businessDetail?.title}_${sheet?.title}_${time}`),
        }),
      );
    }
  };

  return (
    <div className={style.setting_box}>
      <div className={style.setting_list_item}>
        <div className={style.setting_item}>
          <span className="font-noto text-on-surface" onClick={handleDownloadCSV} onKeyDown={() => {}}>
            CSVエクスポート
          </span>
        </div>
        <div className={style.setting_item}>
          <span className="font-noto text-on-surface" onClick={handleDownloadPdf} onKeyDown={() => {}}>
            PDFエクスポート
          </span>
        </div>
      </div>
    </div>
  );
};

export default DropdownExport;
