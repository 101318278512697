import ForwardedInput from '@/components/atoms/Input';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateCircleInfoBusinessPlan } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import { Chart } from '@/stores/chart/model';
import { defaultFormat, formatTimeForInputDateDefault } from '@/utils/formatTime';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { MESSAGE_REQUIRED_NAME, MESSAGE_VALIDATE_DAY } from '@/utils/constants/messageValidate';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import FieldDateCustom from '@/components/atoms/FieldDateCustom';
import 'react-datepicker/dist/react-datepicker.css';
import { ICircleMultiple } from '@/stores/business/model';

interface Props {
  circleData: Chart;
  handleClose: () => void;
}

const ModalSowingChart = (prop: Props) => {
  const { circleData, handleClose } = prop;
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICircleMultiple>();

  useEffect(() => {
    if (circleData) {
      setValue('name', circleData.name);
      setValue('sowing', formatTimeForInputDateDefault(circleData.sowing));
      setValue('planting', formatTimeForInputDateDefault(circleData.planting));
      setValue('startOfHarvest', formatTimeForInputDateDefault(circleData.harvest.start));
      setValue('harvestFinished', formatTimeForInputDateDefault(circleData.harvest.end));
    }
  }, [circleData]);

  const handleSubmitEmail = (data: ICircleMultiple) => {
    if (businessId && circleData) {
      const dataUpdate = {
        title: data.name,
        seedDate: defaultFormat(data.sowing),
        transplantDate: defaultFormat(data.planting),
        harvestStartDate: defaultFormat(data.startOfHarvest),
        harvestEndDate: defaultFormat(data.harvestFinished),
      };
      dispatch(
        updateCircleInfoBusinessPlan({
          businessId: +businessId,
          sheetId: sheetActive,
          circleId: +circleData.id,
          dataUpdate,
          callback: handleClose,
        }),
      );
    }
  };

  const validateCustomRule = (value: string, name: string) => {
    if (name.includes('planting')) {
      if (new Date(value) < new Date(watch('sowing'))) {
        return MESSAGE_VALIDATE_DAY;
      }
    } else if (name.includes('startOfHarvest')) {
      if (new Date(value) < new Date(watch('sowing')) || new Date(value) < new Date(watch('planting'))) {
        return MESSAGE_VALIDATE_DAY;
      }
    } else if (name.includes('harvestFinished')) {
      if (
        new Date(value) < new Date(watch('sowing')) ||
        new Date(value) < new Date(watch('planting')) ||
        new Date(value) < new Date(watch('harvestFinished'))
      ) {
        return MESSAGE_VALIDATE_DAY;
      }
    }
    return undefined;
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitEmail)}>
      <div className="space-y-0.5 py-2 lg:space-y-4 lg:py-6">
        <div className="space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            名前
          </DefaultText>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
            }}
            render={({ field }) => (
              <ForwardedInput
                {...field}
                type="text"
                className={'border-1 h-10 w-full rounded border-on-surface-variant px-4 py-1 lg:h-12'}
              />
            )}
          />
          <ErrorTooltip errorMessage={errors?.name?.message} hasError={!!errors?.name?.message} />
        </div>
        <div className="space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            播種
          </DefaultText>
          <Controller
            name="sowing"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
            }}
            render={({ field }) => <FieldDateCustom {...field} name={'sowing'} setValue={setValue} />}
          />
          <ErrorTooltip errorMessage={errors?.sowing?.message} hasError={!!errors?.sowing?.message} />
        </div>
        <div className="w-full space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            定植
          </DefaultText>
          <Controller
            name="planting"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
              validate: (value) => validateCustomRule(value, 'planting'),
            }}
            render={({ field }) => <FieldDateCustom {...field} name={'planting'} setValue={setValue} />}
          />
          <ErrorTooltip errorMessage={errors?.planting?.message} hasError={!!errors?.planting?.message} />
        </div>
        <div className="space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            収穫開始
          </DefaultText>
          <Controller
            name="startOfHarvest"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
              validate: (value) => validateCustomRule(value, 'startOfHarvest'),
            }}
            render={({ field }) => <FieldDateCustom {...field} name={'startOfHarvest'} setValue={setValue} />}
          />
          <ErrorTooltip errorMessage={errors?.startOfHarvest?.message} hasError={!!errors?.startOfHarvest?.message} />
        </div>
        <div className="space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            収穫終了
          </DefaultText>
          <Controller
            name="harvestFinished"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_NAME,
              validate: (value) => validateCustomRule(value, 'harvestFinished'),
            }}
            render={({ field }) => <FieldDateCustom {...field} name={'harvestFinished'} setValue={setValue} />}
          />
          <ErrorTooltip errorMessage={errors?.harvestFinished?.message} hasError={!!errors?.harvestFinished?.message} />
        </div>
        <div className="space-y-0.5 lg:space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            {
              '栽培スケジュールを更新しますが、収益計画にはまだ反映されていません。収益計画に反映させる場合は、「収益計画に反映」というボタンを押下してください。'
            }
          </DefaultText>
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <div className="my-auto cursor-pointer" onClick={handleClose} onKeyDown={() => {}}>
          <DefaultText variant="length" className="text-sm text-on-surface">
            キャンセル
          </DefaultText>
        </div>
        <PrimaryButton text="保存" variant="outlined" type="submit" />
      </div>
    </form>
  );
};

export default ModalSowingChart;
