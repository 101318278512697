import React, { ChangeEvent } from 'react';
import { NumericFormat } from 'react-number-format';
import { SetValueConfig } from 'react-hook-form/dist/types/form';
import Decimal from 'decimal.js';
import { REGEX } from '@/utils/constants/regex';

type Props = {
  className?: string;
  setValue?: (name: string, value: string, options?: SetValueConfig) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  step?: number;
  min?: number;
  max?: number;
  name?: string;
  thousandSeparator?: boolean;
  allowLeadingZeros?: boolean;
  allowNegative?: boolean;
  decimalScale?: number;
  isHiddenArrowUpDown?: boolean;
  isFiledNumberDialog?: boolean;
  hasError?: boolean;
};

const StepperNumberFormat: React.FC<Props> = (props: Props) => {
  const {
    onChange,
    value: valueInit,
    step,
    className,
    thousandSeparator = true,
    allowLeadingZeros = false,
    allowNegative = false,
    decimalScale = 0,
    setValue,
    name,
    max,
    min,
    isHiddenArrowUpDown = true,
    isFiledNumberDialog = false,
    hasError,
  } = props;

  const getDecimalMultiplier = (num: number): number => {
    const decimalPart = num.toString().split('.')[1];
    return decimalPart ? 10 ** decimalPart.length : 1;
  };

  const increaseValue = () => {
    if (valueInit !== undefined && step && setValue) {
      const multiplier = getDecimalMultiplier(step);
      const remainder = (+valueInit * multiplier) % (step * multiplier);
      const newValue = new Decimal(+valueInit * multiplier).plus(step * multiplier - remainder).toString();
      if (max && Number(+valueInit + step) > max + remainder) return;
      setValue(name ?? '', new Decimal(+newValue).div(multiplier).toString(), { shouldValidate: true });
    }
  };

  const decreaseValue = () => {
    if (valueInit !== undefined && step && (min || min === 0) && setValue) {
      const value = valueInit?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '');
      const multiplier = getDecimalMultiplier(step);
      const remainder = (+value * multiplier) % (step * multiplier);
      const newValue = new Decimal(+value * multiplier).sub(remainder || step * multiplier).toString();
      if (Number(+value * multiplier - (remainder ? 0 : remainder + step * multiplier)) < min * multiplier) return;
      setValue(name ?? '', new Decimal(+newValue).div(multiplier).toNumber().toString(), { shouldValidate: true });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp') {
      increaseValue();
    }

    if (e.key === 'ArrowDown') {
      decreaseValue();
    }
  };

  const valueInput = () => {
    if (isFiledNumberDialog && valueInit === '') return '0';
    else if (!isFiledNumberDialog && valueInit === '') return '';
    else return valueInit;
  };

  return (
    <div className={`relative ${className ?? ''}`}>
      <NumericFormat
        thousandSeparator={thousandSeparator}
        allowLeadingZeros={allowLeadingZeros}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        className={`inline-block h-12 w-full rounded border border-bor-default px-4 py-2 lg:h-12 ${
          hasError && '!border-2 !border-error'
        }`}
        value={valueInput()}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      <div
        className={`absolute right-0 top-0 h-full w-6 flex-col border-l border-outline ${
          isHiddenArrowUpDown ? 'hidden' : 'flex'
        }`}
      >
        <div
          className="flex h-1/2 w-full cursor-pointer items-center justify-center border-b border-outline hover:bg-black/[0.08]"
          onClick={increaseValue}
          onKeyDown={() => {}}
        >
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 5L5 0L10 5H0Z" fill="#1D1C16" />
          </svg>
        </div>
        <div
          className="flex h-1/2 w-full cursor-pointer items-center justify-center hover:bg-black/[0.08]"
          onClick={decreaseValue}
          onKeyDown={() => {}}
        >
          <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L5 5L10 0H0Z" fill="#1D1C16" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default StepperNumberFormat;
