import Decimal from 'decimal.js';
import { formatNumberWithCommas } from '@/utils/helpers';
export const removeComma = (num: string): number | null => {
  const value = num.replaceAll(',', '');
  return !isNaN(Number(value)) ? Number(value) : null;
};

export const isDivisible = (num: string, step: number): boolean => {
  const value = removeComma(num);
  if (value !== null) {
    const decimalValue = new Decimal(+value);
    return decimalValue.mod(step).equals(0);
  }
  return false;
};

export const convertDateBp = (num1: number, num2: number): string => {
  if (num1 === 0 || num2 === 0) return '0';
  return String(+((num1 / num2) * 100).toFixed(2));
};

export const convertDateTotal = (total: number, count: number): string => {
  const result = formatNumberWithCommas(String((total / count).toFixed(2)));
  return `${+result < 0 ? '▲' + result : result}%.`;
};
