import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import { ITag, ITagCreate } from '@/types/tag';
import { IPagination, TCallback } from '@/types/common';
import { setShowToast } from '@/stores/common';
import { messageNotification } from '@/utils/constants/messageNotification';

export interface QuestionState {
  tags: ITag[];
  page?: number;
  limit?: number;
  total?: number;
}

const initialState: QuestionState = {
  tags: [],
  page: 1,
  limit: 10,
  total: 0,
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    addTags: (state, action) => {
      state.tags = [action.payload, ...state.tags];
    },
  },
});

export const createTags = createAsyncThunk('tag/getListTags', async (data: ITagCreate, thunkAPI) => {
  const response = await axiosInstance.post('tags', data);
  thunkAPI.dispatch(addTags(response.data));
});

export const getListTags = createAsyncThunk('tag/getListTags', async (params: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `tags?page=${params.page}&limit=${params.limit}&keyword=${params.keyword}&organizationId=${params.organizationId}`,
  );
  thunkAPI.dispatch(setTags(response.data.items));
});

export const deleteTag = createAsyncThunk('tag/deleteTag', async (id: number, thunkAPI) => {
  await axiosInstance.delete(`tags/${id}`);
  thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
});

export const editNameTag = createAsyncThunk(
  'tag/editNameTag',
  async (params: ITag & { organizationId: number } & TCallback, thunkAPI) => {
    await axiosInstance.patch(`tags/${params.id}`, {
      title: params.nameEdit,
      organizationId: params.organizationId,
    });
    thunkAPI.dispatch(
      getListTags({
        page: 0,
        limit: 0,
        keyword: '',
        organizationId: params.organizationId,
      }),
    );
    params.callback?.();
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
  },
);

export const { setTags, addTags } = questionSlice.actions;
export default questionSlice.reducer;
