import { DataDropDown, getIcon, replacePathParams } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import style from './style.module.sass';
import { EPath } from '@/route/route';

type DropdownSettingProps = {
  modalModalDeleteClick?: () => void;
  listDataDropdown?: DataDropDown[];
  organizationId: number | undefined;
};

function DropdownSettingListHouses({ modalModalDeleteClick, organizationId }: DropdownSettingProps) {
  return (
    <div className={style.setting_box}>
      <div className={style.setting_list_item}>
        <Link to={replacePathParams(EPath.settingOrganization, { organizationId: organizationId ?? '' })}>
          <div className={style.setting_item}>
            {getIcon('account2')}

            <span className="font-noto" style={{ color: '#1D1C16' }}>
              組織設定
            </span>
          </div>
        </Link>
        <div className={style.setting_item} onClick={modalModalDeleteClick}>
          {getIcon('trash')}
          <span className="font-noto" style={{ color: '#BA1A1A' }}>
            組織を削除
          </span>
        </div>
      </div>
    </div>
  );
}

export default DropdownSettingListHouses;
