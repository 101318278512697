import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import IconDragDrop from '@/components/icon/IconDragDrop';
import { MESSAGE_REQUIRED_DEFAULT } from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import IconTrashBlack from '@/components/icon/IconTrash/IconTrashBlack';
import { Control } from 'react-hook-form/dist/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { handleOnchangeDialog } from '@/utils/helpers';

interface SortableItemProps {
  id: string;
  errors:
    | {
        [k: string]:
          | {
              message?: string;
            }
          | undefined;
      }
    | undefined;
  control: Control;
  handleModalDeleteClick: (id: number) => void;
}
export const SortableArea: FC<SortableItemProps> = ({ id, errors, control, handleModalDeleteClick }) => {
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });
  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      key={id}
      className={`mt-0.5 h-14 w-fit space-y-2 rounded-lg border border-outline-variant bg-surface p-2 lg:h-[72px] lg:p-3 ${
        errors?.[`title-${id}`]?.message || errors?.[`area-${id}`]?.message ? '!h-[84px]' : ''
      }`}
      style={styles}
    >
      <div className="flex gap-px lg:gap-2">
        <div {...listeners} className="my-auto">
          <IconDragDrop />
        </div>
        <div className="flex gap-2">
          <div>
            <Controller
              name={`title-${id}`}
              control={control}
              defaultValue=""
              rules={{
                required: MESSAGE_REQUIRED_DEFAULT,
              }}
              render={({ field }) => (
                <ForwardedInput
                  {...field}
                  type="text"
                  className={'border-1 h-10 w-full min-w-[366px] rounded border-outline px-4 py-1 lg:h-12'}
                />
              )}
            />
            <ErrorTooltip
              errorMessage={errors?.[`title-${id}`]?.message}
              hasError={!!errors?.[`title-${id}`]?.message}
            />
          </div>
          <div>
            <Controller
              name={`area-${id}`}
              control={control}
              rules={{
                required: MESSAGE_REQUIRED_DEFAULT,
              }}
              render={({ field }) => (
                <ForwardedInput
                  {...field}
                  type="text"
                  className={
                    'border-1 h-10 w-full min-w-[80px] rounded border-outline px-4 py-1 lg:h-12 lg:min-w-[130px]'
                  }
                  onChange={(e) => handleOnchangeDialog(e, field.onChange)}
                  isStringPseudoNumber={true}
                />
              )}
            />
            <ErrorTooltip errorMessage={errors?.[`area-${id}`]?.message} hasError={!!errors?.[`area-${id}`]?.message} />
          </div>
        </div>
        <div className="my-auto" onClick={() => handleModalDeleteClick(+id)} onKeyDown={() => {}}>
          <IconTrashBlack />
        </div>
      </div>
    </div>
  );
};
