import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EPath } from '@/route/route';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from '@/route/PrivateRoute';
import PublicRoute from '@/route/PublicRoute';
import { useDispatch, useSelector } from 'react-redux';
import { history, RootState } from '@/stores';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import LoadingSpinner from '@/components/atoms/Loading';
import { ToastContainer } from 'react-toastify';
import FormLoginPassword from '@/components/auth/FormLoginPassword/FormLoginPassword';
import { getInfoUser } from '@/stores/auth';
import { useLoadScript } from '@react-google-maps/api';
import { setLoadMap } from '@/stores/common';
import moment from 'moment-timezone';

import Main from '../pages/Main';
import Layout from '../layout';
import LayoutAuth from '../layout/LayoutAuth';
import Organization from '../pages/Organization';
import SettingOrganization from '../pages/SettingOrganization';
import AllQuestionsWrap from '../pages/AllQuestion';
import AllHousesWrap from '../pages/AllHouses';
import AllInstructionWrap from '../pages/AllInstruction';
import AllLiteratureWrap from '../pages/AllLiterature';
import Account from '../pages/Account';
import QuestionOrganization from '../pages/QuestionOrganization';
import FeedbackOrganization from '../pages/FeedbackOrganization';
import DocumentOrganization from '../pages/DocumentOrganization';
import Invitation from '../components/auth/Invitation/Invitation';
import Login from '@/components/auth/Login/Login';
import Register from '../components/auth/SignUp/SignUp';
import NewQuestion from '../pages/NewQuestion';
import EditQuestion from '../pages/EditQuestion';
import NewFeedback from '../pages/NewFeedback';
import EditFeedback from '../pages/EditFeedback';
import NewDocument from '../pages/NewDocument';
import EditDocument from '../pages/EditDocument';
import DetailQuestion from '../pages/DetailQuestion';
import DetailDocument from '../pages/DetailDocument';
import DetailFeedback from '../pages/DetailFeedback';
import DetailHouse from '../pages/DetailHouse';
import Notification from '../pages/Notification';
import BusinessPlan from '../pages/BusinessPlan';
import OrganizationHouse from '../pages/OrganizationHouse';
import ListHouse from '../pages/ListHouse';

const routes = [
  {
    path: '/',
    component: LayoutAuth,
    isParent: true,
    children: [
      { path: EPath.chart, component: Main, exact: true },
      { path: EPath.newQuestion, component: NewQuestion },
      { path: EPath.editQuestion, component: EditQuestion },
      { path: EPath.editQuestionAll, component: EditQuestion },
      { path: EPath.editFeedbackAll, component: EditFeedback },
      { path: EPath.editDocumentAll, component: EditDocument },
      { path: EPath.newFeedback, component: NewFeedback },
      { path: EPath.editFeedback, component: EditFeedback },
      { path: EPath.newDocument, component: NewDocument },
      { path: EPath.editDocument, component: EditDocument },
      { path: EPath.settingOrganization, component: SettingOrganization },
      { path: EPath.houseDetail, component: DetailHouse },
      { path: EPath.houseDetailListHouse, component: DetailHouse },
      { path: EPath.editHousesDetailAll, component: DetailHouse },
      { path: EPath.settingHousesDetailAll, component: DetailHouse },
    ],
  },
  {
    path: '/',
    component: Layout,
    isParent: true,
    children: [
      { path: EPath.organization, component: Organization },
      { path: EPath.organizationDetail, component: Organization },
      { path: EPath.questions, component: AllQuestionsWrap },
      { path: EPath.feedbacks, component: AllInstructionWrap },
      { path: EPath.documents, component: AllLiteratureWrap },
      { path: EPath.houses, component: AllHousesWrap },
      { path: EPath.account, component: Account },
      { path: EPath.questionDetail, component: DetailQuestion },
      { path: EPath.questionDetailAll, component: DetailQuestion },
      { path: EPath.feedbackDetail, component: DetailFeedback },
      { path: EPath.feedbackDetailAll, component: DetailFeedback },
      { path: EPath.documentDetail, component: DetailDocument },
      { path: EPath.documentDetailAll, component: DetailDocument },
      { path: EPath.houseDetail, component: DetailHouse },
      { path: EPath.organizationHouse, component: OrganizationHouse },
      { path: EPath.notification, component: Notification },
      { path: EPath.business, component: BusinessPlan },
      { path: EPath.organizationQuestion, component: QuestionOrganization },
      { path: EPath.organizationFeedback, component: FeedbackOrganization },
      { path: EPath.organizationDocument, component: DocumentOrganization },
      { path: EPath.organizationHouseList, component: ListHouse },
      { path: EPath.organizationHouseBusiness, component: BusinessPlan },
      { path: EPath.housesDetailAll, component: OrganizationHouse },
    ],
  },
];

const App = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_API ?? '',
    libraries: ['places'],
    language: 'ja',
  });
  moment.tz.setDefault('Asia/Tokyo');
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(setLoadMap(isLoaded));
  }, [isLoaded]);

  useEffect(() => {
    if (user) dispatch(getInfoUser(Number(user?.id)));
  }, []);

  return (
    <>
      <Router history={history}>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              path={EPath.root}
              element={
                <PublicRoute isAuthenticated={isAuthenticated}>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={EPath.login}
              element={
                <PublicRoute isAuthenticated={isAuthenticated}>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path={EPath.register}
              element={
                <PublicRoute isAuthenticated={isAuthenticated}>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path={EPath.invite}
              element={
                <PublicRoute isAuthenticated={isAuthenticated}>
                  <Invitation />
                </PublicRoute>
              }
            />
            <Route
              path={EPath.password}
              element={
                <PublicRoute isAuthenticated={isAuthenticated}>
                  <FormLoginPassword />
                </PublicRoute>
              }
            />
            {routes.map((route) => {
              if (route?.isParent) {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <PrivateRoute isAuthenticated={isAuthenticated}>
                        <route.component />
                      </PrivateRoute>
                    }
                  >
                    {route.children.map((c) => (
                      <Route
                        key={c.path}
                        path={c.path}
                        element={
                          <PrivateRoute isAuthenticated={isAuthenticated}>
                            <c.component />
                          </PrivateRoute>
                        }
                      />
                    ))}
                  </Route>
                );
              } else {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <PrivateRoute isAuthenticated={isAuthenticated}>
                        <route.component />
                      </PrivateRoute>
                    }
                  />
                );
              }
            })}
            <Route path="*" element={<Navigate to={EPath.notFoundPage} />} />
          </Routes>
        </Suspense>
      </Router>
      <ToastContainer />
      <LoadingSpinner />
    </>
  );
};

export default App;
