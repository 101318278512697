import cn from '@/utils/general';
import React from 'react';

type ParagraphOwnProps = {
  content?: React.ReactNode;
  className?: string;
};

type ParagraphProps = ParagraphOwnProps & Omit<React.HTMLProps<HTMLParagraphElement>, keyof ParagraphOwnProps>;

const Paragraph = ({ className, content, ...rest }: ParagraphProps) => {
  return React.createElement(
    'p',
    {
      className: cn('break-words', className),
      ...rest,
    },
    content,
  );
};

export default Paragraph;
