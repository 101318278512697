import ForwardedInput from '@/components/atoms/Input';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { useDispatch } from 'react-redux';
import { getAPIBusinessList, updateNameBP } from '@/stores/business/services';
import { useParams } from 'react-router-dom';

interface IFormInputs {
  name: string;
}

interface Props {
  name: string;
  id: number;
  handleClose: () => void;
}
const ModalBusinessPlan = (props: Props) => {
  const { name, id, handleClose } = props;
  const dispatch = useDispatch();
  const { houseId } = useParams();
  const { control, setValue, handleSubmit } = useForm<IFormInputs>();

  useEffect(() => {
    setValue('name', name);
  }, [name]);

  const editBusinessPlan = async (values: IFormInputs) => {
    await dispatch(
      updateNameBP({
        id: id,
        title: values.name,
      }),
    );
    if (houseId) {
      await dispatch(getAPIBusinessList({ id: +houseId }));
    }
    handleClose();
  };

  return (
    <>
      <form onSubmit={handleSubmit(editBusinessPlan)}>
        <div className="space-y-4 py-6">
          <div className="space-y-2">
            <DefaultText variant="title-children" className="text-on-surface">
              名前
            </DefaultText>
            <Controller
              name="name"
              control={control}
              defaultValue="name"
              render={({ field }) => (
                <ForwardedInput
                  {...field}
                  type="text"
                  className={'border-1 h-12 w-full rounded border-on-surface-variant px-4 py-1'}
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <div
            className="my-auto cursor-pointer rounded-xl px-3 pb-[10px] pt-[9px] hover:bg-black/[0.08]"
            onClick={handleClose}
            onKeyDown={() => {}}
          >
            <DefaultText variant="length" className="text-sm text-on-primary">
              キャンセル
            </DefaultText>
          </div>
          <PrimaryButton text="保存" variant="outlined" type="submit" />
        </div>
      </form>
    </>
  );
};

export default ModalBusinessPlan;
