import cn from '@/utils/general';
import { ReactNode } from 'react';
import Label from '@/components/atoms/AText/Label';

type ButtonProps = {
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};
export const FilledButton = ({ children, onClick, disabled, className }: ButtonProps) => {
  return (
    <>
      <button
        className={cn(
          `flex flex-row items-center overflow-hidden rounded-xl
          border border-solid 
          border-outline
          bg-primary
          px-6 pb-[9px] pt-[8px]
          text-on-primary transition-all
          duration-200
          ease-in
          hover:shadow-elevation-light-1 active:scale-[0.98] active:transition-all active:duration-200 active:ease-in disabled:pointer-events-none disabled:bg-disabled disabled:bg-opacity-[0.12]
          `,
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        aria-disabled={disabled}
      >
        <i className="mr-2 hidden h-[18px] w-[18px]" />
        <Label
          tag="span"
          size="large"
          className="active::before:bg-white/[0.12] pointer-events-none relative mx-auto text-center text-inherit before:absolute before:-left-6 before:-top-2 before:hidden before:h-[calc(100%+8px+9px)] before:w-[calc(100%+48px)] hover:before:bg-white/[0.08]"
        >
          {children}
        </Label>
        <i className="ml-2 hidden h-[18px] w-[18px]" />
      </button>
    </>
  );
};

export const OutLinedButton = ({ children, onClick, disabled, className }: ButtonProps) => {
  return (
    <>
      <button
        className={cn(
          `flex items-center justify-center overflow-hidden rounded-xl border border-solid border-outline bg-transparent
          px-6 pb-[9px]
          pt-[8px]
          text-on-primary transition-all duration-200
          hover:bg-M3-light-state-layer-primary/[0.08] active:bg-M3-light-state-layer-primary/[0.12]
          disabled:bg-on-surface
          disabled:bg-opacity-[0.12]
          `,
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        aria-disabled={disabled}
      >
        <i className="mr-2 hidden h-[18px] w-[18px] " />
        <Label tag="span" size="large" tColor="text-on-primary">
          {children}
        </Label>
        <i className="ml-2 hidden h-[18px] w-[18px] " />
      </button>
    </>
  );
};

export const TextButton = ({ children, onClick, disabled, className }: ButtonProps) => {
  return (
    <>
      <button
        className={cn(
          `flex items-center justify-center overflow-hidden rounded-xl border border-none
          bg-transparent px-6
          pb-[9px]
          pt-[8px] text-on-primary transition-all
          duration-200 hover:bg-M3-light-state-layer-primary/[0.08]
          active:bg-M3-light-state-layer-primary/[0.12] disabled:bg-on-surface
          disabled:bg-opacity-[0.12]
          `,
          className,
        )}
        onClick={onClick}
        disabled={disabled}
      >
        <i className="mr-2 hidden h-[18px] w-[18px] " />
        <Label tag="span" size="large">
          {children}
        </Label>
        <i className="ml-2 hidden h-[18px] w-[18px] " />
      </button>
    </>
  );
};
