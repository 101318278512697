const REGEX = {
  VALIDATE_MAIL: /^[\w.+-]{1,64}@[a-z\d](?:[a-z\d-]{0,253}[a-z\d])?(?:\.[a-z\d](?:[a-z\d-]{0,253}[a-z\d])?)+$/,
  VALIDATE_TEXT_ALL_SPACE: /^\s*$/gi,
  VALIDATE_NUMBER: /^[0-9\b]+$/,
  VALIDATE_PASSWORD: /^\d+$/,
  DETECT_LINK: /(https?:\/\/[^\s]+)/g,
  imageExtensionsRegex: /\.(png|jpg|jpeg|gif|bmp|tiff)$/i,
  convertNumberFormThousandsRegex: /\B(?=(\d{3})+(?!\d))/g,
  REMOVE_NON_NUMERIC_CHARACTERS: /[^\d.]/g,
  INTEGER_NUMBER: /(^0+)|(\D+)/g,
  LEADING_ZEROS_AND_COMMAS: /(^0+|,)/g,
};

export { REGEX };
