import PrimaryButton from '@/components/atoms/PrimaryButton';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import InputFormText from '@/components/atoms/InputFormText';
import Label from '@/components/atoms/Label';
import Text from '@/components/atoms/Text';
import MapComponent from '@/components/pages/AllHouses/MapComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EPath } from '@/route/route';
import { getHousesDetailAsync, setPath, updateHousesAsync, uploadFileHouses } from '@/stores/houses';
import { RootState } from '@/stores';
import DisplayImage from '@/components/molecules/DisplayImage';
import { THousesUpdate } from '@/api/types/housesTypes';
import { handleOnChangeInputNumberFormat, replacePathParams } from '@/utils/helpers';
import {
  MESSAGE_REQUIRED_DEFAULT,
  MESSAGE_REQUIRED_HOUSE_NAME,
  MESSAGE_VALIDATE_ADDRESS_HOUSE,
  MESSAGE_VALIDATE_MIN,
} from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { REGEX } from '@/utils/constants/regex';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';
import { TMarker } from '@/types/houses';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useOutsideClick } from '@/hooks/useClickOutside';

const InformationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onBlur',
  });
  const [image, setImage] = useState<string | null>(null);
  const { houseDetail, path } = useSelector((state: RootState) => state.houses);
  const [isClosedImg, setIsClosedImg] = useState<boolean>(false);
  const [markers, setMarkers] = useState<TMarker[]>([]);
  const [selected, setSelected] = useState<TMarker | null>(null);
  const [isFocusSearch, setIsFocusSearch] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [errorPlace, setErrorPlace] = useState<string>('');

  const {
    setValue: setValuePlacesAutocomplete,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({});

  useEffect(() => {
    if (houseDetail) {
      setMarkers([
        {
          id: houseDetail.id,
          lat: +houseDetail.coordinatesLat,
          lng: +houseDetail.coordinatesLong,
        },
      ]);
      setSelected({
        lat: +houseDetail.coordinatesLat,
        lng: +houseDetail.coordinatesLong,
      });
    }
  }, [houseDetail]);

  const onSubmit = (data: FieldValues) => {
    setIsSubmit(true);
    if (errorPlace) return;
    if (houseDetail) {
      const dataUpdate: THousesUpdate = {
        id: houseDetail?.id,
        title: data.name,
        area: +data?.area?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, ''),
        unitConversion: Number(data?.area?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')),
        treeQuantity: data.shares ? Number(String(data.shares).replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')) : null,
        coordinatesLat: selected?.lat ? String(selected?.lat) : '',
        coordinatesLong: selected?.lng ? String(selected?.lng) : '',
        note: data.remarks,
        image: path,
        manager: data.manager,
        address: data.place,
      };

      dispatch(
        updateHousesAsync({
          data: dataUpdate,
          organizationId: Number(houseDetail?.organizationId),
          callback: () =>
            navigate(
              replacePathParams(EPath.organizationHouse, {
                organizationId: Number(houseDetail?.organizationId),
                houseId: houseDetail.id,
              }),
            ),
        }),
      );
    }
  };

  useEffect(() => {
    if (houseDetail) {
      setValue('name', houseDetail.title);
      setValue('area', String(houseDetail.unitConversion * 100));
      setValue('shares', houseDetail.treeQuantity ?? '');
      setValue('place', houseDetail.address);
      setValue('manager', houseDetail?.manager ?? '');
      setValue('remarks', houseDetail?.note ?? '');
    }
  }, [houseDetail]);

  useEffect(() => {
    if (houseDetail) {
      dispatch(getHousesDetailAsync({ id: +houseDetail.id }));
    }
  }, [houseDetail?.id]);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImage(URL.createObjectURL(event.target.files[0]));
      dispatch(uploadFileHouses([event.target.files[0]]));
    }
  };

  const handleCloseImg = () => {
    setImage('');
    setIsClosedImg(true);
    dispatch(setPath(''));
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValuePlacesAutocomplete(value);
    const coordinates = value.split(',');
    if (coordinates.length === 2 && typeof +coordinates[0] === 'number' && typeof +coordinates[1] === 'number')
      setMarkers([
        {
          id: 0,
          lat: +coordinates[0],
          lng: +coordinates[1],
        },
      ]);

    if (
      (coordinates[0] &&
        coordinates[1] &&
        typeof +coordinates[0] === 'number' &&
        typeof +coordinates[0] === 'number') ||
      !value
    ) {
      setErrorPlace('');
      return;
    }

    setErrorPlace(MESSAGE_VALIDATE_ADDRESS_HOUSE);
  };

  const handleSelect = async (address: string) => {
    setErrorPlace('');
    setValuePlacesAutocomplete(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setValue('place', address);
    setMarkers([
      {
        id: 0,
        lat,
        lng,
      },
    ]);
    setSelected({
      lat,
      lng,
    });
    setIsFocusSearch(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsFocusSearch(false));

  return (
    <form className="flex flex-col gap-4 text-on-surface" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Label htmlFor="name" className="lg mb-2 block" text="ハウスの名前（必須）" />
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: MESSAGE_REQUIRED_HOUSE_NAME,
          }}
          render={({ field }) => (
            <ForwardedInput
              {...field}
              type="text"
              className={'border-1 h-12 w-full rounded px-4 py-1'}
              hasError={!!errors?.name?.message}
            />
          )}
        />
        <ErrorTooltip errorMessage={errors['name']?.message?.toString()} hasError={!!errors?.name?.message} />
      </div>
      <div>
        <Label htmlFor="area" className="lg mb-2 block" text="ハウス面積（必須）" />
        <div className="flex items-center">
          <Controller
            name="area"
            control={control}
            defaultValue=""
            rules={{
              min: {
                value: 1,
                message: MESSAGE_VALIDATE_MIN(1),
              },
              required: MESSAGE_REQUIRED_DEFAULT,
            }}
            render={({ field }) => (
              <StepperNumberFormat
                {...field}
                className="max-w-[160px]"
                step={1}
                onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                setValue={setValue}
                name="area"
                min={0}
                hasError={!!errors?.area?.message}
              />
            )}
          />
          <Text text="m2" className="md ml-2 inline-block" />
        </div>
        <ErrorTooltip errorMessage={errors['area']?.message?.toString()} hasError={!!errors?.area?.message} />
      </div>
      <div>
        <Label htmlFor="shares" className="lg mb-2 block" text="株数" />
        <div className="flex items-center">
          <Controller
            name="shares"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <StepperNumberFormat
                {...field}
                className="max-w-[160px]"
                step={1}
                onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                setValue={setValue}
                name="shares"
                min={0}
              />
            )}
          />
          <Text text="株" className="md ml-2 inline-block" />
        </div>
      </div>
      <div>
        <Label htmlFor="shares" className="lg mb-2 block " text="ハウス管理者" />
        <InputFormText
          id="manager"
          name="manager"
          type="text"
          register={register}
          error={errors['manager']?.message?.toString()}
        />
      </div>
      <div>
        <Label htmlFor="media" className="lg mb-2 block" text="画像" />
        {((Number(houseDetail?.image?.length) > 0 && !isClosedImg) || image) && (
          <DisplayImage image={image} path={houseDetail?.image?.[0]?.path ?? ''} handleCloseImg={handleCloseImg} />
        )}

        <div className="mt-2 ">
          <label
            htmlFor="file-upload"
            className="mt-4 inline-block w-[100%] w-full cursor-pointer rounded-xl border border-br-gray !bg-white px-6 py-2.5 text-center text-sm text-on-primary transition-all duration-200 ease-in hover:!bg-M3-on-surface-variant/[0.08] hover:shadow-elevation-light-1 active:scale-[0.98] active:transition-all active:duration-200 active:ease-in lg:w-auto"
          >
            画像のアップロード
          </label>
          <input id="file-upload" type="file" className="hidden" onChange={changeHandler} />
        </div>
      </div>
      <div ref={ref} className="relative">
        <Label htmlFor="place" className="lg mb-2 block" text="場所" />
        <InputFormText
          id="place"
          name="place"
          type="text"
          register={register}
          error={errors['place']?.message?.toString()}
          onFocus={() => {
            setIsFocusSearch(true);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
        />
        {data.length > 0 && isFocusSearch && (
          <ul className="absolute z-50 w-full rounded border border-outline bg-white">
            {data.map((item) => (
              <li
                className="px-4 py-2 hover:bg-gray-background"
                key={item.place_id}
                onClick={() => handleSelect(item.description)}
                onKeyDown={() => {}}
              >
                {item.description}
              </li>
            ))}
          </ul>
        )}
        <ErrorTooltip errorMessage={errorPlace} hasError={!!errorPlace && isSubmit} />
        <div className="mt-2 h-40 w-full lg:w-[280px]">
          <MapComponent className="h-40 w-full lg:w-[280px]" zoom={10} markers={markers} />
        </div>
      </div>
      <div className="flex flex-col">
        <Label htmlFor="remarks" className="lg mb-2 inline-block" text="備考" />

        <Controller
          name="remarks"
          control={control}
          defaultValue="123" // Set default value if needed
          render={({ field }) => (
            <textarea
              {...field}
              id="remarks"
              className="regular-text md inline-block h-[180px] rounded border border-outline px-4 py-1 focus:outline-none"
            />
          )}
        />
      </div>
      <div>
        <PrimaryButton type="submit" text="保存" className="w-full text-on-surface lg:w-auto" />
      </div>
    </form>
  );
};

export default InformationForm;
