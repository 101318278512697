import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListTags, deleteTag, editNameTag, createTags } from '@/stores/tag';
import IconRemoveTag from '@/components/icon/IconRemoveTag';
import IconExplain from '@/components/icon/IconExplain';
import { RootState } from '@/stores';
import IconMoreTag from '@/components/icon/IconMoreTag';
import { ITag } from '@/types/tag';
import IconTick from '@/components/icon/IconTick';
import ListTags from '@/components/molecules/ListTags';
import { CONSTANTS } from '@/utils/constants/consts';
import { useParams } from 'react-router-dom';
import { IPagination } from '@/types/common';

type Props = {
  selected: Array<ITag>;
  setSelected: (tag: Array<ITag>) => void;
};

const ListTagsWrap = (props: Props) => {
  const { organizationId } = useParams();
  const { selected, setSelected } = props;
  const dispatch = useDispatch();
  const [fixedPosition, setFixedPosition] = useState({
    top: 0,
    left: 0,
  });
  const { tags } = useSelector((state: RootState) => state.tag);
  const [listTags, setListTags] = useState<Array<ITag>>([]);
  const [isExplain, setIsExplain] = useState<boolean>(true);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [tagCreate, setTagCreate] = useState<string>('');
  const { width } = useSelector((state: RootState) => state.common);

  const refList = useRef(null);

  useEffect(() => {
    const tagsInit = tags?.map((item) => {
      const isCheck = selected.find((tagSelect) => tagSelect.id === item.id);
      return {
        ...item,
        nameEdit: item.title,
        isUpdate: false,
        isCheck: !!isCheck,
      };
    });
    setListTags(tagsInit);
  }, [tags]);

  useEffect(() => {
    const params: IPagination = {
      page: 0,
      limit: 0,
      keyword: inputSearch,
    };
    if (organizationId) {
      params.organizationId = +organizationId;
    }
    dispatch(getListTags(params));
  }, [inputSearch]);

  const showEditTag = (i: number, id: number) => {
    const childRect = document.getElementById(`tag-${id}`)?.getBoundingClientRect();

    setFixedPosition({
      top: childRect?.top ?? 0,
      left: childRect?.left ?? 0,
    });

    const listTagsCopy = [...listTags]?.map((item, index) => ({
      ...item,
      isUpdate: index === i,
    }));

    setListTags(listTagsCopy);
  };

  const removeTag = async (i: number) => {
    await dispatch(deleteTag(i));

    dispatch(
      getListTags({
        page: 0,
        limit: 0,
        keyword: inputSearch,
      }),
    );
  };

  const editTag = (i: number) => {
    const listTagsCopy = [...listTags];
    setListTags(
      listTagsCopy?.map((item, index) => ({
        ...item,
        isEdit: i === index,
      })),
    );
  };

  const changeEditNameTag = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const listTagsCopy = [...listTags];
    listTagsCopy[i].nameEdit = e.target.value;
    setListTags(listTagsCopy);
  };

  const handleEditNameTag = (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
    if (e.key === 'Enter' && listTags[i].nameEdit !== listTags[i].title && organizationId) {
      dispatch(
        editNameTag({
          ...listTags[i],
          organizationId: +organizationId,
          callback: () => editNameTagState(listTags[i].id, listTags[i].nameEdit),
        }),
      );
    }
  };

  const editNameTagState = (id: number, nameEdit: string | undefined) => {
    nameEdit &&
      setSelected(
        selected.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              title: nameEdit,
            };
          }

          return item;
        }),
      );
  };

  const unChooseAll = () => {
    setSelected([]);
    setListTags(
      listTags.map((item) => ({
        ...item,
        isCheck: false,
      })),
    );
  };

  const unChoose = (id: number) => {
    const selectTag = [...selected].filter((item) => item.id !== id);
    setSelected(selectTag);

    const listTagsCopy = [...listTags]?.map((item) => ({
      ...item,
      isCheck: id === item.id ? !item.isCheck : item.isCheck,
    }));
    setListTags(listTagsCopy);
  };

  const selectTagQuestion = (tag: ITag) => {
    const tagSelect = selected.findIndex((item) => item.id === tag.id);
    if (tagSelect >= 0) {
      const selectedCopy = [...selected].filter((tagSelect) => tagSelect.id !== tag.id);
      setSelected(selectedCopy);
    } else {
      setSelected([...selected, tag]);
    }
    const listTagsCopy = [...listTags]?.map((item) => ({
      ...item,
      isCheck: tag.id === item.id ? !item.isCheck : item.isCheck,
    }));
    setListTags(listTagsCopy);
  };

  const handleChangeTagInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && tagCreate && organizationId) {
      dispatch(
        createTags({
          title: tagCreate,
          organizationId: +organizationId,
        }),
      );
      setTagCreate('');
    }
  };

  return (
    <div className="w-[calc(100vw-200px)] min-w-[300px] max-w-480" ref={refList}>
      <div className="mb-1 rounded bg-white shadow-elevation-2">
        <div className="max-h-[400px] overflow-y-auto">
          {listTags?.length > 0 &&
            isExplain &&
            listTags?.map((tag, index) => (
              <div
                className="group relative flex h-11 items-center justify-between border border-b-[#CBC7B5] pl-4"
                key={tag.id}
              >
                <div className="flex w-11/12 justify-start">
                  {tag.isEdit ? (
                    <input
                      className="w-full rounded border-2 px-2"
                      type="text"
                      value={tag.nameEdit}
                      onChange={(e) => changeEditNameTag(e, index)}
                      onKeyDown={(e) => handleEditNameTag(e, index)}
                    />
                  ) : (
                    <div
                      className="flex w-full items-center justify-start"
                      onClick={() => selectTagQuestion(tag)}
                      onKeyDown={() => {}}
                    >
                      {tag.title}
                      {tag.isCheck && (
                        <span className="ml-5">
                          <IconTick />
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="flex h-11 items-center pl-4 pr-7"
                  id={`tag-${tag.id}`}
                  onClick={() => showEditTag(index, tag.id)}
                  onKeyDown={() => {}}
                >
                  <span className="hidden group-hover:block">
                    <IconMoreTag />
                  </span>
                </div>
                {tag.isUpdate && (
                  <div
                    style={{
                      top: width <= CONSTANTS.SIZE_MOBILE ? fixedPosition.top + 30 : fixedPosition.top,
                      left: width <= CONSTANTS.SIZE_MOBILE ? fixedPosition.left - 50 : fixedPosition.left + 79,
                    }}
                    className={
                      'fixed z-50 flex w-50 flex-col items-start rounded bg-[#EFEDE6] pb-2 pl-4 pr-6 pt-2 shadow-elevation-2'
                    }
                  >
                    <div className="flex h-10 items-center" onClick={() => editTag(index)} onKeyDown={() => {}}>
                      リネーム
                    </div>
                    <div
                      className="flex h-10 items-center text-error"
                      onClick={() => removeTag(tag.id)}
                      onKeyDown={() => {}}
                    >
                      削除
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <input
          value={tagCreate}
          onChange={(e) => setTagCreate(e.target.value)}
          className="h-11 w-full rounded border-t pl-4 pr-7 focus:outline-none"
          type="text"
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="relative h-12 h-fit overflow-x-auto overflow-y-hidden rounded-lg border border-outline bg-white">
        <div className="flex h-12 w-full items-center px-3">
          {selected.length > 0 && (
            <ListTags listTags={selected ?? []} isDelete deleteTag={unChoose} classNames="flex-nowrap" />
          )}
          <input
            className="ml-2.5 h-10 w-auto min-w-[20px] grow border-none !outline-none"
            value={inputSearch}
            onChange={(e) => handleChangeTagInput(e)}
          />
          <div className="hidden h-12 items-center pr-3 sm:flex">
            <span className="py-5" onClick={unChooseAll} onKeyDown={() => {}}>
              <IconRemoveTag />
            </span>
            <span className="py-5 pl-3" onClick={() => setIsExplain(!isExplain)} onKeyDown={() => {}}>
              <IconExplain />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTagsWrap;
