import Cookies from 'js-cookie';
import { IUser } from '@/types/auth';

export const setTokensAndAuthenticate = (user?: IUser) => {
  Cookies.set('isAuthenticated', 'true');

  if (user) {
    const userCurrent: IUser = {
      id: user.id,
      email: user.email,
      name: user.name,
      photo: user.photo,
    };
    Cookies.set('user', JSON.stringify(userCurrent));
  }
};

export const clearTokensAndLogout = () => {
  Cookies.remove('token');
  Cookies.remove('refreshToken');
  Cookies.remove('isAuthenticated');
  Cookies.remove('user');
  localStorage.removeItem('isRemember');
};
