import React from 'react';
import { IconThumbnail } from '@/components/icon/IconThumbnail/IconThumbnail';

interface ImageRendererProps {
  imagePath: string | undefined;
}

const Image: React.FC<ImageRendererProps> = ({ imagePath }) => {
  return (
    <>
      {imagePath ? (
        <img
          src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(imagePath)}`}
          alt="House Image"
          style={{ width: '48px', height: '48px', objectFit: 'cover' }}
        />
      ) : (
        <IconThumbnail />
      )}
    </>
  );
};

export default Image;
