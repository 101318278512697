import React from 'react';
import { ITag } from '@/types/tag';
import IconRemoveTag from '@/components/icon/IconRemoveTag';
interface Props {
  listTags: ITag[];
  isDelete?: boolean;
  deleteTag?: (id: number) => void;
  classNames?: string;
}

const ListTags: React.FC<Props> = (props: Props) => {
  const { listTags, isDelete, deleteTag, classNames = '' } = props;

  return (
    <div className={`flex flex-wrap items-center gap-3 ${classNames}`}>
      {listTags.map((tag) => (
        <div
          key={tag?.id}
          className="flex h-8 max-w-[200px] items-center gap-2 truncate rounded-lg border border-outline bg-surface px-3 text-base"
        >
          <div className="flex text-on-surface-variant">{tag?.title}</div>
          {isDelete && (
            <span onClick={() => deleteTag?.(tag?.id)}>
              <IconRemoveTag />
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default ListTags;
