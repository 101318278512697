/* eslint-disable */
import { CellChange, Column, DefaultCellTypes, NumberCell, ReactGrid, Row } from '@silevis/reactgrid';
import { getRows } from './getRows';
import { getColumns } from './getColumns';
import './index.css';
import {
  InputVariables,
  TDataCostSalesFlows,
  TDataProfitFlows,
  TDataSalesFlows,
  TDataSellingExpensesFlows,
  TOperatingIncome,
  TTotalValue,
} from './interfaces';
import { FlagCell, FlagCellTemplate } from './IconCellTemplate';
import * as React from 'react';
import { useEffect, useImperativeHandle, ForwardedRef, useState, forwardRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { useParams } from 'react-router-dom';
import {
  getListBusinessPlan,
  updateRecordBusinessPlan,
  getBusinessPlanResult,
  getParamValues,
} from '@/stores/business/services';
import { IBusinessPlanDetail } from '@/stores/business/model';
import Dialog from '@/components/molecules/BusinessTable/Dialog';
import { getNumberByStr } from '@/utils/helpers';
import {IFieldUpdate} from '@/types/common';

interface TData {
  [key: string]: { [key: string]: number };
}

interface Props {
  selectedGroupBy: { time: string };
}

export type ChildRef = {
  handleUndoChanges: () => void;
  handleRedoChanges: () => void;
  canUndo: boolean;
  canRedo: boolean;
};

const BusinessTable = forwardRef((props: Props, ref: ForwardedRef<ChildRef>) => {
  const { selectedGroupBy } = props;
  const dispatch = useDispatch();
  const { businessId = '' } = useParams();
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<Row<FlagCell | DefaultCellTypes>[]>([]);
  const { listSheet, listBusinessPlan, sheetActive } = useSelector((state: RootState) => state.business);
  const { width } = useSelector((state: RootState) => state.common);
  const hasIdAndActive = useMemo(() => businessId && sheetActive, [businessId, sheetActive]);

  useEffect(() => {
    if (hasIdAndActive) {
      dispatch(
        getBusinessPlanResult({
          businessId: +businessId,
          sheetId: sheetActive,
        }),
      );

      dispatch(
        getParamValues({
          businessId: +businessId,
          sheetId: sheetActive,
        }),
      );
    }
  }, [sheetActive]);

  useEffect(() => {
    if (listSheet.length > 0 && hasIdAndActive) {
      dispatch(
        getListBusinessPlan({
          id: +businessId,
          sheetId: sheetActive,
        }),
      );
    }
  }, [listSheet, sheetActive]);

  const timeBy = useMemo(() => (selectedGroupBy.time === '年' ? 'month' : 'year'), [selectedGroupBy.time]);

  useEffect(() => {
    if (listBusinessPlan.length > 0) {
      const dataSalesFlows: TDataSalesFlows = {
        totalSales: [],
        monthlySales: [],
        specificWorkContract: [],
        workContract: [],
        miscellaneousIncome: [],
        subsidyIncome: [],
      };
      const dataCostSalesFlows: TDataCostSalesFlows = {
        monthLaborCost: [],
        consultingLaborCosts: [],
        consumptionVolumePrice: [],
        depreciationExpense: [],
        equipmentRepairCosts: [],
        seedCost: [],
        fertilizerCost: [],
        pesticidesHygieneCosts: [],
        toolsCost: [],
        materialCosts: [],
        workClothingCosts: [],
        miscellaneousExpensesCosts: [],
      };

      const dataSellingExpensesFlows: TDataSellingExpensesFlows = {
        promotionalCosts: [],
        packingMaterialCosts: [],
        logisticsCosts: [],
        otherSalesCosts: [],
        shippingFee: [],
      };

      const dataProfitFlows: TDataProfitFlows = {
        officePersonnelCosts: [],
        rentFee: [],
        insuranceSupportCosts: [],
        associationFees: [],
        tax: [],
        otherAdministrativeExpenses: [],
      };

      const operatingIncome: TOperatingIncome = {
        costOfSalesRatio: [],
        grossProfitMargin: [],
        sellingExpenseRatio: [],
        expenseRate: [],
        operatingProfitMargin: [],
      };

      const listYear: string[] = [];

      const totalValue: TTotalValue = {
        totalSales: [],
        costOfSales: [],
        grossProfit: [],
        sellingCosts: [],
        directProfit: [],
        managementCosts: [],
        operatingIncome: [],
      };

      listBusinessPlan.forEach((item: IBusinessPlanDetail) => {
        const year = item.yearMonth.split('/')[0];
        if (!listYear.includes(year)) {
          listYear.push(year);
        }
        totalValue.totalSales.push(getNumberByStr(item.totalSales));
        totalValue.costOfSales.push(getNumberByStr(item.costOfSales));
        totalValue.grossProfit.push(getNumberByStr(item.grossProfit));
        totalValue.sellingCosts.push(getNumberByStr(item.sellingCosts));
        totalValue.directProfit.push(getNumberByStr(item.directProfit));
        totalValue.managementCosts.push(getNumberByStr(item.managementCosts));
        totalValue.operatingIncome.push(getNumberByStr(item.operatingIncome));

        dataSalesFlows.monthlySales.push(getNumberByStr(item.monthlySales));
        dataSalesFlows.specificWorkContract.push(getNumberByStr(item.specificWorkContract));
        dataSalesFlows.workContract.push(getNumberByStr(item.workContract));
        dataSalesFlows.miscellaneousIncome.push(getNumberByStr(item.miscellaneousIncome));
        dataSalesFlows.subsidyIncome.push(getNumberByStr(item.subsidyIncome));
        //
        dataCostSalesFlows.monthLaborCost.push(getNumberByStr(item.monthLaborCost));
        dataCostSalesFlows.consultingLaborCosts.push(getNumberByStr(item.consultingLaborCosts));
        dataCostSalesFlows.consumptionVolumePrice.push(getNumberByStr(item.consumptionVolumePrice));
        dataCostSalesFlows.depreciationExpense.push(getNumberByStr(item.depreciationExpense));
        dataCostSalesFlows.equipmentRepairCosts.push(getNumberByStr(item.equipmentRepairCosts));
        dataCostSalesFlows.seedCost.push(getNumberByStr(item.seedCost));
        dataCostSalesFlows.fertilizerCost.push(getNumberByStr(item.fertilizerCost));
        dataCostSalesFlows.pesticidesHygieneCosts.push(getNumberByStr(item.pesticidesHygieneCosts));
        dataCostSalesFlows.toolsCost.push(getNumberByStr(item.toolsCost));
        dataCostSalesFlows.materialCosts.push(getNumberByStr(item.materialCosts));
        dataCostSalesFlows.workClothingCosts.push(getNumberByStr(item.workClothingCosts));
        dataCostSalesFlows.miscellaneousExpensesCosts.push(getNumberByStr(item.miscellaneousExpensesCosts));
        //
        dataSellingExpensesFlows.promotionalCosts.push(getNumberByStr(item.promotionalCosts));
        dataSellingExpensesFlows.packingMaterialCosts.push(getNumberByStr(item.packingMaterialCosts));
        dataSellingExpensesFlows.logisticsCosts.push(getNumberByStr(item.logisticsCosts));
        dataSellingExpensesFlows.otherSalesCosts.push(getNumberByStr(item.otherSalesCosts));
        dataSellingExpensesFlows.shippingFee.push(getNumberByStr(item.shippingFee));
        //

        dataProfitFlows.officePersonnelCosts.push(getNumberByStr(item.officePersonnelCosts));
        dataProfitFlows.rentFee.push(getNumberByStr(item.rentFee));
        dataProfitFlows.insuranceSupportCosts.push(getNumberByStr(item.insuranceSupportCosts));
        dataProfitFlows.associationFees.push(getNumberByStr(item.associationFees));
        dataProfitFlows.tax.push(getNumberByStr(item.tax));
        dataProfitFlows.otherAdministrativeExpenses.push(getNumberByStr(item.otherAdministrativeExpenses));

        //

        operatingIncome.costOfSalesRatio.push(item.costOfSalesRatio);
        operatingIncome.grossProfitMargin.push(item.grossProfitMargin);
        operatingIncome.sellingExpenseRatio.push(item.sellingExpenseRatio);
        operatingIncome.expenseRate.push(item.administrativeExpenseRate);
        operatingIncome.operatingProfitMargin.push(item.operatingProfitMargin);
      });

      const salesFlow = [
        {
          type: 'monthlySales',
          title: '商品売上',
          values: dataSalesFlows.monthlySales,
          variant: 'form1',
        },
        {
          type: 'specificWorkContract',
          title: '特定作業受託',
          variant: 'form2',
          values: dataSalesFlows.specificWorkContract,
        },
        {
          type: 'workContract',
          title: '作業受託',
          variant: 'form3',
          values: dataSalesFlows.workContract,
        },
        {
          type: 'miscellaneousIncome',
          title: '雑収入',
          variant: 'form4',
          values: dataSalesFlows.miscellaneousIncome,
        },
        {
          type: 'subsidyIncome',
          title: '補助金収入',
          variant: 'form5',
          values: dataSalesFlows.subsidyIncome,
        },
      ];
      const costSalesFlows = [
        {
          type: 'monthLaborCost',
          title: 'パート人件費',
          variant: 'form6',
          values: dataCostSalesFlows.monthLaborCost,
        },
        {
          type: 'consultingLaborCosts',
          variant: 'form7',
          title: '栽培技術コンサル人件費',
          values: dataCostSalesFlows.consultingLaborCosts,
        },
        {
          type: 'consumptionVolumePrice',
          title: '動力光熱費',
          variant: 'form8',
          values: dataCostSalesFlows.consumptionVolumePrice,
        },
        {
          type: 'depreciationExpense',
          title: '減価償却費',
          variant: 'form9',
          values: dataCostSalesFlows.depreciationExpense,
        },
        {
          type: 'equipmentRepairCosts',
          title: '設備修繕費',
          variant: 'form10',
          values: dataCostSalesFlows.equipmentRepairCosts,
        },
        { type: 'seedCost', title: '種苗費', variant: 'form11', values: dataCostSalesFlows.seedCost },
        { type: 'fertilizerCost', title: '肥料費', variant: 'form12', values: dataCostSalesFlows.fertilizerCost },
        {
          type: 'pesticidesHygieneCosts',
          title: '農薬・衛生費',
          values: dataCostSalesFlows.pesticidesHygieneCosts,
          variant: 'form13',
        },
        { type: 'toolsCost', title: '農具費', variant: 'form14', values: dataCostSalesFlows.toolsCost },
        { type: 'materialCosts', title: '諸材料費', variant: 'form15', values: dataCostSalesFlows.materialCosts },
        {
          type: 'workClothingCosts',
          title: '作業用衣料費',
          variant: 'form16',
          values: dataCostSalesFlows.workClothingCosts,
        },
        {
          type: 'miscellaneousExpensesCosts',
          title: '雑費（予備費）',
          values: dataCostSalesFlows.miscellaneousExpensesCosts,
          variant: 'form17',
        },
      ];
      const sellingExpensesFlows = [
        {
          type: 'promotionalCosts',
          title: '販促活動費',
          values: dataSellingExpensesFlows.promotionalCosts,
          variant: 'form18',
        },
        {
          type: 'packingMaterialCosts',
          title: '梱包材料費',
          values: dataSellingExpensesFlows.packingMaterialCosts,
          variant: 'form19',
        },
        {
          type: 'logisticsCosts',
          title: '物流・輸送費',
          values: dataSellingExpensesFlows.logisticsCosts,
          variant: 'form20',
        },
        {
          type: 'otherSalesCosts',
          title: 'その他販売経費',
          values: dataSellingExpensesFlows.otherSalesCosts,
          variant: 'form21',
        },
        {
          type: 'shippingFee',
          title: '出荷手数料（JA等）',
          values: dataSellingExpensesFlows.shippingFee,
          variant: 'form22',
        },
      ];
      const profitFlows = [
        {
          type: 'officePersonnelCosts',
          title: '本社人件費',
          values: dataProfitFlows.officePersonnelCosts,
          variant: 'form23',
        },
        { type: 'rentFee', title: '地代・貸借料', values: dataProfitFlows.rentFee, variant: 'form24' },
        {
          type: 'insuranceSupportCosts',
          title: '農業共済掛金',
          values: dataProfitFlows.insuranceSupportCosts,
          variant: 'form25',
        },
        { type: 'associationFees', title: '組合費等', values: dataProfitFlows.associationFees, variant: 'form26' },
        { type: 'tax', title: '租税効果(固定資産税)', values: dataProfitFlows.tax, variant: 'form27' },
        {
          type: 'otherAdministrativeExpenses',
          title: 'その他管理経費',
          values: dataProfitFlows.otherAdministrativeExpenses,
          variant: 'form28',
        },
      ];

      const operatingIncomeDate = [
        {
          type: 'costOfSalesRatio',
          title: '売上原価率',
          values: operatingIncome.costOfSalesRatio,
          variant: '',
        },
        {
          type: 'grossProfitMargin',
          title: '売上総利益率',
          values: operatingIncome.grossProfitMargin,
          variant: '',
        },
        {
          type: 'sellingExpenseRatio',
          title: '販売費率',
          values: operatingIncome.sellingExpenseRatio,
          variant: '',
        },
        {
          type: 'expenseRate',
          title: '一般管理費率',
          values: operatingIncome.expenseRate,
          variant: '',
        },
        {
          type: 'operatingProfitMargin',
          title: '営業利益率',
          values: operatingIncome.operatingProfitMargin,
          variant: '',
        },
      ];

      const plannerData: InputVariables = {
        salesFlow: salesFlow,
        costSalesFlows,
        sellingExpensesFlows,
        profitFlows,
        operatingIncome: operatingIncomeDate,
      };

      const listId = listBusinessPlan.map((item) => item.yearMonth);

      setColumns(getColumns(timeBy, listId, width));
      setRows(getRows(timeBy, plannerData, listYear, totalValue, listId));
    }
  }, [listBusinessPlan, timeBy]);

  const [cellChangesIndex, setCellChangesIndex] = React.useState(() => -1);
  const [cellChanges, setCellChanges] = React.useState<CellChange<NumberCell>[][]>(() => []);

  const [people, setPeople] = useState<TData>({});

  const handleChangesA = (changes: CellChange[]) => {
    setPeople(applyChangesToPeople(changes as CellChange<NumberCell>[], people));
  };

  const applyNewValue = (
    changes: CellChange<NumberCell>[],
    prevPeople: TData,
    usePrevValue: boolean = false,
  ): TData => {
    const dataUpdate: IFieldUpdate = {
      items: []
    }
    changes.forEach((change) => {
      const index = dataUpdate.items.findIndex(item => item.date === change.columnId)
      if(index >= 0) {
        dataUpdate.items[index] = {
          ...dataUpdate.items[index],
          [change.rowId]: usePrevValue ? change.previousCell.value : (change.newCell.value || 0)
        }
      }else {
        dataUpdate.items = [...dataUpdate.items, {
          date: change.columnId,
          [change.rowId]: usePrevValue ? change.previousCell.value : (change.newCell.value || 0)
        }]
      }
    });

    dispatch(
      updateRecordBusinessPlan({
        id: +businessId,
        sheetId: sheetActive,
        dataUpdate: dataUpdate,
      }),
    );
    return prevPeople;
  };

  const applyChangesToPeople = (changes: CellChange<NumberCell>[], prevPeople: TData): TData => {
    const updated = applyNewValue(changes, prevPeople);
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
    setCellChangesIndex(cellChangesIndex + 1);
    return updated;
  };

  const undoChanges = (changes: CellChange<NumberCell>[], prevPeople: TData): TData => {
    const updated = applyNewValue(changes, prevPeople, true);
    setCellChangesIndex(cellChangesIndex - 1);
    return updated;
  };

  const redoChanges = (changes: CellChange<NumberCell>[], prevPeople: TData): TData => {
    const updated = applyNewValue(changes, prevPeople);
    setCellChangesIndex(cellChangesIndex + 1);
    return updated;
  };

  useImperativeHandle(ref, () => ({
    handleUndoChanges: () => {
      if (cellChangesIndex >= 0) {
        setPeople(undoChanges(cellChanges[cellChangesIndex], people));
      }
    },
    handleRedoChanges: () => {
      if (cellChangesIndex + 1 <= cellChanges.length - 1) {
        setPeople(redoChanges(cellChanges[cellChangesIndex + 1], people));
      }
    },
    canUndo: cellChangesIndex >= 0,
    canRedo: cellChangesIndex + 1 <= cellChanges.length - 1,
  }));

  return (
    <div className="business-table">
      <div className={`absolute md:left-[215px] ${screen.height < 830 ? 'top-[-100px]' : 'top-[50px]'} z-50`}>
        <Dialog />
      </div>
      <ReactGrid
        rows={rows}
        columns={columns}
        customCellTemplates={{ flag: new FlagCellTemplate() }}
        onCellsChanged={handleChangesA}
        stickyTopRows={2}
        stickyLeftColumns={1}
        enableRangeSelection
      />
    </div>
  );
});

export default BusinessTable;
