import { useLocation } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { IFormCreateOrganization } from '@/types/organization';
import { createOrganizationAsync } from '@/stores/organization';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { TMenuItem } from '@/types/common';
import { getListDocument, getListFeedback, getListHouse, getListQuestion, setDataSideBar } from '@/stores/common';
import { comparePath } from '@/utils/helpers';
import { EPath } from '@/route/route';

export const useService = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [sidebarSp, setSidebarSp] = useState(false);
  const { dataSideBar } = useSelector((state: RootState) => state.common);

  const ref = useRef<HTMLDivElement>(null);
  const refMobile = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setShowSetting(false));
  useOutsideClick(refMobile, () => setSidebarSp?.(false));

  const onOpenModal = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  const toggleMenu = (path: string) => {
    const pathArr = path.split('/');
    const organizationId = +pathArr[2];
    if (comparePath(path, EPath.organizationHouseList)) {
      dispatch(
        getListHouse({
          organizationId: [
            {
              operation: 'in',
              value: [organizationId],
            },
          ],
        }),
      );
    } else if (comparePath(path, EPath.organizationQuestion)) {
      dispatch(
        getListQuestion({
          organizationId: [
            {
              operation: 'in',
              value: [organizationId],
            },
          ],
        }),
      );
    } else if (comparePath(path, EPath.organizationFeedback)) {
      dispatch(
        getListFeedback({
          organizationId: [
            {
              operation: 'in',
              value: [organizationId],
            },
          ],
        }),
      );
    } else if (comparePath(path, EPath.organizationDocument)) {
      dispatch(
        getListDocument({
          organizationId: [
            {
              operation: 'in',
              value: [organizationId],
            },
          ],
        }),
      );
    }

    const dataCopy: TMenuItem[] = JSON.parse(JSON.stringify(dataSideBar));
    toggleOpenStatusRecursive(dataCopy, path);
    dispatch(setDataSideBar(dataCopy));
  };

  const toggleOpenStatusRecursive = (dataCopy: TMenuItem[], path: string) => {
    for (const item of dataCopy) {
      if (item.path === path) {
        item.isOpen = !item.isOpen;
        return;
      }

      if (item.subItems && item.subItems.length > 0) {
        toggleOpenStatusRecursive(item.subItems, path);
      }
    }
  };

  const loadOpenStatusRecursive = (dataCopy: TMenuItem[], path: string) => {
    for (const item of dataCopy) {
      if (path.includes(item.path)) {
        item.isOpen = !item.isOpen;
        toggleMenu(item.path);
      }

      if (item.subItems && item.subItems.length > 0) {
        loadOpenStatusRecursive(item.subItems, path);
      }
    }
  };

  const showSettingBox = () => {
    setShowSetting(!showSetting);
  };

  const isPathActive = (path: string): boolean => {
    const currentPath = location.pathname;
    return comparePath(currentPath, path);
  };

  const handleSubmitForm = (data: IFormCreateOrganization) => {
    dispatch(createOrganizationAsync(data));
    setOpen(false);
  };

  return {
    isPathActive,
    toggleMenu,
    open,
    onOpenModal,
    onCloseModal,
    showSettingBox,
    showSetting,
    ref,
    refMobile,
    handleSubmitForm,
    sidebarSp,
    dataSideBar,
    toggleOpenStatusRecursive,
    loadOpenStatusRecursive,
  };
};
