import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import { TCheckEmail, TLogin, TSignUp, TUser } from '@/api/types/authTypes';
import { EPath } from '@/route/route';
import { clearTokensAndLogout, setTokensAndAuthenticate } from '@/utils/cookie';
import { push } from 'redux-first-history';
import Cookies from 'js-cookie';
import { IUser, IUserInvite } from '@/types/auth';
import { TCallback } from '@/types/common';
import { setShowToast } from '@/stores/common';
import { messageNotification } from '@/utils/constants/messageNotification';
import { uploadFile } from '@/utils/helpers';

export interface AuthState {
  isAuthenticated: boolean;
  user: IUser | null;
  userName: string;
  infoUserInvite: IUserInvite;
  infoLogin: TLogin;
  pathAvatar: string;
  infoUser: IUser;
}

const initialState: AuthState = {
  isAuthenticated:
    localStorage.getItem('isRemember') === 'true' || sessionStorage.getItem('isAuthenticated') === 'true',
  user: Cookies.get('user') ? JSON.parse(Cookies.get('user') ?? '') : null,
  userName: Cookies.get('userName') || '',
  infoUserInvite: Cookies.get('user') ? JSON.parse(Cookies.get('user') ?? '') : null,
  infoLogin: {
    email: '',
    password: '',
  },
  pathAvatar: '',
  infoUser: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      const user = {
        id: action.payload.id,
        email: action.payload.email,
        name: action.payload.name,
        photo: action.payload.photo,
      };
      state.user = user;
      setTokensAndAuthenticate(user);
    },
    checkExists: (state, action) => {
      state.isAuthenticated = false;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.infoLogin = {
        email: '',
        password: '',
      };
      clearTokensAndLogout();
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setInfoUserInvite: (state, action: PayloadAction<IUserInvite>) => {
      state.infoUserInvite = action.payload;
    },
    setInfoLogin: (state, action: PayloadAction<TLogin>) => {
      state.infoLogin = action.payload;
    },
    setPathAvatar: (state, action: PayloadAction<string>) => {
      state.pathAvatar = action.payload;
    },
    setInfoUser: (state, action) => {
      state.infoUser = action.payload;
    },
  },
});

export const checkExistAsync = createAsyncThunk(
  'loading/auth/checkExistAsync',
  async (params: TCheckEmail & TCallback, thunkAPI) => {
    const response = await axiosInstance.post('auth/email/exists', params);
    thunkAPI.dispatch(checkExists(response.data));
    thunkAPI.dispatch(setInfoLogin({ email: params.email, password: '' }));
    params.callback?.();
  },
);

export const loginAsync = createAsyncThunk('loading/auth/checkExistAsync', async (params: TLogin, thunkAPI) => {
  const response = await axiosInstance.post('auth/email/login', params);
  localStorage.setItem('isRemember', String(!!params.remember));
  if (params.remember) {
    localStorage.setItem('isRemember', String(!!params.remember));
  } else {
    sessionStorage.setItem('isAuthenticated', 'true');
  }
  thunkAPI.dispatch(login(response.data));
  thunkAPI.dispatch(getInfoUser(response.data.id));
});

export const registerAsync = createAsyncThunk('loading/auth/register', async (params: TSignUp, thunkAPI) => {
  await axiosInstance.post('auth/email/register', params);
  thunkAPI.dispatch(
    push(EPath.password, {
      state: { email: params.email },
    }),
  );
});

export const logoutAsync = createAsyncThunk('loading/auth/logout', async (params: TSignUp, thunkAPI) => {
  const response = await axiosInstance.post('auth/logout', params);
  thunkAPI.dispatch(login(response.data));
});

export const getInfoInvite = createAsyncThunk('loading/auth/getInfoInvite', async (token: string, thunkAPI) => {
  const response = await axiosInstance.get(`organizations/read-invitation-token?token=${token}`);
  thunkAPI.dispatch(setInfoUserInvite(response.data));
});

export const activeMember = createAsyncThunk(
  'loading/auth/active',
  async (params: { email: string; token: string } & TCallback) => {
    await axiosInstance.post('organizations/member/active-member', { token: params.token });
    params.callback?.();
  },
);

export const getInfoUser = createAsyncThunk('auth/getInfoUser', async (id: number, thunkAPI) => {
  const res = await axiosInstance.get(`users/${id}`);
  thunkAPI.dispatch(setInfoUser(res));
});

export const updateInfoUser = createAsyncThunk(
  'loading/auth/update',
  async (params: { data: TUser; id: number }, thunkAPI) => {
    await axiosInstance.patch(`users/${params.id}`, params.data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getInfoUser(params.id));
  },
);

export const deleteAccount = createAsyncThunk('auth/delete', async (callback: TCallback, thunkAPI) => {
  await axiosInstance.delete('auth/me');
  callback?.callback?.();
  thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
});

export const uploadAvatar = createAsyncThunk('loading/auth/uploadAvatar', async (data: File[], thunkAPI) => {
  const res = await uploadFile(data);
  thunkAPI.dispatch(setPathAvatar(res?.data[0].path));
});

export const {
  login,
  logout,
  checkExists,
  setIsAuthenticated,
  setInfoUserInvite,
  setInfoLogin,
  setPathAvatar,
  setInfoUser,
} = authSlice.actions;
export default authSlice.reducer;
