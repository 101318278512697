import { Charts } from '@/stores/chart/model';
import c from 'clsx';
import React from 'react';
import ChartNameCellNoneAll from '../../ChartNameCell/ChartAll/ChartNameCellNoneAll';
import s from './../style.module.sass';
import { nanoid } from 'nanoid';

type Props = {
  className?: string;
  data: Charts;
};

const ChartNameAreaNoneAll: React.FC<Props> = (props) => {
  const { className, data } = props;
  return (
    <div className={c(s.root, className)} id="chart-name-area-none">
      {Object.keys(data).map((k) => (
        <React.Fragment key={k}>
          <span>
            {data[k].map((d) => (
              <div className="flex h-12 hover:bg-gray-background" key={nanoid(5)}>
                <ChartNameCellNoneAll
                  className={s.cell}
                  label={d.name}
                  area={k}
                  id={d.id}
                  key={nanoid(5)}
                  circleData={d}
                />
              </div>
            ))}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChartNameAreaNoneAll;
