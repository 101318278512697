import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import IconDragDrop from '@/components/icon/IconDragDrop';
import { MESSAGE_REQUIRED_DATE, MESSAGE_REQUIRED_NAME } from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import IconTrashBlack from '@/components/icon/IconTrash/IconTrashBlack';
import { Control } from 'react-hook-form/dist/types';
import FieldDateCustom from '@/components/atoms/FieldDateCustom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableItemProps {
  id: string;
  errors:
    | {
        [k: string]:
          | {
              message?: string;
            }
          | undefined;
      }
    | undefined;
  control: Control;
  handleModalDeleteClick: (id: string) => void;
  validateCustomRule: (value: string, name: string, id: number) => string | undefined;
  setValue?: (name: string, value: string) => void;
}
export const SortableItem: FC<SortableItemProps> = ({
  id,
  errors,
  control,
  handleModalDeleteClick,
  validateCustomRule,
  setValue,
}) => {
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      key={id}
      ref={setNodeRef}
      className={`mt-0.5 h-14 w-[1105px] space-y-2 rounded-lg border border-outline-variant bg-surface p-2 lg:h-[72px] ${
        errors?.[`name-${id}`]?.message ||
        errors?.[`sowing-${id}`]?.message ||
        errors?.[`planting-${id}`]?.message ||
        errors?.[`start_of_harvest-${id}`]?.message ||
        errors?.[`harvest_finished-${id}`]?.message
          ? '!h-[84px]'
          : ''
      } lg:p-3`}
      style={styles}
    >
      <div className="flex gap-2">
        <div {...listeners} className="my-auto">
          <IconDragDrop />
        </div>
        <div className="flex grow gap-4">
          <div>
            <Controller
              name={`name-${id}`}
              control={control}
              defaultValue=""
              rules={{
                required: MESSAGE_REQUIRED_NAME,
              }}
              render={({ field }) => (
                <ForwardedInput
                  {...field}
                  type="text"
                  className={'border-1 h-10 w-full min-w-[312px] rounded border-outline px-4 py-1 lg:h-12'}
                />
              )}
            />
            <ErrorTooltip errorMessage={errors?.[`name-${id}`]?.message} hasError={!!errors?.[`name-${id}`]?.message} />
          </div>
          <div>
            <Controller
              name={`sowing-${id}`}
              control={control}
              rules={{
                required: MESSAGE_REQUIRED_DATE,
              }}
              render={({ field }) => <FieldDateCustom {...field} name={`sowing-${id}`} setValue={setValue} />}
            />
            <ErrorTooltip
              errorMessage={errors?.[`sowing-${id}`]?.message}
              hasError={!!errors?.[`sowing-${id}`]?.message}
            />
          </div>
          <div>
            <Controller
              name={`planting-${id}`}
              control={control}
              rules={{
                required: MESSAGE_REQUIRED_DATE,
                validate: (value) => validateCustomRule(value, `planting-${id}`, +id),
              }}
              render={({ field }) => <FieldDateCustom {...field} name={`planting-${id}`} setValue={setValue} />}
            />
            <ErrorTooltip
              errorMessage={errors?.[`planting-${id}`]?.message}
              hasError={!!errors?.[`planting-${id}`]?.message}
            />
          </div>
          <div>
            <Controller
              name={`start_of_harvest-${id}`}
              control={control}
              rules={{
                required: MESSAGE_REQUIRED_DATE,
                validate: (value) => validateCustomRule(value, `start_of_harvest-${id}`, +id),
              }}
              render={({ field }) => <FieldDateCustom {...field} name={`start_of_harvest-${id}`} setValue={setValue} />}
            />
            <ErrorTooltip
              errorMessage={errors?.[`start_of_harvest-${id}`]?.message}
              hasError={!!errors?.[`start_of_harvest-${id}`]?.message}
            />
          </div>
          <div>
            <Controller
              name={`harvest_finished-${id}`}
              control={control}
              rules={{
                required: MESSAGE_REQUIRED_DATE,
                validate: (value) => validateCustomRule(value, `harvest_finished-${id}`, +id),
              }}
              render={({ field }) => <FieldDateCustom {...field} name={`harvest_finished-${id}`} setValue={setValue} />}
            />
            <ErrorTooltip
              errorMessage={errors?.[`harvest_finished-${id}`]?.message}
              hasError={!!errors?.[`harvest_finished-${id}`]?.message}
            />
          </div>
        </div>
        <div className="my-auto" onClick={() => handleModalDeleteClick(id)} onKeyDown={() => {}}>
          <IconTrashBlack />
        </div>
      </div>
    </div>
  );
};
