import HouseSummary from '@/components/organisms/HouseSummary';
import React from 'react';

const OrganizationHouse = () => {
  return (
    <div className="flex h-[calc(100vh-97px)] w-full overflow-y-scroll">
      <HouseSummary />
    </div>
  );
};

export default OrganizationHouse;
