import axios from 'axios';
import { clearTokensAndLogout } from '@/utils/cookie';
import { store } from '@/stores';
import { setShowToast } from '@/stores/common';
import { errorCode, keyErrorCode, keyErrorCodeOb } from '@/utils/constants/errorCode';
import { EPath } from '@/route/route';
import { moveToPathHouse } from '@/utils/helpers';

const baseUrl = process.env.REACT_APP_BASE_URL;
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accepted: 'appication/json',
    'Content-Type': 'application/json',
  },
});
let refreshAttempts = 0;
axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async (error) => {
    const errorCodeRes: keyErrorCode | keyErrorCodeOb = error.response.data.message;
    if (typeof errorCodeRes !== 'string') {
      let keyCodePrevious = '';
      for (const key in errorCodeRes) {
        const keyCode: keyErrorCode = errorCodeRes[key];
        keyCodePrevious !== keyCode && store.dispatch(setShowToast({ message: errorCode[keyCode], type: 'error' }));
        keyCodePrevious = keyCode;
        moveToPathHouse(keyCode);
      }
    } else {
      store.dispatch(setShowToast({ message: errorCode[errorCodeRes], type: 'error' }));
      moveToPathHouse(errorCodeRes);
    }

    const originalConfig = error.config;

    if (error?.response?.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      if (refreshAttempts < 3) {
        try {
          await axiosInstance.post('auth/refresh', {}, originalConfig);
          refreshAttempts++;
          return axiosInstance(originalConfig);
        } catch (refreshError) {
          clearTokensAndLogout();
          window.location.href = EPath.login;
          return Promise.reject(refreshError);
        }
      } else {
        window.location.href = EPath.login;
        return Promise.reject(error);
      }
    }

    if (error?.response?.status === 429) {
      await new Promise((resolve) => setTimeout(resolve, 100000));

      return axiosInstance(originalConfig);
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
