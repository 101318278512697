import ListHouse from '@/components/pages/ListHouse';
import React from 'react';

const ListHouseWraper = () => {
  return (
    <div>
      <ListHouse />
    </div>
  );
};

export default ListHouseWraper;
