import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { TMarker } from '@/types/houses';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores';

type Props = {
  markers: TMarker[];
  zoom?: number;
  className?: string;
};

const MapComponent = (props: Props) => {
  const { markers, zoom = 10, className } = props;
  const { isLoadMap } = useSelector((state: RootState) => state.common);

  if (!isLoadMap) return <></>;

  return (
    <GoogleMap
      zoom={zoom}
      center={{ lat: markers[0]?.lat ?? 34.74634, lng: markers[0]?.lng ?? 137.892145 }}
      mapContainerClassName={className}
    >
      {markers.length > 0 &&
        markers.map((item) => <Marker key={item.id} position={{ lat: item.lat, lng: item.lng }} />)}
    </GoogleMap>
  );
};

export default MapComponent;
