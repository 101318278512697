import BodyText from '@/components/atoms/AText/BodyText';
import Authar from '@/components/atoms/Authar';
import DisplayFiles from '@/components/atoms/DisplayFiles';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import IconModeEdit from '@/components/icon/IconModeEdit';
import IconMore from '@/components/icon/IconMore/IconMore';
import ListTags from '@/components/molecules/ListTags';
import Medias from '@/components/molecules/Medias';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import { EPath } from '@/route/route';
import { RootState } from '@/stores';
import cn from '@/utils/general';
import { comparePath, replacePathParams } from '@/utils/helpers';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { deleteDocument, getDocumentDetailAsync } from '@/stores/document';
import Linkify from '@/components/atoms/Linkify';
import IconArrowBack from '@/components/icon/IconBackArrow';

const autharData = {
  name: '梅津 由宇',
  tags: ['組織内'],
};

const DetailDocument = () => {
  const isAdmin = true;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { documentDetail } = useSelector((state: RootState) => state.document);
  const { organizationInfo } = useSelector((state: RootState) => state.organization);
  const { user } = useSelector((state: RootState) => state.auth);
  const { QFDCData } = useSelector((state: RootState) => state.common);

  const [showModalDelete, setShowModalDelete] = useState(false);

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  useEffect(() => {
    if (id && !isNaN(+id)) {
      dispatch(getDocumentDetailAsync(+id));
    } else {
      navigate(EPath.notFoundPage);
    }
  }, [id]);

  const [show, setShow] = useState(false);
  const handleOnClick = () => {
    setShow((prev) => !prev);
  };

  const handleOnCloseModal = () => {
    setShow(false);
  };
  const settingRef = useRef<HTMLDivElement>(null);
  useOutsideClick(settingRef, handleOnCloseModal);

  const getPathBackDeleteDocument = () => {
    if (comparePath(window.location.pathname, EPath.documentDetail) && documentDetail) {
      return navigate(replacePathParams(EPath.organizationDocument, { organizationId: documentDetail.organizationId }));
    } else {
      return navigate(replacePathParams(EPath.documents, {}));
    }
  };

  const handleDeleteDocument = () => {
    if (documentDetail?.id) {
      const params = {
        documentId: documentDetail.id,
        organizationId: documentDetail.organizationId,
        callback: getPathBackDeleteDocument,
      };
      dispatch(deleteDocument(params));
      handleCloseModal();
    }
  };

  const getEPathEditBack = () => {
    if (comparePath(window.location.pathname, EPath.documentDetail)) {
      return replacePathParams(EPath.editDocument, {
        id: Number(documentDetail?.id),
        organizationId: documentDetail?.organizationId ?? '',
      });
    }
    return replacePathParams(EPath.editDocumentAll, {
      id: Number(documentDetail?.id),
    });
  };

  const getEPathDetailBack = () => {
    if (comparePath(window.location.pathname, EPath.documentDetail) && documentDetail) {
      return replacePathParams(EPath.organizationDocument, { organizationId: documentDetail.organizationId });
    } else {
      return replacePathParams(EPath.documents, {});
    }
  };

  const getEPathBack = (type: string): string => {
    if (type === 'edit') return getEPathEditBack();
    else return getEPathDetailBack();
  };

  return (
    <>
      <div className="h-[calc(100vh-70px)] overflow-y-auto p-4 pt-0 font-noto md:p-8 md:pt-4">
        <div className="m-auto flex max-w-[640px] flex-col">
          <div className="mb-6 flex flex-col flex-wrap justify-between md:flex-row-reverse">
            <div className="flex justify-between py-2 pb-4 md:p-0">
              <Link to={getEPathBack('detail')} className="my-auto md:hidden">
                <IconArrowBack className="w-6 text-on-surface-variant" />
              </Link>
              {user &&
                documentDetail &&
                (user.id === documentDetail.createdBy || user.id === organizationInfo?.owner.members.id) && (
                  <div className="flex flex-row">
                    <button className={cn('m-1 hidden p-2', isAdmin ? 'flex' : null)}>
                      <Link to={getEPathBack('edit')}>
                        <IconModeEdit className="w-6 text-on-surface-variant" />
                      </Link>
                    </button>
                    <button className={cn('relative m-1 hidden p-2', isAdmin ? 'flex' : null)} onClick={handleOnClick}>
                      <IconMore />
                      {show && (
                        <div ref={settingRef} className="absolute right-0 top-12 z-10 w-[200px]">
                          <ul className="w-full items-end rounded bg-surface-at-2 py-2 shadow-elevation-M3-light-2">
                            <li
                              className="flex h-[40px] w-full items-center gap-2 pl-4 pr-6 hover:bg-black/[0.08]"
                              onClick={handleModalDeleteClick}
                              onKeyDown={() => {}}
                            >
                              <BodyText tag="span" size="large" className="text-error">
                                削除
                              </BodyText>
                            </li>
                          </ul>
                        </div>
                      )}
                    </button>
                  </div>
                )}
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <Authar
                data={autharData}
                typeShare={String(documentDetail?.publish)}
                postedDate={documentDetail?.createdAt}
                isUpdate={false}
                createBy={documentDetail?.user}
              />
              <div></div>
            </div>
          </div>
          <div className="mb-6">
            <div className="mb-6">
              <Medias listImagesPath={QFDCData.imagesPath} isShowDefault={true} />
            </div>
            <BodyText size="medium" className="w-full">
              <div className="w-full max-w-[640px] break-words">
                <Linkify>{documentDetail?.content}</Linkify>
              </div>
            </BodyText>
          </div>
          <ListTags listTags={documentDetail?.tagRelation ?? []} />
          <div className="mb-6">
            <DisplayFiles className="cursor-pointer" files={QFDCData.files} isRemove={false} />
          </div>
        </div>
      </div>
      <CommonModal
        open={showModalDelete}
        title={'文献の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        handleClick={handleDeleteDocument}
      >
        <ModalDelete contentDelete={'文献を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default DetailDocument;
