import React, { useRef, useState } from 'react';
import { HeadLine, Label } from '../AText';
import { OutLinedButton, TextButton } from '@/components/molecules/ButtonIcon';
import IconBackArrow from '@/components/icon/IconBackArrow';
import RemoveImage from '@/components/icon/RemoveImage';
import { FILE_IMAGE, FILE_MAX } from '@/utils/constants/consts';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import DisplayFiles from '@/components/atoms/DisplayFiles';
import { MAX_5_FILE, MAX_5_IMAGE } from '@/utils/constants/messageValidate';
import { FileRes } from '@/types/common';
import { setQFDCData } from '@/stores/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';

interface Props {
  setIsShow: (show: boolean) => void;
  uploadFile: (filesImage: File[], files: File[], imagesPath: string[], filesInit: FileRes[]) => void;
  filesImageRes?: string[];
  filesRes?: FileRes[];
}

type error = {
  image: string;
  file: string;
};

const AddImageDialog = (props: Props) => {
  const { setIsShow, uploadFile, filesImageRes = [], filesRes = [] } = props;
  const dispatch = useDispatch();
  const { QFDCData } = useSelector((state: RootState) => state.common);
  const [filesImageInit, setFilesImageInit] = useState<string[]>(filesImageRes);
  const [filesInit, setFilesInit] = useState<FileRes[]>(filesRes);
  const [isOver, setIsOver] = useState(false);
  const [filesImage, setFilesImage] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [filesPreview, setFilesPreview] = useState<Array<string>>([]);
  const [error, setError] = useState<error>({
    image: '',
    file: '',
  });

  const ref = useRef<HTMLDivElement>(null);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
    event.dataTransfer && updateFile(event.dataTransfer?.files);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      updateFile(event.target.files);
    }
  };

  const updateFile = (filesUpdate: FileList) => {
    if (filesUpdate) {
      const error = {
        file: '',
        image: '',
      };
      const droppedFiles: Array<File> = Array.from(filesUpdate);
      const filesUpload: Array<File> = droppedFiles.filter((item) => !FILE_IMAGE.includes(item.type));
      const images: Array<File> = droppedFiles.filter((item) => {
        return FILE_IMAGE.includes(item.type);
      });
      if (files.length + filesUpload.length + filesInit.length <= FILE_MAX) {
        setFiles([...files, ...filesUpload]);
        error.file = '';
      } else {
        error.file = MAX_5_FILE;
      }

      if (filesImage.length + images.length + filesImageInit.length <= FILE_MAX) {
        setFilesImage([...filesImage, ...images]);

        const selectedFiles: Array<string> = [];
        const targetFilesObject = [...images];
        targetFilesObject.forEach((file) => {
          return selectedFiles.push(URL.createObjectURL(file));
        });
        setFilesPreview([...filesPreview, ...selectedFiles]);
        error.image = '';
      } else {
        error.image = MAX_5_IMAGE;
      }

      setError({
        image: error.image,
        file: error.file,
      });
    }
  };

  const removeImage = (index: number) => {
    const filesPreviewCopy = [...filesPreview];
    filesPreviewCopy.splice(index, 1);
    setFilesPreview(filesPreviewCopy);
    const filesImageCopy = [...filesImage];
    filesImageCopy.splice(index, 1);
    setFilesImage(filesImageCopy);
  };

  const removeFile = (indexRemove: number) => {
    setFiles(files.filter((_, index) => index !== indexRemove));
  };

  const removeImageInit = (path: string) => {
    const filesImageInitNew = filesImageInit.filter((item) => item !== path);
    setFilesImageInit(filesImageInitNew);
    dispatch(setQFDCData({ ...QFDCData, filesPath: QFDCData.filesPath.filter((item) => item !== path) }));
  };

  const removeFileInit = (indexRemove: number) => {
    setFilesInit(filesInit.filter((_, index) => index !== indexRemove));
  };

  return (
    <div className="w-[calc(100vw-64px)] max-w-[528px] rounded-2xl bg-gray-background p-2 md:p-6">
      <div className="mb-4 flex items-center">
        <span>
          <IconBackArrow />
        </span>
        <HeadLine content="画像" size="small" tag="h4" />
      </div>
      {(filesPreview.length > 0 || files.length > 0 || filesImageInit.length > 0 || filesInit.length > 0) && !isOver ? (
        <div onDragOver={handleDragOver}>
          <div className="flex gap-1">
            {filesImageInit.length > 0 &&
              filesImageInit.map((path) => (
                <div key={path} className="col-sm-1">
                  <div className="card relative">
                    <img
                      className="h-[88px] w-[88px] rounded-lg object-cover"
                      src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(path)}`}
                      alt=""
                    />
                    <div className="absolute right-2 top-2" onClick={() => removeImageInit(path)} onKeyDown={() => {}}>
                      <RemoveImage />
                    </div>
                  </div>
                </div>
              ))}
            {filesPreview.map((url, index) => {
              return (
                <div key={url} className="col-sm-1">
                  <div className="card relative">
                    <img className="h-[88px] w-[88px] rounded-lg object-cover" src={url} alt="" />
                    <div className="absolute right-2 top-2" onClick={() => removeImage(index)}>
                      <RemoveImage />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <DisplayFiles files={filesInit} removeFile={removeFileInit} className="bg-surface-at-1" />
          <DisplayFiles files={files} removeFile={removeFile} className="bg-surface-at-1" classNameWrap="!mt-2" />
          <ErrorTooltip className="pl-0" errorMessage={error?.image} hasError={!!error?.image} />
          <ErrorTooltip className="pl-0" errorMessage={error?.file} hasError={!!error?.file} />
          <label
            htmlFor="file-upload"
            className={`mt-4 inline-block cursor-pointer rounded-xl border border-bor-default !bg-white px-6 py-2.5 text-sm leading-[150%] text-on-primary transition-all duration-200 ease-in hover:!bg-black/[0.08] active:scale-[0.98] active:transition-all active:duration-200 active:ease-in ${
              files.length >= 5 && filesPreview.length >= 5 && 'border-none !bg-gray-disable !text-on-surface'
            }`}
          >
            画像・ファイルを追加
          </label>
          <input id="file-upload" type="file" multiple className="hidden" onChange={changeHandler} />
        </div>
      ) : (
        <div id="drag-container" ref={ref} className="relative">
          <input id="file-upload" type="file" multiple className="hidden" onChange={changeHandler} />
          <div
            onClick={() => document?.getElementById('file-upload')?.click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              backgroundColor: isOver ? 'lightgray' : 'white',
            }}
            className="flex min-h-[200px] flex-col gap-2 rounded-lg border border-outline bg-white p-4"
          ></div>
          <div className="pointer-events-none absolute top-[75px] w-full">
            <div className="pointer-events-none flex flex-1 flex-col justify-end text-center">
              <HeadLine size="small" tag="h4" content="Add images/files" className="text-on-surface" />
            </div>
            <div className="pointer-events-none flex-1 text-center align-bottom">
              <Label tag="span" className="text-on-surface-variant" content="or drag and drop" />
            </div>
          </div>
          <ErrorTooltip className="pl-0" errorMessage={error?.image} hasError={!!error?.image} />
          <ErrorTooltip className="pl-0" errorMessage={error?.file} hasError={!!error?.file} />
        </div>
      )}
      <div className="mt-6 flex w-full flex-row justify-end gap-2 pl-2">
        <TextButton onClick={() => setIsShow(false)}>キャンセル</TextButton>
        <OutLinedButton onClick={() => uploadFile(filesImage, files, filesImageInit, filesInit)}>完了</OutLinedButton>
      </div>
    </div>
  );
};

export default AddImageDialog;
