import NotifyCard from '@/components/atoms/NotifyCard';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import BodyText from '@/components/atoms/AText/BodyText';
import cn from '@/utils/general';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification, getYearNotification, readAllNotification } from '@/stores/notification';
import { RootState } from '@/stores';
import { TDataNotification, TListFilter, TNotification, TParams } from '@/types/notification';
import {
  NOTIFICATION_TYPE_BUSINESS_PLAN,
  NOTIFICATION_TYPE_DOCUMENT,
  NOTIFICATION_TYPE_FEEDBACK,
  NOTIFICATION_TYPE_QUESTION,
  NOTIFICATION_TYPE_QUESTION_COMMENT,
  TEXT_SHARE_TYPE,
} from '@/utils/constants/consts';
import { shareType } from '@/types/enum';
import BaseButton from '../../atoms/Button/Button';

const NotifyBoard = () => {
  const [selected, setSelected] = useState({ id: 1, title: '過去3ヶ月', value: 3 });
  const [timeTypes, setTimeTypes] = useState<TListFilter[]>([]);
  const { listNotification, yearNotification } = useSelector((state: RootState) => state.notification);
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const dataAdd = yearNotification.map((item, index) => {
      return {
        id: index + 2,
        title: `${item}年`,
        value: +item,
      };
    });
    setTimeTypes([{ id: 1, title: '過去3ヶ月', value: 3 }, ...dataAdd]);
  }, [yearNotification]);

  const dispatch = useDispatch();

  const fetchData = () => {
    const params: TParams = {};
    if (selected.id === 1) {
      params.filter3month = 3;
    } else {
      params.year = selected.value;
    }
    dispatch(getNotification(params));
  };
  useEffect(() => {
    fetchData();
  }, [selected]);

  useEffect(() => {
    dispatch(getYearNotification());
  }, []);

  const checkBreadCrumb = (data: TDataNotification | undefined, notification: TNotification) => {
    const breadCrumb = [];
    if (
      notification.type === NOTIFICATION_TYPE_QUESTION ||
      notification.type === NOTIFICATION_TYPE_DOCUMENT ||
      notification.type === NOTIFICATION_TYPE_FEEDBACK ||
      notification.type === NOTIFICATION_TYPE_QUESTION_COMMENT
    ) {
      breadCrumb.push({
        name: data?.organization?.title ?? data?.question?.organization.title ?? '',
        to: '#',
      });
      let name = '';
      if (
        notification.type === NOTIFICATION_TYPE_QUESTION ||
        notification.type === NOTIFICATION_TYPE_QUESTION_COMMENT
      ) {
        name = '質問';
      } else if (notification.type === NOTIFICATION_TYPE_DOCUMENT) {
        name = '文献';
      } else {
        name = '指導';
      }
      breadCrumb.push({
        name: name,
        to: '#',
      });
    } else {
      breadCrumb.push({
        name:
          data?.['__house__']?.organization.title ??
          data?.sheetInfo.businessPlanInfo?.['__house__'].organization.title ??
          '',
        to: '#',
      });
      breadCrumb.push({
        name: 'ハウス',
        to: '#',
      });
      breadCrumb.push({
        name: data?.['__house__']?.title ?? data?.sheetInfo.businessPlanInfo?.['__house__'].title ?? '',
        to: '#',
      });
    }

    return breadCrumb;
  };

  const checkActivity = (data: TDataNotification | undefined, notification: TNotification) => {
    let activity = '';
    if (
      notification.type === NOTIFICATION_TYPE_QUESTION ||
      notification.type === NOTIFICATION_TYPE_DOCUMENT ||
      notification.type === NOTIFICATION_TYPE_FEEDBACK
    ) {
      activity = `新規 ${data?.publish === shareType.public ? TEXT_SHARE_TYPE.public : TEXT_SHARE_TYPE.private}`;
    } else if (notification.type === NOTIFICATION_TYPE_BUSINESS_PLAN) {
      activity = '新規';
    } else {
      activity = 'コメント';
    }

    return activity;
  };

  const dataNotification = (data: TDataNotification | undefined, notification: TNotification) => {
    if (data) {
      const activity = checkActivity(data, notification);
      const breadCrumb = checkBreadCrumb(data, notification);

      return {
        ...data,
        idNotification: notification.id,
        type: notification.type,
        activity: activity,
        createAt: notification.createdAt,
        comment:
          notification.questionComment || notification.businessPlanComment || notification.scheduleComment
            ? data.content
            : undefined,
        breadCrumb: breadCrumb,
        recipients: notification.recipients[0].statusCd,
      };
    }

    return undefined;
  };

  const readAll = () => {
    user?.id && dispatch(readAllNotification({ userId: user?.id, callback: () => fetchData() }));
  };
  return (
    <div className="m-auto flex max-w-[800px] flex-col gap-6 p-4 pb-10 font-noto">
      <div className="flex justify-end">
        <div className="flex w-full items-center justify-between text-on-surface">
          <BaseButton className="px-5 text-sm !text-on-surface" variant="on-secondary" onClick={readAll}>
            すべて既読
          </BaseButton>
          <Listbox value={selected} onChange={setSelected}>
            <div className="relative mt-1 rounded-lg border border-M3-outline">
              <Listbox.Button className="relative flex w-full cursor-default items-center justify-between rounded-lg border-M3-outline bg-white py-1 pl-4 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                <BodyText size="large" tag="span" className="font-normal">
                  {selected.title}
                </BodyText>
                <span className="leading-12 pointer-events-none m-1 flex items-center p-2">
                  <i className="icon-24px icon-arrow_drop_down h-6 w-6 bg-M3-on-surface-variant"></i>
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-elevation-M3-light-2 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {timeTypes.map((type) => (
                    <Listbox.Option
                      key={nanoid(5)}
                      className={({ active }) =>
                        `relative flex h-10 cursor-default select-none items-center pl-4 pr-6 ${
                          active ? 'bg-gray-background ' : ''
                        }`
                      }
                      value={type}
                    >
                      {({ selected }) => (
                        <BodyText
                          size="large"
                          tag="span"
                          className={cn('flex truncate ', selected ? 'font-medium' : 'font-normal')}
                        >
                          {type.title}
                        </BodyText>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div className="flex h-[calc(100vh-230px)] flex-col gap-6 overflow-y-auto">
        {listNotification.length > 0 &&
          listNotification.map((notify) => {
            return (
              <NotifyCard
                key={nanoid(5)}
                data={dataNotification(
                  notify.question ??
                    notify.document ??
                    notify.feedback ??
                    notify.questionComment ??
                    notify.businessPlanComment ??
                    notify?.scheduleComment ??
                    notify?.businessPlan,
                  notify,
                )}
              />
            );
          })}
      </div>
    </div>
  );
};

export default NotifyBoard;
