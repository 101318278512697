import { ChangeEvent, useState } from 'react';

export const useService = () => {
  const [searchKey, setSearchKey] = useState('');
  const [isShow, setIsShow] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsShow(true);
    setSearchKey(e.target.value);
  };

  const handleClickOutside = () => {
    setIsShow(false);
  };

  return {
    searchKey,
    handleChange,
    handleClickOutside,
    isShow,
    setSearchKey,
  };
};
