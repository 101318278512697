import SearchBar from '../SearchBar';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconClose } from '@/components/icon/IconClose/IconClose';
import style from './style.module.sass';
import { useService } from './service';
import ModalFilter from '@/components/organisms/Modal/ModalFilter';
import { useOutsideClick } from '@/hooks/useClickOutside';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { IDataFilter, IFilter, ITypeFilter } from '@/types/question';
import { IPagination } from '@/types/common';
import { Link } from 'react-router-dom';
import c from 'clsx';
import { SCREEN_SIZE } from '@/utils/constants/consts';

type FilterBlockProps = {
  addButton?: {
    text: string;
    url: string;
  } | null;
  filterIcon?: boolean;
  data: ITypeFilter[];
  setData: (value: ITypeFilter[]) => void;
  dataOption: IFilter<IPagination, IDataFilter>;
  handleSubmitFilter: (value?: string, clearFilter?: boolean) => void;
};

const FilterBlock = ({
  addButton,
  filterIcon = true,
  data,
  setData,
  handleSubmitFilter,
  dataOption,
}: FilterBlockProps) => {
  const [dataFilterInit, setDataFilterInit] = useState(data);
  const [width, setWidth] = useState(window.innerWidth);
  const [openInputSearchMobile, setOpenInputSearchMobile] = useState<boolean>(false);
  const { displayFilter, handleToggleFilterBlock, handleCloseFilterBlock, setDisplayFilterMobile } = useService();
  const ref = useRef<HTMLDivElement>(null);
  const refMobile = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    handleCloseFilterBlock();
    setData(dataFilterInit);
  });
  useOutsideClick(refMobile, () => setDisplayFilterMobile(false));

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = () => {
    setDataFilterInit(data);
    handleCloseFilterBlock();
    handleSubmitFilter();
  };

  const clearFilter = () => {
    const dataClear = data.map((_, index) => ({
      id: index,
      type: '',
      operator: '',
      value: [],
    }));
    setData(dataClear);
    setDataFilterInit(dataClear);
    handleSubmitFilter(undefined, true);
  };

  const handleClearSearch = () => {
    handleSubmitFilter('', false);
  };

  const checkDataFilter = useMemo(() => {
    return data.filter((item) => item.value.length > 0);
  }, [data]);

  return (
    <>
      <div className="flex w-full gap-4">
        {filterIcon && (!openInputSearchMobile || width > SCREEN_SIZE.lg) && (
          <div ref={ref} className={'items-center'}>
            <div
              className={`flex rounded-[12px] border border-bor-default p-2 px-3 transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in lg:rounded-[100px] lg:border-0 lg:py-[14px] ${
                checkDataFilter.length > 0 && 'bg-primary2'
              } ${displayFilter ? style.box_btn_show_filter : style.box_btn_filter}`}
            >
              <button type="button" className="flex gap-2" onClick={handleToggleFilterBlock}>
                <div
                  onClick={() => {
                    handleToggleFilterBlock();
                    setDataFilterInit(data);
                  }}
                  onKeyDown={() => {}}
                  className="my-auto"
                >
                  <i className="icon-filter_list icon-24px flex h-6 w-6 bg-on-surface-variant" />
                </div>
                <p className="inline-block h-[21px] text-sm leading-[21px] lg:hidden">フィルター</p>
              </button>
              {checkDataFilter.length > 0 && (
                <div className="my-auto" onClick={clearFilter} onKeyDown={() => {}}>
                  <IconClose width="24px" height="24px"></IconClose>
                </div>
              )}
            </div>
            {displayFilter && (
              <div
                className={c(
                  style.box_group_filter,
                  'fixed left-0 top-0 z-[99] !h-[calc(100vh)] w-screen md:left-auto md:top-[188px] md:w-full lg:absolute lg:top-[64px] lg:z-50',
                )}
              >
                <ModalFilter
                  handleToggleFilterBlock={() => {
                    setData(dataFilterInit);
                    handleToggleFilterBlock();
                  }}
                  data={data}
                  setData={setData}
                  dataOption={dataOption}
                  handleSubmitFilter={handleSubmit}
                />
              </div>
            )}
          </div>
        )}
        <SearchBar
          setOpenInputSearchMobile={setOpenInputSearchMobile}
          openInputSearchMobile={openInputSearchMobile}
          handleEnter={(value) => handleSubmitFilter(value)}
          handleClear={handleClearSearch}
        />
      </div>
      {addButton && (
        <Link className="hidden lg:block" to={addButton.url}>
          <PrimaryButton iconLeft="icon-button-add" text={addButton.text ?? '新規作成'} className="icon-button" />
        </Link>
      )}
    </>
  );
};

export default FilterBlock;
