import React from 'react';
import { chartConsts } from '../../organisms/Chart/consts';
import c from 'clsx';
import s from './style.module.sass';

type Props = {
  className?: string;
  left: number;
  range: number;
  label: string;
  offset: number;
  color?: string;
};

const MileStone: React.FC<Props> = (props) => {
  const { className, left, range, label, offset, color = '#959181' } = props;
  const width = range - offset;

  const buildBehindArrow = (width: number) => {
    const w = width > 0 ? width : 0;
    return (
      <svg width={w} height="2" viewBox={`0 0 ${w} 2`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={`M0 1H${w}`} stroke={color} />
      </svg>
    );
  };

  const buildForwardArrow = (width: number) => {
    const w = width > 0 ? width : 0;
    return (
      <svg width={w} height="8" viewBox={`0 0 ${w} 8`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d={`M ${w - 4},0
        L ${w - 0.5}, 3.5
        L ${w - 4},7`}
          stroke={color}
          strokeWidth={1}
        />
        <path d={`M 0,3.5 L ${w},3.5`} stroke={color} strokeWidth={1} />
      </svg>
    );
  };

  return (
    <div className={c(s.root, className)} style={{ left: `${left}px`, height: `${chartConsts.mileStoneSize}px` }}>
      {width >= 28 && buildBehindArrow(width / 2 - 11)}
      {width >= 28 ? <p className={c(s.label)}>{label}</p> : buildForwardArrow(width)}
      {width >= 28 && buildForwardArrow(width / 2 - 11)}
    </div>
  );
};

export default MileStone;
