export const errorCode = {
  EA0001: 'メールアドレスが間違っています。',
  EA0002: 'メールアドレスが存在しません。',
  EA0003: 'OTPが間違っています。',
  EA0004: 'アカウントが既に存在します。',
  EA0005: '必須項目を入力してください。',
  EA0006: 'エラーが発生しました。',
  EA0007: 'エラーが発生しました。',
  EA0008: 'エラーが発生しました。',
  EA0009: 'エラーが発生しました。',
  EA0010: '質問が見つかりません。',
  EA0011: 'エラーが発生しました。',
  EA0012: 'エラーが発生しました。',
  EA0013: 'エラーが発生しました。',
  EA0014: 'エラーが発生しました。',
  EA0015: '指導が見つかりません。',
  EA0016: 'エラーが発生しました。',
  EA0017: 'エラーが発生しました。',
  EA0018: '文献が見つかりません。',
  EA0019: 'エラーが発生しました。',
  EA0020: 'エラーが発生しました。',
  EA0021: 'タグが存在しません。',
  EA0022: '組織が存在しません。',
  EA0023: 'エラーが発生しました。',
  EA0024: 'エラーが発生しました。',
  EA0025: 'メンバーが存在しません。',
  EA0026: '権限がありません。',
  EA0027: 'エラーが発生しました。',
  EA0028: 'エラーが発生しました。',
  EA0029: 'エラーが発生しました。',
  EA0030: 'エラーが発生しました。',
  EA0049: '不正な値なので、エラーが発生しました。',
  EA0062: '内容を入力してください。',
  EA9999: 'システムエラー',
  EA0079: '組織のオーナーはアカウントを削除することができません。',
};

export type keyErrorCodeOb = {
  [k: string]: keyErrorCode;
};

export type keyErrorCode =
  | 'EA0001'
  | 'EA0002'
  | 'EA0003'
  | 'EA0004'
  | 'EA0005'
  | 'EA0006'
  | 'EA0007'
  | 'EA0008'
  | 'EA0009'
  | 'EA0010'
  | 'EA0011'
  | 'EA0012'
  | 'EA0013'
  | 'EA0014'
  | 'EA0015'
  | 'EA0016'
  | 'EA0017'
  | 'EA0018'
  | 'EA0019'
  | 'EA0020'
  | 'EA0021'
  | 'EA0022'
  | 'EA0023'
  | 'EA0024'
  | 'EA0025'
  | 'EA0026'
  | 'EA0027'
  | 'EA0028'
  | 'EA0029'
  | 'EA0030'
  | 'EA9999'
  | 'EA0079';
