import React from 'react';

type Props = {
  className?: string;
};
const IconHarvest: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <>
      <svg
        width="60"
        height="24"
        viewBox="0 0 60 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect width="60" height="24" rx="12" fill="#FF5449" />
      </svg>
    </>
  );
};

export default IconHarvest;
