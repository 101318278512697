import cn from '@/utils/general';
import React from 'react';

type TextOwnProps = {
  text?: string;
  className?: string;
};

type TextProps = TextOwnProps & Omit<React.HTMLProps<HTMLSpanElement>, keyof TextOwnProps>;

const Text = ({ className, text, ...rest }: TextProps) => {
  return React.createElement(
    'span',
    {
      className: cn('break-words regular-text', className),
      ...rest,
    },
    text,
  );
};

export default Text;
