import React from 'react';
import AllQuestions from '@/components/pages/AllQuestion';
import { useParams } from 'react-router-dom';

function QuestionOrganization() {
  const { organizationId } = useParams();
  return <AllQuestions isCreate={true} organizationId={organizationId ? +organizationId : 0} />;
}

export default QuestionOrganization;
