import React, { PropsWithChildren } from 'react';
import c from 'clsx';
import s from './style.module.sass';
import { useService } from './service';

type Props = PropsWithChildren<{
  className?: string;
  id: string;
  width: number;
  left: number;
  moveDate?: string;
}>;

const ChartGroupAll: React.FC<Props> = (props) => {
  const { className, id, width, left, children, moveDate } = props;

  const { attributes, listeners, setNodeRef, translate } = useService(id);

  return (
    <div
      className={c(s.root, className, '!cursor-default')}
      style={{
        width: `${width}px`,
        left: `${left}px`,
        transform: translate,
      }}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {!!moveDate && <p className={s.moveDate}>{moveDate}</p>}
      {children}
    </div>
  );
};

export default ChartGroupAll;
