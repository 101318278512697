const RemoveImage = () => {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1975_6251)">
          <rect width="24" height="24" rx="12" fill="#EAE3BD" />
          <path
            d="M16.6673 8.27398L15.7273 7.33398L12.0007 11.0607L8.27398 7.33398L7.33398 8.27398L11.0607 12.0007L7.33398 15.7273L8.27398 16.6673L12.0007 12.9407L15.7273 16.6673L16.6673 15.7273L12.9407 12.0007L16.6673 8.27398Z"
            fill="#1F1C05"
          />
        </g>
        <defs>
          <clipPath id="clip0_1975_6251">
            <rect width="24" height="24" rx="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default RemoveImage;
