import React from 'react';
import c from 'clsx';
import Input from '../../atoms/Input';
import { useService } from './service';
import { chartConsts } from '../../organisms/Chart/consts';
import s from './style.module.sass';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type Props = {
  className?: string;
  area: string;
  id: string;
  label: string;
};

const ChartNameCell: React.FC<Props> = (props) => {
  const { className, area, id, label } = props;
  const { isEdit, inputRef, handleSubmit } = useService(area, id);
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={styles} className="group/item flex h-12 pl-1 hover:bg-gray-background" key={id}>
      <div className="flex items-center" {...listeners}>
        <svg
          width="24"
          height="24"
          className={'hidden group-hover/item:block'}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z"
            fill="#615F50"
          ></path>
        </svg>
      </div>
      <div className="block w-6 group-hover/item:hidden"></div>
      <div
        className={`${c(s.root, className)} my-auto`}
        style={{
          height: `${chartConsts.mileStoneSize}px`,
        }}
      >
        {isEdit ? (
          <form onSubmit={handleSubmit}>
            <Input ref={inputRef} />
          </form>
        ) : (
          <>
            <DefaultText variant="text-link" className={`text-sm text-on-surface ${s.label}`}>
              {label}
            </DefaultText>
            {/* <div className={s.editIcon} onClick={handleOpenEdit}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.06 3.59L20.41 4.94C21.2 5.72 21.2 6.99 20.41 7.77L7.18 21H3V16.82L13.4 6.41L16.23 3.59C17.01 2.81 18.28 2.81 19.06 3.59ZM5 19L6.41 19.06L16.23 9.23L14.82 7.82L5 17.64V19Z"
                fill="#959181"
              />
            </svg>
          </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default ChartNameCell;
