import Guidance from '@/components/organisms/Guidance';
import { RootState } from '@/stores';
import {
  getDocumentsAsync,
  getOrganizationFilterAsync,
  getTagFilterAsync,
  getUserFilterAsync,
  setKeyword,
} from '@/stores/document';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ITypeFilter } from '@/types/question';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { IParamsFilter } from '@/types/common';

import { DEFAULT_PAGE_SIZE, QUANTITY_TYPE_FILTER, typeFilterQuestion } from '@/utils/constants/consts';

import { getDetailOrganization, setTitleHeader } from '@/stores/common';
import { titleMapping } from '@/layout/header/header.Config';
import { FilterHouses } from '@/types/houses';

type Props = {
  isCreate?: boolean;
  organizationId?: number;
};
const data: ITypeFilter[] = [];
Array(QUANTITY_TYPE_FILTER.FEEDBACK)
  .fill('')
  .forEach((value, index) => {
    data.push({
      id: index,
      type: '',
      operator: '',
      value: [],
    });
  });
const AllLiterature = ({ isCreate, organizationId }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documents, dataOption, page, keyword, total } = useSelector((state: RootState) => state.document);
  const [dataFilter, setDataFilter] = useState<ITypeFilter[]>(data);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [dataFilterSelected, setDataFilterSelected] = useState<FilterHouses>({});

  useEffect(() => {
    if (organizationId) {
      dispatch(
        getDocumentsAsync({
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          keyword: '',
          organizationId: [
            {
              operation: 'in',
              value: [+organizationId],
            },
          ],
        }),
      );
      dispatch(getDetailOrganization(Number(organizationId)));
    } else {
      dispatch(
        getDocumentsAsync({
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          keyword: '',
        }),
      );
      dispatch(setTitleHeader(titleMapping.literatures));
    }
  }, []);

  useEffect(() => {
    dispatch(getTagFilterAsync({ ...dataOption[typeFilterQuestion.TAG].pagination, organizationId: organizationId }));
  }, [dataOption[typeFilterQuestion.TAG].pagination.page, dataOption[typeFilterQuestion.TAG].pagination.limit]);

  useEffect(() => {
    dispatch(
      getUserFilterAsync({ ...dataOption[typeFilterQuestion.AUTHOR].pagination, organizationId: organizationId }),
    );
  }, [dataOption[typeFilterQuestion.AUTHOR].pagination.page, dataOption[typeFilterQuestion.AUTHOR].pagination.limit]);

  useEffect(() => {
    dispatch(
      getOrganizationFilterAsync({
        ...dataOption[typeFilterQuestion.ORGANIZATION].pagination,
        organizationId: organizationId,
      }),
    );
  }, [
    dataOption[typeFilterQuestion.ORGANIZATION].pagination.page,
    dataOption[typeFilterQuestion.ORGANIZATION].pagination.limit,
  ]);

  const handleLinkDetail = (id: number) => {
    if (isCreate) {
      navigate(`${replacePathParams(EPath.documentDetail, { id: id, organizationId: organizationId ?? '' })}`);
    } else {
      navigate(`${replacePathParams(EPath.documentDetailAll, { id: id })}`);
    }
  };

  const handleSubmitFilter = (searchKey?: string, clearFilter?: boolean) => {
    if (searchKey || searchKey === '') {
      dispatch(setKeyword(searchKey));
    }
    const dataFilterApi: IParamsFilter = {};
    if (!clearFilter) {
      dataFilter.forEach((item) => {
        if (item.type) {
          dataFilterApi[item.type] = [
            {
              operation: item.operator,
              value: item.value,
            },
          ];
        }
      });
    }

    if (organizationId) {
      dataFilterApi.organizationId = [{ operation: 'in', value: [+organizationId] }];
    }

    const data = {
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      keyword: searchKey ?? keyword,
      ...dataFilterApi,
    };
    setDataFilterSelected(data);
    dispatch(getDocumentsAsync(data));
  };

  const fetchMoreData = () => {
    if (documents.length >= (total ?? 0)) {
      setHasMore(false);
      return;
    }

    if (organizationId) {
      dispatch(
        getDocumentsAsync({
          ...dataFilterSelected,
          page: (page ?? 1) + 1,
          limit: DEFAULT_PAGE_SIZE,
          keyword,
          organizationId: [
            {
              operation: 'in',
              value: [+organizationId],
            },
          ],
        }),
      );
    } else {
      dispatch(
        getDocumentsAsync({
          ...dataFilterSelected,
          page: (page ?? 1) + 1,
          limit: DEFAULT_PAGE_SIZE,
          keyword,
        }),
      );
    }
  };

  useEffect(() => {
    setHasMore(documents.length < (total ?? 0));
  }, [total]);

  return (
    <Guidance
      contents={documents}
      handleLinkDetail={handleLinkDetail}
      dataFilter={dataFilter}
      setDataFilter={setDataFilter}
      handleSubmitFilter={handleSubmitFilter}
      dataOption={dataOption}
      isCreate={isCreate}
      ePath={EPath.newDocument}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
    />
  );
};

export default AllLiterature;
