import * as React from 'react';
import { CellTemplate, Cell, Compatible, Uncertain, UncertainCompatible, getCellProperty } from '@silevis/reactgrid';
import './index.css';
import Label from '@/components/atoms/AText/Label';
import cn from '@/utils/general';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { setDialogType } from '@/stores/business/slice';

export interface FlagCell extends Cell {
  type: 'flag';
  text: string;
  className?: string;
  variant?: string;
  isOpen?: boolean;
}

export class FlagCellTemplate implements CellTemplate<FlagCell> {
  dispatch = useDispatch();
  dialogType = useSelector((state: RootState) => state.business.dialogType);
  getCompatibleCell(uncertainCell: Uncertain<FlagCell>): Compatible<FlagCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = getCellProperty(uncertainCell, 'variant', 'string');
    return { ...uncertainCell, text, value, isOpen: uncertainCell.isOpen };
  }

  update(cell: Compatible<FlagCell>, cellToMerge: UncertainCompatible<FlagCell>): Compatible<FlagCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  handleShowDialog(type: string) {
    this.dispatch(setDialogType(''));
    this.dispatch(setDialogType(type));
  }

  render(cell: Compatible<FlagCell>, isInEditMode: boolean): React.ReactNode {
    const variant = cell.variant;
    const text = cell.text.toLowerCase();
    const className = cell?.className?.toLowerCase();

    if (!isInEditMode) {
      return (
        <div className={cn('text-icon-cell flex w-full flex-row items-center gap-2', className)}>
          <Label tag="span" size="medium" className="block grow text-ellipsis text-on-surface-variant ">
            {text}
          </Label>
          {variant && (
            <span
              className="flex items-center justify-center hover:cursor-pointer"
              onClick={() => this.handleShowDialog(variant ?? '')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.handleShowDialog(cell.text);
                }
              }}
            >
              <i className="icon-24px icon-mode_edit h-4_5 w-4_5 bg-outline" />
            </span>
          )}
        </div>
      );
    }
    return <></>;
  }
}
