import { IconClose } from '@/components/icon/IconClose/IconClose';
import { IconCloseHover } from '@/components/icon/IconClose/IconCloseHover';
import { useState } from 'react';
import DefaultText from '../Text/DefaultText';

type Props = {
  text: string;
};

const TagsClose = (props: Props) => {
  const { text } = props;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div
        className="flex h-[33px] rounded-lg border border-outline bg-surface hover:bg-onSurfaceVariant-opacity"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <DefaultText variant="length" className="my-[6px] pl-[12px] pr-[8px] text-[14px] text-on-surface-variant">
          {text}
        </DefaultText>
        <div className="my-[6px] hidden pr-[8px] pt-[1.5px]">{isHovered ? <IconCloseHover /> : <IconClose />}</div>
      </div>
    </>
  );
};

export default TagsClose;
