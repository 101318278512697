import DefaultText from '@/components/atoms/Text/DefaultText';
import style from './style.module.sass';

type DropdownSettingChartProps = {
  onAreaEditClick: () => void;
  modalSeedingWithinAreaClick: () => void;
  modalModalDeleteClick: () => void;
};

function DropdownSettingChart({
  onAreaEditClick,
  modalSeedingWithinAreaClick,
  modalModalDeleteClick,
}: DropdownSettingChartProps) {
  return (
    <>
      <div className={`${style.setting_box} left-[-130px] h-[136px] lg:left-[-140px]`}>
        <div className={style.setting_list_item}>
          <div className={style.setting_item} onClick={onAreaEditClick}>
            <DefaultText variant="title-children" className="text-on-surface">
              エリアの編集
            </DefaultText>
          </div>
          <div className={style.setting_item} onClick={modalSeedingWithinAreaClick}>
            <DefaultText variant="title-children" className="text-on-surface">
              エリア内播種の編集
            </DefaultText>
          </div>
          <div className={style.setting_item} onClick={modalModalDeleteClick}>
            <DefaultText variant="title-children" className="text-error">
              削除
            </DefaultText>
          </div>
        </div>
      </div>
    </>
  );
}

export default DropdownSettingChart;
