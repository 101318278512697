import NotifyBoard from '@/components/organisms/NotifyBoard';
import React from 'react';

const Notification = () => {
  return (
    <>
      <NotifyBoard />
    </>
  );
};

export default Notification;
