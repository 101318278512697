import Authar from '@/components/atoms/Authar';
import PageHeader from '@/components/molecules/PageHeader';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import FooterQFD from '../../molecules/FooterQFD';
import Medias from '@/components/molecules/Medias';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import DisplayFiles from '@/components/atoms/DisplayFiles';
import { ITag } from '@/types/tag';
import { setQFDCData } from '@/stores/common';
import { useNavigate, useParams } from 'react-router-dom';
import ListTags from '@/components/molecules/ListTags';
import { shareType } from '@/types/enum';
import { EPath } from '@/route/route';
import { IQuestionCreateApi } from '@/types/question';
import { createDocument } from '@/stores/document';
import TextArea from '@/components/atoms/TextArea';
import { replacePathParams, getTitleHeader } from '@/utils/helpers';
import { createQuestion } from '@/stores/question';
import { createFeedback } from '@/stores/feedback/slice';
import { CONSTANTS } from '@/utils/constants/consts';

const autharData = {
  name: '梅津 由宇',
  tags: ['組織内'],
};

type Props = {
  typeCreate: string;
};

const NewQFD = (prop: Props) => {
  const { typeCreate } = prop;
  const isAdmin = false;
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { QFDCData, titleHeader } = useSelector((state: RootState) => state.common);
  const { user, infoUser } = useSelector((state: RootState) => state.auth);
  const [selected, setSelected] = useState<Array<ITag>>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const { width } = useSelector((state: RootState) => state.common);

  const isMobile = width <= CONSTANTS.SIZE_MOBILE;

  useEffect(() => {
    dispatch(
      setQFDCData({
        content: '',
        imagesDisplay: [],
        filesPath: [],
        imagesPath: [],
        files: [],
        listTags: [],
        typeShare: String(shareType.private),
      }),
    );
  }, []);

  const setTypeShare = (value: string) => {
    dispatch(
      setQFDCData({
        ...QFDCData,
        typeShare: value,
      }),
    );
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleCreateDocument = async () => {
    const tagsId = selected.map((item) => item.id).join(',');
    if (organizationId && !isNaN(+organizationId)) {
      const data: IQuestionCreateApi = {
        organizationId: +organizationId,
        title: user?.name ?? '',
        content: text,
        publish: +QFDCData.typeShare,
        tags: tagsId,
      };
      if (QFDCData.filesPath.length > 0) {
        data.image = [...QFDCData.filesPath, ...QFDCData.imagesPath];
      }

      if (typeCreate === 'document') {
        dispatch(createDocument({ ...data, callback: () => navigate(filterEPath()) }));
      } else if (typeCreate === 'question') {
        dispatch(createQuestion({ ...data, callback: () => navigate(filterEPath()) }));
      } else if (typeCreate === 'feedback') {
        dispatch(createFeedback({ ...data, callback: () => navigate(filterEPath()) }));
      }
    }
  };

  const filterEPath = () => {
    if (organizationId) {
      switch (typeCreate) {
        case 'document':
          return replacePathParams(EPath.organizationDocument, { organizationId: organizationId });
        case 'question':
          return replacePathParams(EPath.organizationQuestion, { organizationId: organizationId });
        case 'feedback':
          return replacePathParams(EPath.organizationFeedback, { organizationId: organizationId });
        default:
          return EPath.organizationQuestion;
      }
    }

    return '';
  };

  const unChoose = (id: number) => {
    const selectTag = [...selected].filter((item) => item.id !== id);
    setSelected(selectTag);

    dispatch(
      setQFDCData({
        ...QFDCData,
        listTags: selectTag,
      }),
    );
  };

  return (
    <div className="flex flex-col justify-between font-noto lg:h-screen">
      <div className="border-b border-b-[#E8E2D0] px-2 py-1 lg:px-8 lg:py-2">
        <PageHeader isAdmin={isAdmin} title={getTitleHeader(titleHeader, isMobile)} ePathBack={filterEPath()} />
      </div>
      <div
        className="h-[calc(100vh-200px)] overflow-y-auto md:grow"
        onClick={() => {
          textareaRef.current?.focus();
        }}
      >
        <div className="mx-auto flex grow flex-col p-4 !pb-5 sm:w-[80%] lg:w-[calc(16/36*100%)] lg:p-0 lg:pt-2">
          <div className="mb-6 flex justify-between">
            <div className="flex items-center gap-2">
              <Authar
                data={autharData}
                setTypeShare={(value) => setTypeShare(value)}
                typeShare={QFDCData.typeShare}
                createBy={infoUser}
              />
            </div>
          </div>
          <Medias listImagesPath={QFDCData.imagesPath} />
          <br />
          <TextArea btnRef={textareaRef} value={text} onChange={handleTextareaChange} />
          <ListTags listTags={QFDCData.listTags} isDelete deleteTag={unChoose} />
          <DisplayFiles files={QFDCData.files} isRemove={false} className="bg-white" />
        </div>
      </div>
      <footer className="bottom-0 flex w-full justify-between bg-gray-footer px-2 md:px-6">
        <FooterQFD
          selected={selected}
          setSelected={setSelected}
          handleCreateUpdate={handleCreateDocument}
          isEdit={false}
          ePathDetail={EPath.documentDetail}
          filesImageRes={QFDCData.imagesPath}
          ePathOrganization={filterEPath()}
          filesRes={QFDCData.files}
        />
      </footer>
    </div>
  );
};

export default NewQFD;
