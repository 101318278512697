import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useEffect, useState } from 'react';
import HouseComponent from './HouseComponent';
import MapComponent from './MapComponent';
import { nanoid } from 'nanoid';
import ChartAllHouses from '../Main/ChartAllHouses';
import { getHousesALlAsync, saveHouses, setKeyword } from '@/stores/houses';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { TMarker } from '@/types/houses';
import { DEFAULT_PAGE_SIZE } from '@/utils/constants/consts';

function AllHousesPage() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('house');
  const [markers, setMarkers] = useState<TMarker[]>([]);
  const { housesAll } = useSelector((state: RootState) => state.houses);

  const handleTabClick = (tabId: React.SetStateAction<string>) => {
    setActiveTab(tabId);
  };
  const nameTags = [
    {
      name: 'ハウス',
      value: 'house',
    },
    {
      name: '栽培計画',
      value: 'chart',
    },
    {
      name: 'マップ',
      value: 'maps',
    },
  ];

  useEffect(() => {
    setMarkers(
      housesAll.map((item) => ({
        id: item.id,
        lat: +item.coordinatesLat,
        lng: +item.coordinatesLong,
      })),
    );
  }, [housesAll]);

  useEffect(() => {
    dispatch(
      getHousesALlAsync({
        page: 0,
        limit: 0,
      }),
    );

    return () => {
      dispatch(
        saveHouses({
          houses: [],
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          total: 0,
        }),
      );
      dispatch(setKeyword(''));
    };
  }, []);

  return (
    <div className="relative h-[calc(100%-64px)] bg-surface-at-1 lg:pb-4">
      <div className="mt-[50px] lg:mt-0 lg:px-4 lg:pt-4">
        <ul
          className="flex h-[48px] justify-center border-b border-outline-variant bg-on-error pl-[18px] lg:h-[64px] lg:justify-start lg:rounded-t-2xl lg:pt-[16px]"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          {nameTags.map((i) => (
            <li
              key={nanoid()}
              className={`mr-2 rounded-t-lg hover:bg-black/[0.08] ${
                activeTab === i.value ? 'border-b-[3px] border-primary' : ''
              }`}
            >
              <button
                onClick={() => handleTabClick(i.value)}
                role="tab"
                aria-controls={i.value}
                aria-selected={activeTab === i.value}
              >
                <DefaultText
                  variant="length"
                  className={`inline-block ${
                    activeTab === i.value ? 'text-on-surface' : 'text-on-surface-variant'
                  } rounded-t-lg px-4 text-center text-sm font-medium ${activeTab === i.value ? 'active' : ''}`}
                >
                  {i.name}
                </DefaultText>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="lg:overflow-scroll-mb h-[calc(100vh-100px)] bg-surface-at-1 md:!h-[calc(100vh-160px)] lg:m-0 lg:mx-4 lg:rounded-b-2xl">
        <div className={'mx-auto h-full bg-white'}>
          <div id="myTabContent" className={'h-full p-[8px] lg:p-[0px]'}>
            {activeTab === 'house' && <HouseComponent />}
            {activeTab === 'chart' && <ChartAllHouses />}
            {activeTab === 'maps' && <MapComponent className="h-[calc(100vh-165px)]" zoom={16} markers={markers} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllHousesPage;
