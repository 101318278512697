import React from 'react';
import style from './style.module.sass';
import { getIcon } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import { EPath } from '@/route/route';
import { logout } from '@/stores/auth';
import { useDispatch, useSelector } from 'react-redux';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';
import { RootState } from '@/stores';

function DropdownAccount() {
  const dispatch = useDispatch();
  const { infoUser } = useSelector((state: RootState) => state.auth);
  const handleSubmitLogout = () => {
    dispatch(logout());
  };
  return (
    <div className={style.setting_box}>
      <div className={style.setting_list_item}>
        <Link to={EPath.account}>
          <div className={style.setting_item}>
            <DisplayAvatar
              path={infoUser?.file && infoUser?.file[0]?.path}
              className="mr-5 !h-5 w-5 rounded-full text-sm leading-5"
              nameUser={infoUser?.name ?? ''}
            />

            <span style={{ color: '#1D1C16' }}>アカウント</span>
          </div>
        </Link>
        <div className={style.setting_item} onClick={handleSubmitLogout}>
          {getIcon('logout')}

          <span style={{ color: '#1D1C16' }}>ログアウト</span>
        </div>
      </div>
    </div>
  );
}

export default DropdownAccount;
