import Breadcrumb, { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import BaseButton from '@/components/atoms/Button/Button';
import DefaultText from '@/components/atoms/Text/DefaultText';
import ListGuideComponent from '@/components/molecules/ListGuideComponent/ListGuideComponent';
import { useOutsideClick } from '@/hooks/useClickOutside';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EPath } from '@/route/route';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { DEFAULT_ITEM_COUNT } from '@/utils/constants/consts';
import { getAPIFeedbackList } from '@/stores/feedback/services';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import { replacePathParams } from '@/utils/helpers';
import { nanoid } from 'nanoid';

type Props = {
  organizationId: string;
  titleHeader: string;
};

const GuideComponent: React.FC<Props> = (props: Props) => {
  const dispatchGuide = useDispatch();
  const { organizationId: organizationIdGuide, titleHeader } = props;
  const [isShowAllGuide, setIsShowAllGuide] = useState(false);
  const tableHeadingGuide = useMemo(
    () => [
      { id: 1, label: '' },
      { id: 2, label: '' },
      { id: 3, label: '公開範囲' },
      { id: 4, label: '作成者' },
      { id: 5, label: '公開日' },
      { id: 6, label: '' },
    ],
    [],
  );

  const { feedbacks } = useSelector((state: RootState) => state.feedback);
  useEffect(() => {
    dispatchGuide(
      getAPIFeedbackList({
        page: 1,
        limit: 0,
        organizationId: [{ operation: 'in', value: [+organizationIdGuide] }],
      }),
    );
  }, [organizationIdGuide]);

  const BREADCRUMBS_ROOT: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: '#',
    },
    {
      name: '指導',
      to: replacePathParams(EPath.organizationFeedback, {
        organizationId: organizationIdGuide ?? '',
      }),
    },
  ];

  const refGuide = useRef<HTMLDivElement>(null);

  useOutsideClick(refGuide, () => new Array(feedbacks.length).fill(false));

  return (
    <div
      className={`container_HouseComponent lg:min-w-full ${
        feedbacks.length > 0 ? 'min-w-[800px]' : 'w-[calc(100vw-48px)] md:w-[calc(100vw-288px)]'
      }`}
    >
      <Breadcrumb data={BREADCRUMBS_ROOT} className="h-[18px]" />
      <DefaultText variant="title-children" className="h-[27px] text-[18px] text-on-surface">
        {'指導'}
      </DefaultText>
      <div className="flex flex-1 flex-col">
        {feedbacks.length > 0 ? (
          <div className="questionComponentListColumn h-[25px]">
            {tableHeadingGuide.map((e, i) => (
              <DefaultText
                variant="text-status"
                className={`${
                  i === 0 ? 'justify-start' : 'justify-center'
                } 2xl:text-text-[11px] flex items-center text-[11px] text-on-surface ${i === 0 && 'pl-[16px]'}`}
                key={nanoid(5)}
              >
                {e.label}
              </DefaultText>
            ))}
          </div>
        ) : (
          <div className="text-center text-lg text-on-surface-variant">{MESSAGE_NO_DATA}</div>
        )}

        <div className="max-h-[500px] overflow-auto">
          <ul>
            {(isShowAllGuide ? feedbacks : feedbacks.slice(0, 3)).map((feedback) => (
              <ListGuideComponent key={nanoid(5)} feedback={feedback} />
            ))}
          </ul>
        </div>
      </div>
      {!isShowAllGuide && feedbacks.length > DEFAULT_ITEM_COUNT && (
        <div className="pt-[8px]">
          <BaseButton
            variant="on-secondary"
            type="submit"
            className="w-[119px]"
            onClick={() => setIsShowAllGuide(!isShowAllGuide)}
          >
            <DefaultText variant="length" className="text-[14px] text-on-primary">
              もっと見る
            </DefaultText>
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default GuideComponent;
