import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { renameChart } from '../../../stores/chart/effects';

export const useService = (area: string, id: string) => {
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenEdit = () => {
    setEdit(true);
  };
  const handleSubmit = () => {
    if (inputRef?.current?.value) {
      dispatch(renameChart({ name: inputRef.current.value, area, id }));
    }
    setEdit(false);
  };

  return {
    isEdit,
    inputRef,
    handleOpenEdit,
    handleSubmit,
  };
};
