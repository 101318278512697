import React from 'react';

interface Props {
  width: number;
  height: number;
}
export const IconNavigateNext = (props: Props) => {
  const { width, height } = props;
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.27869 4.5L6.22119 5.5575L9.65619 9L6.22119 12.4425L7.27869 13.5L11.7787 9L7.27869 4.5Z"
          fill="#959181"
        />
      </svg>
    </>
  );
};
