import React, { useEffect, useState } from 'react';
import Header from './header';
import { Outlet, useParams } from 'react-router-dom';
import style from './style.module.sass';
import SidebarMenu from '@/layout/sidebar/menu';
import { setWidthScreen } from '@/stores/common';
import { useDispatch } from 'react-redux';
import { getOrganizationMember, setOrganizationInfo } from '@/stores/organization';

function Layout() {
  const dispatch = useDispatch();
  const { organizationId = '' } = useParams();
  const [narrowSidebar, setNarrowSidebar] = useState(false);
  const [sidebarSp, setSidebarSp] = useState(false);

  const handleWindowSizeChange = () => {
    dispatch(setWidthScreen(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (!organizationId) {
      dispatch(setOrganizationInfo(null));
    } else {
      dispatch(getOrganizationMember(+organizationId));
    }
  }, [organizationId]);

  function showSidebarSpEvent() {
    setSidebarSp(!sidebarSp);
  }

  return (
    <div className={style.container_full}>
      <SidebarMenu
        narrowSidebar={narrowSidebar}
        setNarrowSidebar={setNarrowSidebar}
        sidebarSp={sidebarSp}
        showSidebarSpEvent={showSidebarSpEvent}
      />
      <div className={narrowSidebar ? style.content_full_narrow : style.content_full}>
        <Header onClick={showSidebarSpEvent} />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
