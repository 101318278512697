const Star = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.77L3.82 19.5L5.46 12.47L0 7.74L7.19 7.13L10 0.5L12.81 7.13L20 7.74L14.54 12.47L16.18 19.5L10 15.77Z"
        fill="#615F50"
      />
    </svg>
  );
};

export default Star;
