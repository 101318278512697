import { DefaultCellTypes, Row } from '@silevis/reactgrid';
import { months } from './helpers';

import {
  headerCell,
  iconCell,
  monthHeaderCell,
  nonEditable,
  numberCell,
  textCell,
  textCellNoSideBorder,
} from './cells';
import {
  CostSalesFlows,
  InputVariables,
  MonthlyValues,
  ProfitFlows,
  SalesFlows,
  SellingExpensesFlows,
  TTotalValue,
} from './interfaces';
import { FlagCell } from './IconCellTemplate';
import { convertDateBp } from '@/utils/number';
import { formatNumberWithCommas } from '@/utils/helpers';

export const HEADER_ROW_ID = 'header';
export const NOMINAL_ROW_ID = 'nominal_Header';
const ROW_HEIGHT = 32;
const HEADING_ROW_HEIGHT = 24;
export const SALESFLOW_ROW_ID = '売上 合計';
export const COSTSALESFLOW_ROW_ID = '売上 合計';
export const SELLINGEXPENSESFLOWS_ROW_ID = '販売費 合計';

function getCountByYear(listMonth: string[]) {
  const countByYear: number[] = [];
  let yearFocus = listMonth[0].split('/')[0];
  let count = 0;
  listMonth.forEach((item, index) => {
    const yearCurrent = item.split('/')[0];
    if (yearFocus !== yearCurrent) {
      countByYear.push(count);
      count = 0;
    }

    yearFocus = yearCurrent;

    count += 1;

    if (index === listMonth.length - 1) {
      countByYear.push(count);
    }
  });

  const countByYearConvert = countByYear.map((item) => Math.floor(item / 2) + 1);

  return {
    countByYearConvert,
    countByYear,
  };
}

function getNominalRow(listYear: string[], listMonth: string[], groupBy: string): Row {
  const data: DefaultCellTypes[] = [];
  if (groupBy === 'year') {
    const indexFlag = {
      turn: 0,
      index: 0,
    };

    const { countByYear, countByYearConvert } = getCountByYear(listMonth);

    let yearFocus2 = listYear[0];

    listMonth.forEach((item) => {
      const yearCurrent = item.split('/')[0];
      indexFlag.index += 1;
      if (indexFlag.index === countByYearConvert[indexFlag.turn]) {
        data.push(
          nonEditable(
            textCellNoSideBorder(
              item.split('/')[0],
              `justify-content-center ${countByYear[indexFlag.turn] % 2 !== 0 ? '!w-[300px]' : ''}`,
              {
                color: 'rgb(var(--color-on-surface))',
                border: {
                  right: {
                    style: 'none',
                  },
                },
              },
            ),
          ),
        );
      } else {
        if (yearFocus2 !== yearCurrent) {
          data.push(
            nonEditable(
              textCellNoSideBorder('', 'justify-content-center', {
                color: 'rgb(var(--color-on-surface))',
              }),
            ),
          );
          indexFlag.turn += 1;
          indexFlag.index = 1;
        }
        data.push(
          nonEditable(
            textCellNoSideBorder('', 'justify-content-center', {
              color: 'rgb(var(--color-on-surface))',
              border: {
                right: {
                  style: 'none',
                },
              },
            }),
          ),
        );
      }

      yearFocus2 = yearCurrent;
    });

    data.push(
      nonEditable(
        textCellNoSideBorder('', 'justify-content-center', {
          color: 'rgb(var(--color-on-surface))',
        }),
      ),
    );
  } else {
    listYear.forEach((item) => {
      data.push(
        nonEditable(
          textCellNoSideBorder(item, 'justify-content-center border-r border-green', {
            color: 'rgb(var(--color-on-surface))',
          }),
        ),
      );
    });
  }
  return {
    rowId: NOMINAL_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [nonEditable(headerCell('名目', 'border-b-none h-[100px] padding-x-1_5 h-12')), ...data],
  };
}

const monthsInYear = (listYearMonth: string[]) => {
  return [
    ...listYearMonth.map((yearMonth) => {
      return nonEditable(monthHeaderCell(`${String(+yearMonth.split('/')[1])}月`, 'justify-content-center'));
    }),
  ];
};

function getHeaderRow(listYear: string[], groupBy: string, listMonth: string[]): Row {
  const data: DefaultCellTypes[] = [];
  let yearFocus = listYear[0];
  listYear.forEach((year, index) => {
    const listYearMonth = listMonth.filter((yearMonth) => yearMonth.split('/')[0] === year);
    if (groupBy === 'year') {
      if (year !== yearFocus) {
        data.push(nonEditable(monthHeaderCell('累計', 'justify-content-center')));
      }
      yearFocus = year;
      data.push(...monthsInYear(listYearMonth));

      if (index === listYear.length - 1) {
        data.push(nonEditable(monthHeaderCell('累計', 'justify-content-center')));
      }
    } else {
      data.push(nonEditable(monthHeaderCell('累計', 'justify-content-center')));
    }
  });
  return {
    rowId: HEADER_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [nonEditable(textCell('', '')), ...data],
  };
}

const getParamsNonEditable = (value: number) => {
  if (value >= 0)
    return nonEditable(numberCell(value, 'text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1'));
  else
    return nonEditable(
      textCell(
        `▲${formatNumberWithCommas(String(Math.abs(value)))}`,
        'text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1 !text-[#BA1A1A] justify-end',
      ),
    );
};
const dataTotal: { [k: string]: { year: string; total: number }[] } = {
  totalSales: [],
  monthlySales: [],
  costOfSales: [],
  grossProfit: [],
  sellingCosts: [],
  directProfit: [],
  managementCosts: [],
  operatingIncome: [],
};

const nonEditRow = (
  groups: (SalesFlows | CostSalesFlows | SellingExpensesFlows | ProfitFlows)[],
  groupBy: string,
  listMonth: string[],
  typeTotal: string,
  valueTotal?: number[],
) => {
  if (valueTotal && valueTotal.length > 0) {
    const data: DefaultCellTypes[] = [];
    let yearFocus = listMonth[0].split('/')[0];
    let total = 0;
    valueTotal.forEach((value, index) => {
      const yearCurrent = listMonth[index].split('/')[0];
      if (yearFocus !== yearCurrent) {
        data.push(getParamsNonEditable(total));
        dataTotal[typeTotal].push({
          year: yearFocus,
          total: total,
        });
        total = 0;
      }

      yearFocus = yearCurrent;
      total += value;
      if (groupBy === 'year') data.push(getParamsNonEditable(value));

      if (index === valueTotal.length - 1) {
        dataTotal[typeTotal].push({
          year: yearCurrent,
          total: total,
        });
        data.push(getParamsNonEditable(total));
      }
    });
    return data;
  }

  return [
    ...months().map((_) =>
      nonEditable(numberCell(33333, 'text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1')),
    ),
    nonEditable(numberCell(44444, 'text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1')),
  ];
};

const addDataTotalYear = (
  data: DefaultCellTypes[],
  item: string | number,
  total: number,
  count: number,
  year: string,
  typeTotal: string,
) => {
  if (typeof item === 'number') {
    data.push(nonEditable(numberCell(total, 'text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1')));
  } else {
    let result: string = '';
    const monthlySales = dataTotal.monthlySales.find((item) => item.year === year);
    const totalSales = dataTotal.totalSales.find((item) => item.year === year);
    const costOfSales = dataTotal.costOfSales.find((item) => item.year === year);
    const grossProfit = dataTotal.grossProfit.find((item) => item.year === year);
    const sellingCosts = dataTotal.sellingCosts.find((item) => item.year === year);
    const managementCosts = dataTotal.managementCosts.find((item) => item.year === year);
    const operatingIncome = dataTotal.operatingIncome.find((item) => item.year === year);
    if (
      totalSales &&
      costOfSales &&
      grossProfit &&
      sellingCosts &&
      managementCosts &&
      operatingIncome &&
      monthlySales
    ) {
      switch (typeTotal) {
        case 'costOfSalesRatio':
          result = convertDateBp(costOfSales.total, monthlySales.total);
          break;
        case 'grossProfitMargin':
          result = convertDateBp(grossProfit.total, monthlySales.total);
          break;
        case 'sellingExpenseRatio':
          result = convertDateBp(sellingCosts.total, monthlySales.total);
          break;
        case 'expenseRate':
          result = convertDateBp(managementCosts.total, monthlySales.total);
          break;
        case 'operatingProfitMargin':
          result = convertDateBp(operatingIncome.total, monthlySales.total);
          break;
      }
    }
    data.push(
      nonEditable(
        textCell(
          `${+result < 0 ? '▲' + String(Math.abs(+result)) : result}%`,
          `${
            +result < 0 && '!text-[#BA1A1A]'
          } text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1 justify-center`,
        ),
      ),
    );
  }
};

const addDataNormal = (data: DefaultCellTypes[], item: string | number) => {
  if (typeof item === 'number') {
    data.push(numberCell(item, 'text-on-surface tracking-8 padding-x-1_5 padding-y-1'));
  } else {
    data.push(
      textCell(
        `${
          +item < 0
            ? '▲' + formatNumberWithCommas(String(Math.abs(+item)))
            : formatNumberWithCommas(String(Math.abs(+item)))
        }%`,
        `${
          +item < 0 && '!text-[#BA1A1A]'
        } text-on-surface tracking-8 bg-surface-at-2 padding-x-1_5 padding-y-1 justify-center`,
      ),
    );
  }
};

const editableRow = (values: MonthlyValues, groupBy: string, listMonth: string[], typeTotal: string) => {
  const data: DefaultCellTypes[] = [];
  let total = 0;
  let count = 0;
  let yearFocus = listMonth[0].split('/')[0];
  values.forEach((item, index) => {
    const yearCurrent = listMonth[index].split('/')[0];
    if (yearFocus !== yearCurrent) {
      if (typeTotal === 'monthlySales') {
        dataTotal[typeTotal].push({
          year: yearFocus,
          total: total,
        });
      }
      addDataTotalYear(data, item, total, count, yearFocus, typeTotal);

      total = 0;
      count = 0;
    }

    yearFocus = yearCurrent;

    if (typeof item === 'number') {
      total += item;
    } else {
      total += +item;
      count += 1;
    }

    if (groupBy === 'year') {
      addDataNormal(data, item);
    }

    if (index === values.length - 1) {
      addDataTotalYear(data, item, total, count, yearFocus, typeTotal);
    }
  });
  return data;
};

function getGroupRows(
  title:
    | '売上 合計'
    | '売上原価 合計'
    | '販売費 合計'
    | '売上総利益'
    | '売上総利益ー販売費'
    | '一般管理費 合計'
    | '営業利益',
  groups: (SalesFlows | CostSalesFlows | SellingExpensesFlows | ProfitFlows)[],
  type: string,
  valueTotal: number[],
  groupBy: string,
  listMonth: string[],
): Row<DefaultCellTypes | FlagCell>[] {
  return [
    {
      rowId: `${title}_Header`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(headerCell(title, 'bg-surface-at-2 text-on-surface padding-x-1_5')),
        ...nonEditRow(groups, groupBy, listMonth, type, valueTotal),
      ],
    },
    ...groups.map<Row<DefaultCellTypes | FlagCell>>(({ title, type, values, variant }) => ({
      rowId: type ?? '',
      height: ROW_HEIGHT,
      cells: [
        iconCell(title, 'padding-right-3 padding-y-1 padding-left-4_5 uppercase', variant),
        ...editableRow(values, groupBy, listMonth, type),
      ],
    })),
  ];
}

export function getRows(
  groupBy: string, // 年 || 月
  { salesFlow, costSalesFlows, sellingExpensesFlows, profitFlows, operatingIncome }: InputVariables,
  listYear: string[],
  dataTotal: TTotalValue,
  listMonth: string[],
): Row<FlagCell | DefaultCellTypes>[] {
  return [
    getNominalRow(listYear, listMonth, groupBy),
    getHeaderRow(listYear, groupBy, listMonth),
    ...getGroupRows('売上 合計', salesFlow, 'totalSales', dataTotal.totalSales, groupBy, listMonth),
    ...getGroupRows('売上原価 合計', costSalesFlows, 'costOfSales', dataTotal.costOfSales, groupBy, listMonth),
    ...getGroupRows('売上総利益', [], 'grossProfit', dataTotal.grossProfit, groupBy, listMonth),
    ...getGroupRows('販売費 合計', sellingExpensesFlows, 'sellingCosts', dataTotal.sellingCosts, groupBy, listMonth),
    ...getGroupRows('売上総利益ー販売費', [], 'directProfit', dataTotal.directProfit, groupBy, listMonth),
    ...getGroupRows('一般管理費 合計', profitFlows, 'managementCosts', dataTotal.managementCosts, groupBy, listMonth),
    ...getGroupRows('営業利益', operatingIncome, 'operatingIncome', dataTotal.operatingIncome, groupBy, listMonth),
  ];
}
