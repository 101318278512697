import cn from '@/utils/general';
import React from 'react';

type HeadingOwnProps = {
  text?: React.ReactNode;
  className?: string;
  level: number;
};

type HeadingProps = HeadingOwnProps & Omit<React.HTMLProps<HTMLLabelElement>, keyof HeadingOwnProps>;

const Heading = ({ className, text, level, ...rest }: HeadingProps) => {
  return React.createElement(
    `h${level}`,
    {
      className: cn('break-words headline', className),
      ...rest,
    },
    text,
  );
};

export default Heading;
