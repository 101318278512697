import { useDispatch, useSelector } from 'react-redux';
import { openGroup, closeGroup } from '@/stores/chart/effects';
import { IndexedObject } from '@/utils/types';

export const useService = () => {
  const dispatch = useDispatch();
  const closeGroups = useSelector((state: IndexedObject) => state.chart.closeGroups);

  const handleClickToggle = (area: string) => {
    const isClose = closeGroups.some((g: string) => g === area);
    if (isClose) {
      dispatch(openGroup({ area }));
    } else {
      dispatch(closeGroup({ area }));
    }
  };

  return {
    closeGroups,
    handleClickToggle,
  };
};
