import { IOptionFilter, TMenuItem } from '@/types/common';
import { EPath } from '@/route/route';

export const CONSTANTS = {
  SORT_DATE_BUSINESS_PLAN_DESC: true,
  SORT_DATE_BUSINESS_PLAN_ASC: false,
  TYPE_DROPDOWN_SETTING: 1,
  TYPE_DROPDOWN_PRIVILEGES_MEMBER: 2,
  TYPE_DROPDOWN_ACCOUNT: 3,
  TYPE_DROPDOWN_TABLE: 4,
  SIZE_MOBILE: 768,
  SLUG_ACCOUNT_PAGE: 'account',
  INDEX_ACCOUNT_PAGE: -1,
  SLUG_NOTIFY_PAGE: 'notification',
  INDEX_NOTIFY_PAGE: -2,
  INDEX_HOME_PAGE: 0,
};

export const LOCAL_VARS = {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
};

export const FILE_IMAGE = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/jp2'];

export const FILE_MAX = 5;

export const DEFAULT_ITEM_COUNT = 3;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGE_SIZE_CHART = 100;

export enum typeFilterQuestion {
  AUTHOR = 'createdBy',
  ORGANIZATION = 'organizationId',
  PUBLICATION_RANGE = 'publish',
  COMMENT = 'commentCount',
  TAG = 'tags',
  MANAGER = 'manager',
}
export const DATA_TYPE_FILTER: IOptionFilter[] = [
  {
    label: '作成者',
    value: typeFilterQuestion.AUTHOR,
  },
  {
    label: '組織',
    value: typeFilterQuestion.ORGANIZATION,
  },
  {
    label: '公開範囲',
    value: typeFilterQuestion.PUBLICATION_RANGE,
  },
  {
    label: 'コメント数',
    value: typeFilterQuestion.COMMENT,
  },
  {
    label: 'タグ',
    value: typeFilterQuestion.TAG,
  },
];

export const DATA_TYPE_FILTER_HOUSE: IOptionFilter[] = [
  {
    label: '担当者',
    value: typeFilterQuestion.MANAGER,
  },
  {
    label: '組織',
    value: typeFilterQuestion.ORGANIZATION,
  },
];

export const DATA_OPERATOR_FILTER = [
  {
    label: '含む',
    value: 'in',
  },
  {
    label: '含まない',
    value: 'nin',
  },
];

export const DATA_OPTION_INIT = {
  [typeFilterQuestion.AUTHOR]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
  [typeFilterQuestion.ORGANIZATION]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
  [typeFilterQuestion.PUBLICATION_RANGE]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [
      {
        id: 0,
        title: '組織横断',
      },
      {
        id: 1,
        title: '組織内',
      },
    ],
  },
  [typeFilterQuestion.COMMENT]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
  [typeFilterQuestion.TAG]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
};

export const DATA_OPTION_INIT_HOUSE = {
  [typeFilterQuestion.MANAGER]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
  [typeFilterQuestion.ORGANIZATION]: {
    pagination: {
      page: 0,
      limit: 0,
      total: 0,
    },
    data: [],
  },
};

export const QUANTITY_TYPE_FILTER = {
  HOUSE: 2,
  QUESTION: 5,
  FEEDBACK: 4,
};

export const SIDE_MENU_DATA: TMenuItem[] = [
  {
    title: 'すべてのハウス',
    path: EPath.houses,
  },
  {
    title: 'すべての質問',
    path: EPath.questions,
  },
  {
    title: 'すべての指導',
    path: EPath.feedbacks,
  },
  {
    title: 'すべての文献',
    path: EPath.documents,
  },
];

export const listDialogForm = {
  form1: ['商品売上'],
  form2: [
    '特定作業受託',
    '作業受託',
    '雑収入',
    'パート人件費',
    '栽培技術コンサル人件費',
    '設備修繕費',
    '肥料費',
    '農薬・衛生費',
    '諸材料費 ',
    '作業用衣料費',
    '雑費（予備費）',
    '販促活動費',
    '梱包材料費',
    '物流・輸送費',
    '本社人件費',
    '農業共済掛金',
    'その他管理経費',
  ],
  form3: ['動力光熱費'],
  form4: ['1'],
};

export const OPTION_ROLE = [
  { value: '2', label: '管理者' },
  { value: '3', label: '作業者' },
  { value: '', label: '組織から除外' },
];

export const NOTIFICATION_TYPE_QUESTION = 1;
export const NOTIFICATION_TYPE_QUESTION_COMMENT = 2;
export const NOTIFICATION_TYPE_DOCUMENT = 3;
export const NOTIFICATION_TYPE_FEEDBACK = 4;
export const NOTIFICATION_TYPE_BUSINESS_PLAN = 5;
export const NOTIFICATION_TYPE_BUSINESS_PLAN_COMMENT = 6;
export const NOTIFICATION_TYPE_SCHEDULE_COMMENT = 7;

export const TEXT_SHARE_TYPE = {
  public: '組織横断公開',
  private: '組織内公開',
};

export const SCREEN_SIZE = {
  lg: 905,
};

export const CHECK_REMEMBER = {
  check: 1,
  unCheck: 0,
};

export const ROLE = {
  admin: 2,
  user: 3,
};

export const TITLE_WEB = process.env.REACT_APP_TITLE;
