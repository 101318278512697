import cn from '@/utils/general';

type IconProps = {
  className?: string;
};

const IconModeEdit = ({ className }: IconProps) => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={cn(className)}>
        <path
          fill="currentColor"
          d="M19.06 3.58988L20.41 4.93988C21.2 5.71988 21.2 6.98988 20.41 7.76988L7.18 20.9999H3V16.8199L13.4 6.40988L16.23 3.58988C17.01 2.80988 18.28 2.80988 19.06 3.58988ZM5 18.9999L6.41 19.0599L16.23 9.22988L14.82 7.81988L5 17.6399V18.9999Z"
        />
      </svg>
    </>
  );
};

export default IconModeEdit;
