import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { RootState } from '@/stores';
import { ICircleMultiple } from '@/stores/business/model';
import { updateEditPowerHeatingExpenses, updateSalaryPartTime } from '@/stores/business/services';
import { DialogBusinessPlan } from '@/types/business';
import { getValidateMsg, MESSAGE_VALIDATE_MAX, MESSAGE_VALIDATE_MIN } from '@/utils/constants/messageValidate';
import { handleOnChangeInputNumberFormat } from '@/utils/helpers';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInputRow from '../FormInputRow';
import FormEditWraper from './FormEditWraper';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';

interface Props {
  data: DialogBusinessPlan[];
}

const FormEdit3 = (props: Props) => {
  const { data: dataInit } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICircleMultiple>();

  useEffect(() => {
    if (dataInit) {
      dataInit.forEach((item) => {
        setValue(item.name, item.defaultValue ? String(item.defaultValue) : '');
      });
    }
  }, [dataInit]);

  const onSubmit = (data: ICircleMultiple) => {
    setIsDisable(true);
    if (businessId && sheetActive) {
      if (dataInit[0].name === 'salaryPartTime') {
        dispatch(
          updateSalaryPartTime({
            businessId: +businessId,
            sheetId: sheetActive,
            dataUpdate: {
              salaryPartTime: +data.salaryPartTime,
              experience: +data.experience,
            },
          }),
        );

        return;
      }
      dispatch(
        updateEditPowerHeatingExpenses({
          businessId: +businessId,
          sheetId: sheetActive,
          dataUpdate: {
            electricityPrice: +data.electricityPrice,
            heavyOilPrice: +data.heavyOilPrice,
          },
        }),
      );
    }
    setIsDisable(false);
  };

  return (
    <form
      className="w-[calc(100vw-64px)] max-w-[479px] rounded-2xl bg-gray-background p-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormEditWraper isDisable={isDisable}>
        {dataInit?.length > 0 &&
          dataInit.map((item) => (
            <>
              <FormInputRow
                key={item.name}
                labelRight={item.labelRight}
                labelLeft={item.labelLeft}
                classNames="flex-col !items-start sm:flex-row"
              >
                <Controller
                  name={item.name}
                  control={control}
                  rules={{
                    validate: (value) => getValidateMsg(value, item.step),
                    min: {
                      value: item.min,
                      message: MESSAGE_VALIDATE_MIN(item.min),
                    },
                    max: {
                      value: item.max,
                      message: MESSAGE_VALIDATE_MAX(item.max),
                    },
                  }}
                  render={({ field }) => (
                    <StepperNumberFormat
                      {...field}
                      className="regular-text lg text-on-surface"
                      onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                      setValue={setValue}
                      min={item.min}
                      max={item.max}
                      step={item.step}
                      name={item.name}
                      decimalScale={item.step.toString().split('.')[item.step.toString().split('.').length - 1].length}
                      isHiddenArrowUpDown={false}
                      isFiledNumberDialog={true}
                      hasError={!!errors?.[item.name]?.message}
                    />
                  )}
                />
              </FormInputRow>
              <ErrorTooltip
                className="ml-24"
                errorMessage={errors?.[item.name]?.message ?? ''}
                hasError={!!errors?.[item.name]?.message}
              />
            </>
          ))}
      </FormEditWraper>
    </form>
  );
};

export default FormEdit3;
