import c from 'clsx';
import React from 'react';
import ChartNameCellNone from '../ChartNameCell/ChartNameCellNone';
import s from './style.module.sass';
import { Charts } from '@/stores/chart/model';

type Props = {
  className?: string;
  data: Charts;
};

const ChartNameAreaNone = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, data } = props;

  return (
    <div ref={ref} className={c(s.root, className)} id="chart-name-area-none">
      {Object.keys(data).map((k) => (
        <React.Fragment key={k}>
          <span>
            {data[k].map((d) => (
              <div className="flex h-12 hover:bg-gray-background" key={d.id}>
                <ChartNameCellNone
                  className={s.cell}
                  areaName={d.areaName}
                  label={d.name}
                  area={k}
                  circleDate={d}
                  id={d.id}
                  key={d.id}
                />
              </div>
            ))}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
});
ChartNameAreaNone.displayName = 'ChartNameAreaNone';
export default ChartNameAreaNone;
