const RemoveImage = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.316 6.288V6.36L12.222 10.05L14.22 16.116L14.166 16.152L9 12.39L3.834 16.152L3.762 16.116L5.76 10.05L0.684 6.36V6.288H6.984L8.964 0.221999H9.018L10.998 6.288H17.316Z"
        fill="#FFE600"
      />
    </svg>
  );
};

export default RemoveImage;
