import { useState } from 'react';

export const useService = () => {
  const [isShow, setIsShow] = useState(false);
  const handleToggleImageDialog = () => {
    setIsShow(!isShow);
  };
  return {
    isShow,
    handleToggleImageDialog,
    setIsShow,
  };
};
