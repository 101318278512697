import React from 'react';
import c from 'clsx';
import s from './style.module.sass';
import ItemCube from '../../atoms/ItemCube';

type Props = {
  className?: string;
  left: number;
  isEdit: boolean;
  type: 'sowing' | 'planting';
  size: number;
  offset: number;
  handleDClick: () => void;
};

const MileStone: React.FC<Props> = (props) => {
  const { className, left, isEdit, type, size, offset, handleDClick } = props;

  return (
    <div
      className={c(s.root, className)}
      style={{
        left: `${left - offset}px`,
        zIndex: `${isEdit ? 2 : 0}`,
      }}
      onDoubleClick={handleDClick}
    >
      <ItemCube type={type} size={size} />
    </div>
  );
};

export default MileStone;
