import type { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb';
import BaseButton from '@/components/atoms/Button/Button';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { IconArrowDropUp } from '@/components/icon/IconArrowDropUp/IconArrowDropUp';
import ListHouseComponent from '@/components/molecules/ListHouseComponent/ListHouseComponent';
import { EPath } from '@/route/route';
import { RootState } from '@/stores';
import { getHousesAsync } from '@/stores/houses';
import { CONSTANTS, DEFAULT_ITEM_COUNT } from '@/utils/constants/consts';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import { replacePathParams } from '@/utils/helpers';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  organizationId: string;
  titleHeader: string;
};

const HouseComponent: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { organizationId, titleHeader } = props;
  const { houses } = useSelector((state: RootState) => state.houses);
  const tableHeading = useMemo(
    () => ['ハウス名', '事業計画更新日', '面積(a)', '栽培株数(株)', '栽植密度(株/10a)', ''],
    [],
  );

  const BREADCRUMBS_ROOT: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: '#',
    },
    {
      name: 'ハウス',
      to: replacePathParams(EPath.organizationHouseList, {
        organizationId: organizationId ?? '',
      }),
    },
  ];
  const [sortDate, setSortDate] = useState(CONSTANTS.SORT_DATE_BUSINESS_PLAN_DESC);
  const [isShowAll, setIsShowAll] = useState(false);
  function handleSortDateBusiness() {
    setSortDate(!sortDate);
  }
  const rotateData = {
    normal: 0,
    rotate: 180,
  };

  useEffect(() => {
    dispatch(
      getHousesAsync({
        page: 1,
        limit: 0,
        organizationId: [{ operation: 'in', value: [+organizationId] }],
        businessPlan: sortDate ? 'desc' : 'asc',
      }),
    );
  }, [organizationId, sortDate]);

  return (
    <div
      className={`container_HouseComponent bg-on-error lg:min-w-full ${
        houses.length > 0 ? 'min-w-[800px]' : 'w-[calc(100vw-48px)] md:w-[calc(100vw-288px)]'
      }`}
    >
      <Breadcrumb data={BREADCRUMBS_ROOT} className="h-[18px]" />
      <DefaultText variant="title-children" className="h-[27px] text-[18px] text-on-surface">
        {'ハウス'}
      </DefaultText>
      <div className="flex flex-1 flex-col">
        {houses.length > 0 ? (
          <div className="houseComponentListColumn h-[28px] 2xl:h-10">
            {tableHeading.map((e, i) => (
              <div
                key={nanoid(5)}
                className={`${
                  i === 0 ? 'justify-start' : 'justify-center'
                } 2xl:text-text-[11px] flex items-center whitespace-nowrap text-[11px] text-on-surface ${
                  i === 0 && 'pl-[80px]'
                } ${i !== 0 && 'pl-2'}`}
              >
                {i === 1 ? (
                  <React.Fragment>
                    {e}
                    <div className="ml-[8px] h-[18px] w-[18px] rounded-full bg-primary lg:ml-[24px] lg:h-[24px] lg:w-[24px]">
                      <span
                        className="flex w-[18px] justify-center pt-[1px] lg:w-[24px] lg:pt-[4px]"
                        onClick={handleSortDateBusiness}
                        onKeyDown={() => {}}
                      >
                        <IconArrowDropUp
                          className={`${
                            sortDate ? 'before-rotate-' + rotateData.normal : 'rotate' + rotateData.rotate
                          }`}
                        />
                      </span>
                    </div>
                  </React.Fragment>
                ) : (
                  e
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-lg text-on-surface-variant">{MESSAGE_NO_DATA}</div>
        )}
        <div className="max-h-[500px] overflow-auto">
          <ul>
            {(isShowAll ? houses : houses.slice(0, 3)).map((houses) => (
              <ListHouseComponent
                key={nanoid(5)}
                data={houses}
                dataFilter={{
                  organizationId: [{ operation: 'in', value: [+organizationId] }],
                }}
              />
            ))}
          </ul>
        </div>
      </div>
      {!isShowAll && houses.length > DEFAULT_ITEM_COUNT && (
        <div className="pt-[8px]">
          <BaseButton
            variant="on-secondary"
            type="submit"
            className="w-[119px]"
            onClick={() => setIsShowAll(!isShowAll)}
          >
            <DefaultText variant="length" className="text-[14px] text-on-primary">
              もっと見る
            </DefaultText>
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default HouseComponent;
