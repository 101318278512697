import React from 'react';

type Props = {
  path?: string;
  className?: string;
  nameUser: string;
};
const DisplayAvatar: React.FC<Props> = (props: Props) => {
  const { path, className, nameUser } = props;

  return (
    <>
      {path ? (
        <img
          src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(path)}`}
          className={`h-full object-cover ${className ?? 'h-[80px] w-[80px] rounded-full'}`}
          alt="avt"
          loading="lazy"
        />
      ) : (
        <p
          className={`${
            className ?? 'h-[80px] w-[80px] text-4xl leading-[80px]'
          } rounded-full bg-[#D9D9D9] text-center uppercase `}
        >
          {nameUser?.charAt(0)}
        </p>
      )}
    </>
  );
};

export default DisplayAvatar;
