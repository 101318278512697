import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EPath } from '@/route/route';
import IconArrowBack from '@/components/icon/IconBackArrow';
import DefaultText from '@/components/atoms/Text/DefaultText';
import BaseButton from '@/components/atoms/Button/Button';
import BodyText from '@/components/atoms/AText/BodyText';
import SelectWithIcon from '@/components/atoms/SelectWithIcon';
import { replacePathParams } from '@/utils/helpers';
import { RootState } from '@/stores';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeOwnerOrganizationAsync,
  changeRoleMemberAsync,
  deleteOrganizationsAsync,
  getOrganizationDetailAsync,
  inviteMemberAsync,
  removeMemberAsync,
  restoreMemberAsync,
  updateOrganizationAsync,
} from '@/stores/organization';
import { IOptionFilter } from '@/types/common';
import { IUserInfo } from '@/types/organization';
import 'react-responsive-modal/styles.css';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { OPTION_ROLE } from '@/utils/constants/consts';
import {
  MESSAGE_REQUIRED_EMAIL,
  MESSAGE_REQUIRED_NAME,
  MESSAGE_VALIDATE_EMAIL,
  MESSAGE_VALIDATE_EMAIL_EXISTS,
} from '@/utils/constants/messageValidate';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';

function SettingOrganization() {
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { titleOrganization, organizationInfo } = useSelector((state: RootState) => state.organization);
  const { user } = useSelector((state: RootState) => state.auth);
  const [displayName, setDisplayName] = useState<string>(titleOrganization);
  const [userIdSelected, setUserIdSelected] = useState<number>(-1);
  const [isLoadingButton, setIsLoadingButton] = useState({
    isBtnInvite: false,
  });

  const formInviteMember = useForm<IUserInfo>({
    defaultValues: {
      organizationId: organizationId ? +organizationId : 0,
      name: '',
      email: '',
      roleCd: +OPTION_ROLE[0]?.value,
    },
  });

  const memberList = organizationInfo?.members?.length
    ? [
        {
          value: organizationInfo.owner?.userId.toString(),
          label: organizationInfo.owner?.members?.name ?? '',
        },
        ...organizationInfo.members.map((item) => ({
          value: item?.userId.toString(),
          label: item?.members?.name ?? '',
        })),
      ]
    : [
        {
          value: organizationInfo?.owner?.userId.toString() ?? '',
          label: organizationInfo?.owner?.members?.name ?? '',
        },
      ];

  const isRoleUser = organizationInfo?.members.find((val) => val.userId === user?.id)?.roleCd === +OPTION_ROLE[1].value;

  const [openModal, setOpenModal] = useState(false);

  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (!organizationId) return;
    dispatch(getOrganizationDetailAsync(+organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (!titleOrganization) return;
    setDisplayName(titleOrganization);
  }, [titleOrganization]);

  useEffect(() => {
    setUserIdSelected(+memberList[0]?.value || 0);
  }, [organizationInfo?.members]);

  const handleChangeRole = (option: IOptionFilter, userId: number, memberId: number) => {
    if (!option.value) {
      const data = {
        organizationId: organizationId ? +organizationId : 0,
        memberId: userId,
      };
      dispatch(removeMemberAsync(data));
    } else {
      const data = {
        organizationId: organizationId ? +organizationId : 0,
        memberId: memberId,
        roleCd: +option.value,
      };
      dispatch(changeRoleMemberAsync(data));
    }
  };

  const handleRestoreMember = (option: IOptionFilter, idMember: number) => {
    const data = {
      organizationId: organizationId ? +organizationId : 0,
      userId: idMember,
      roleCd: +option.value,
    };
    dispatch(restoreMemberAsync(data));
  };

  const handleChangeOwner = () => {
    const data = {
      organizationId: organizationId ? +organizationId : 0,
      userId: userIdSelected,
    };
    dispatch(changeOwnerOrganizationAsync(data));
  };

  const resetFormInvite = () => {
    formInviteMember.reset({
      organizationId: organizationId ? +organizationId : 0,
      name: '',
      email: '',
      roleCd: +OPTION_ROLE[0]?.value,
    });
    setIsLoadingButton({ ...isLoadingButton, isBtnInvite: false });
  };

  const handleInviteMember: SubmitHandler<IUserInfo> = (data) => {
    setIsLoadingButton({ ...isLoadingButton, isBtnInvite: true });
    dispatch(inviteMemberAsync({ ...data, callback: () => resetFormInvite() }));
  };

  const handleDeleteOrganization = () => {
    const data = {
      id: organizationId ? +organizationId : 0,
    };
    dispatch(deleteOrganizationsAsync({ ...data, callback: () => navigate(EPath.documents) }));
  };

  return (
    <>
      <div className="flex items-center border-b border-outline-variant md:h-16 md:px-12">
        <Link to={replacePathParams(EPath.organization, { organizationId: organizationId ?? '' })}>
          <button className="m-1 p-2">
            <IconArrowBack className="w-6 text-on-surface-variant" />
          </button>
        </Link>
        <span className="truncate-text font-noto text-xl">{titleOrganization}の情報</span>
      </div>
      <div className="mx-auto w-[calc(100vw-64px)] pb-10 font-noto lg:w-[80%] xl:w-1/2">
        {isRoleUser ? (
          <div className="pt-8">
            <h2 className="pb-6 text-lg font-normal">基本情報</h2>
            <label className="flex text-sm font-medium">表示名</label>
            <div className="mt-2 text-base font-normal">{titleOrganization}</div>
            <div className="py-6 text-lg">オーナー</div>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-3">
                <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
                  <DisplayAvatar
                    path={
                      organizationInfo?.owner?.members?.file?.length
                        ? organizationInfo?.owner?.members?.file[0]?.path
                        : ''
                    }
                    nameUser={organizationInfo?.owner?.members?.name ?? ''}
                    className="h-10 w-10 leading-10"
                  />
                </div>
                <span className="flex flex-col">
                  <BodyText tag="span" size="medium" className="text-sm font-semibold">
                    {organizationInfo?.owner?.members?.name}
                  </BodyText>
                </span>
              </div>
              <div className="text-xs text-on-surface-variant">{organizationInfo?.owner?.members?.email}</div>
            </div>
            <div className="my-6 text-lg">メンバー</div>
            <div className="flex flex-col gap-4">
              {organizationInfo?.members.map((item) => (
                <div className="flex items-center justify-between" key={item.members.id}>
                  <div className="flex items-center gap-2">
                    <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
                      <DisplayAvatar
                        path={item?.members?.file?.length ? item?.members?.file[0]?.path : ''}
                        nameUser={item?.members?.name ?? ''}
                        className="h-10 w-10 leading-10"
                      />
                    </div>
                    <span className="flex flex-col">
                      <BodyText tag="span" size="medium" className="text-sm font-medium">
                        {item.members.name}
                      </BodyText>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="border-b border-outline-variant pb-6 pt-8">
              <label className="text-sm font-medium">表示名</label>
              <div className="mb-6 mt-2">
                <input
                  value={displayName}
                  className="h-12 w-full rounded border border-outline px-4 py-2.5 focus:outline-none disabled:opacity-50"
                  type="text"
                  onChange={(e) => setDisplayName(e.target.value)}
                  disabled={user?.id !== organizationInfo?.owner?.members?.id}
                />
                {!displayName && (
                  <ErrorTooltip className="!pl-0" errorMessage={'表示名を入力してください。'} hasError={true} />
                )}
              </div>
              <BaseButton
                variant="primary"
                type="submit"
                className="w-full md:w-[77px]"
                onClick={() =>
                  dispatch(updateOrganizationAsync({ id: organizationId ? +organizationId : 0, title: displayName }))
                }
                disabled={user?.id !== organizationInfo?.owner?.members?.id}
              >
                <DefaultText variant="length" className="text-sm text-on-primary">
                  保存
                </DefaultText>
              </BaseButton>
            </div>
            <div className="border-b border-outline-variant py-6">
              <label className="text-lg">オーナー</label>
              <div className="my-6 flex items-center">
                <div className="flex min-w-[184px] items-center gap-2">
                  <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
                    <DisplayAvatar
                      path={
                        organizationInfo?.owner?.members?.file?.length
                          ? organizationInfo?.owner?.members?.file[0]?.path
                          : ''
                      }
                      nameUser={organizationInfo?.owner?.members?.name ?? ''}
                      className="h-10 w-10 leading-10"
                    />
                  </div>
                  <span className="flex flex-col">
                    <BodyText tag="span" size="medium" className="text-sm font-normal">
                      {organizationInfo?.owner?.members?.name}
                    </BodyText>
                  </span>
                </div>
                <div className="text-xs text-on-surface-variant">{organizationInfo?.owner?.members?.email}</div>
              </div>
              <div className="mb-6 text-sm font-normal">
                この組織のオーナー権限を他のメンバーに移します。組織を削除できるのはオーナーのみです。
              </div>
              <div className="flex flex-col items-center gap-4 md:flex-row">
                <SelectWithIcon
                  data={memberList}
                  className="w-full focus:outline-none md:w-[160px]"
                  classNameWrap="w-full md:w-auto"
                  defaultValue={memberList.find((val) => +val.value == userIdSelected)?.value}
                  onChange={(val) => setUserIdSelected(+val.value)}
                  disabled={user?.id !== organizationInfo?.owner?.members?.id}
                />
                <BaseButton
                  variant="primary"
                  type="submit"
                  className="w-full md:w-[77px]"
                  onClick={handleChangeOwner}
                  disabled={
                    user?.id !== organizationInfo?.owner?.members?.id ||
                    userIdSelected === organizationInfo?.owner?.members?.id
                  }
                >
                  <DefaultText variant="length" className="text-sm text-on-primary">
                    保存
                  </DefaultText>
                </BaseButton>
              </div>
            </div>
            <form className="flex flex-col gap-5 pb-[28px] pt-6">
              <div className="mb-6 flex flex-wrap items-start gap-4 md:flex-nowrap">
                <div className="flex w-full flex-col gap-2">
                  <Controller
                    rules={{
                      required: true,
                    }}
                    control={formInviteMember.control}
                    name="name"
                    render={({ field: { onChange } }) => (
                      <input
                        value={formInviteMember.watch('name')}
                        className="h-12 w-full rounded border border-outline px-4 py-2.5 focus:outline-none"
                        type="text"
                        onChange={onChange}
                        placeholder="名前"
                      />
                    )}
                  />
                  <ErrorTooltip
                    className="!pl-0"
                    errorMessage={MESSAGE_REQUIRED_NAME}
                    hasError={!!formInviteMember.formState.errors.name}
                  />
                </div>
                <div className="flex w-full flex-col gap-2">
                  <Controller
                    rules={{
                      required: MESSAGE_REQUIRED_EMAIL,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: MESSAGE_VALIDATE_EMAIL,
                      },
                      validate: (value) => {
                        if (organizationInfo?.members.some((item) => item.members.email === value))
                          return MESSAGE_VALIDATE_EMAIL_EXISTS;
                        return true;
                      },
                    }}
                    control={formInviteMember.control}
                    name="email"
                    render={({ field: { onChange } }) => (
                      <input
                        value={formInviteMember.watch('email').trim()}
                        className="h-12 w-full rounded border border-outline px-4 py-2.5 focus:outline-none"
                        type="text"
                        onChange={onChange}
                        placeholder="メールアドレス"
                      />
                    )}
                  />
                  <ErrorTooltip
                    className="!pl-0"
                    errorMessage={formInviteMember.formState.errors.email?.message}
                    hasError={!!formInviteMember.formState.errors.email?.message}
                  />
                </div>
                <Controller
                  rules={{
                    required: true,
                  }}
                  control={formInviteMember.control}
                  name="roleCd"
                  render={({ field: { onChange } }) => (
                    <SelectWithIcon
                      data={OPTION_ROLE.slice(0, 2)}
                      className="w-full focus:outline-none lg:w-[160px]"
                      classNameWrap="w-full "
                      defaultValue={String(formInviteMember.watch('roleCd'))}
                      onChange={(val) => {
                        onChange(+val.value);
                      }}
                    />
                  )}
                />
                <div className="mt-1 w-full md:w-[77px]">
                  <BaseButton
                    variant="primary"
                    type="submit"
                    className="w-full md:w-[77px]"
                    onClick={formInviteMember.handleSubmit(handleInviteMember)}
                    disabled={isLoadingButton.isBtnInvite}
                  >
                    <DefaultText variant="length" className="text-sm text-on-primary">
                      招待
                    </DefaultText>
                  </BaseButton>
                </div>
              </div>
              {organizationInfo?.members.map((item) => (
                <div
                  className="flex flex-col items-start justify-between gap-2 md:flex-row md:items-center"
                  key={item.members.id}
                >
                  <div className="flex min-w-[184px] items-center gap-2">
                    <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
                      <DisplayAvatar
                        path={item?.members?.file?.length ? item?.members?.file[0]?.path : ''}
                        nameUser={item?.members?.name ?? ''}
                        className="h-10 w-10 leading-10"
                      />
                    </div>
                    <span className="flex flex-col">
                      <BodyText tag="span" size="medium" className="text-sm font-medium">
                        {item.members.name}
                      </BodyText>
                      <span className="text-[11px] font-medium text-error">{!item.statusLogin && '未ログイン'}</span>
                    </span>
                  </div>
                  <span className="grow text-sm text-on-surface-variant">{item.members.email}</span>
                  <SelectWithIcon
                    data={OPTION_ROLE}
                    className="w-full focus:outline-none lg:w-[160px]"
                    classNameWrap="w-full"
                    onChange={(value) => handleChangeRole(value, item.members.id ?? 0, item.id ?? 0)}
                    defaultValue={OPTION_ROLE.find((val) => +val.value === item.roleCd)?.value}
                  ></SelectWithIcon>
                </div>
              ))}
            </form>
            <div className="border-b border-outline-variant pb-6">
              <div className="mb-6">除外されたメンバー</div>
              <div className="flex flex-col gap-5">
                {organizationInfo?.membersDeleted.map((item) => (
                  <div
                    className="flex flex-col items-start justify-between gap-2 md:flex-row md:items-center"
                    key={item.members.id}
                  >
                    <div className="flex min-w-[184px] items-center gap-2">
                      <div className="h-10 w-10 overflow-hidden rounded-full border border-outline">
                        <DisplayAvatar
                          path={item?.members?.file?.length ? item?.members?.file[0]?.path : ''}
                          nameUser={item?.members?.name ?? ''}
                          className="h-10 w-10 leading-10"
                        />
                      </div>
                      <span className="flex flex-col">
                        <BodyText tag="span" size="medium" className="text-sm font-medium">
                          {item.members.name}
                        </BodyText>
                        <span className="text-[11px] font-medium text-error">{!item.statusLogin && '未ログイン'}</span>
                      </span>
                    </div>
                    <span className="grow text-on-surface-variant">{item.members.email}</span>
                    <SelectWithIcon
                      data={OPTION_ROLE}
                      className="w-full focus:outline-none sm:w-[160px]"
                      classNameWrap="w-full sm:w-auto"
                      onChange={(value) => handleRestoreMember(value, item.userId ?? 0)}
                      defaultValue={OPTION_ROLE[2].value}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="pt-6">
              <div className="mb-6 text-lg">組織の削除</div>
              <div className="mb-6 text-sm">
                この組織を削除します。組織内にある全てのデータが削除され、ほか組織に公開した記事なども見れなくなります。この操作は元に戻せません。
              </div>
              <BaseButton
                variant="error"
                type="submit"
                className="w-full md:w-[77px]"
                onClick={onOpenModal}
                disabled={user?.id !== organizationInfo?.owner?.members?.id}
              >
                <DefaultText variant="length" className="text-sm text-on-primary text-white">
                  削除
                </DefaultText>
              </BaseButton>
            </div>
          </>
        )}
      </div>
      <CommonModal
        open={openModal}
        title={'組織の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={onCloseModal}
        handleClick={handleDeleteOrganization}
        boxClassName={'w-[calc(100vw-64px)] !max-w-[480px]'}
      >
        <ModalDelete
          contentDelete={
            '組織の名前を削除します。組織内にある全てのデータが削除され、ほか組織に公開した記事なども見れなくなります。この操作は元に戻せません。'
          }
        />
      </CommonModal>
    </>
  );
}

export default SettingOrganization;
