import DefaultText from '@/components/atoms/Text/DefaultText';
import { Controller, useForm } from 'react-hook-form';
import { MESSAGE_REQUIRED_PASSWORD, MESSAGE_VALIDATE_PASSWORD } from '@/utils/constants/messageValidate';
import { REGEX } from '@/utils/constants/regex';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { Link, useLocation } from 'react-router-dom';
import BaseCheckBox from '@/components/atoms/CheckBox/CheckBox';
import BaseButton from '@/components/atoms/Button/Button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync } from '@/stores/auth';
import { RootState } from '@/stores';
import { CHECK_REMEMBER, TITLE_WEB } from '@/utils/constants/consts';

interface IFormInputs {
  email: string;
  password: string;
  isRegister: boolean;
}

type Props = {
  className?: string;
};

const FormLoginPassword: React.FC<Props> = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>();
  const dispatch = useDispatch();
  const { className } = props;
  const { infoLogin, infoUserInvite } = useSelector((state: RootState) => state.auth);
  const [isCheckRemember, setIsCheckRemember] = useState<boolean>(false);
  const location = useLocation();
  const { state } = location;
  const getEmail = () => {
    if (state) return state.state.email;
    else if (infoUserInvite) return infoUserInvite.email;
    else if (infoLogin) return infoLogin.email;
  };

  const handleSubmitLogin = (data: IFormInputs) => {
    const formData = {
      email: getEmail(),
      password: data.password,
      remember: isCheckRemember ? CHECK_REMEMBER.check : CHECK_REMEMBER.unCheck,
    };
    dispatch(loginAsync(formData));
  };

  const handleChangeRemember = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckRemember(e.target.checked);
  };

  return (
    <div className="container-login-email m-auto h-588 max-w-480 bg-white px-6 lg:px-0">
      <div className="flex w-full pt-16 2xl:pt-40">
        <DefaultText variant="title" className="block h-12 w-full text-center text-[32px] text-on-surface">
          {TITLE_WEB} App
        </DefaultText>
      </div>

      <form onSubmit={handleSubmit(handleSubmitLogin)}>
        <div className={`w-full ${infoUserInvite ? 'pt-6' : 'pt-20'} ${className}`}>
          <DefaultText variant="length" className="flex pb-2 text-sm text-on-surface">
            ワンタイムパスワード
          </DefaultText>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: MESSAGE_REQUIRED_PASSWORD,
              pattern: {
                value: REGEX.VALIDATE_PASSWORD,
                message: MESSAGE_VALIDATE_PASSWORD,
              },
            }}
            render={({ field }) => (
              <ForwardedInput
                {...field}
                type="text"
                className={'border-1 h-12 w-full rounded border-on-surface-variant px-4 py-1'}
                hasError={!!errors?.password?.message}
              />
            )}
          />
          <ErrorTooltip errorMessage={errors?.password?.message} hasError={!!errors?.password?.message} />
          <DefaultText variant="text-status" className="flex px-4 pt-1 text-[11px] text-on-surface-variant">
            ログインすることで、本サービスの利用規約とプライバシーポリシーに同意することになります。
          </DefaultText>
        </div>
        <div className="flex gap-6 pt-6">
          <Link to={'#'}>
            <DefaultText variant="text-link" className="flex text-sm text-tertiary underline">
              利用規約
            </DefaultText>
          </Link>
          <Link to={'#'}>
            <DefaultText variant="text-link" className="flex text-sm text-tertiary underline">
              プライバシーポリシー
            </DefaultText>
          </Link>
        </div>
        <div className="items-center gap-2 pl-3.5 pt-6">
          <div className="my-auto flex h-12">
            <span className="my-auto h-[18px] w-[18px]">
              <BaseCheckBox onChange={handleChangeRemember} />
            </span>
            <DefaultText variant="text-link" className="my-auto pl-4 text-sm text-on-surface">
              次回から自動でログインする
            </DefaultText>
          </div>
        </div>
        <div className="pt-6">
          <BaseButton variant="primary" type="submit" className="w-full md:w-[105px]">
            <DefaultText variant="length" className="text-sm text-on-primary">
              ログイン
            </DefaultText>
          </BaseButton>
        </div>
      </form>
    </div>
  );
};

export default FormLoginPassword;
