import c from 'clsx';
import React from 'react';
import { Chart, Charts } from '@/stores/chart/model';
import ChartNameCell from '../../molecules/ChartNameCell';
import ChartButton from './ChartButton';
import { useService } from './service';
import s from './style.module.sass';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { changeOrderCircle } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { setCharts } from '@/stores/chart/effects';

type Props = {
  className?: string;
  data: Charts;
};

const ChartNameArea = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, data } = props;
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const { closeGroups, handleClickToggle } = useService();
  const { sheetActive } = useSelector((state: RootState) => state.business);

  const dragEndEvent = (e: DragEndEvent, k: string) => {
    if (e && businessId && e.active.id && e?.over?.id && e.active.id !== e?.over?.id) {
      const areasStateCopy = JSON.parse(JSON.stringify(data));
      const indexFrom = areasStateCopy[k].findIndex((item: Chart) => item.id === e.active.id);
      const indexTo = areasStateCopy[k].findIndex((item: Chart) => item.id === e?.over?.id);

      const itemMove = areasStateCopy[k].splice(indexFrom, 1);
      areasStateCopy[k].splice(indexTo, 0, ...itemMove);

      dispatch(setCharts(areasStateCopy));
      dispatch(
        changeOrderCircle({
          businessId: +businessId,
          sheetId: sheetActive,
          idFrom: +e.active.id,
          idTo: +e.over.id,
        }),
      );
    }
  };

  return (
    <>
      <div ref={ref} className={c(s.root, className)} id="chart-name-area">
        {Object.keys(data).map((k) => (
          <React.Fragment key={k}>
            <ChartButton
              label={k}
              onClick={() => handleClickToggle(k)}
              isArrowClose={closeGroups.some((g: string) => g === k)}
              areas={data[k]}
            />
            <DndContext onDragEnd={(e) => dragEndEvent(e, k)}>
              <span>
                <SortableContext items={data[k]}>
                  {data[k].map(
                    (d) =>
                      !closeGroups.some((g: string) => g === k) && (
                        <ChartNameCell className={s.cell} label={d.name} area={k} id={String(d.id)} key={d.id} />
                      ),
                  )}
                </SortableContext>
              </span>
            </DndContext>
          </React.Fragment>
        ))}
      </div>
    </>
  );
});
ChartNameArea.displayName = 'ChartNameArea';
export default ChartNameArea;
