import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { IComment } from '@/types/question';
import CardComment from '@/components/molecules/CardComment';
import { useDispatch } from 'react-redux';
import { createCommentBusiness, createScheduleCommentBusiness } from '@/stores/business/services';

type Props = {
  listComment: IComment[];
  businessId: number | null;
  idSheet: number;
  isShedule?: boolean;
};

const CommentsBusiness = (props: Props) => {
  const { listComment, businessId, idSheet, isShedule } = props;
  const dispatch = useDispatch();
  const [content, setContent] = useState<string>('');

  const sendComment = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      if (businessId) {
        dispatch(
          (isShedule ? createScheduleCommentBusiness : createCommentBusiness)({
            id: businessId,
            content: content,
            idSheet: idSheet,
          }),
        );
        setContent('');
      }
    }
  };

  return (
    <div className="rounded-2xl bg-surface-at-1">
      <div className="p-2 pb-0 text-sm sm:p-4">
        <div className="mb-4 flex gap-2">
          <span>コメント</span>
          <span>{listComment.length}件</span>
        </div>
        <div className="flex h-[150px] max-h-[350px] flex-col-reverse gap-3 overflow-auto sm:gap-6 lg:h-auto">
          {listComment?.map((comment) => <CardComment key={comment.id} comment={comment} isHidenFile />)}
        </div>
      </div>
      <div className="flex h-[72px] w-full items-center gap-2 px-2 py-3 sm:px-4">
        <div className="h-12 grow">
          <input
            className="h-full w-full rounded-3xl border border-outline px-4 py-2 text-base focus:outline-none"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => sendComment(e)}
            placeholder={'コメント'}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentsBusiness;
