import type { Charts } from '@/datasources/chart/model';
import { setCharts } from '@/stores/chart/effects';
import { IndexedObject } from '@/utils/types';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { ICirclePlanArea } from '@/stores/business/model';

export const useService = (grouping: boolean) => {
  const dispatch = useDispatch();
  const charts = useSelector((state: IndexedObject) => state.chart.charts);
  const { dataChart } = useSelector((state: RootState) => state.business);

  const startAtRef = useRef<HTMLInputElement>(null);
  const [startAt, setStartAt] = useState<string>();
  const endAtRef = useRef<HTMLInputElement>(null);
  const [endAt, setEndAt] = useState<string>();

  const [autoStartAt, setAutoStartAt] = useState<string>();
  const [autoEndAt, setAutoEndAt] = useState<string>();

  const handleChangeStartAt = () => {
    if (!startAtRef) return;
    setStartAt(startAtRef.current?.value);
  };
  const handleChangeEndAt = () => {
    if (!endAtRef) return;
    setEndAt(endAtRef.current?.value);
  };

  const autoCulcTerm = (data: Charts) => {
    const s: Dayjs[] = [];
    const e: Dayjs[] = [];
    Object.keys(data).forEach((k) => {
      data[k].forEach((d) => {
        s.push(dayjs(d.sowing));
        e.push(dayjs(d.harvest.end));
      });
    });

    const min = s.reduce((min, current) => {
      return current.isBefore(min) ? current : min;
    }, dayjs(s[0]));
    const max = e.reduce((max, current) => {
      return current.isAfter(max) ? current : max;
    }, dayjs(e[0]));
    setAutoStartAt(min.format('YYYY/MM'));
    setAutoEndAt(max.format('YYYY/MM'));
  };

  const convertData = (data: ICirclePlanArea[]) => {
    const dataConvert: Charts = {};
    data?.forEach((item: ICirclePlanArea) => {
      const dataPush = {
        id: String(item.id),
        name: item.title,
        order: item.order,
        sowing: item.seedDate,
        planting: item.transplantDate,
        harvest: {
          start: item.harvestStartDate,
          end: item.harvestEndDate,
        },
        organization: {
          id: item.sheetInfo.businessPlanInfo['__house__'].organization.id,
          title: item.sheetInfo.businessPlanInfo['__house__'].organization.title,
        },
        house: {
          id: item.sheetInfo.businessPlanInfo['__house__'].id,
          title: item.sheetInfo.businessPlanInfo['__house__'].title,
        },
        businessPlan: {
          id: item.sheetInfo.businessPlanInfo.id,
          title: item.sheetInfo.businessPlanInfo.title,
        },
        sheet: {
          id: item.sheetInfo.id,
          title: item.sheetInfo.title,
        },
        area: {
          id: item.areaInfo?.id,
          title: item.areaInfo?.title,
          order: item.areaInfo.order,
        },
      };

      const idAreaCustom = `${item.areaInfo?.title}/${item.areaId}`;
      if (dataConvert[idAreaCustom]) {
        dataConvert[idAreaCustom] = [...dataConvert[idAreaCustom], dataPush];
      } else {
        dataConvert[idAreaCustom] = [dataPush];
      }
    });

    const sortedObject = Object.fromEntries(
      Object.entries(dataConvert).sort(([, a], [, b]) => a[0].area.order - b[0].area.order),
    );

    return sortedObject;
  };

  const convertDataNoGroup = (data: ICirclePlanArea[]) => {
    return data
      .slice()
      .sort((a, b) => {
        return new Date(a.seedDate) > new Date(b.seedDate) ? 1 : -1;
      })
      .map((item) => {
        return {
          name: item.title,
          id: String(item.id),
          sowing: item.seedDate,
          planting: item.transplantDate,
          harvest: {
            start: item.harvestStartDate,
            end: item.harvestEndDate,
          },
          areaName: item?.areaInfo?.title ?? '',
          organization: {
            id: item.sheetInfo.businessPlanInfo['__house__'].organization.id,
            title: item.sheetInfo.businessPlanInfo['__house__'].organization.title,
          },
          house: {
            id: item.sheetInfo.businessPlanInfo['__house__'].id,
            title: item.sheetInfo.businessPlanInfo['__house__'].title,
          },
          businessPlan: {
            id: item.sheetInfo.businessPlanInfo.id,
            title: item.sheetInfo.businessPlanInfo.title,
          },
          sheet: {
            id: item.sheetInfo.id,
            title: item.sheetInfo.title,
          },
          area: {
            id: item.areaInfo?.id,
            order: item.areaInfo?.order,
            title: item.areaInfo?.title,
          },
        };
      });
  };

  useEffect(() => {
    if (grouping) {
      const dataConvert = convertData(dataChart);
      for (const key in dataConvert) {
        dataConvert[key].sort((a, b) => {
          const orderA = a.order ?? 0;
          const orderB = b.order ?? 0;
          return orderA - orderB;
        });
      }

      dispatch(setCharts(dataConvert));
      autoCulcTerm(dataConvert);
    } else {
      const dataConvert = convertDataNoGroup(dataChart);
      dispatch(setCharts({ all: dataConvert }));
      autoCulcTerm({ all: dataConvert });
    }
  }, [grouping, dispatch, dataChart]);

  return {
    charts,
    startAtRef,
    startAt,
    handleChangeStartAt,
    endAtRef,
    endAt,
    handleChangeEndAt,
    autoStartAt,
    autoEndAt,
    setCharts,
  };
};
