import PrimaryButton from '@/components/atoms/PrimaryButton';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { setDialogType } from '@/stores/business/slice';

type FormEditWraperProps = {
  children: ReactNode;
  isDisable?: boolean;
};
const FormEditWraper = ({ children, isDisable = false }: FormEditWraperProps) => {
  const dispatch = useDispatch();
  const hiddenDialog = () => {
    dispatch(setDialogType(''));
  };

  return (
    <>
      <div className="flex flex-col gap-4 overflow-x-auto">{children}</div>
      <div className="mt-6 flex justify-end gap-2">
        <PrimaryButton onClick={hiddenDialog} type="button" text="キャンセル" variant="text" />
        <PrimaryButton disabled={isDisable} type="submit" text="保存" variant="outlined" />
      </div>
    </>
  );
};

export default FormEditWraper;
