import moment from 'moment-timezone';

export const fullTime = (time: string | Date) => {
  return moment(time).format('YYYY/MM/DD hh:mm');
};

export const defaultFormat = (time: string | Date) => {
  return moment(time).format('YYYY/MM/DD');
};

export const formatTimeForInputDate = (time: string | Date) => {
  return moment(time).format('YYYY/MM/DD');
};

export const formatTimeForInputDateDefault = (time: string | Date) => {
  return moment(time).format('YYYY/MM/DD');
};
