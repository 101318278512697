import type { Chart } from '@/stores/chart/model';
import c from 'clsx';
import React from 'react';
import Arrow from '../../atoms/Arrow';
import Input from '../../atoms/Input';
import { chartConsts } from '../../organisms/Chart/consts';
import ChartGroupAll from '../ChartGroup/ChartGroupAll';
import MileStone from '../MileStone';
import Term from '../Term';
import { useChartDroppable, useService } from './service';
import s from './style.module.sass';

type Props = {
  className?: string;
  scaleUnit: 'month' | 'day';
  startAt: string;
  endAt: string;
  area: string;
  chart: Chart;
  tableWidth: number;
  handleMove: (area: string, id: string, value: number) => void;
};

const ChartBarAll: React.FC<Props> = (props) => {
  const { className, scaleUnit, startAt, endAt, area, chart, tableWidth, handleMove } = props;

  const { p, moveDate, rootRef, rootY, editRef1, editRef2, editType, handleEditOpen, handleEditClose, handleEdit } =
    useService(startAt, endAt, area, chart, scaleUnit, handleMove);
  const { setNodeRef } = useChartDroppable(chart.id);

  const offset =
    scaleUnit === 'day'
      ? -(chartConsts[scaleUnit].cellWidth - chartConsts.mileStoneSize) / 2
      : chartConsts.mileStoneSize / 2;

  let editLeft = 0;

  if (editType === 'harvest') {
    editLeft = p.base + p.p3s - 40;
  } else if (editType === 'sowing') {
    editLeft = p.base + p.p1 - 40;
  } else if (editType === 'planting') {
    editLeft = p.base + p.p2 - 40;
  }

  const editStyle = {
    top: `${rootY - 60}px`,
    left: `${editLeft}px`,
  };

  return (
    <div className={s.root} ref={rootRef}>
      <div
        className={c(s.chartBar, className)}
        style={{
          width: `${tableWidth}px`,
          height: `${chartConsts.mileStoneSize}px`,
        }}
        ref={setNodeRef}
        id={chart.id}
      >
        <ChartGroupAll className={c(s.group)} left={p.base} width={p.p3e} id={chart.id} moveDate={moveDate}>
          <MileStone
            type="sowing"
            left={p.p1}
            isEdit={editType === 'sowing'}
            size={chartConsts.mileStoneSize}
            offset={offset}
            handleDClick={() => handleEditOpen('sowing')}
          />
          <Arrow
            left={
              scaleUnit === 'day'
                ? p.p1 + offset + chartConsts.arrowPadding + chartConsts[scaleUnit].cellWidth
                : p.p1 + offset + chartConsts.arrowPadding
            }
            range={
              scaleUnit === 'day'
                ? p.p2 - p.p1 - offset - chartConsts.arrowPadding * 2 - chartConsts[scaleUnit].cellWidth
                : p.p2 - p.p1 - offset - chartConsts.arrowPadding * 2
            }
            label={`${p.d1}日`}
            offset={offset}
          />
          <MileStone
            type="planting"
            left={p.p2}
            isEdit={editType === 'planting'}
            size={chartConsts.mileStoneSize}
            offset={offset}
            handleDClick={() => handleEditOpen('planting')}
          />
          <Arrow
            left={
              scaleUnit === 'day'
                ? p.p2 + offset + chartConsts.arrowPadding + chartConsts[scaleUnit].cellWidth
                : p.p2 + offset + chartConsts.arrowPadding
            }
            range={
              scaleUnit === 'day'
                ? p.p3s - p.p2 - chartConsts.arrowPadding * 2 - chartConsts[scaleUnit].cellWidth
                : p.p3s - p.p2 - chartConsts.arrowPadding * 2
            }
            label={`${p.d2}日`}
            offset={offset}
          />
          <Term
            left={p.p3s}
            width={p.p3e - p.p3s + chartConsts[scaleUnit].cellWidth / chartConsts[scaleUnit].splitUnit}
            term={p.d3}
            isEdit={editType === 'harvest'}
            handleDClick={() => handleEditOpen('harvest')}
          />
        </ChartGroupAll>
      </div>
      {editType && (
        <div className={s.editRoot}>
          <div className={s.editOverlay} onClick={handleEditClose} onKeyDown={() => {}} />
          {/* root要素の親からの相対縦位置、マイルストーンの相対横位置をserviceから受け取っています */}
          {/* 仮で位置補正しているのでデザイン決まり次第調整してください */}
          <div className={s.edit} style={editStyle}>
            {/* 単純にtype=dateのinputを置いているだけです */}
            {/* 何かしらの方法で初期日時の設定を実装するか、正規表現「XXXX-XX-XX」でバリデートするtype=textが良いかと思います */}
            {editType === 'harvest' ? (
              <>
                <div>
                  <Input type="date" ref={editRef1} />
                </div>
                <div>
                  <Input type="date" ref={editRef2} />
                </div>
              </>
            ) : (
              <div className={s.inputWrap}>
                <Input type="date" ref={editRef1} />
              </div>
            )}
            <button onClick={handleEdit} className={s.editButton}>
              編集
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartBarAll;
