import React from 'react';
import Label from '../AText/Label';
import BodyText from '../AText/BodyText';
import { fullTime } from '@/utils/formatTime';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb';
import { useDispatch } from 'react-redux';
import { readNotification } from '@/stores/notification';
import { useNavigate } from 'react-router-dom';
import { getNavigatePath, NotifyCardProps } from '@/components/atoms/NotifyCard/converter';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';

const NotifyCard = ({ data }: NotifyCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateTo = () => {
    if (data?.idNotification) dispatch(readNotification(data?.idNotification));
    const navigatePath = getNavigatePath(data);
    if (navigatePath) {
      navigate(navigatePath);
    }
  };

  return (
    <div>
      {data ? (
        <div
          className={`${
            data.recipients ? 'opacity-50' : ''
          } w-full rounded-lg border border-outline-variant bg-gray-background-1 pb-2`}
          onClick={navigateTo}
          onKeyDown={() => {}}
        >
          <div className="flex flex-col gap-1 border-b border-outline-variant bg-white px-4 py-2">
            <div className="flex">
              <Breadcrumb data={data.breadCrumb} />
            </div>
            <BodyText tag="h4" size="medium" className="flex text-on-surface">
              {data?.question?.title ?? data?.title ?? data.sheetInfo.businessPlanInfo?.title}
            </BodyText>
          </div>
          <div className="flex items-center justify-between px-4 py-2">
            <div className="flex items-center gap-3">
              <div className="flex gap-2">
                <div className="h-10 w-10">
                  <DisplayAvatar
                    path={
                      (data?.user ?? data?.createdByName)?.file?.length
                        ? (data?.user ?? data?.createdByName)?.file[0]?.path
                        : ''
                    }
                    nameUser={(data?.user ?? data?.createdByName)?.name ?? ''}
                    className="h-10 w-10 rounded-full leading-10"
                  />
                </div>
                <span className="my-auto flex flex-col">
                  <div className="flex flex-wrap items-center gap-x-6 gap-y-0">
                    <BodyText tag="span" size="medium" className="truncate-text !line-clamp-3 font-medium text-black">
                      {(data?.user ?? data?.createdByName)?.name ?? ''}
                    </BodyText>
                    <Label tag="span" size="medium" className="text-on-surface-variant md:mt-0">
                      {data.activity}
                    </Label>
                  </div>
                  <div className="flex md:hidden">
                    <Label tag="span" size="small" className="text-on-surface-variant">
                      {fullTime(data?.createAt ?? '')}
                    </Label>
                  </div>
                </span>
              </div>
            </div>
            <div className="hidden items-center justify-center md:flex">
              <Label tag="span" size="small" className="text-on-surface-variant">
                {fullTime(data?.createAt ?? '')}
              </Label>
            </div>
          </div>
          {data?.comment && (
            <div className="px-4 py-2">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-2">
                <BodyText size="medium" tag="p" className="flex">
                  {data?.comment}
                </BodyText>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotifyCard;
