import type { FC } from 'react';

const LoadMore: FC = () => {
  return (
    <div className="relative">
      <div className="absolute top-[-50px] z-50 flex w-full items-center justify-center">
        <div className="flex items-center space-x-24">
          <div className="container relative space-y-10">
            <div className="flex items-center space-x-4">
              <div className="loading-spinner border-t- h-10 w-10 animate-spin rounded-full border-2 border-white border-t-slate-400"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadMore;
