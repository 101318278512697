import cn from '@/utils/general';
import { ButtonHTMLAttributes, ReactNode } from 'react';

type ButtonVariant = 'filled' | 'outlined' | 'text' | 'delete';

const buttonVariant: Record<ButtonVariant, string> = {
  filled: 'flex-row border-solid border-outline bg-primary hover:shadow-elevation-light-1 disabled:bg-disabled',
  outlined:
    'items-center border-solid border-outline bg-transparent hover:bg-M3-light-state-layer-primary/[0.08] active:bg-M3-light-state-layer-primary/[0.12] disabled:bg-on-surface',
  text: 'items-center border-none pb-[10px] pt-[9px] bg-transparent hover:bg-M3-light-state-layer-primary/[0.08] active:bg-M3-light-state-layer-primary/[0.12] disabled:bg-on-surface',
  delete:
    'flex-row border-solid border-outline bg-error text-white hover:shadow-elevation-light-1 disabled:bg-disabled',
};
type PrimaryButtonOwnProps = {
  variant?: ButtonVariant;
  className?: string;
  text?: ReactNode;
  iconLeft?: string;
  iconRight?: string;
  onClick?: () => void;
};

type PrimaryButtonProps = PrimaryButtonOwnProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof PrimaryButtonOwnProps>;

const PrimaryButton = ({
  variant = 'filled',
  text,
  className,
  iconLeft,
  iconRight,
  onClick,
  ...rest
}: PrimaryButtonProps) => {
  return (
    <button
      className={cn(
        'label lg inline-flex items-center overflow-hidden rounded-xl border border-solid border-outline px-6 pb-[9px] pt-[8px] text-on-primary transition-all duration-200 active:scale-[0.98] active:transition-all active:duration-200 active:ease-in disabled:pointer-events-none disabled:bg-on-surface disabled:bg-opacity-[0.12] disabled:text-on-primary/38',
        buttonVariant[variant],
        iconLeft && 'pl-4',
        className,
      )}
      onClick={onClick}
      {...rest}
    >
      {iconLeft && (
        <span className={cn('', iconLeft)}>
          <i className="mr-2 hidden h-[18px] w-[18px] before:bg-on-primary" />
        </span>
      )}
      <span className="w-full whitespace-nowrap text-center">{text}</span>
      {iconRight && (
        <span className={cn('', iconRight)}>
          <i className="ml-2 hidden h-[18px] w-[18px] " />
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
