import DefaultText from '@/components/atoms/Text/DefaultText';
import IconColonization from '@/components/icon/IconChart/IconColonization';
import IconHarvest from '@/components/icon/IconChart/IconHarvest';
import IconSowing from '@/components/icon/IconChart/IconSowing';
import ChartAll from '@/components/organisms/Chart/ChartAll';
import Select from '../../atoms/Select';
import '../../styles/common/index.sass';
import { useService } from './service';
import s from './style.module.sass';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { useEffect, useState } from 'react';
import { getDataChartAll } from '@/stores/business/services';
import { setCharts } from '@/stores/chart/effects';
import { setDataChart, setPage } from '@/stores/business/slice';
import IconZoomIn from '@/components/icon/IconZoomIn';
import IconZoomOut from '@/components/icon/IconZoomOut';

const scaleValues = [
  {
    label: '年/月',
    value: 'month',
  },
  {
    label: '月/日',
    value: 'day',
  },
];

const groupByValues = [
  {
    label: 'エリア',
    value: 'true',
  },
  {
    label: 'なし',
    value: 'false',
  },
];

function ChartAllHouses() {
  const dispatch = useDispatch();
  const { scaleRef, scale, handleScaleChange, areaGroupingRef, isAreaGrouping, handleAreaGroupingChange } =
    useService();
  const { dataChart, page } = useSelector((state: RootState) => state.business);
  const [zoom, setZoom] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getDataChartAll({
        page: page,
        limit: 20,
        order: 1,
      }),
    );
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(setCharts({}));
      dispatch(setDataChart([]));
      dispatch(setPage(1));
    };
  }, []);

  const handleZoomIn = () => {
    setZoom(true);
  };

  const handleZoomOut = () => {
    setZoom(false);
  };

  return (
    <div className={`${s.root} ${zoom && s.zoom} flex h-[calc(100vh-125px)] flex-col md:h-[calc(100vh-170px)]`}>
      {dataChart.length > 0 && (
        <div className="flex justify-between">
          <div className=""></div>
          <div className="flex flex-wrap gap-4 p-4 lg:flex-nowrap">
            <div className="flex max-w-[296px] gap-[16px] rounded-lg border border-outline-variant px-[16px] py-[8px]">
              <DefaultText
                variant="text-status"
                className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
              >
                凡例
              </DefaultText>
              <div className="flex gap-1">
                <IconSowing className="!cursor-default" />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  播種
                </DefaultText>
              </div>
              <div className="flex gap-1">
                <IconColonization className="!cursor-default" />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  定植
                </DefaultText>
              </div>
              <div className="flex gap-1">
                <IconHarvest className="!cursor-default" />
                <DefaultText
                  variant="text-status"
                  className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                >
                  収穫
                </DefaultText>
              </div>
            </div>
            <div className="flex gap-2">
              <DefaultText
                variant="text-tableBody"
                className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
              >
                GroupBy
              </DefaultText>
              <Select
                className="!min-w-[100px] lg:!min-w-[160px]"
                data={groupByValues}
                ref={areaGroupingRef}
                onChange={handleAreaGroupingChange}
              />
            </div>
            <div className="flex gap-2">
              <DefaultText
                variant="text-tableBody"
                className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
              >
                タイムスケール
              </DefaultText>
              <Select
                className="!min-w-[100px] lg:!min-w-[160px]"
                data={scaleValues}
                ref={scaleRef}
                onChange={handleScaleChange}
              />
              {zoom ? (
                <div
                  className="flex items-center rounded-full border border-on-surface-variant p-3"
                  onClick={handleZoomOut}
                  onKeyDown={() => {}}
                >
                  <IconZoomOut />
                </div>
              ) : (
                <div
                  className="flex items-center rounded-full border border-on-surface-variant p-3"
                  onClick={handleZoomIn}
                  onKeyDown={() => {}}
                >
                  <IconZoomIn />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ChartAll scaleUnit={scale} grouping={isAreaGrouping === 'true'} isAreaGrouping={isAreaGrouping} />
    </div>
  );
}

export default ChartAllHouses;
