import CommonModal from '@/components/atoms/Modal/CommonModal';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import Text from '@/components/atoms/Text';
import ModalBusinessPlan from '@/components/molecules/ModalComponent/ModalBusinessPlan';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import cn from '@/utils/general';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BusinessForm from '../BusinessForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBP, getAPIBusinessList } from '@/stores/business/services';
import { getHousesDetailAsync } from '@/stores/houses';
import { RootState } from '@/stores';
import { IBusinessItem } from '@/stores/business/model';
import { defaultFormat } from '@/utils/formatTime';
import { EPath } from '@/route/route';
import MapComponent from '@/components/pages/AllHouses/MapComponent';
import { convertNumberFormThousands, replacePathParams } from '@/utils/helpers';
import StarLight from '@/components/icon/StarLight';
import Linkify from '@/components/atoms/Linkify';
import SearchBar from '@/components/molecules/SearchBar';
import DropdownEditBusiness from '@/components/molecules/Dropdown/DropdownEdit/DropdownEditBusiness';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { TMarker } from '@/types/houses';
import IconEdit from '@/components/icon/IconEdit';
import { getOrganizationDetailAsync } from '@/stores/organization';

type THouseListItem = {
  title: string;
  date: string;
  id: number;
  star: boolean;
};

const HouseListItem = ({ title, date, id, star = false }: THouseListItem) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { houseId } = useParams();
  const { houseDetail } = useSelector((state: RootState) => state.houses);
  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalBusinessPlan, setShowModalBusinessPlan] = useState(false);
  const refModal = useRef<HTMLDivElement>(null);
  const [isTop, setIsTop] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleCloseModalBusinessPlan = () => {
    setShowModalBusinessPlan(false);
  };

  const handleModalBusinessPlansClick = () => {
    setShowModalBusinessPlan(true);
  };

  const deleteBusinessPlan = async () => {
    await dispatch(deleteBP({ id }));
    if (houseId) {
      dispatch(getAPIBusinessList({ id: +houseId }));
    }
  };

  useOutsideClick(refModal, handleShow);

  useEffect(() => {
    const element = document.getElementById(`id-${id}`);
    const rect = element?.getBoundingClientRect();
    setIsTop(!!(rect?.bottom && rect?.bottom > 800));
  }, [show]);

  return (
    <>
      <div className={cn('relative flex border-b px-4 py-2', 'border-b-M3-outline-variant')} id={`id-${id}`}>
        <Link
          to={replacePathParams(EPath.organizationHouseBusiness, {
            organizationId: houseDetail?.organizationId ?? '',
            houseId: houseId ?? '',
            businessId: id,
          })}
          className="flex w-full justify-between"
        >
          <div className="flex items-center gap-2">
            <div className="regular-text md grow">{title}</div>
            {star && <StarLight />}
          </div>
          <div className="regular-text md w-[120px] px-2 text-center">{defaultFormat(date)}</div>
        </Link>
        <button className="ml-4 inline-block hover:cursor-pointer" onClick={handleShow}>
          <i className="icon-24px icon-more h-6 w-6 bg-on-surface-variant" />
        </button>
        {show && (
          <div ref={refModal} className={`${isTop && 'absolute right-0 top-[-172px]'}`}>
            <DropdownEditBusiness
              modalModalBusinessPlanClick={handleModalBusinessPlansClick}
              modalModalDeleteClick={handleModalDeleteClick}
            />
          </div>
        )}
      </div>
      <CommonModal
        open={showModalDelete}
        title={'事業計画の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={deleteBusinessPlan}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete
          contentDelete={
            '事業計画の名前を削除します。事業計画内にあるすべてのデータ、損益計画、栽培計画も削除されます。この操作は元に戻せません。'
          }
        />
      </CommonModal>
      <CommonModal
        open={showModalBusinessPlan}
        title={'事業計画'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModalBusinessPlan}
        boxClassName={'!w-[calc(100%-64px)] lg:!w-[640px]'}
        buttonHidden={true}
      >
        <ModalBusinessPlan name={title} id={id} handleClose={handleCloseModalBusinessPlan} />
      </CommonModal>
    </>
  );
};

const HouseSummary = () => {
  const { houseId, organizationId } = useParams();
  const navigate = useNavigate();
  const [showModalBusinessForm, setShowModalBusinessForm] = useState(false);
  const { titleOrganization } = useSelector((state: RootState) => state.organization);
  const { businessList } = useSelector((state: RootState) => state.business);
  const { houseDetail } = useSelector((state: RootState) => state.houses);
  const [markers, setMarkers] = useState<TMarker[]>([]);
  const [_businessList, setBusinessList] = useState<IBusinessItem[] | []>(businessList ?? []);
  const [openInputSearchMobile, setOpenInputSearchMobile] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationId && !titleOrganization) {
      dispatch(getOrganizationDetailAsync(Number(organizationId)));
    }
  });

  useEffect(() => {
    if (houseDetail) {
      setMarkers([
        {
          id: houseDetail.id,
          lat: +houseDetail.coordinatesLat,
          lng: +houseDetail.coordinatesLong,
        },
      ]);
    }
  }, [houseDetail]);

  useEffect(() => {
    if (houseId && !isNaN(+houseId)) {
      dispatch(getAPIBusinessList({ id: +houseId }));
      dispatch(getHousesDetailAsync({ id: +houseId }));
    } else {
      navigate(EPath.notFoundPage);
    }
  }, [houseId]);

  useEffect(() => {
    setBusinessList(businessList ?? []);
  }, [businessList]);

  const handleCloseModalBusinessForm = () => {
    setShowModalBusinessForm(false);
  };

  const handleModalBusinessPlansClick = () => {
    setShowModalBusinessForm(true);
  };

  const handleSearchBar = (valueSearch: string) => {
    if (houseId && !isNaN(+houseId)) dispatch(getAPIBusinessList({ id: +houseId, keyword: valueSearch }));
  };

  return (
    <>
      <div className=" w-full bg-surface-at-1 pb-10 font-noto lg:p-4">
        <div className="w-full bg-white py-4 lg:rounded-2xl">
          <div className="flex items-center justify-between px-4">
            <div className="flex gap-2">
              <SearchBar
                setOpenInputSearchMobile={setOpenInputSearchMobile}
                openInputSearchMobile={openInputSearchMobile}
                handleEnter={(value) => handleSearchBar(value)}
              />
            </div>
            <div>
              <PrimaryButton
                onClick={handleModalBusinessPlansClick}
                onKeyDown={() => {}}
                iconLeft="icon-button-add"
                className="icon-button hidden md:inline-flex"
                text="事業計画の新規作成"
              />
            </div>
            <div
              onClick={handleModalBusinessPlansClick}
              onKeyDown={() => {}}
              className="absolute bottom-4 right-8 z-10 inline-block cursor-pointer rounded-2xl bg-primary p-4 transition-all duration-200 ease-in hover:scale-[0.98] active:scale-[0.9] active:transition-all active:duration-200 active:ease-in md:hidden"
            >
              <IconEdit color={'#1F1C00'} />
            </div>
          </div>
          <div className="mt-4 flex flex-wrap gap-10 px-4 lg:px-8">
            <div className="w-full overflow-hidden lg:w-auto">
              <img
                src={`${
                  houseDetail?.image?.[0]?.path
                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(houseDetail?.image[0]?.path)}`
                    : '/media.png'
                }`}
                className="h-[160px] w-full object-cover lg:w-[280px]"
                alt="houseImg"
              />
            </div>
            <div className="max-h-[160px] w-full overflow-hidden lg:max-w-[280px]">
              <MapComponent className="h-[160px]" markers={markers} />
            </div>
            <div>
              <div className="flex w-full flex-col gap-4 text-on-surface lg:w-[280px]">
                <div className="flex justify-between">
                  <Text text="面積(a)" className="md w-[113px] font-medium" />
                  <Text
                    text={convertNumberFormThousands(Number(houseDetail?.unitConversion))}
                    className="regular-text md font-medium"
                  />
                </div>
                <div className="flex justify-between">
                  <Text text="株数" className="md w-[113px] font-medium" />
                  <Text
                    text={convertNumberFormThousands(Number(houseDetail?.treeQuantity))}
                    className="regular-text md font-medium"
                  />
                </div>
                <div className="flex justify-between">
                  <Text text="栽植密度(株/10a)" className="md w-[113px] font-medium" />
                  <Text
                    text={convertNumberFormThousands(Math.floor(houseDetail?.plantingDensity ?? 0))}
                    className="regular-text md font-medium"
                  />
                </div>
                <div className="flex justify-between">
                  <Text text="管理者" className="md w-[113px] font-medium" />
                  <Text
                    text={houseDetail?.manager}
                    className="regular-text md text-ellipsis-2 flex-1 text-end font-medium"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mx-8 mt-4 text-on-surface">
            <div className="regular-text md text-on-surface">
              <Linkify>{houseDetail?.note ?? ''}</Linkify>
            </div>
            <span className="text-sm leading-normal">
              ※面積：事業計画作成時の初期値として利用されます。
              <br /> ※株数：事業計画作成時には利用されません。デフォルトの株数は、3,500株/10アールとなります。
            </span>
          </div>
          <div className="mt-4 text-on-surface">
            <div className="py-1 pl-4 pr-16">
              <div className="label sm flex w-full ">
                <div className="grow">事業計画名</div>
                <div className="w-[120px] px-2 text-center">更新日</div>
              </div>
            </div>
            <div className="h-[394px] overflow-auto">
              {_businessList.map((item) => (
                <HouseListItem
                  key={nanoid(5)}
                  title={item.title}
                  date={item.updatedAt ?? item.createdAt}
                  id={item.id}
                  star={!!item.countStatusSheet}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        open={showModalBusinessForm}
        title={'事業計画の新規作成'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModalBusinessForm}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[640px]'}
        buttonHidden={true}
      >
        <BusinessForm handleCloseForm={handleCloseModalBusinessForm} houseDetail={houseDetail} />
      </CommonModal>
    </>
  );
};

export default HouseSummary;
