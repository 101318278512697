import c from 'clsx';
import React from 'react';
import { Chart, Charts } from '@/stores/chart/model';
import ChartNameCellAll from '../../ChartNameCell/ChartAll';
import { useService } from '../service';
import s from './../style.module.sass';
import ChartButtonAll from './ChartButton';
import { EPath } from '@/route/route';
import { useNavigate } from 'react-router-dom';
import { replacePathParams } from '@/utils/helpers';

type Props = {
  className?: string;
  data: Charts;
};

const ChartNameAreaAll: React.FC<Props> = (props) => {
  const { className, data } = props;
  const navigate = useNavigate();
  const { closeGroups, handleClickToggle } = useService();

  const handleNavigateToBP = (d: Chart) => {
    navigate(
      `${replacePathParams(EPath.organizationHouseBusiness, {
        organizationId: d.organization.id,
        houseId: d.house.id,
        businessId: d.businessPlan.id,
      })}?tab=2`,
    );
  };

  return (
    <div className={c(s.root, className)} id="chart-name-area">
      {Object.keys(data).map((k) => (
        <React.Fragment key={k}>
          <ChartButtonAll
            label={k}
            data={data[k][0]}
            onClick={() => handleClickToggle(k)}
            isArrowClose={closeGroups.some((g: string) => g === k)}
          />
          <span>
            {data[k].map(
              (d) =>
                !closeGroups.some((g: string) => g === k) && (
                  <div
                    className="flex h-12 cursor-pointer pl-5 hover:bg-gray-background"
                    key={d.id}
                    onClick={() => handleNavigateToBP(d)}
                    onKeyDown={() => {}}
                  >
                    <ChartNameCellAll className={s.cell} label={d.name} area={k} id={d.id} key={d.id} />
                  </div>
                ),
            )}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChartNameAreaAll;
