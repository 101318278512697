import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import IconAddCircleOutline from '@/components/icon/IconAddCircleOutline';
import { IconArrowSelect } from '@/components/icon/IconArrowDropUp/IconArrowSelect';
import IconMoreGrey from '@/components/icon/IconMore/IconMoreGrey';
import c from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import DropdownSettingChart from '../Dropdown/DropdownSettingChart';
import ModalAreaManagement from '../ModalComponent/ModalAreaManagement';
import ModalDelete from '../ModalComponent/ModalDelete';
import ModalSeedingWithinArea from '../ModalComponent/ModalSeedingWithinArea';
import s from './style.module.sass';
import { Chart } from '@/stores/chart/model';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAreaApi } from '@/stores/chart/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import { CONSTANTS } from '@/utils/constants/consts';

type ChartButtonProps = {
  label: string;
  onClick: () => void;
  isArrowClose: boolean;
  areas: Chart[];
};

const ChartButton: React.FC<ChartButtonProps> = ({ label, onClick, isArrowClose, areas }) => {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const { listAreas } = useSelector((state: RootState) => state.chart);
  const [showSetting, setShowSetting] = useState(false);
  const [showAreaManagement, setShowAreaManagement] = useState(false);
  const [showSeedingWithinArea, setShowSeedingWithinArea] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isTop, setIsTop] = useState<boolean>(false);
  const { width } = useSelector((state: RootState) => state.common);

  const handleIconMoreClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setShowSetting(!showSetting);
  };

  const handleAreaEditClick = () => {
    setShowAreaManagement(true);
  };

  const handleSeedingWithinAreaClick = () => {
    setShowSeedingWithinArea(true);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleCloseModal = () => {
    setShowAreaManagement(false);
    setShowSeedingWithinArea(false);
    setShowModalDelete(false);
  };

  const customLabel = (str: string) => {
    return str.split('/')[0];
  };

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    setShowSetting(false);
  });

  const handleDeleteArea = () => {
    if (businessId && sheetActive && areas) {
      dispatch(
        deleteAreaApi({
          businessId: +businessId,
          sheetId: sheetActive,
          areaId: areas[0].area.id,
          callback: () => handleCloseModal(),
        }),
      );
    }
  };

  useEffect(() => {
    const element = document.getElementById(`menu-list-${areas[0].area.id}`);
    const rect = element?.getBoundingClientRect();
    setIsTop(
      !!(width < CONSTANTS.SIZE_MOBILE ? rect?.bottom && rect?.bottom > 650 : rect?.bottom && rect?.bottom > 500),
    );
  }, [showSetting]);

  return (
    <>
      <button className="group flex h-12 w-full justify-between py-2 pl-2 pr-3 hover:bg-gray-background">
        <div className={s.groupButton} onClick={onClick}>
          <IconArrowSelect className={c(isArrowClose && s.arrowClose)} />
          <div className="flex w-full justify-between">
            <DefaultText variant="text-link" className="text-sm text-on-surface">
              {customLabel(label ?? '')}
            </DefaultText>
          </div>
        </div>
        <div className="my-auto flex gap-1">
          <div className="my-auto flex gap-1">
            <div ref={ref} id={`menu-list-${areas[0].area.id}`} className="relative m-auto h-6 w-10">
              <div className="hidden group-hover:flex">
                <div onClick={(e) => handleIconMoreClick(e)} onKeyDown={() => {}}>
                  <IconMoreGrey />
                </div>
                <div
                  className="m-auto hidden h-6 w-6 group-hover:block"
                  onClick={handleAreaEditClick}
                  onKeyDown={() => {}}
                >
                  <IconAddCircleOutline />
                </div>
              </div>
              <div className={`mt-8 ${isTop && 'absolute top-[-215px]'}`}>
                {showSetting && (
                  <DropdownSettingChart
                    onAreaEditClick={handleAreaEditClick}
                    modalSeedingWithinAreaClick={handleSeedingWithinAreaClick}
                    modalModalDeleteClick={handleModalDeleteClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </button>
      <CommonModal
        open={showAreaManagement}
        title={'エリア管理'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[640px]'}
        buttonHidden={true}
      >
        <ModalAreaManagement handleClose={handleCloseModal} listAreas={listAreas} />
      </CommonModal>
      <CommonModal
        open={showSeedingWithinArea}
        title={'エリア内の播種'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModal}
        boxClassName={'!w-[calc(100%-64px)] xl:!w-[1145px] !max-w-[1310px]'}
        buttonHidden={true}
      >
        <ModalSeedingWithinArea handleClose={handleCloseModal} areas={areas} />
      </CommonModal>
      <CommonModal
        open={showModalDelete}
        title={'エリアの削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={handleDeleteArea}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete contentDelete={'エリアの名前を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ChartButton;
