import React from 'react';
function IconRemoveTag() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.66634 1.27398L8.72634 0.333984L4.99967 4.06065L1.27301 0.333984L0.333008 1.27398L4.05967 5.00065L0.333008 8.72732L1.27301 9.66732L4.99967 5.94065L8.72634 9.66732L9.66634 8.72732L5.93967 5.00065L9.66634 1.27398Z"
        fill="#615F50"
      />
    </svg>
  );
}

export default IconRemoveTag;
