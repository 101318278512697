import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { getOrganizationMember } from '@/stores/organization';
import { useDispatch } from 'react-redux';

function LayoutAuth() {
  const dispatch = useDispatch();
  const { organizationId = '' } = useParams();

  useEffect(() => {
    if (!organizationId) return;
    dispatch(getOrganizationMember(+organizationId));
  }, [organizationId]);
  return <Outlet />;
}

export default LayoutAuth;
