import cn from '@/utils/general';
import React from 'react';

const LabelTagTypes = ['label'];
type LabelTagTypes = {
  tag?: (typeof LabelTagTypes)[number];
  children?: string | React.ReactNode;
  size?: 'small' | 'large' | 'medium';
  tColor?: string;
  className?: string;
};

// Label in design typography style

const Label: React.FC<LabelTagTypes> = ({
  tag = 'label',
  size = 'medium',
  children,
  tColor = 'text-on-surface',
  className,
}) => {
  let fontSize = '';
  switch (size) {
    case 'small':
      fontSize = 'text-[0.6875rem] leading-[1.5em] tracking-[0.5px]';
      break;
    case 'medium':
      fontSize = 'text-xs leading-[1.5em] tracking-[0.5px]';
      break;
    case 'large':
      fontSize = 'text-sm leading-[1.5em] tracking-[0.1px]';

      break;
  }
  return (
    <>
      {React.createElement(tag, { className: cn(`${fontSize} font-normal inline-flex`, tColor, className) }, children)}
    </>
  );
};

export default Label;
