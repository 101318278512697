import { getIcon } from '@/utils/helpers';
import { Link } from 'react-router-dom';
import style from './style.module.sass';
import React from 'react';

type DropdownSettingProps = {
  modalModalDeleteClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  moveFormPath: string;
};

const DropdownSettingHouses: React.FC<DropdownSettingProps> = ({
  modalModalDeleteClick,
  moveFormPath,
}: DropdownSettingProps) => {
  return (
    <div className={style.setting_box}>
      <div className={style.setting_list_item}>
        <Link to={moveFormPath}>
          <div className={style.setting_item}>
            {getIcon('setting')}

            <span className="font-noto text-on-surface">ハウス設定</span>
          </div>
        </Link>
        <div className={style.setting_item} onClick={(e) => modalModalDeleteClick?.(e)}>
          {getIcon('trash')}
          <span className="font-noto" style={{ color: '#BA1A1A' }}>
            ハウスを削除
          </span>
        </div>
      </div>
    </div>
  );
};

export default DropdownSettingHouses;
