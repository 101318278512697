import { useDispatch, useSelector } from 'react-redux';
import { moveChartDate } from '@/stores/chart/effects';
import { chartConsts } from '../../organisms/Chart/consts';
import dayjs from 'dayjs';
import { IndexedObject } from '@/utils/types';

type TableData = {
  head: string;
  subheads: {
    value: string;
    width: number;
  }[];
};

type SubHeadProps =
  | {
      scaleUnit: 'month';
      y: number;
      sm: number;
      em: number;
    }
  | {
      scaleUnit: 'day';
      y: number;
      m: number;
    };

const createSubhead = (props: SubHeadProps) => {
  const BORDER_WIDTH = chartConsts[props.scaleUnit].border;
  const CELL_WIDTH = chartConsts[props.scaleUnit].cellWidth;
  const DAYS_IN_MONTH_UNIT = chartConsts[props.scaleUnit].splitUnit;

  const sh = [];
  let totalWidth = 0;
  switch (props.scaleUnit) {
    case 'month':
      for (let m = props.sm; m <= props.em; m++) {
        const split = dayjs(`${props.y}-${m}-01`).daysInMonth();
        const width = ((CELL_WIDTH + BORDER_WIDTH) * split) / DAYS_IN_MONTH_UNIT;
        totalWidth += width;
        sh.push({
          value: `${m}月`,
          width,
        });
      }
      break;
    case 'day':
      for (let d = 1; d <= dayjs(`${props.y}-${props.m}-01`).daysInMonth(); d++) {
        sh.push({
          value: `${d}日`,
          width: CELL_WIDTH + BORDER_WIDTH,
        });
      }
      totalWidth = CELL_WIDTH + BORDER_WIDTH;
      break;
  }
  return {
    sh,
    totalWidth,
  };
};

export const useService = (scaleUnit: 'month' | 'day', startAt: string, endAt: string) => {
  const closeGroups = useSelector((state: IndexedObject) => state.chart.closeGroups);
  // scaleUnit month
  const s = dayjs(startAt);
  const e = dayjs(endAt);

  const tableData: TableData[] = [];
  let tableWidth = 0;
  switch (scaleUnit) {
    case 'month':
      for (let y = s.year(); y <= e.year(); y++) {
        let subheads;
        if (s.year() === e.year()) {
          const { sh, totalWidth } = createSubhead({
            y,
            sm: s.month() + 1,
            em: e.month() + 1,
            scaleUnit,
          });
          subheads = sh;
          tableWidth += totalWidth;
        } else if (y === s.year()) {
          const { sh, totalWidth } = createSubhead({
            y,
            sm: s.month() + 1,
            em: 12,
            scaleUnit,
          });
          subheads = sh;
          tableWidth += totalWidth;
        } else if (y === e.year()) {
          const { sh, totalWidth } = createSubhead({
            y,
            sm: 1,
            em: e.month() + 1,
            scaleUnit,
          });
          subheads = sh;
          tableWidth += totalWidth;
        } else {
          const { sh, totalWidth } = createSubhead({
            y,
            sm: 1,
            em: 12,
            scaleUnit,
          });
          subheads = sh;
          tableWidth += totalWidth;
        }

        const d = {
          head: `${y}`,
          subheads,
        };
        tableData.push(d);
      }
      break;
    case 'day':
      for (let y = s.year(); y <= e.year(); y++) {
        let sm;
        let em;
        if (s.year() === e.year()) {
          sm = s.month() + 1;
          em = e.month() + 1;
        } else if (y === s.year()) {
          sm = s.month() + 1;
          em = 12;
        } else if (y === e.year()) {
          sm = 1;
          em = e.month() + 1;
        } else {
          sm = 1;
          em = 12;
        }
        for (let m = sm; m <= em; m++) {
          const { sh, totalWidth } = createSubhead({
            y,
            m,
            scaleUnit,
          });

          const subheads = sh;
          tableWidth += totalWidth;
          const d = {
            head: `${y}年${m}月`,
            subheads,
          };
          tableData.push(d);
        }
      }
      break;
  }

  return {
    tableData,
    tableWidth: tableWidth + 1,
    closeGroups,
  };
};

export const useDataControl = () => {
  const dispatch = useDispatch();
  const moveDate = (area: string, id: string, value: number) => {
    dispatch(moveChartDate({ area, id, value }));
  };
  return {
    moveDate,
  };
};
