export const EPath = {
  root: '/',
  chart: '/chart',
  password: '/password',
  login: '/login',
  register: '/register',
  invite: '/invite/:token',
  organization: '/organization/:organizationId',
  settingOrganization: '/setting-organization/:organizationId',
  organizationDetail: '/organization/:id',
  newQuestion: '/organization/:organizationId/questions/create',
  editQuestion: '/organization/:organizationId/questions/:id/edit',
  editQuestionAll: '/questions/:id/edit',
  questionDetail: '/organization/:organizationId/questions/:id',
  questionDetailAll: '/questions/:id',

  newFeedback: '/organization/:organizationId/feedbacks/create',
  editFeedback: '/organization/:organizationId/feedbacks/:id/edit',
  editFeedbackAll: '/feedbacks/:id/edit',
  feedbackDetail: '/organization/:organizationId/feedbacks/:id',
  feedbackDetailAll: '/feedbacks/:id',

  newDocument: '/organization/:organizationId/documents/create',
  editDocument: '/organization/:organizationId/documents/:id/edit',
  editDocumentAll: '/documents/:id/edit',
  documentDetail: '/organization/:organizationId/documents/:id',
  documentDetailAll: '/documents/:id',

  business: '/business/:id',

  // sidebar all item
  questions: '/questions',
  houses: '/houses',
  housesDetailAll: '/houses/:houseId',
  editHousesDetailAll: '/houses/:houseId/edit',
  settingHousesDetailAll: '/houses/:houseId/setting',
  houseDetailListHouse: '/organization/:organizationId/houses/:houseId/edit',
  feedbacks: '/feedbacks',
  documents: '/documents',
  account: '/account',
  notification: '/notification',
  houseDetail: '/organization/:organizationId/house/:houseId/edit',
  organizationHouse: '/organization/:organizationId/houses/:houseId',
  organizationHouseBusiness: '/organization/:organizationId/house/:houseId/business/:businessId',

  organizationQuestion: '/organization/:organizationId/questions',
  organizationDocument: '/organization/:organizationId/documents',
  organizationFeedback: '/organization/:organizationId/feedbacks',

  organizationHouseList: '/organization/:organizationId/houses',

  notFoundPage: '/404',
};
