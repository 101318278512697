import axiosInstance from '@/api/axios-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  EditDepreciationExpense,
  IBusinessDetail,
  IBusinessPlanDetail,
  IBusinessPlanList,
  IBusinessPostResponse,
  IDataUpdate,
  IDataUpdateCircle,
  IDateUpdateHeatingExpenses,
  IDateUpdateProductSales,
  IParamsAll,
} from './model';
import {
  actions,
  setCommentsList,
  setScheduleCommentsList,
  setListSheet,
  setSheetActive,
  setDataChart,
  State,
  setDataBusinessPlanResult,
  setDialogType,
  setBusinessDetail,
  setCountComment,
  setCountCommentChart,
  setTotalChartAll,
} from './slice';
import { setShowToast, setTitleHeaderDetail } from '@/stores/common';
import { ICommentCreate } from '@/types/business';
import { IFieldUpdate, TCallback, TCallbackParam } from '@/types/common';
import { messageNotification } from '@/utils/constants/messageNotification';
import { getCountNotification } from '@/stores/notification';

const postAPIBusiness = createAsyncThunk(
  'loading/business/requestStatus',
  async function (params: IBusinessDetail & TCallback, thunkAPI) {
    try {
      params.callback?.();
      const response: IBusinessPostResponse = await axiosInstance.post('business-plan', { ...params });
      thunkAPI.dispatch(actions.setBusinessPostResponse(response));
      thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
      thunkAPI.dispatch(getAPIBusinessList({ id: params.greenHouseId }));
    } catch (error) {
      console.log(error);
    }
  },
);

type TBusinessListRequest = {
  id: number;
  page?: number;
  limit?: number;
  keyword?: string;
};

const getAPIBusinessList = createAsyncThunk(
  'businessList/requestStatus',
  async function (params: TBusinessListRequest, thunkAPI) {
    const { id, page, limit, keyword } = params;
    try {
      const response: IBusinessPlanList = await axiosInstance.get(
        `green-house/${id}/business-plan?page=${page ?? 1}&limit=${limit ?? 0}&keyword=${keyword ?? ''}`,
      );
      thunkAPI.dispatch(actions.getBusinessList(response.data.items));
      thunkAPI.dispatch(getCountNotification());
    } catch (error) {
      console.log(error);
    }
  },
);

export const getCommentBusiness = createAsyncThunk('business/getComment', async (data: ICommentCreate, thunkAPI) => {
  const res = await axiosInstance.get(`/business-plan/${data.id}/sheets/${data.idSheet}/comments`);
  thunkAPI.dispatch(
    setCommentsList(
      res.data.sort(
        (a: { createdAt: string }, b: { createdAt: string }) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      ),
    ),
  );
  thunkAPI.dispatch(setCountComment(res.data.length));
});

export const getScheduleCommentBusiness = createAsyncThunk(
  'business/getScheduleComment',
  async (data: ICommentCreate, thunkAPI) => {
    const res = await axiosInstance.get(`/business-plan/${data.id}/sheets/${data.idSheet}/schedule-comments`);
    thunkAPI.dispatch(setCountCommentChart(res.data.length));
    thunkAPI.dispatch(
      setScheduleCommentsList(
        res.data.sort(
          (a: { createdAt: string }, b: { createdAt: string }) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        ),
      ),
    );
  },
);

export const getDataChart = createAsyncThunk(
  'loading/business/getDataChart',
  async (data: { id: number; idSheet: number }, thunkAPI) => {
    const res = await axiosInstance.get(`/business-plan/${data.id}/sheets/${data.idSheet}/circlePlan`);
    thunkAPI.dispatch(setDataChart(res.data));
  },
);

export const getDataChartAll = createAsyncThunk(
  'loading/business/getDataChartAll',
  async (data: IParamsAll, thunkAPI) => {
    const res = await axiosInstance.get(
      `/business-plan/list-circle-plan/${data.order}?page=${data.page}&limit=${data.limit}&organizationId=${
        data.organizationId ?? ''
      }`,
    );
    const state = thunkAPI.getState() as { business: State };
    thunkAPI.dispatch(setTotalChartAll(res.data.total));
    thunkAPI.dispatch(setDataChart([...state.business.dataChart, ...res.data.items]));
  },
);

export const getSheetBusiness = createAsyncThunk(
  'business/getSheetBusiness',
  async (data: { id: number }, thunkAPI) => {
    const res = await axiosInstance.get(`business-plan/${data.id}/sheets`);
    thunkAPI.dispatch(setListSheet(res.data.sheets));
    const state = thunkAPI.getState() as { business: State };
    if (!state.business.sheetActive) {
      thunkAPI.dispatch(setSheetActive(res.data.sheets[0].id));
    }
    thunkAPI.dispatch(setTitleHeaderDetail(res?.data.businessPlan?.title));
  },
);

export const createCommentBusiness = createAsyncThunk(
  'business/createComment',
  async (data: ICommentCreate, thunkAPI) => {
    const dataClone = {
      content: data.content,
    };
    await axiosInstance.post(`/business-plan/${data.id}/sheets/${data.idSheet}/comments`, dataClone);
    await thunkAPI.dispatch(getCommentBusiness(data));
    thunkAPI.dispatch(getCountNotification());
  },
);

export const createScheduleCommentBusiness = createAsyncThunk(
  'business/createScheduleComment',
  async (data: ICommentCreate, thunkAPI) => {
    const dataClone = {
      sheetId: data.idSheet,
      content: data.content,
    };
    await axiosInstance.post(`/business-plan/${data.id}/sheets/${data.idSheet}/schedule-comment`, dataClone);
    await thunkAPI.dispatch(getScheduleCommentBusiness(data));
    thunkAPI.dispatch(getCountNotification());
  },
);

const getListSheet = createAsyncThunk('business/getListSheet', async function (id: number, thunkAPI) {
  try {
    const response = await axiosInstance.get(`business-plan/${id}/sheets`);
    thunkAPI.dispatch(actions.setListSheet(response.data.sheets));
  } catch (error) {
    console.log(error);
  }
});

const getListBusinessPlan = createAsyncThunk(
  'business/getListBusinessPlan',
  async function (data: { id: number; sheetId: number }, thunkAPI) {
    try {
      const response = await axiosInstance.get(`business-plan/${data.id}/sheets/${data.sheetId}/list-result`);
      thunkAPI.dispatch(
        actions.setListBusinessPlan(
          response.data.sort((a: IBusinessPlanDetail, b: IBusinessPlanDetail) => {
            return new Date(a.yearMonth) < new Date(b.yearMonth) ? -1 : 1;
          }),
        ),
      );
    } catch (error) {
      console.log(error);
    }
  },
);

const updateLineChart = createAsyncThunk(
  'business/updateLineChart',
  async function (data: { sheetId: number; seedDate: string; circlePlanId: number; businessPlanId: number }, thunkAPI) {
    try {
      await axiosInstance.patch(`business-plan/${data.businessPlanId}/sheets/${data.sheetId}/masterCicles`, data);
      thunkAPI.dispatch(
        getDataChart({
          id: data.businessPlanId,
          idSheet: data.sheetId,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
);

const updateRecordBusinessPlan = createAsyncThunk(
  'business/updateRecordBusinessPlan',
  async function (data: { id: number; sheetId: number; dataUpdate: IFieldUpdate }, thunkAPI) {
    try {
      await axiosInstance.patch(
        `business-plan/${data.id}/sheets/${data.sheetId}/edit-multiple-fields`,
        data.dataUpdate,
      );
      thunkAPI.dispatch(
        getListBusinessPlan({
          id: data.id,
          sheetId: data.sheetId,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
);

const updateCircleInfoBusinessPlan = createAsyncThunk(
  'business/updateCircleInfoBusinessPlan',
  async function (
    data: { businessId: number; sheetId: number; circleId: number; dataUpdate: IDataUpdateCircle } & TCallback,
    thunkAPI,
  ) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/circlePlan/${data.circleId}`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
    data.callback?.();
  },
);

const updateCircleInfoMultipleBusinessPlan = createAsyncThunk(
  'business/updateCircleInfoMultipleBusinessPlan',
  async function (
    data: { businessId: number; sheetId: number; areaId: number; dataUpdate: IDataUpdateCircle[] } & TCallback,
    thunkAPI,
  ) {
    const dataUpdateApi = {
      items: data.dataUpdate,
    };
    await axiosInstance.patch(
      `business-plan/${data.businessId}/${data.areaId}/sheets/${data.sheetId}/circlePlan`,
      dataUpdateApi,
    );
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
    data.callback?.();
  },
);

const changeOrderCircle = createAsyncThunk(
  'business/changeOrderCircle',
  async function (data: { businessId: number; sheetId: number; idFrom: number; idTo: number }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/${data.idFrom}/move/${data.idTo}`,
    );
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
  },
);

const changeOrderArea = createAsyncThunk(
  'business/changeOrderCircle',
  async function (data: { businessId: number; sheetId: number; idFrom: number; idTo: number }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/areaId/${data.idFrom}/move/${data.idTo}`,
    );
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
  },
);

const updateNameBP = createAsyncThunk(
  'business/updateNameBP',
  async function (data: { id: number; title: string }, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.id}`, {
      title: data.title,
    });
    thunkAPI.dispatch(setTitleHeaderDetail(data.title));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
  },
);

const deleteBP = createAsyncThunk('business/deleteBP', async function (params: { id: number } & TCallback, thunkAPI) {
  await axiosInstance.delete(`business-plan/${params.id}`);
  thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
  params.callback?.();
});

const getInfoBP = createAsyncThunk('business/getInfoBP', async function (id: number, thunkAPI) {
  const res = await axiosInstance.get(`business-plan/${id}`);
  thunkAPI.dispatch(setBusinessDetail(res.data));
});

const editNameSheetApi = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; id: number; title: string }, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/sheets/${data.id}`, data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(getSheetBusiness({ id: data.businessId }));
  },
);

const copySheetApi = createAsyncThunk(
  'business/copySheetApi',
  async function (data: { businessId: number; sheetId: number }, thunkAPI) {
    await axiosInstance.post(`business-plan/${data.businessId}/copy-sheet/${data.sheetId}`);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(getSheetBusiness({ id: data.businessId }));
  },
);

const activeFavouriteSheet = createAsyncThunk(
  'business/activeFavouriteSheet',
  async function (data: { businessId: number; sheetId: number }, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/status-sheets/${data.sheetId}`);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(getSheetBusiness({ id: data.businessId }));
  },
);

const getBusinessPlanResult = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number }, thunkAPI) {
    const dataRes = await axiosInstance.get(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/business-plan-result`,
    );
    const dataAdd = {
      specificWorkContract: dataRes.data.specificWorkContract,
      workContract: dataRes.data.workContract,
      miscellaneousIncome: dataRes.data.miscellaneousIncome,
      consultingLaborCosts: dataRes.data.consultingLaborCosts,
      equipmentRepairCosts: dataRes.data.equipmentRepairCosts,
      workClothingCosts: dataRes.data.workClothingCosts,
      miscellaneousExpensesCosts: dataRes.data.miscellaneousExpensesCosts,
      promotionalCosts: dataRes.data.promotionalCosts,
      packingMaterialCosts: dataRes.data.packingMaterialCosts,
      logisticsCosts: dataRes.data.logisticsCosts,
      otherSalesCosts: dataRes.data.otherSalesCosts,
      officePersonnelCosts: dataRes.data.officePersonnelCosts,
      insuranceSupportCosts: dataRes.data.insuranceSupportCosts,
      associationFees: dataRes.data.associationFees,
      otherAdministrativeExpenses: dataRes.data.otherAdministrativeExpenses,
      toolsCost: dataRes.data.toolsCost,
    };
    const state = thunkAPI.getState() as { business: State };
    thunkAPI.dispatch(setDataBusinessPlanResult({ ...state.business.dataBusinessPlanResult, ...dataAdd }));
  },
);

const getParamValues = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; sheetId: number }, thunkAPI) {
    const dataRes = await axiosInstance.get(`business-plan/${data.businessId}/sheets/${data.sheetId}/param-values`);
    const dataAdd = {
      transplantAreaQuantity: dataRes.data.transplantAreaQuantity,
      weightFruit: dataRes.data.weightFruit,
      stem: dataRes.data.stem,
      fruitsQuantityStem: dataRes.data.fruitsQuantityStem,
      seedCostPrice: dataRes.data.seedCostPrice,
      salaryPartTime: dataRes.data.salaryPartTime,
      electricityPrice: dataRes.data.electricityPrice,
      heavyOilPrice: dataRes.data.heavyOilPrice,
      newBuildings: dataRes.data.newBuildings,
      usedBuildings: dataRes.data.usedBuildings,
      newMechanicalEquipment: dataRes.data.newMechanicalEquipment,
      usedMechanicalEquipment: dataRes.data.usedMechanicalEquipment,
      experience: dataRes.data.experience,
      transplantSellingPrice: dataRes.data.transplantSellingPrice,
      subsidyIncome: dataRes.data.subsidyIncome,
      groundRent: dataRes.data.groundRent,
      shippingFee: dataRes.data.shippingFee,
      fertilizerCost: dataRes.data.fertilizerCost,
      pesticidesHygieneCosts: dataRes.data.pesticidesHygieneCosts,
      materialCosts: dataRes.data.materialCosts,
    };
    const state = thunkAPI.getState() as { business: State };
    thunkAPI.dispatch(setDataBusinessPlanResult({ ...state.business.dataBusinessPlanResult, ...dataAdd }));
  },
);

const updateProductSales = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDateUpdateProductSales }, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/sheets/${data.sheetId}/product-sales`, data.dataUpdate);
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateSpecificWorkContract = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/specific-work-contract`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateWorkContract = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/sheets/${data.sheetId}/work-contract`, data.dataUpdate);
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateMiscellaneousIncome = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/miscellaneous-income
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateSubsidyIncome = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/subsidy-income
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateSalaryPartTime = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/salary-part-time
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateConsultingLaborCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/consulting-labor-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateEditPowerHeatingExpenses = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDateUpdateHeatingExpenses }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/edit-power-heating-expenses
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateEquipmentRepairCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/equipment-repair-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateFertilizerCost = createAsyncThunk(
  'loading//editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/fertilizer-cost
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updatePesticidesHygieneCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/pesticides-hygiene-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateToolsCost = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/tools-cost
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);
const updateMaterialCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/material-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);
const updateWorkClothingCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/work-clothing-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);
const updateMiscellaneousExpensesCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/miscellaneous-expenses-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updatePromotionalCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/promotional-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updatePackingMaterialCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/packing-material-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateLogisticsCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/logistics-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateOtherSalesCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/other-sales-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateShippingFee = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/shipping-fee
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateOfficePersonnelCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/office-personnel
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateGroundRent = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/ground-rent
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateInsuranceSupportCosts = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/insurance-support-costs
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateFruitsQuantityStem = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/edit-fruits-quantity-stem
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateAssociationFees = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/association-fees
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateOtherAdministrativeExpenses = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/other-administrative-expenses
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateSeedCostPrice = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: IDataUpdate }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/edit-seed-cost-price
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const updateEditDepreciationExpense = createAsyncThunk(
  'loading/business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; dataUpdate: EditDepreciationExpense }, thunkAPI) {
    await axiosInstance.patch(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/edit-depreciation-expense
`,
      data.dataUpdate,
    );
    thunkAPI.dispatch(getBusinessPlanResult({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getParamValues({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(getListBusinessPlan({ id: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    thunkAPI.dispatch(setDialogType(''));
  },
);

const downloadCsv = createAsyncThunk(
  'business/downloadCsv',
  async function (data: { businessId: number; sheetId: number } & TCallbackParam) {
    const dataResCsv = await axiosInstance.get(`business-plan/${data.businessId}/sheets/${data.sheetId}/export-csv`, {
      method: 'GET',
      responseType: 'blob',
    });

    data.callback(dataResCsv as unknown as Blob);
  },
);

const downloadPdf = createAsyncThunk(
  'business/downloadCsv',
  async function (data: { businessId: number; sheetId: number } & TCallbackParam) {
    const dataResPdf = await axiosInstance.get(`business-plan/${data.businessId}/sheets/${data.sheetId}/export-pdf`, {
      method: 'GET',
      responseType: 'blob',
    });
    data.callback(dataResPdf as unknown as Blob);
  },
);

export {
  postAPIBusiness,
  getAPIBusinessList,
  getListSheet,
  getListBusinessPlan,
  updateRecordBusinessPlan,
  updateLineChart,
  updateCircleInfoBusinessPlan,
  updateCircleInfoMultipleBusinessPlan,
  changeOrderCircle,
  updateNameBP,
  deleteBP,
  editNameSheetApi,
  copySheetApi,
  activeFavouriteSheet,
  getBusinessPlanResult,
  getParamValues,
  updateProductSales,
  updateSpecificWorkContract,
  updateWorkContract,
  updateMiscellaneousIncome,
  updateSubsidyIncome,
  updateSalaryPartTime,
  updateConsultingLaborCosts,
  updateEditPowerHeatingExpenses,
  updateEquipmentRepairCosts,
  updateFertilizerCost,
  updatePesticidesHygieneCosts,
  updateToolsCost,
  updateMaterialCosts,
  updateWorkClothingCosts,
  updateMiscellaneousExpensesCosts,
  updatePromotionalCosts,
  updatePackingMaterialCosts,
  updateLogisticsCosts,
  updateOtherSalesCosts,
  updateShippingFee,
  updateOfficePersonnelCosts,
  updateGroundRent,
  updateInsuranceSupportCosts,
  updateFruitsQuantityStem,
  updateAssociationFees,
  updateOtherAdministrativeExpenses,
  updateSeedCostPrice,
  updateEditDepreciationExpense,
  downloadCsv,
  getInfoBP,
  changeOrderArea,
  downloadPdf,
};
