import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { FileRes } from '@/types/common';
import { REGEX } from '@/utils/constants/regex';
import RemoveImage from '@/components/icon/RemoveImage';
import IconFile from '@/components/icon/IconFile';
import { getTypeFile } from '@/utils/helpers';
import { removeFilesComment, removeImageComment } from '@/stores/common';

const FilesComment = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<FileRes[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const { listFilesComment } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    const images = listFilesComment
      .map((file: FileRes) => file.path)
      .filter((path: string) => REGEX.imageExtensionsRegex.test(path));
    const files = listFilesComment.filter((files: FileRes) => !REGEX.imageExtensionsRegex.test(files.path));
    setFiles(files);
    setImages(images);
  }, [listFilesComment]);

  const removeImage = (path: string) => {
    setImages(images.filter((pathItem) => pathItem !== path));
    dispatch(removeImageComment(path));
  };

  const removeFiles = (id: number) => {
    setFiles(files.filter((file) => file.id !== id));
    dispatch(removeFilesComment(id));
  };

  return (
    <div className="float-right mt-5 flex w-[calc(100%-74px)] items-center gap-2 overflow-x-auto">
      <div className="flex gap-2">
        {images.map((image: string) => (
          <div className="relative w-[48px]" key={image}>
            <div className="absolute right-0 top-0" onClick={() => removeImage(image)}>
              <RemoveImage />
            </div>
            <img
              className="h-12 rounded-lg object-cover"
              width={48}
              height={48}
              src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(image)}`}
              alt=""
            />
          </div>
        ))}
      </div>
      <div className="flex gap-2">
        {files.map((file: FileRes) => (
          <div className="relative flex max-w-[200px] break-normal rounded-lg bg-white text-sm leading-6" key={file.id}>
            <div className="absolute right-0 top-0" onClick={() => removeFiles(file.id)}>
              <RemoveImage />
            </div>
            <div className="flex w-12 justify-center gap-2 rounded-lg bg-yellow-secondary px-[14px] py-4">
              <IconFile />
            </div>
            <div className="ml-2 flex w-3/5 flex-col overflow-hidden">
              <span className="truncate font-sans">{file.name}</span>
              <span className="truncate font-sans">{getTypeFile(file.name)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilesComment;
