import style from './style.module.sass';
import Label from '@/components/atoms/AText/Label';
import TextArea from '@/components/atoms/TextArea';
import { FilledButton } from '@/components/molecules/ButtonIcon';
import cn from '@/utils/general';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { deleteAccount, getInfoUser, logout, setPathAvatar, updateInfoUser, uploadAvatar } from '@/stores/auth';
import c from 'clsx';
import { REGEX } from '@/utils/constants/regex';
import { MESSAGE_REQUIRED_DEFAULT } from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import DisplayAvatar from '@/components/molecules/DisplayAvatar/DisplayAvatar';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import CommonModal from '@/components/atoms/Modal/CommonModal';

function Account() {
  const fields = {
    name: Yup.string().required(MESSAGE_REQUIRED_DEFAULT),
  };
  const validationSchema = Yup.object().shape(fields).required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: 'onBlur',
    resolver: yupResolver<FieldValues>(validationSchema),
  });
  const dispatch = useDispatch();
  const { user, pathAvatar, infoUser } = useSelector((state: RootState) => state.auth);
  const [describeValue, setDescribeValue] = useState<string>(infoUser?.describe ?? '');
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const handleUpdateUserInfo = (data: FieldValues) => {
    const formData = {
      ...data,
      describe: describeValue,
      file: [pathAvatar],
    };
    dispatch(updateInfoUser({ data: formData, id: Number(user?.id) }));
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescribeValue(event.target.value);
  };

  const handleShowConfirmDelete = () => {
    setIsShowModal(true);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
  };

  const handleDeleteAccount = () => {
    dispatch(deleteAccount({ callback: () => dispatch(logout()) }));
    setIsShowModal(false);
  };

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && REGEX.imageExtensionsRegex.test(event.target.files[0]?.name)) {
      const fileUpload: Array<File> = Array.from(event.target.files);
      dispatch(uploadAvatar([...fileUpload]));
    }
  };

  const handleDeleteAvatar = () => {
    dispatch(setPathAvatar(''));
  };

  useEffect(() => {
    dispatch(getInfoUser(Number(user?.id)));
    return () => {
      dispatch(setPathAvatar(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setPathAvatar(infoUser?.file?.[0]?.path ?? ''));
  }, [user]);

  return (
    <div className="h-[calc(100vh-150px)] overflow-y-auto lg:h-[calc(100vh-64px)]">
      <div className="m-auto max-w-[1200px] p-4 font-noto">
        <div className={style.box_account}>
          <form className={style.form_edit_account} onSubmit={handleSubmit(handleUpdateUserInfo)}>
            <div className={c(style.avatar, 'relative h-[80px] w-[80px]')}>
              <label htmlFor="file-upload" className="cursor-pointer">
                <DisplayAvatar path={pathAvatar} nameUser={user?.name ?? ''} />
              </label>
              {pathAvatar && (
                <div className="absolute right-[-8px] top-[-2px] p-1" onClick={handleDeleteAvatar} onKeyDown={() => {}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM17 15.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12L17 15.59Z"
                      fill="#9E9E9E"
                    />
                  </svg>
                </div>
              )}
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                className="hidden"
                multiple
                onChange={handleChangeAvatar}
              />
            </div>
            <div className={style.account_name}>
              <Label size="large" className="text-black">
                名前
              </Label>
              <div className="flex items-center">
                <Controller
                  name="name"
                  control={control}
                  defaultValue={infoUser?.name}
                  rules={{
                    required: MESSAGE_REQUIRED_DEFAULT,
                  }}
                  render={({ field }) => (
                    <ForwardedInput {...field} type="text" className="w-full" hasError={!!errors?.name?.message} />
                  )}
                />
              </div>
              <ErrorTooltip errorMessage={errors['name']?.message?.toString()} hasError={!!errors?.name?.message} />
            </div>
            <div className={style.business_content}>
              <Label size="large" className="text-[#1E1E1E]">
                業務内容
              </Label>
              <TextArea
                className={style.content}
                name="describe"
                value={describeValue}
                onChange={handleTextareaChange}
              />
            </div>
            <div className={style.btn_save}>
              <PrimaryButton type="submit" text="保存" variant="outlined" className="w-full md:w-auto" />
            </div>
          </form>
          <div className={style.line} style={{ border: '1px solid #E8E2D0' }}></div>

          <div className={style.text_delete_account}>アカウントの削除</div>
          <div className="text-sm">このアカウントを削除します。この操作は元に戻せません。</div>
          <div className={style.btn_delete}>
            <FilledButton
              className={cn('w-full text-white md:w-auto', style.btn_delete_account)}
              onClick={handleShowConfirmDelete}
            >
              削除
            </FilledButton>
          </div>
        </div>
      </div>
      <CommonModal
        open={isShowModal}
        title={'アカウントの削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={handleDeleteAccount}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[640px]'}
      >
        <ModalDelete contentDelete={'アカウントを削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </div>
  );
}
export default Account;
