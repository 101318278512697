import React from 'react';

type IconArrowProps = {
  className?: string;
  sortDate?: boolean;
};
export const IconArrowDropUp = (props: IconArrowProps) => {
  const { className, sortDate } = props;

  const rotateData = {
    normal: 0,
    rotate: 180,
  };

  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className} ${sortDate ? 'before-rotate-' + rotateData.normal : 'rotate' + rotateData.rotate}`}
      >
        <path d="M4.66675 9.33333L8.00008 6L11.3334 9.33333H4.66675Z" fill="#1D1C16" />
      </svg>
    </>
  );
};
