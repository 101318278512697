import React from 'react';
import AllInstructions from '@/components/pages/AllInstruction';
import { useParams } from 'react-router-dom';

function FeedbackOrganization() {
  const { organizationId } = useParams();
  return <AllInstructions isCreate={true} organizationId={organizationId ? +organizationId : 0} />;
}

export default FeedbackOrganization;
