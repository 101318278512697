export const chartConsts = {
  mileStoneSize: 24,
  arrowPadding: 2,
  month: {
    border: 1,
    cellWidth: 80,
    splitUnit: 31,
  },
  day: {
    border: 1,
    cellWidth: 28,
    splitUnit: 1,
  },
  allTime: {
    border: 1,
    cellWidth: 108,
    splitUnit: 32,
  },
};
