import PrimaryButton from '@/components/atoms/PrimaryButton';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ICircleMultiple } from '@/stores/business/model';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import { useParams } from 'react-router-dom';
import { deleteAreaApi, updateListAreas } from '@/stores/chart/services';
import { IArea } from '@/stores/chart/model';
import { formatNumberWithoutDecimals } from '@/utils/helpers';
import { DragEndEvent, DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { SortableArea } from '@/components/molecules/ModalComponent/SortableIArea';
import { changeOrderArea } from '@/stores/business/services';

type Props = {
  handleClose: () => void;
  listAreas: IArea[];
};
const ModalAreaManagement = (props: Props) => {
  const { handleClose, listAreas } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICircleMultiple>();
  const [listAreasState, setListAreasState] = useState<IArea[]>([]);
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const { houseDetail } = useSelector((state: RootState) => state.houses);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [areaIdDelete, setAreaIdDelete] = useState(0);

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleModalDeleteClick = (id: number) => {
    setShowModalDelete(true);
    setAreaIdDelete(id);
  };

  useEffect(() => {
    listAreas.forEach((item) => {
      setValue(`title-${item.id}`, item.title);
      setValue(`area-${item.id}`, String(item.area));
    });
    if (listAreas) {
      setListAreasState(listAreas);
    }
  }, [listAreas]);

  const deleteArea = () => {
    if (businessId && sheetActive) {
      setListAreasState(listAreasState.filter((item) => item.id !== areaIdDelete));
      dispatch(
        deleteAreaApi({
          businessId: +businessId,
          sheetId: sheetActive,
          areaId: areaIdDelete,
          callback: () => setShowModalDelete(false),
        }),
      );
    }
  };

  const handleUpdateArea = (data: ICircleMultiple) => {
    if (sheetActive && businessId) {
      const dataUpdate: { items: IArea[] } = {
        items: [],
      };
      listAreasState.forEach((item) => {
        const element = {
          id: item.id,
          title: data[`title-${item.id ?? item.idFake}`],
          area: +data[`area-${item.id ?? item.idFake}`],
        };
        dataUpdate.items.push(element);
      });
      dispatch(
        updateListAreas({
          businessId: +businessId,
          sheetId: sheetActive,
          dataUpdate,
          callback: () => handleClose(),
        }),
      );
    }
  };

  const addArea = () => {
    const idFake = new Date().getTime();
    setListAreasState([...listAreasState, { id: new Date().getTime(), idFake: idFake, title: '', area: 0 }]);
    setValue(`title-${idFake}`, '');
    setValue(`area-${idFake}`, String(0));
  };

  const watchAllFields = watch();

  const totalAcreage = useMemo(() => {
    let total = 0;
    listAreasState.forEach((item) => {
      total += +watchAllFields[`area-${item.id ?? item.idFake}`];
    });
    return total;
  }, [watchAllFields]);

  const dragEndEvent = (e: DragEndEvent) => {
    if (e && businessId && e.active.id && e?.over?.id && e.active.id !== e?.over?.id) {
      const areasStateCopy = [...listAreasState];
      const indexFrom = areasStateCopy.findIndex((item) => item.id == e.active.id);
      const indexTo = areasStateCopy.findIndex((item) => item.id == e?.over?.id);

      const itemMove = areasStateCopy.splice(indexFrom, 1);
      areasStateCopy.splice(indexTo, 0, ...itemMove);

      setListAreasState(areasStateCopy);
      dispatch(
        changeOrderArea({
          businessId: +businessId,
          sheetId: sheetActive,
          idFrom: +e.active.id,
          idTo: +e.over.id,
        }),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateArea)}>
      <div className="py-6">
        <div className="overflow-x-auto">
          <div className="flex px-8 pb-4">
            <DefaultText variant="length" className="min-w-[390px] text-sm text-on-surface">
              名前
            </DefaultText>
            <DefaultText variant="length" className="min-w-[100px] text-sm text-on-surface">
              面積
            </DefaultText>
          </div>
          <DndContext onDragEnd={dragEndEvent}>
            <SortableContext items={listAreas}>
              {listAreasState.map((item: IArea) => (
                <SortableArea
                  key={item.id}
                  id={String(item.id)}
                  control={control}
                  errors={errors}
                  handleModalDeleteClick={handleModalDeleteClick}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
        <div className="flex justify-between pt-4">
          <PrimaryButton
            text={'エリア追加'}
            variant={'outlined'}
            type="button"
            className="px-4 !text-xs lg:px-6 lg:!text-sm "
            onClick={addArea}
          />
          <div className="text-sm lg:pr-8">
            <div className="flex justify-end gap-0.5">
              <DefaultText variant="length" className="text-on-surface">
                エリア面積合計:
              </DefaultText>
              <DefaultText variant="length" className="text-error lg:min-w-[80px]">
                {formatNumberWithoutDecimals(totalAcreage)}
              </DefaultText>
            </div>
            <div className="flex justify-end gap-0.5">
              <DefaultText variant="length" className="text-on-surface">
                ハウス面積:
              </DefaultText>
              <DefaultText variant="length" className="text-on-surface lg:min-w-[80px]">
                {houseDetail?.area ? formatNumberWithoutDecimals(+houseDetail?.area) : 0}
              </DefaultText>
            </div>
          </div>
        </div>

        <CommonModal
          open={showModalDelete}
          title={'エリアの削除'}
          textBtnCancel={'キャンセル'}
          textBtnSubmit={'削除'}
          buttonVariant={'delete'}
          handleClose={handleCloseModal}
          handleClick={deleteArea}
          boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        >
          <ModalDelete contentDelete={'エリアの名前を削除します。この操作は元に戻せません。'} />
        </CommonModal>

        <div className="mt-5 flex justify-end gap-2">
          <div
            className="my-auto cursor-pointer rounded-xl px-3 pb-[10px] pt-[9px] hover:bg-black/[0.08]"
            onClick={handleClose}
            onKeyDown={() => {}}
          >
            <DefaultText variant="length" className="text-sm text-on-surface">
              キャンセル
            </DefaultText>
          </div>
          <PrimaryButton text="保存" variant="outlined" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default ModalAreaManagement;
