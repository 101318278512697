import React from 'react';
import IconFile from '@/components/icon/IconFile';
import IconRemove from '@/components/icon/IconRemove';
import { FileRes } from '@/types/common';
import { downloadFile, getTypeFile } from '@/utils/helpers';
import { useDispatch } from 'react-redux';
import { setIsLoading, setShowToast } from '@/stores/common';

type ChipProps = {
  files: File[] | FileRes[];
  removeFile?: (index: number) => void;
  isRemove?: boolean;
  className?: string;
  classNameWrap?: string;
};

const DisplayFiles = ({ files, removeFile, isRemove = true, className, classNameWrap }: ChipProps) => {
  const dispatch = useDispatch();

  const downloadFileDisplay = async (file: FileRes) => {
    dispatch(setIsLoading(true));
    let images;
    try {
      images = await downloadFile(file.path);
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(setShowToast({ message: 'ファイルのダウンロードに失敗しました', type: 'error' }));
    }

    if (images) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(images);
      link.download = file.name;

      document.body.appendChild(link);
      link.click();
      dispatch(setIsLoading(false));
    }
  };

  return (
    <div className={`mt-4 flex cursor-pointer flex-col gap-2 ${classNameWrap ?? ''}`}>
      {files.map((file, index) => {
        return (
          <div
            className={`flex h-16 items-center gap-2 rounded-2xl border border-outline-variant p-2 ${className}`}
            key={file.name}
          >
            <div
              className="flex w-12 rounded-lg bg-yellow-secondary px-[14px] py-4"
              onClick={() => {
                if (Array.isArray(files) && files.length > 0 && 'path' in file) {
                  downloadFileDisplay(file);
                }
              }}
              onKeyDown={() => {}}
            >
              <IconFile />
            </div>
            <div
              className="flex min-w-[30px] grow flex-col"
              onClick={() => {
                if (Array.isArray(files) && files.length > 0 && 'path' in file) {
                  downloadFileDisplay(file);
                }
              }}
              onKeyDown={() => {}}
            >
              <span className="truncate text-sm font-medium leading-6 text-on-surface">{file?.name}</span>
              <span className="text-xs font-medium leading-6 text-on-surface-variant">{getTypeFile(file.name)}</span>
            </div>
            {isRemove && (
              <div
                className="mr-[17px] w-[50px] p-5 pr-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeFile?.(index);
                }}
                onKeyDown={() => {}}
              >
                <IconRemove />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DisplayFiles;
