import React from 'react';

type Props = {
  className?: string;
};

const IconNotify: React.FC<Props> = ({ className }: Props) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M28 37.75C29.1 37.75 30 36.85 30 35.75H26C26 36.85 26.9 37.75 28 37.75ZM34 31.75V26.75C34 23.68 32.37 21.11 29.5 20.43V19.75C29.5 18.92 28.83 18.25 28 18.25C27.17 18.25 26.5 18.92 26.5 19.75V20.43C23.64 21.11 22 23.67 22 26.75V31.75L20 33.75V34.75H36V33.75L34 31.75ZM32 32.75H24V26.75C24 24.27 25.51 22.25 28 22.25C30.49 22.25 32 24.27 32 26.75V32.75Z"
          fill="#615F50"
        />
      </g>
      <defs>
        <clipPath id="clip0_1364_7675">
          <rect width="56" height="56" rx="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconNotify;
