import React, { useEffect, useState } from 'react';
import GuideComponent from './GuideComponent';
import HouseComponent from './HouseComponent';
import IiteratureComponent from './IiteratureComponent';
import QuestionComponent from './QuestionComponent';
import { nanoid } from 'nanoid';
import { getDetailOrganization, setTitleHeader } from '@/stores/common';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';

function OrganizationTopPage() {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const [activeTab, setActiveTab] = useState('house');
  const { titleHeader } = useSelector((state: RootState) => state.common);
  const handleTabClick = (tabId: React.SetStateAction<string>) => {
    setActiveTab(tabId);
  };
  const nameTags = [
    {
      name: 'ハウス1',
      value: 'house',
    },
    {
      name: '質問',
      value: 'question',
    },
    {
      name: '指導',
      value: 'guide',
    },
    {
      name: '文献',
      value: 'literature',
    },
  ];
  const componentsData = [
    { id: 1, component: <HouseComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} /> },
    { id: 2, component: <QuestionComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} /> },
    { id: 3, component: <GuideComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} /> },
    { id: 4, component: <IiteratureComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} /> },
  ];

  useEffect(() => {
    if (organizationId) {
      dispatch(setTitleHeader(''));
      dispatch(getDetailOrganization(+organizationId));
    }
  }, [organizationId]);

  return (
    <>
      <div className="h-[48px] lg:hidden">
        <ul
          className="flex h-[48px] justify-center border-b border-outline-variant bg-on-error"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          {nameTags.map((i) => (
            <li
              key={nanoid()}
              className={`mr-2 ${activeTab === i.value ? 'rounded-t-lg border-b-[3px] border-primary' : ''}`}
              role="presentation"
            >
              <button
                className={`inline-block ${
                  activeTab === i.value ? 'text-on-surface' : 'text-on-surface-variant'
                } rounded-t-lg px-4 text-center text-sm font-medium ${activeTab === i.value ? 'active' : ''}`}
                onClick={() => handleTabClick(i.value)}
                role="tab"
                aria-controls={i.value}
                aria-selected={activeTab === i.value}
              >
                {i.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="overflow-scroll-y bg-surface-at-1 lg:h-[calc(100vh-64px)] lg:p-[16px]">
        {/* PC */}
        <div className="hidden w-fit rounded-2xl bg-on-error p-[16px] md:w-full lg:block">
          {componentsData.map((data, index) => (
            <div
              key={data.id}
              className={`w-fit rounded-lg bg-on-error p-[16px] md:w-full lg:border lg:border-outline-variant ${
                index !== 0 ? 'mt-[24px]' : ''
              }`}
            >
              {data.component}
            </div>
          ))}
        </div>
        {/* Mobile */}
        <div className="max-w-2xl lg:hidden">
          <div id="myTabContent">
            {nameTags.map((tag) => (
              <div
                key={tag.value}
                className={`w-fit rounded-lg p-[8px] ${activeTab === tag.value ? '' : 'hidden'}`}
                id={tag.value}
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="w-fit rounded-lg bg-on-error p-[16px]">
                  {tag.value === 'house' && (
                    <HouseComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} />
                  )}
                  {tag.value === 'question' && (
                    <QuestionComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} />
                  )}
                  {tag.value === 'guide' && (
                    <GuideComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} />
                  )}
                  {tag.value === 'literature' && (
                    <IiteratureComponent organizationId={organizationId ?? ''} titleHeader={titleHeader} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganizationTopPage;
