import IconCloseRounded from '@/components/icon/IconCloseRounded';
import { useService } from './service';
import { useOutsideClick } from '@/hooks/useClickOutside';
import React, { useRef } from 'react';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { IconCloseHover } from '@/components/icon/IconClose/IconCloseHover';

type SearchBarProps = {
  handleEnter?: (value: string) => void;
  handleClear?: () => void;
  setOpenInputSearchMobile: (open: boolean) => void;
  openInputSearchMobile: boolean;
};

const SearchBar = ({ handleEnter, handleClear, setOpenInputSearchMobile, openInputSearchMobile }: SearchBarProps) => {
  const { searchKey, handleChange, handleClickOutside, setSearchKey } = useService();
  const ref = useRef<HTMLInputElement>(null);

  useOutsideClick(ref, handleClickOutside);

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleEnter?.(searchKey);
      setOpenInputSearchMobile(!openInputSearchMobile);
    }
  };

  const handleSetOpenInputSearchMobile = () => {
    setOpenInputSearchMobile(!openInputSearchMobile);
  };

  const handleClearFilter = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    setOpenInputSearchMobile(false);
    setSearchKey('');
    handleEnter?.('');
    handleClear?.();
  };

  const handleSearchMobile = () => {
    handleEnter?.(searchKey);
    setOpenInputSearchMobile(!openInputSearchMobile);
  };

  return (
    <>
      <div className="hidden items-center lg:inline-flex">
        <span className="z-10 -mr-12 p-3">
          <i className="icon-search flex h-6 w-6 bg-on-surface-variant" />
        </span>
        <div className="relative">
          <input
            type="text"
            onChange={handleChange}
            placeholder="検索"
            onKeyDown={(e) => onEnter(e)}
            value={searchKey}
            ref={ref}
            className="h-12 w-full rounded border border-on-surface-variant px-10 py-1 text-base leading-[1.5em] text-on-surface placeholder:text-base placeholder:leading-[1.5em] focus:border-on-surface focus:outline-none focus:ring-1 focus:ring-on-surface focus-visible:outline-none xl:w-[465px] "
          />
        </div>
        <button
          type="button"
          onClick={() => handleClearFilter()}
          className="z-10 -ml-12 p-3 transition-all duration-150 hover:bg-on-surface/[0.08] active:bg-on-surface/[0.12]"
        >
          <IconCloseRounded />
        </button>
      </div>

      {openInputSearchMobile ? (
        <div className="flex w-full items-center">
          <span className="z-10 -mr-12 p-3 lg:hidden">
            <i className="icon-search flex h-6 w-6 bg-on-surface-variant" />
          </span>
          <div className="relative grow lg:hidden">
            <input
              type="text"
              onChange={handleChange}
              placeholder="検索"
              onKeyDown={(e) => onEnter(e)}
              value={searchKey}
              ref={ref}
              className="h-12 w-full rounded border border-on-surface-variant px-10 py-1 text-base leading-[1.5em] text-on-surface placeholder:text-base placeholder:leading-[1.5em] focus:border-on-surface focus:outline-none focus:ring-1 focus:ring-on-surface focus-visible:outline-none xl:w-[465px] "
            />
          </div>
          <button
            type="button"
            onClick={() => handleClearFilter()}
            className="z-10 -ml-12 p-3 transition-all duration-150 hover:bg-on-surface/[0.08] active:bg-on-surface/[0.12] lg:hidden"
          >
            <IconCloseRounded />
          </button>
          <button className="w-[100px] font-noto text-sm font-medium lg:hidden" onClick={handleSearchMobile}>
            検索
          </button>
        </div>
      ) : (
        <div
          className={`my-auto flex h-10 gap-2 rounded-[12px] transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in ${
            searchKey && 'border border-br-gray bg-primary2 px-4'
          } lg:hidden`}
        >
          <PrimaryButton
            variant="outlined"
            text="検索"
            iconLeft="icon-button-search"
            className={`icon-button ${searchKey && 'hover:bg-red border-0 px-0'} rounded-[12px] text-on-primary`}
            onClick={handleSetOpenInputSearchMobile}
          />
          {searchKey && (
            <div className="my-auto" onClick={(e) => handleClearFilter(e)} onKeyDown={() => {}}>
              <IconCloseHover />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchBar;
