import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, DragMoveEvent, useDroppable } from '@dnd-kit/core';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chartConsts } from '../../organisms/Chart/consts';
import { editChart } from '@/stores/chart/effects';
import type { Chart, EditType } from '@/stores/chart/model';
import { updateLineChart } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';

export const useService = (
  startAt: string,
  endAt: string,
  area: string,
  chart: Chart,
  scaleUnit: 'month' | 'day',
  handleMove: (area: string, id: string, value: number) => void,
) => {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const BORDER_WIDTH = chartConsts[scaleUnit].border;
  const CELL_WIDTH = chartConsts[scaleUnit].cellWidth;
  const DAYS_IN_MONTH_UNIT = chartConsts[scaleUnit].splitUnit;

  const [moveDate, setMoveDate] = useState<string>();
  // 月中の日付が来ても最初の月もフルで表示する
  const s = dayjs(startAt);
  const m1 = dayjs(chart.sowing);
  const m2 = dayjs(chart.planting);
  const ts = dayjs(chart.harvest.start);
  const te = dayjs(chart.harvest.end);

  const base = createPosition(m1, s, scaleUnit) + 1;

  const p1 = 0;
  const p2 = createPosition(m2, m1, scaleUnit);
  const p3s = createPosition(ts, m1, scaleUnit);
  const p3e = createPosition(te, m1, scaleUnit);

  const d1 = m2.diff(m1, 'days');
  const d2 = ts.diff(m2, 'days');
  const d3 = te.diff(ts, 'days');

  const handleDragMove = (e: DragMoveEvent) => {
    const cellUnit = CELL_WIDTH + BORDER_WIDTH;
    const moving = Math.round(e.delta.x / (cellUnit / DAYS_IN_MONTH_UNIT));
    setMoveDate(m1.add(moving, 'days').format('YYYY/MM/DD'));
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const cellUnit = CELL_WIDTH + BORDER_WIDTH;
    const moving = Math.round(e.delta.x / (cellUnit / DAYS_IN_MONTH_UNIT));
    handleMove(area, chart.id, moving);
    const dateUpdate = m1.add(moving, 'days').format('YYYY/MM/DD');
    if (businessId) {
      dispatch(
        updateLineChart({
          sheetId: sheetActive,
          seedDate: dateUpdate,
          circlePlanId: +e.active.id,
          businessPlanId: +businessId,
        }),
      );
    }
    setMoveDate(undefined);
  };

  const p = {
    base,
    p1,
    p2,
    p3s,
    p3e,
    d1,
    d2,
    d3,
  };

  const edit = useEdit(area, chart.id);

  return {
    p,
    moveDate,
    handleDragMove,
    handleDragEnd,
    ...edit,
  };
};

function createPosition(d: Dayjs, s: Dayjs, scaleUnit: 'month' | 'day') {
  const BORDER_WIDTH = chartConsts[scaleUnit].border;
  const CELL_WIDTH = chartConsts[scaleUnit].cellWidth;
  const DAYS_IN_MONTH_UNIT = chartConsts[scaleUnit].splitUnit;

  return (d.diff(s, 'days') * (CELL_WIDTH + BORDER_WIDTH)) / DAYS_IN_MONTH_UNIT;
}

export function useChartDroppable(id: string) {
  const { setNodeRef } = useDroppable({
    id: id ?? 'droppable',
  });
  return {
    DndContext,
    setNodeRef,
  };
}

function useEdit(area: string, id: string) {
  const dispatch = useDispatch();
  const rootRef = useRef<HTMLDivElement>(null);
  const [rootY, setRootY] = useState<number>(0);
  const editRef1 = useRef<HTMLInputElement>(null);
  const editRef2 = useRef<HTMLInputElement>(null);
  const [editType, setEditType] = useState<EditType>();

  const handleEditOpen = (type: EditType) => {
    setEditType(type);
    if (rootRef.current?.offsetTop) {
      setRootY(rootRef.current.offsetTop);
    }
  };
  const handleEditClose = () => {
    setEditType(undefined);
  };

  const handleEdit = () => {
    if (editType === 'harvest') {
      if (editRef1.current?.value && editRef2.current?.value) {
        dispatch(
          editChart({
            area,
            id,
            type: editType,
            date: [editRef1.current?.value, editRef2.current?.value],
          }),
        );

        setEditType(undefined);
      }
    } else if (editType === 'planting' || editType === 'sowing') {
      if (editRef1.current?.value) {
        dispatch(
          editChart({
            area,
            id,
            type: editType,
            date: [editRef1.current?.value],
          }),
        );

        setEditType(undefined);
      }
    }
  };

  return {
    rootRef,
    rootY,
    editRef1,
    editRef2,
    editType,
    handleEditOpen,
    handleEditClose,
    handleEdit,
  };
}
