import { useRef, useState } from 'react';

type ScaleUnit = 'day' | 'month';

type ScaleUnit2 = 'true' | 'false';

export const useService = () => {
  const scaleRef = useRef<HTMLSelectElement>(null);
  const [scale, setScale] = useState<ScaleUnit>('month');

  const areaGroupingRef = useRef<HTMLSelectElement>(null);
  const [isAreaGrouping, setAreaGrouping] = useState('true');

  const handleScaleChange = () => {
    if (!scaleRef.current?.value) return;
    setScale(scaleRef.current?.value as ScaleUnit);
  };

  const handleAreaGroupingChange = () => {
    if (!areaGroupingRef?.current?.value) return;
    setAreaGrouping(areaGroupingRef.current?.value as ScaleUnit2);
  };

  return {
    scaleRef,
    scale,
    handleScaleChange,
    areaGroupingRef,
    isAreaGrouping,
    handleAreaGroupingChange,
  };
};
