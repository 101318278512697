import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import IconMoreGrey from '@/components/icon/IconMore/IconMoreGrey';
import c from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../../atoms/Input';
import DropdownSettingChartNone from '../Dropdown/DropdownSettingChart/DropdownSettingChartNone';
import ModalDelete from '../ModalComponent/ModalDelete';
import ModalSowingChart from '../ModalComponent/ModalSowingChart';
import { useService } from './service';
import s from './style.module.sass';
import { Chart } from '@/stores/chart/model';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCirclePlan } from '@/stores/chart/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import { CONSTANTS } from '@/utils/constants/consts';

type Props = {
  className?: string;
  area: string;
  id: string;
  label: string;
  areaName?: string;
  circleDate: Chart;
};

const ChartNameCellNone: React.FC<Props> = (props) => {
  const { className, area, id, label, areaName, circleDate } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const { sheetActive } = useSelector((state: RootState) => state.business);
  const { width } = useSelector((state: RootState) => state.common);
  const refDiv = useRef<HTMLDivElement>(null);
  const { isEdit, inputRef, handleSubmit } = useService(area, id);
  const [hoveredGroup, setHoveredGroup] = useState<boolean>(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showAreaManagement, setShowAreaManagement] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isTop, setIsTop] = useState<boolean>(false);

  const handleIconMoreClick = () => {
    setShowSetting(!showSetting);
  };

  const handleAreaEditClick = () => {
    setShowAreaManagement(true);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleCloseModal = () => {
    setShowAreaManagement(false);
    setShowModalDelete(false);
  };

  useOutsideClick(refDiv, () => {
    setShowSetting(false);
  });

  useEffect(() => {
    const element = document.getElementById(`id-${id}`);
    const rect = element?.getBoundingClientRect();
    setIsTop(
      !!(width < CONSTANTS.SIZE_MOBILE ? rect?.bottom && rect?.bottom > 650 : rect?.bottom && rect?.bottom > 500),
    );
  }, [showSetting]);

  const handleDeleteCircle = () => {
    if (sheetActive && businessId) {
      dispatch(
        deleteCirclePlan({
          businessId: +businessId,
          sheetId: sheetActive,
          circlePlanId: +circleDate.id,
          callback: () => setShowModalDelete(false),
        }),
      );
    }
  };

  return (
    <>
      <div
        className={`${c(s.root, className)} my-auto h-12 w-full`}
        onMouseEnter={() => setHoveredGroup(true)}
        onMouseLeave={() => setHoveredGroup(false)}
        ref={refDiv}
      >
        {isEdit ? (
          <form onSubmit={handleSubmit}>
            <Input ref={inputRef} />
          </form>
        ) : (
          <>
            <div className="">
              <DefaultText variant="breadcrumbs" className={`text-xs text-on-surface-variant ${s.areaName}`}>
                {areaName}
              </DefaultText>
              <DefaultText variant="text-link" className={`text-sm text-on-surface ${s.label}`}>
                {label}
              </DefaultText>
            </div>
            <div
              id={`id-${id}`}
              className="relative my-auto h-6 w-6"
              onClick={handleIconMoreClick}
              onKeyDown={() => {}}
            >
              {hoveredGroup && (
                <div className="pr-0.5" onClick={handleIconMoreClick}>
                  <IconMoreGrey />
                </div>
              )}
              <div className={`mt-8 ${isTop && 'absolute top-[-172px]'}`}>
                {showSetting && (
                  <DropdownSettingChartNone
                    onAreaEditClick={handleAreaEditClick}
                    modalModalDeleteClick={handleModalDeleteClick}
                  />
                )}{' '}
              </div>
            </div>
          </>
        )}
      </div>
      <CommonModal
        breadcrumb="エリアA"
        open={showAreaManagement}
        title={'播種'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModal}
        handleClick={() => {}}
        boxClassName={'!w-[calc(100vw-64px)] !max-w-[640px]'}
        buttonHidden={true}
      >
        <ModalSowingChart circleData={circleDate} handleClose={handleCloseModal} />
      </CommonModal>
      <CommonModal
        open={showModalDelete}
        title={'播種の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={handleDeleteCircle}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete contentDelete={'播種の名前を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ChartNameCellNone;
