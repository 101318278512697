import axiosInstance from '@/api/axios-client';
import { FilterHouses } from '@/types/houses';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeedbackState, actions } from './slice';

const getAPIFeedbackList = createAsyncThunk('feedback/requestStatus', async function (params: FilterHouses, thunkAPI) {
  const response = await axiosInstance.post('feedback/list', params);
  const { feedback } = thunkAPI.getState() as { feedback: FeedbackState };
  const feedbackClone = {
    ...response.data,
    items: response.data.page === 1 ? [...response.data.items] : [...feedback.feedbacks, ...response.data.items],
  };
  thunkAPI.dispatch(actions.saveFeedbacks(feedbackClone));
});

export { getAPIFeedbackList };
