import React from 'react';
type IconCloseProps = {
  width?: string;
  height?: string;
};
export const IconClose = (props: IconCloseProps) => {
  const { width = '18px', height = '18px' } = props;
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.25 4.80799L13.1925 3.75049L9 7.94299L4.8075 3.75049L3.75 4.80799L7.9425 9.00049L3.75 13.193L4.8075 14.2505L9 10.058L13.1925 14.2505L14.25 13.193L10.0575 9.00049L14.25 4.80799Z"
          fill="#615F50"
        />
      </svg>
    </>
  );
};
