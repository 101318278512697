import React from 'react';

const HeadlineTagTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const LabelTagTypes = ['label'];
const BodyTagTypes = ['a'];

type HeadlineTagTypes = {
  tag: (typeof HeadlineTagTypes)[number];
  content?: string;
  size?: 'small' | 'large' | 'medium';
  tColor?: string;
  className?: string;
};

type LabelTagTypes = {
  tag?: (typeof LabelTagTypes)[number];
  content?: string;
  size?: 'small' | 'large' | 'medium';
  tColor?: string;
  className?: string;
};
type BodyTagTypes = {
  tag: (typeof LabelTagTypes)[number];
  content?: string;
  size?: 'small' | 'large' | 'medium';
  tColor?: string;
  className?: string;
};

export const HeadLine: React.FC<HeadlineTagTypes> = ({
  tag = 'h3',
  size = 'medium',
  content,
  tColor = 'text-black',
  className,
}) => {
  let fontSize = '';
  switch (size) {
    case 'small':
      fontSize = 'text-lg leading-[1.5em]';
      break;
    case 'medium':
      fontSize = 'text-2xl leading-[1.5em]';

      break;
    case 'large':
      fontSize = 'text-[2rem] leading-[1.5em]';

      break;
  }
  return (
    <>{React.createElement(tag, { className: `${fontSize} font-normal text-black ${tColor} ${className}` }, content)}</>
  );
};

export const Label: React.FC<LabelTagTypes> = ({
  tag = 'label',
  size = 'medium',
  content,
  tColor = 'text-black',
  className,
}) => {
  let fontSize = '';
  switch (size) {
    case 'small':
      fontSize = 'text-[0.6875rem] leading-[1.5em] tracking-[0.5px]';
      break;
    case 'medium':
      fontSize = 'text-xs leading-[1.5em] tracking-[0.5px]';

      break;
    case 'large':
      fontSize = 'text-sm leading-[1.5em] tracking-[0.1px]';

      break;
  }
  return (
    <>
      {React.createElement(tag, { className: `${fontSize} ${tColor} font-normal inline-flex ${className}` }, content)}
    </>
  );
};

export const AText: React.FC<BodyTagTypes> = ({ tag = 'label', size = 'medium', content, tColor, className }) => {
  let fontSize = '';
  switch (size) {
    case 'small':
      fontSize = 'text-xs leading-[1.75em] tracking-[0.4px]';
      break;
    case 'medium':
      fontSize = 'text-sm leading-[1.75em] tracking-[0.25px]';
      break;
    case 'large':
      fontSize = 'text-base leading-[1.75em] tracking-[0.5px]';

      break;
  }
  return (
    <>
      {React.createElement(tag, { className: `${fontSize} ${tColor} font-normal inline-flex ${className}` }, content)}
    </>
  );
};
