import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import { TNotification, TParams } from '@/types/notification';
import { TCallback } from '@/types/common';

export interface QuestionState {
  listNotification: TNotification[];
  countNotification: number | null;
  yearNotification: string[];
}

const initialState: QuestionState = {
  listNotification: [],
  countNotification: null,
  yearNotification: [],
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setListNotification: (state, action) => {
      state.listNotification = action.payload;
    },
    setCountNotification: (state, action) => {
      state.countNotification = action.payload;
    },
    setYearNotification: (state, action) => {
      state.yearNotification = action.payload;
    },
  },
});

export const getNotification = createAsyncThunk('tag/getNotification', async (params: TParams, thunkAPI) => {
  const response = await axiosInstance.post('notifications', params);
  thunkAPI.dispatch(setListNotification(response.data));
});

export const getCountNotification = createAsyncThunk('tag/getCountNotification', async (_, thunkAPI) => {
  const response = await axiosInstance.get('notifications');
  thunkAPI.dispatch(setCountNotification(response.data));
});

export const getYearNotification = createAsyncThunk('tag/getYearNotification', async (_, thunkAPI) => {
  const response = await axiosInstance.get('notifications/years');
  thunkAPI.dispatch(setYearNotification(response.data));
});

export const readNotification = createAsyncThunk('tag/readNotification', async (id: number, thunkAPI) => {
  await axiosInstance.patch(`notifications/${id}`);
  thunkAPI.dispatch(getCountNotification());
});

export const readAllNotification = createAsyncThunk(
  'tag/readAllNotification',
  async (params: { userId: number } & TCallback, thunkAPI) => {
    await axiosInstance.patch(`notifications/read-all/${params.userId}`);
    thunkAPI.dispatch(getCountNotification());
    params.callback?.();
  },
);

export const { setListNotification, setCountNotification, setYearNotification } = questionSlice.actions;
export default questionSlice.reducer;
