import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import { actions } from '@/stores/chart/slice';
import { IArea } from '@/stores/chart/model';
import { setShowToast } from '@/stores/common';
import { messageNotification } from '@/utils/constants/messageNotification';
import { TCallback } from '@/types/common';
import { getDataChart, getSheetBusiness } from '@/stores/business/services';
import { setSheetActive, State } from '@/stores/business/slice';

const getListAreas = createAsyncThunk(
  'business/getListAreas',
  async function (data: { businessId: number; sheetId: number }, thunkAPI) {
    const dataRes = await axiosInstance.get(`business-plan/${data.businessId}/sheets/${data.sheetId}/areas`);
    const dataList = dataRes.data.map((item: IArea) => ({
      id: item.id,
      title: item.title,
      area: item.area,
    }));
    thunkAPI.dispatch(actions.setListAreas(dataList));
  },
);

const updateListAreas = createAsyncThunk(
  'business/updateListAreas',
  async function (data: { businessId: number; sheetId: number; dataUpdate: { items: IArea[] } } & TCallback, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/sheets/${data.sheetId}/area`, data.dataUpdate);
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
    thunkAPI.dispatch(getListAreas({ businessId: data.businessId, sheetId: data.sheetId }));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    data.callback?.();
  },
);

const deleteCirclePlan = createAsyncThunk(
  'business/deleteCirclePlan',
  async function (data: { businessId: number; sheetId: number; circlePlanId: number } & TCallback, thunkAPI) {
    await axiosInstance.delete(
      `business-plan/${data.businessId}/sheets/${data.sheetId}/circlePlan/${data.circlePlanId}`,
    );
    thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
    data.callback?.();
  },
);

const deleteAreaApi = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; sheetId: number; areaId: number } & TCallback, thunkAPI) {
    await axiosInstance.delete(`business-plan/${data.businessId}/sheets/${data.sheetId}/area/${data.areaId}`);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
    thunkAPI.dispatch(getDataChart({ id: data.businessId, idSheet: data.sheetId }));
    thunkAPI.dispatch(getListAreas({ businessId: data.businessId, sheetId: data.sheetId }));
    data.callback?.();
  },
);

const deleteSheetApi = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; sheetId: number } & TCallback, thunkAPI) {
    await axiosInstance.delete(`business-plan/${data.businessId}/sheets/${data.sheetId}`);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
    const state = thunkAPI.getState() as { business: State };
    if (data.sheetId === state.business.sheetActive) await thunkAPI.dispatch(setSheetActive(0));
    thunkAPI.dispatch(getSheetBusiness({ id: data.businessId }));
    data.callback?.();
  },
);

const reCalculatorApi = createAsyncThunk(
  'business/editNameSheet',
  async function (data: { businessId: number; sheetId: number } & TCallback, thunkAPI) {
    await axiosInstance.patch(`business-plan/${data.businessId}/sheets/${data.sheetId}/recalculator-business-plan`);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.success, type: 'success' }));
    data.callback?.();
  },
);

export { getListAreas, updateListAreas, deleteAreaApi, deleteCirclePlan, deleteSheetApi, reCalculatorApi };
