import React, { useEffect } from 'react';
import { EPath } from '@/route/route';
import { Link } from 'react-router-dom';
import DefaultText from '@/components/atoms/Text/DefaultText';
import style from '@/layout/sidebar/style.module.sass';
import { IconCloseWhite } from '@/components/icon/IconClose/IconCloseWhite';
import { useService } from '@/layout/sidebar/service';
import MenuItem from '@/layout/sidebar/MenuItem';
import IconPlus from '@/components/icon/IconPlus';
import IconNotify from '@/components/icon/IconNotify';
import IconAccount from '@/components/icon/IconAccount';
import DropdownAccount from '@/components/molecules/Dropdown/DropdownAccount';
import { Controller, useForm } from 'react-hook-form';
import { MESSAGE_REQUIRED_ORGANIZATION_NAME } from '@/utils/constants/messageValidate';
import ForwardedInput from '@/components/atoms/Input';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { IFormCreateOrganization } from '@/types/organization';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationAsync } from '@/stores/organization';
import { RootState } from '@/stores';
import { replacePathParams } from '@/utils/helpers';
import { TMenuItem } from '@/types/common';
import { setDataSideBar } from '@/stores/common';
import { SIDE_MENU_DATA, TITLE_WEB } from '@/utils/constants/consts';
import packageJson from '../../../package.json';
import { getCountNotification } from '@/stores/notification';

const organizationInit: TMenuItem = {
  isOpen: false,
  title: 'ハウス',
  path: EPath.organization,
  subItems: [],
};

type SidebarMenuProps = {
  narrowSidebar: boolean;
  sidebarSp: boolean;
  setNarrowSidebar: (narrow: boolean) => void;
  showSidebarSpEvent: () => void;
};

const SidebarMenu = (props: SidebarMenuProps) => {
  const { narrowSidebar, setNarrowSidebar, sidebarSp, showSidebarSpEvent } = props;
  const dispatch = useDispatch();
  const {
    open,
    onOpenModal,
    onCloseModal,
    showSettingBox,
    ref,
    showSetting,
    handleSubmitForm,
    dataSideBar,
    toggleMenu,
    isPathActive,
    loadOpenStatusRecursive,
  } = useService();
  const { listOrganization } = useSelector((state: RootState) => state.organization);
  const { countNotification } = useSelector((state: RootState) => state.notification);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormCreateOrganization>();

  useEffect(() => {
    dispatch(getCountNotification());
  }, []);

  useEffect(() => {
    if (listOrganization.length > 0) {
      const data: TMenuItem[] = [];
      listOrganization?.forEach((item) => {
        const subItemAdd: TMenuItem[] = [
          {
            isOpen: false,
            title: 'ハウス',
            path: replacePathParams(EPath.organizationHouseList, { organizationId: item.id }),
            subItems: [],
          },
          {
            isOpen: false,
            title: '質問',
            path: replacePathParams(EPath.organizationQuestion, { organizationId: item.id }),
            subItems: [],
          },
          {
            isOpen: false,
            title: '指導',
            path: replacePathParams(EPath.organizationFeedback, { organizationId: item.id }),
            subItems: [],
          },
          {
            isOpen: false,
            title: '文献',
            path: replacePathParams(EPath.organizationDocument, { organizationId: item.id }),
            subItems: [],
          },
        ];
        data.push({
          ...organizationInit,
          path: replacePathParams(EPath.organization, { organizationId: item.id }),
          title: item.title,
          subItems: subItemAdd,
        });
      });
      const currentPath = location.pathname;
      const dataClone: TMenuItem[] = JSON.parse(JSON.stringify(data));
      loadOpenStatusRecursive(dataClone, currentPath);

      dispatch(setDataSideBar([...SIDE_MENU_DATA, ...dataClone]));
    }
  }, [listOrganization]);

  useEffect(() => {
    dispatch(getOrganizationAsync({}));
  }, [location]);

  const handleClose = () => {
    onCloseModal();
    reset();
  };

  return (
    <div className="border-r border-outline-variant">
      <Modal open={open} onClose={handleClose} center showCloseIcon={false} classNames={{ modal: '!bg-gray-footer' }}>
        <div className="w-[calc(100vw-100px)] max-w-[640px]">
          <div className="font-normal">組織の新規作成</div>
          <form
            onSubmit={handleSubmit((data) => {
              handleSubmitForm(data);
              reset();
            })}
          >
            <div className="mt-4 w-full">
              <DefaultText variant="length" className="flex pb-2 text-sm font-normal text-on-surface">
                組織の名前（必須）
              </DefaultText>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_ORGANIZATION_NAME,
                }}
                render={({ field }) => (
                  <ForwardedInput
                    {...field}
                    type="text"
                    className={'border-1 h-12 w-full rounded border-on-surface-variant px-4 py-1'}
                    hasError={!!errors?.title?.message}
                  />
                )}
              />
              <ErrorTooltip errorMessage={errors?.title?.message} hasError={!!errors?.title?.message} />
            </div>
            <div className="mt-8 flex justify-end gap-2">
              <PrimaryButton type="button" text="キャンセル" variant="text" onClick={handleClose} />
              <PrimaryButton text="保存" variant="outlined" />
            </div>
          </form>
        </div>
      </Modal>
      <div className="md:block">
        <aside
          id="layout-menu"
          className={`${narrowSidebar ? style.sidebar_narrow : style.sidebar} ${sidebarSp && style.sidebar_sp_toggle}`}
        >
          <button
            className={narrowSidebar ? style.icon_narrow_rotate : style.icon_narrow}
            onClick={() => setNarrowSidebar(!narrowSidebar)}
          >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
                <path d="M18.47 12.94L17.53 12L13.53 16L17.53 20L18.47 19.06L15.4167 16L18.47 12.94Z" fill="#1F1C05" />
              </g>
              <rect x="4.5" y="4.5" width="23" height="23" rx="11.5" stroke="#959181" />
              <defs>
                <clipPath id="clip0_1552_7685">
                  <rect x="4" y="4" width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          <div className="h-[calc(100% - 70px)] flex h-full max-w-[240px] flex-col bg-white px-[8px] py-[20px] lg:h-screen lg:justify-between">
            <div className={narrowSidebar ? style.app_name_narrow : style.app_name}>
              <div className="flex justify-between">
                <Link to={EPath.houses}>
                  {narrowSidebar ? (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z" fill="#1D1C16"></path>
                    </svg>
                  ) : (
                    <DefaultText variant="title-header-navbar" className="text-[18px] text-secondary">
                      {TITLE_WEB} App
                    </DefaultText>
                  )}
                </Link>
                <button
                  className="absolute left-[250px] top-2 text-[24px] drop-shadow-lg md:hidden"
                  onClick={showSidebarSpEvent}
                >
                  <IconCloseWhite width="24px" height="24px" />
                </button>
              </div>
            </div>
            <div
              className={`${
                narrowSidebar ? style.list_item_narrow : style.list_item
              } mt- grow overflow-y-auto pt-[4px]`}
            >
              <MenuItem items={dataSideBar} toggleMenu={toggleMenu} isPathActive={isPathActive} />
            </div>
            <div className={style.section_bottom}>
              <div className={narrowSidebar ? style.add_group_narrow : style.add_group}>
                <button className={style.add_group_box} onClick={onOpenModal}>
                  <IconPlus />
                  <DefaultText variant="length" className="text-[14px] text-on-primary">
                    組織の追加
                  </DefaultText>
                </button>
              </div>
              <div className={`flex ${narrowSidebar ? style.list_item_footer_narrow : style.list_item_footer}`}>
                <Link to={EPath.notification}>
                  <div
                    className={`relative rounded-full ${
                      isPathActive(EPath.notification) ? 'bg-yellow-secondary' : ''
                    } `}
                  >
                    <IconNotify className="rounded-full transition-all duration-200 ease-in hover:bg-black/[0.08] active:scale-[0.7] active:bg-black/[0.08] active:transition-all active:duration-200 active:ease-in"></IconNotify>
                    {countNotification && countNotification > 0 ? (
                      <div className="absolute left-[25px] top-3 flex flex h-4 items-center rounded-full bg-error px-2 pb-[3px] pt-[2px] text-[11px] text-white">
                        {countNotification}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Link>
                <button
                  className={`relative rounded-full ${isPathActive(EPath.account) ? 'bg-yellow-secondary' : ''}`}
                  onClick={showSettingBox}
                >
                  <span>
                    <IconAccount className="rounded-full transition-all duration-200 ease-in hover:bg-black/[0.08] active:scale-[0.7] active:bg-black/[0.08] active:transition-all active:duration-200 active:ease-in"></IconAccount>
                  </span>
                  {showSetting && (
                    <div ref={ref}>
                      <DropdownAccount />
                    </div>
                  )}
                </button>
              </div>
              <p className="text-sm">Version: v{packageJson.version}</p>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default SidebarMenu;
