import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '@/api/axios-client';
import { TDocument } from '@/api/types/questionTypes';
import { IDataFilter, IFilter, IQuestionCreateApi, TQuestionUpdate } from '@/types/question';
import { FileRes, IPagination, TCallback } from '@/types/common';
import { DATA_OPTION_INIT, DEFAULT_PAGE_SIZE, typeFilterQuestion } from '@/utils/constants/consts';
import { setTags } from '@/stores/tag';
import { CommonState, getListDocument, setQFDCData, setShowToast, setTitleHeaderDetail } from '@/stores/common';
import { isArray } from '@craco/craco/dist/lib/utils';
import { REGEX } from '@/utils/constants/regex';
import { FilterHouses } from '@/types/houses';
import { messageNotification } from '@/utils/constants/messageNotification';
import { getCountNotification } from '@/stores/notification';

export interface DocumentState {
  documents: TDocument[];
  documentDetail: TDocument | null;
  keyword: string;
  page?: number;
  limit?: number;
  total?: number;
  dataOption: IFilter<IPagination, IDataFilter>;
}

const initialState: DocumentState = {
  page: 1,
  limit: 10,
  total: 0,
  keyword: '',
  documents: [],
  documentDetail: null,
  dataOption: DATA_OPTION_INIT,
};

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    saveDocument: (state, action) => {
      const { items, page, limit, total } = action.payload;
      state.documents = items;
      state.page = page;
      state.limit = limit;
      state.total = total;
    },
    setDocumentDetail: (state, action) => {
      state.documentDetail = action.payload;
    },
    setDataOption: (state, action) => {
      state.dataOption[action.payload.type].data = action.payload.data;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const getDocumentsAsync = createAsyncThunk('documents/getDocuments', async (data: FilterHouses, thunkAPI) => {
  const response = await axiosInstance.post('documents/list', data);
  const { document } = thunkAPI.getState() as { document: DocumentState };
  const documentsClone = {
    ...response.data,
    items: response.data.page === 1 ? [...response.data.items] : [...document.documents, ...response.data.items],
  };
  thunkAPI.dispatch(saveDocument(documentsClone));
});

export const getUserFilterAsync = createAsyncThunk('documents/getUserFilter', async (param: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `documents/users?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
  );
  thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.AUTHOR, data: response.data.items }));
});

export const getTagFilterAsync = createAsyncThunk('documents/getTagFilter', async (param: IPagination, thunkAPI) => {
  const response = await axiosInstance.get(
    `documents/tags?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
  );
  thunkAPI.dispatch(setDataOption({ type: typeFilterQuestion.TAG, data: response.data.items }));
});

export const getOrganizationFilterAsync = createAsyncThunk(
  'documents/getOrganizationFilter',
  async (param: IPagination, thunkAPI) => {
    const response = await axiosInstance.get(
      `documents/organizations?page=${param.page}&limit=${param.limit}&id=${param.organizationId ?? ''}`,
    );
    thunkAPI.dispatch(
      setDataOption({
        type: typeFilterQuestion.ORGANIZATION,
        data: response.data.items,
      }),
    );
  },
);

export const createDocument = createAsyncThunk(
  'documents/createDocument',
  async (data: IQuestionCreateApi & TCallback, thunkAPI) => {
    const response = await axiosInstance.post('documents', data);
    thunkAPI.dispatch(setTags(response.data.items));
    thunkAPI.dispatch(setShowToast({ message: messageNotification.createSuccess, type: 'success' }));
    thunkAPI.dispatch(getCountNotification());
    data.callback?.();
  },
);

export const getDocumentDetailAsync = createAsyncThunk(
  'document/getDocumentDetailAsync',
  async (id: number, thunkAPI) => {
    const response = await axiosInstance.get(`documents/${id}`);
    thunkAPI.dispatch(setDocumentDetail(response.data));
    let imageName = [];
    let fileName = [];
    if (response.data.image && isArray(response.data.image)) {
      imageName = response.data.image
        ?.filter((file: FileRes) => REGEX.imageExtensionsRegex.test(file.name))
        .map((item: FileRes) => item.path);
      fileName = response.data.image.filter((file: FileRes) => !REGEX.imageExtensionsRegex.test(file.name));
    }
    const state = thunkAPI.getState() as { common: CommonState };
    thunkAPI.dispatch(
      setQFDCData({
        ...state.common.QFDCData,
        content: response.data.content,
        imagesPath: imageName ?? [],
        files: fileName,
        typeShare: response.data.publish,
        filesPath: response.data.image.map((item: FileRes) => item.path),
      }),
    );
    thunkAPI.dispatch(setTitleHeaderDetail(response.data.title));
  },
);

export const editDocument = createAsyncThunk(
  'document/editDocument',
  async (data: TQuestionUpdate & TCallback, thunkAPI) => {
    await axiosInstance.patch(`documents/${data.id}`, data);
    thunkAPI.dispatch(setShowToast({ message: messageNotification.updateSuccess, type: 'success' }));
    data.callback?.();
  },
);

export const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (params: { documentId: number; organizationId?: number | string } & TCallback, thunkAPI) => {
    if (params.organizationId) {
      await axiosInstance.delete(`documents/${params.documentId}`);
      thunkAPI.dispatch(setShowToast({ message: messageNotification.deleteSuccess, type: 'success' }));
      thunkAPI.dispatch(
        getDocumentsAsync({
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          organizationId: [{ operation: 'in', value: [Number(params.organizationId)] }],
        }),
      );
      thunkAPI.dispatch(
        getListDocument({
          organizationId: [
            {
              operation: 'in',
              value: [+params.organizationId],
            },
          ],
        }),
      );
      params.callback?.();
    }
  },
);

export const { saveDocument, setDocumentDetail, setDataOption, setKeyword } = documentSlice.actions;
export default documentSlice.reducer;
