import cn from '@/utils/general';
import React from 'react';

const BodyTextTagTypes = ['a', 'span', 'p'];

type BodyTextTagTypes = {
  tag?: (typeof BodyTextTagTypes)[number];
  children?: React.ReactNode | string;
  size?: 'small' | 'large' | 'medium';
  tColor?: string;
  className?: string;
};

// Headline in design typography style

const BodyText: React.FC<BodyTextTagTypes> = ({
  tag = 'span',
  size = 'medium',
  children,
  tColor = 'text-on-surface',
  className,
}) => {
  let fontSize = '';
  switch (size) {
    case 'small':
      fontSize = 'text-xs leading-[1.75em] tracking-[0.4px]';
      break;
    case 'medium':
      fontSize = 'text-sm leading-[1.75em] tracking-[0.25px]';
      break;
    case 'large':
      fontSize = 'text-base leading-[1.75em] tracking-[0.5px]';

      break;
  }
  return (
    <>
      {React.createElement(tag, { className: cn(`${fontSize} font-normal inline-flex`, tColor, className) }, children)}
    </>
  );
};

export default BodyText;
