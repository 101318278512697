import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileRes, IQFDCData, IToast, TMenuItem } from '@/types/common';
import { toast } from 'react-toastify';
import { shareType } from '@/types/enum';
import { changeListSubItems, replacePathParams, uploadFile } from '@/utils/helpers';
import { SIDE_MENU_DATA } from '@/utils/constants/consts';
import { REGEX } from '@/utils/constants/regex';
import axiosInstance from '@/api/axios-client';
import { FilterQuestion } from '@/types/question';
import { EPath } from '@/route/route';
import { TQuestion } from '@/api/types/questionTypes';
import { IOrganization } from '@/types/organization';

export interface CommonState {
  isLoading: boolean;
  toast: IToast;
  QFDCData: IQFDCData;
  listFilesComment: FileRes[];
  dataSideBar: TMenuItem[];
  titleHeader: string;
  titleHeaderDetail: string;
  organizationInfo: IOrganization | null;
  isLoadMap: boolean;
  width: number;
}

const initialState: CommonState = {
  isLoading: false,
  toast: {
    type: 'success',
    message: '',
  },
  QFDCData: {
    content: '',
    imagesDisplay: [],
    imagesPath: [],
    filesPath: [],
    files: [],
    listTags: [],
    typeShare: String(shareType.private),
  },
  listFilesComment: [],
  dataSideBar: SIDE_MENU_DATA,
  titleHeader: 'すべてのハウス',
  titleHeaderDetail: '',
  organizationInfo: null,
  isLoadMap: false,
  width: window.innerWidth,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShowToast: (state, action: PayloadAction<IToast>) => {
      state.toast.type = action.payload.type;
      state.toast.message = action.payload.message;
      toast[state.toast.type](state.toast.message);
    },
    setQFDCData: (state, action) => {
      state.QFDCData = action.payload;
    },
    setDataSideBar: (state, action) => {
      state.dataSideBar = action.payload;
    },
    replaceListFilesComment: (state, action) => {
      state.listFilesComment = action.payload;
    },
    setListFilesComment: (state, action) => {
      state.listFilesComment = [...state.listFilesComment, ...action.payload];
    },
    resetListFilesComment: (state) => {
      state.listFilesComment = [];
    },
    removeFilesComment: (state, action) => {
      state.listFilesComment = state.listFilesComment.filter((item) => item.id !== action.payload);
    },
    removeImageComment: (state, action) => {
      state.listFilesComment = state.listFilesComment.filter((item) => item.path !== action.payload);
    },
    setTitleHeader: (state, action) => {
      state.titleHeader = action.payload;
    },
    setTitleHeaderDetail: (state, action) => {
      state.titleHeaderDetail = action.payload;
    },
    setOrganizationInfo: (state, action) => {
      state.organizationInfo = action.payload;
    },
    setLoadMap: (state, action) => {
      state.isLoadMap = action.payload;
    },
    setWidthScreen: (state, action) => {
      state.width = action.payload;
    },
  },
});

export const getDetailOrganization = createAsyncThunk(
  '/organization /uploadFileComment',
  async (id: number, thunkAPI) => {
    const res = await axiosInstance.get(`organizations/${id}`);
    thunkAPI.dispatch(setTitleHeader(res?.data.title));
    thunkAPI.dispatch(setOrganizationInfo(res?.data));
  },
);

export const uploadFileQuestion = createAsyncThunk('loading/question/uploadFile', async (data: File[], thunkAPI) => {
  try {
    const res = await uploadFile(data);
    const images = res?.data
      .map((file: FileRes) => file.path)
      .filter((path: string) => REGEX.imageExtensionsRegex.test(path));
    const files = res?.data.filter((item: FileRes) => !REGEX.imageExtensionsRegex.test(item.path));
    const state = thunkAPI.getState() as { common: CommonState };
    const filesPath = res?.data.map((file: FileRes) => file.path);
    thunkAPI.dispatch(
      setQFDCData({
        ...state.common.QFDCData,
        imagesDisplay: images,
        files: [...state.common.QFDCData.files, ...files],
        filesPath: [...state.common.QFDCData.filesPath, ...filesPath],
        imagesPath: [...state.common.QFDCData.imagesPath, ...images],
      }),
    );
  } catch (e) {
    thunkAPI.dispatch(setShowToast({ message: 'アップロードできません。', type: 'error' }));
  }
});

export const getListHouse = createAsyncThunk('/question/getListHouse', async (data: FilterQuestion, thunkAPI) => {
  const dataRes = await axiosInstance.post('green-house/list', data);
  const listHouse = dataRes.data.items.map((item: TQuestion) => {
    return {
      title: item.title,
      path: replacePathParams(EPath.organizationHouse, {
        organizationId: data?.organizationId?.[0].value[0] ?? '',
        houseId: item.id,
      }),
    };
  });
  if (data.organizationId) {
    const state = thunkAPI.getState() as { common: CommonState };
    const dataCopy = JSON.parse(JSON.stringify(state.common.dataSideBar));

    changeListSubItems(
      dataCopy,
      replacePathParams(EPath.organizationHouseList, { organizationId: data?.organizationId?.[0].value[0] ?? '' }),
      listHouse,
    );
    thunkAPI.dispatch(setDataSideBar(dataCopy));
  }
});

export const getListQuestion = createAsyncThunk('/question/getListQuestion', async (data: FilterQuestion, thunkAPI) => {
  const dataRes = await axiosInstance.post('question/list', data);
  const listQuestion = dataRes.data.items.map((item: TQuestion) => {
    return {
      title: item.title,
      path: replacePathParams(EPath.questionDetail, {
        id: item.id,
        organizationId: data?.organizationId?.[0].value[0] ?? '',
      }),
    };
  });
  if (data.organizationId) {
    const state = thunkAPI.getState() as { common: CommonState };
    const dataCopy = JSON.parse(JSON.stringify(state.common.dataSideBar));

    changeListSubItems(
      dataCopy,
      replacePathParams(EPath.organizationQuestion, { organizationId: data?.organizationId?.[0].value[0] ?? '' }),
      listQuestion,
    );
    thunkAPI.dispatch(setDataSideBar(dataCopy));
  }
});

export const getListFeedback = createAsyncThunk('/question/getListFeedback', async (data: FilterQuestion, thunkAPI) => {
  const dataRes = await axiosInstance.post('feedback/list', data);
  const listFeedback = dataRes.data.items.map((item: TQuestion) => {
    return {
      title: item.title,
      path: replacePathParams(EPath.feedbackDetail, {
        id: item.id,
        organizationId: data?.organizationId?.[0].value[0] ?? '',
      }),
    };
  });
  if (data.organizationId) {
    const state = thunkAPI.getState() as { common: CommonState };
    const dataCopy = JSON.parse(JSON.stringify(state.common.dataSideBar));

    changeListSubItems(
      dataCopy,
      replacePathParams(EPath.organizationFeedback, { organizationId: data?.organizationId?.[0].value[0] ?? '' }),
      listFeedback,
    );
    thunkAPI.dispatch(setDataSideBar(dataCopy));
  }
});

export const getListDocument = createAsyncThunk('/question/getListDocument', async (data: FilterQuestion, thunkAPI) => {
  const dataRes = await axiosInstance.post('documents/list', data);
  const listDocument = dataRes.data.items.map((item: TQuestion) => {
    return {
      title: item.title,
      path: replacePathParams(EPath.documentDetail, {
        organizationId: data?.organizationId?.[0].value[0] ?? '',
        id: item.id,
      }),
    };
  });
  if (data.organizationId) {
    const state = thunkAPI.getState() as { common: CommonState };
    const dataCopy = JSON.parse(JSON.stringify(state.common.dataSideBar));

    changeListSubItems(
      dataCopy,
      replacePathParams(EPath.organizationDocument, { organizationId: data?.organizationId?.[0].value[0] ?? '' }),
      listDocument,
    );
    thunkAPI.dispatch(setDataSideBar(dataCopy));
  }
});

export const uploadFileComment = createAsyncThunk(
  'loading/question/uploadFileComment',
  async (data: File[], thunkAPI) => {
    const res = await uploadFile(data);
    thunkAPI.dispatch(setListFilesComment(res?.data));
  },
);

export const {
  setIsLoading,
  setShowToast,
  setQFDCData,
  setListFilesComment,
  removeFilesComment,
  removeImageComment,
  setDataSideBar,
  resetListFilesComment,
  setTitleHeader,
  setTitleHeaderDetail,
  replaceListFilesComment,
  setOrganizationInfo,
  setLoadMap,
  setWidthScreen,
} = commonSlice.actions;
export default commonSlice.reducer;
