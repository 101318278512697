import type { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import Breadcrumb from '@/components/atoms/Breadcrumb/Breadcrumb';

import BaseButton from '@/components/atoms/Button/Button';
import DefaultText from '@/components/atoms/Text/DefaultText';
import ListQuestionComponent from '@/components/molecules/ListQuestionComponent/ListQuestionComponent';
import { useOutsideClick } from '@/hooks/useClickOutside';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EPath } from '@/route/route';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { getQuestionAsync } from '@/stores/question';
import { DEFAULT_ITEM_COUNT } from '@/utils/constants/consts';
import { MESSAGE_NO_DATA } from '@/utils/constants/messageValidate';
import { replacePathParams } from '@/utils/helpers';
import { nanoid } from 'nanoid';

type Props = {
  organizationId: string;
  titleHeader: string;
};

const QuestionComponent: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { organizationId, titleHeader } = props;
  const [isShowAll, setIsShowAll] = useState(false);
  const tableHeading = useMemo(
    () => [
      { id: 1, label: '' },
      { id: 2, label: 'ステータス' },
      { id: 3, label: '公開範囲' },
      { id: 4, label: '作成者' },
      { id: 5, label: '公開日' },
    ],
    [],
  );

  const { questions } = useSelector((state: RootState) => state.questions);
  useEffect(() => {
    dispatch(
      getQuestionAsync({
        page: 1,
        limit: 0,
        organizationId: [{ operation: 'in', value: [+organizationId] }],
      }),
    );
  }, [organizationId]);

  const BREADCRUMBS_ROOT: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: '#',
    },
    {
      name: '質問',
      to: replacePathParams(EPath.organizationQuestion, {
        organizationId: organizationId ?? '',
      }),
    },
  ];

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => new Array(questions.length).fill(false));

  return (
    <div
      className={`container_HouseComponent lg:min-w-full ${
        questions.length > 0 ? 'min-w-[800px]' : 'w-[calc(100vw-48px)] md:w-[calc(100vw-288px)]'
      }`}
    >
      <Breadcrumb data={BREADCRUMBS_ROOT} className="h-[18px]" />
      <div className="flex items-center justify-between">
        <DefaultText variant="title-children" className="h-[27px] text-[18px] text-on-surface">
          {'質問'}
        </DefaultText>
      </div>
      <div className="flex flex-1 flex-col">
        {questions.length > 0 ? (
          <div className="questionComponentListColumn h-[25px]">
            {tableHeading.map((e, i) => (
              <DefaultText
                variant="text-status"
                className={`${
                  i === 0 ? 'justify-start' : 'justify-center'
                } 2xl:text-text-[11px] flex items-center text-[11px] text-on-surface ${i === 0 && 'pl-[16px]'}`}
                key={nanoid(5)}
              >
                {e.label}
              </DefaultText>
            ))}
          </div>
        ) : (
          <div className="text-center text-lg text-on-surface-variant">{MESSAGE_NO_DATA}</div>
        )}

        <div className="max-h-[500px] overflow-auto">
          <ul>
            {(isShowAll ? questions : questions.slice(0, 3)).map((questions) => (
              <ListQuestionComponent key={nanoid(5)} question={questions} />
            ))}
          </ul>
        </div>
      </div>
      {!isShowAll && questions.length > DEFAULT_ITEM_COUNT && (
        <div className="pt-[8px]">
          <BaseButton
            variant="on-secondary"
            type="submit"
            className="w-[119px]"
            onClick={() => setIsShowAll(!isShowAll)}
          >
            <DefaultText variant="length" className="text-[14px] text-on-primary">
              もっと見る
            </DefaultText>
          </BaseButton>
        </div>
      )}
    </div>
  );
};

export default QuestionComponent;
