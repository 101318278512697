import cn from '@/utils/general';
import React, { useCallback } from 'react';

type InputChipVariantVariant = 'selected' | 'unselected';
type InputChipVariantType = 'rounded' | 'square';

const inputChipVariantCn: Record<InputChipVariantVariant, string> = {
  unselected:
    'text-on-surface-variant border border-outline bg-surface focus:bg-M3-surface focus:border-M3-on-surface-variant',
  selected:
    'text-M3-on-secondary-container border-none bg-secondary-container hover:bg-clip-border hover:bg-blend-soft-light',
};

const inputChipVariantType: Record<InputChipVariantType, string> = {
  square: 'rounded-lg',
  rounded: 'rounded-full',
};
const inputChipVariantIconLeftVariant: Record<InputChipVariantVariant, string> = {
  unselected: 'icon-button-user_image',
  selected: 'icon-button-user-check',
};

const inputChipVariantIconLeftType: Record<InputChipVariantType, string> = {
  square: '',
  rounded: 'rounded-full overlay-dark bg-on-surface-variant',
};

type InputChipOwnProps = {
  variant: InputChipVariantVariant;
  className?: string;
  text?: string;
  type: InputChipVariantType;
  iconRight?: string;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  iconLeft?: string;
};

type InputChipProps = InputChipOwnProps & Omit<React.HTMLProps<HTMLDivElement>, keyof InputChipOwnProps>;

const InputChip = ({
  className,
  type,
  text,
  variant,
  iconRight,
  iconLeft,
  onClickLeft,
  onClickRight,
  ...rest
}: InputChipProps) => {
  const onClickLeftAction = useCallback(() => {
    if (iconLeft && onClickLeft) {
      onClickLeft();
    }
  }, [iconLeft, onClickLeft]);

  const onClickRightAction = useCallback(() => {
    if (iconRight && onClickRight) {
      onClickRight();
    }
  }, [iconRight, onClickRight]);

  return (
    <div
      className={cn(
        'label lg overlay-on-hover flex items-center justify-center px-3 py-[5px]',
        iconRight ? '' : 'hover:cursor-pointer',
        inputChipVariantCn[variant],
        inputChipVariantType[type],
        className,
      )}
      {...rest}
    >
      {iconLeft && (
        <span
          onClick={onClickLeftAction}
          className={cn(
            '-my-1 mr-2',
            inputChipVariantIconLeftType[type],
            inputChipVariantIconLeftVariant[variant],
            iconLeft,
            variant === 'selected' && type === 'rounded' && 'bg-M3-on-secondary-container',
          )}
        >
          <i
            className={cn(
              'hidden h-6 w-6 truncate before:bg-on-surface-variant',
              type === 'square' && 'h-[18px] w-[18px]',
              variant === 'selected' && type === 'rounded' && 'before:bg-white',
            )}
          />
        </span>
      )}
      <span className="truncate">{text}</span>
      {iconRight && (
        <span onClick={onClickRightAction} className={cn('icon-button ml-2 hover:cursor-pointer', iconRight)}>
          <i className="hidden h-[18px] w-[18px] before:bg-on-surface-variant" />
        </span>
      )}
    </div>
  );
};

export default InputChip;
