import React from 'react';
import c from 'clsx';
import Input from '../../../atoms/Input';
import { useService } from './service';
import { chartConsts } from '../../../organisms/Chart/consts';
import s from './../style.module.sass';
import DefaultText from '@/components/atoms/Text/DefaultText';

type Props = {
  className?: string;
  area: string;
  id: string;
  label: string;
};

const ChartNameCellAll: React.FC<Props> = (props) => {
  const { className, area, id, label } = props;
  const { isEdit, inputRef, handleSubmit } = useService(area, id);

  return (
    <div
      className={`${c(s.root, className)} my-auto`}
      style={{
        height: `${chartConsts.mileStoneSize}px`,
      }}
    >
      {isEdit ? (
        <form onSubmit={handleSubmit}>
          <Input ref={inputRef} />
        </form>
      ) : (
        <>
          <DefaultText variant="text-link" className={`text-sm text-on-surface ${s.label}`}>
            {label}
          </DefaultText>
        </>
      )}
    </div>
  );
};

export default ChartNameCellAll;
