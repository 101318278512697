import React from 'react';
import c from 'clsx';
import s from './style.module.sass';
import ChartBar from '../ChartBar';
import type { Charts } from '@/stores/chart/model';
import { chartConsts } from '../../organisms/Chart/consts';
import { useService, useDataControl } from './service';
import { nanoid } from 'nanoid';
import { calculateLeftLineNow } from '@/utils/helpers';

type Props = {
  className?: string;
  scaleUnit: 'month' | 'day';
  startAt: string;
  endAt: string;
  charts: Charts;
  isAreaGrouping?: string;
  checkScroll: boolean;
};

const ChartTable = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, scaleUnit, startAt, endAt, charts, isAreaGrouping, checkScroll } = props;
  const { tableData, tableWidth, closeGroups } = useService(scaleUnit, startAt, endAt);
  const { moveDate } = useDataControl();
  const borderWidthStyle = {
    borderWidth: `${chartConsts[scaleUnit].border}px`,
  };

  const checkDateNow = (year: number | string, month: string) => {
    const now = new Date();
    const yearNow = now.getFullYear();
    const monthNow = now.getMonth() + 1;
    let monthCompare: string | string[] = month.split('');
    monthCompare.pop();
    monthCompare = monthCompare.join('');
    return +year === yearNow && +monthCompare === monthNow;
  };

  return (
    <div ref={ref} className="business-table relative grow overflow-x-auto" id="chart-line">
      <div className={c(s.rootChart, className)}>
        <div className={s.charts}>
          <table className={s.table} style={borderWidthStyle}>
            <tbody>
              <tr className={`${checkScroll && s.headerChart} sticky top-[-1px] z-[10] bg-surface`}>
                {tableData.map((d) => {
                  return (
                    <td key={nanoid(5)} colSpan={d.subheads.length} className={s.head}>
                      {d.head}
                    </td>
                  );
                })}
                <div className="h-[5px] w-full bg-black" />
              </tr>

              <tr className={`${checkScroll && s.headerChart} sticky top-[35px] z-[10] bg-surface`}>
                {tableData.map((d) =>
                  d.subheads.map((sh) => (
                    <td
                      key={nanoid(5)}
                      className={`${s.head} ${checkDateNow(d.head, sh.value) && 'bg-secondary text-white'}`}
                      style={{
                        ...borderWidthStyle,
                        minWidth: `${sh.width}px`,
                      }}
                    >
                      {sh.value}
                    </td>
                  )),
                )}
              </tr>
              <tr>
                {tableData.map((d) =>
                  d.subheads.map((sh, index) => {
                    return (
                      <td key={nanoid(5)} className={s.body} style={checkScroll && index === 0 ? {} : borderWidthStyle}>
                        {checkDateNow(d.head, sh.value) && (
                          <div
                            style={{
                              left: `${calculateLeftLineNow()}px`,
                            }}
                            className={`${s.bodyBefore}`}
                          ></div>
                        )}
                      </td>
                    );
                  }),
                )}
              </tr>
            </tbody>
          </table>
          {Object.keys(charts).map((k, index) => {
            return (
              <span key={nanoid(5)}>
                <span className={isAreaGrouping === 'true' ? `${s.dummy} ${index === 0 && 'mt-[38px]'}` : s.dummy} />
                {charts[k].map((c) => {
                  return (
                    !closeGroups.some((g: string) => g === k) && (
                      <span key={nanoid(5)}>
                        <ChartBar
                          className={s.chart}
                          area={k}
                          chart={c}
                          startAt={startAt}
                          endAt={endAt}
                          scaleUnit={scaleUnit}
                          tableWidth={tableWidth}
                          handleMove={moveDate}
                        />
                      </span>
                    )
                  );
                })}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
});

ChartTable.displayName = 'ChartTable';
export default ChartTable;
