import { THousesCreate } from '@/api/types/housesTypes';
import Heading from '@/components/atoms/Heading';
import ForwardedInput from '@/components/atoms/Input';
import Label from '@/components/atoms/Label';
import LoadMore from '@/components/atoms/LoadMore';
import Paragraph from '@/components/atoms/Paragraph';
import PrimaryButton from '@/components/atoms/PrimaryButton';
import Text from '@/components/atoms/Text';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { IconArrowDropUp } from '@/components/icon/IconArrowDropUp/IconArrowDropUp';
import IconColonization from '@/components/icon/IconChart/IconColonization';
import IconHarvest from '@/components/icon/IconChart/IconHarvest';
import IconSowing from '@/components/icon/IconChart/IconSowing';
import RemoveImage from '@/components/icon/RemoveImage';
import FilterBlock from '@/components/molecules/FilterBlock';
import ListHouseComponent from '@/components/molecules/ListHouseComponent/ListHouseComponent';
import ChartAll from '@/components/organisms/Chart/ChartAll';
import { RootState } from '@/stores';
import { getDetailOrganization, setIsLoading } from '@/stores/common';
import {
  createHouses,
  getHousesALlAsync,
  getHousesAsync,
  getOrganizationFilterAsync,
  getUserFilterAsync,
  saveHouses,
  setKeyword,
  setPath,
  uploadFileHouses,
} from '@/stores/houses';
import { IParamsFilter } from '@/types/common';
import { ITypeFilter } from '@/types/question';
import { CONSTANTS, DEFAULT_PAGE_SIZE, FILE_IMAGE, QUANTITY_TYPE_FILTER } from '@/utils/constants/consts';
import {
  MESSAGE_NO_DATA,
  MESSAGE_REQUIRED_DEFAULT,
  MESSAGE_REQUIRED_HOUSE_NAME,
  MESSAGE_VALIDATE_MIN,
} from '@/utils/constants/messageValidate';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import { useParams } from 'react-router-dom';
import Select from '../../atoms/Select';
import MapComponent from '../AllHouses/MapComponent';
import { useService } from '../Main/service';
import { FilterHouses, TMarker } from '@/types/houses';
import { setDataChart } from '@/stores/business/slice';
import { setCharts } from '@/stores/chart/effects';
import { getDataChartAll } from '@/stores/business/services';
import { REGEX } from '@/utils/constants/regex';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';
import { handleOnChangeInputNumberFormat } from '@/utils/helpers';
import IconZoomOut from '@/components/icon/IconZoomOut';
import IconZoomIn from '@/components/icon/IconZoomIn';
import s from '@/components/pages/Main/style.module.sass';
import IconEdit from '@/components/icon/IconEdit';

const dataInit: ITypeFilter[] = [];

Array(QUANTITY_TYPE_FILTER.HOUSE)
  .fill('')
  .forEach((value, index) => {
    dataInit.push({
      id: index,
      type: '',
      operator: '',
      value: [],
    });
  });
const ListHouse = () => {
  const { organizationId } = useParams();
  const [activeTab, setActiveTab] = useState('house');
  const [dataFilter, setDataFilter] = useState<ITypeFilter[]>(dataInit);
  const { dataOption, housesAll } = useSelector((state: RootState) => state.houses);
  const dispatch = useDispatch();
  const [filesPreview, setFilesPreview] = useState<Array<string>>([]);
  const { path } = useSelector((state: RootState) => state.houses);
  const { dataChart } = useSelector((state: RootState) => state.business);
  const { houses, total, page, keyword } = useSelector((state: RootState) => state.houses);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [dataFilterSelected, setDataFilterSelected] = useState<FilterHouses>({});
  const [markers, setMarkers] = useState<TMarker[]>([]);

  useEffect(() => {
    setMarkers(
      housesAll.map((item) => ({
        id: item.id,
        lat: +item.coordinatesLat,
        lng: +item.coordinatesLong,
      })),
    );
  }, [housesAll]);

  useEffect(() => {
    if (organizationId) {
      dispatch(
        getUserFilterAsync({
          page: 0,
          limit: 0,
          organizationId: +organizationId,
        }),
      );
      dispatch(
        getOrganizationFilterAsync({
          page: 0,
          limit: 0,
          organizationId: +organizationId,
        }),
      );
    }
  }, []);

  const handleTabClick = (tabId: React.SetStateAction<string>) => {
    if (tabId !== 'chart') {
      dispatch(setDataChart([]));
      dispatch(setCharts({}));
    }
    setActiveTab(tabId);
  };
  const nameTags = [
    {
      name: 'ハウス',
      value: 'house',
    },
    {
      name: '栽培計画',
      value: 'chart',
    },
    {
      name: 'マップ',
      value: 'maps',
    },
  ];

  const scaleValues = [
    {
      label: '年/月',
      value: 'month',
    },
    {
      label: '月/日',
      value: 'day',
    },
  ];

  const groupByValues = [
    {
      label: 'エリア',
      value: 'true',
    },
    {
      label: 'なし',
      value: 'false',
    },
  ];
  const tableHeading = ['ハウス名', '事業計画更新日', '面積(a)', '栽培株数(株)', '栽植密度(株/10a)', ''];
  const [sortDate, setSortDate] = useState(CONSTANTS.SORT_DATE_BUSINESS_PLAN_DESC);
  const [zoom, setZoom] = useState<boolean>(false);

  const handleSortDateBusiness = () => {
    setSortDate(!sortDate);
  };

  const { scaleRef, scale, handleScaleChange, areaGroupingRef, isAreaGrouping, handleAreaGroupingChange } =
    useService();

  const [open, setOpen] = useState(false);
  const onCloseModal = () => {
    reset();
    setOpen(false);
    dispatch(setPath(''));
  };
  const onOpenModal = () => {
    setOpen(true);
    dispatch(setPath(''));
  };

  useEffect(() => {
    if (organizationId) {
      dispatch(
        getHousesALlAsync({
          page: 0,
          limit: 0,
          organizationId: [
            {
              operation: 'in',
              value: [+organizationId],
            },
          ],
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'chart' && organizationId) {
      dispatch(
        getDataChartAll({
          page: 0,
          limit: 0,
          order: 1,
          organizationId: +organizationId,
        }),
      );
    }
  }, [activeTab]);

  useEffect(() => {
    if (!organizationId) return;
    dispatch(
      getHousesAsync({
        ...dataFilterSelected,
        page: 1,
        limit: DEFAULT_PAGE_SIZE,
        organizationId: [
          {
            operation: 'in',
            value: [+organizationId],
          },
        ],
        businessPlan: sortDate ? 'desc' : 'asc',
        keyword: keyword,
      }),
    );
    dispatch(getDetailOrganization(Number(organizationId)));
  }, [sortDate, keyword]);

  useEffect(() => {
    return () => {
      dispatch(
        saveHouses({
          houses: [],
          page: 1,
          limit: DEFAULT_PAGE_SIZE,
          total: 0,
        }),
      );
      dispatch(setKeyword(''));
    };
  }, []);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: 'onBlur',
  });
  const handleCreateHouses = (data: FieldValues) => {
    if (organizationId) {
      const formData = {
        ...data,
        area: Number(data.area?.replace(REGEX.LEADING_ZEROS_AND_COMMAS, '')),
        image: path,
        organizationId: +organizationId,
      };
      dispatch(createHouses(formData as THousesCreate));
      onCloseModal();
      reset();
      setFilesPreview([]);
      dispatch(setPath(''));
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      updateFile(event.target.files[0]);
    }
  };

  const updateFile = (file: File) => {
    const isValidImage = file && FILE_IMAGE.includes(file.type);
    setFilesPreview(isValidImage ? [URL.createObjectURL(file)] : []);
    dispatch(uploadFileHouses([file]));
    dispatch(setIsLoading(false));
  };

  const removeImage = (index: number) => {
    const filesPreviewCopy = [...filesPreview];
    filesPreviewCopy.splice(index, 1);
    setFilesPreview(filesPreviewCopy);
    dispatch(setPath(''));
  };

  const onCancelClick = () => {
    reset();
    onCloseModal();
    setFilesPreview([]);
    dispatch(setPath(''));
  };

  const handleSubmitFilter = (searchKey?: string, clearFilter?: boolean) => {
    if (searchKey || searchKey === '') {
      dispatch(setKeyword(searchKey));
    }
    const dataFilterApi: IParamsFilter = {};
    if (!clearFilter) {
      dataFilter.forEach((item) => {
        if (item.type) {
          dataFilterApi[item.type] = [
            {
              operation: item.operator,
              value: item.value,
            },
          ];
        }
      });
    }
    const data = {
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      organizationId: [
        {
          operation: 'in',
          value: [organizationId ? +organizationId : 0],
        },
      ],
      businessPlan: sortDate ? 'desc' : 'asc',
      keyword: searchKey ?? keyword,
      ...dataFilterApi,
    };

    if (dataFilterApi.manager) {
      const listManagerSelected = dataFilterApi.manager[0].value;
      const filterManager = dataOption.manager.data
        .filter((item) => listManagerSelected.includes(item.id))
        .map((item) => item.title);
      const formData = {
        ...data,
        manager: [{ ...dataFilterApi.manager[0], value: filterManager }],
      };
      setDataFilterSelected(formData);
      dispatch(getHousesAsync(formData));
    } else {
      setDataFilterSelected(data);
      dispatch(getHousesAsync(data));
    }
  };

  const fetchMoreData = () => {
    if (houses.length >= (total ?? 0)) {
      setHasMore(false);
      return;
    }
    if (!organizationId) return;
    dispatch(
      getHousesAsync({
        ...dataFilterSelected,
        page: (page ?? 1) + 1,
        limit: DEFAULT_PAGE_SIZE,
        organizationId: [
          {
            operation: 'in',
            value: [+organizationId],
          },
        ],
        businessPlan: sortDate ? 'desc' : 'asc',
        keyword,
      }),
    );
  };

  useEffect(() => {
    setHasMore(houses.length < (total ?? 0));
  }, [total]);

  const handleZoomIn = () => {
    setZoom(true);
  };

  const handleZoomOut = () => {
    setZoom(false);
  };

  return (
    <div className="h-[calc(100vh-64px)] bg-gray-background-1 lg:p-4">
      <div className="h-full bg-white p-2 lg:rounded-2xl">
        <div className="border-b border-b-outline-variant">
          <ul
            className="flex h-[48px] justify-center border-b border-outline-variant bg-on-error pl-[18px] lg:h-[64px] lg:justify-start lg:rounded-t-2xl lg:pt-[16px]"
            id="myTab"
            data-tabs-toggle="#myTabContent"
          >
            {nameTags.map((i) => (
              <li
                key={nanoid()}
                className={`mr-2 ${activeTab === i.value ? 'rounded-t-lg border-b-[3px] border-primary' : ''}`}
              >
                <button
                  onClick={() => handleTabClick(i.value)}
                  role="tab"
                  aria-controls={i.value}
                  aria-selected={activeTab === i.value}
                >
                  <DefaultText
                    variant="length"
                    className={`inline-block ${
                      activeTab === i.value ? 'text-on-surface' : 'text-on-surface-variant'
                    } rounded-t-lg px-4 text-center text-sm font-medium ${activeTab === i.value ? 'active' : ''}`}
                  >
                    {i.name}
                  </DefaultText>
                </button>
              </li>
            ))}
          </ul>
        </div>
        {activeTab !== 'maps' && activeTab !== 'chart' && (
          <div className="z-10 mt-4 flex flex-wrap items-center justify-between gap-1 md:flex-nowrap md:px-4">
            <div className="flex gap-1">
              <FilterBlock
                filterIcon={true}
                data={dataFilter}
                setData={setDataFilter}
                dataOption={dataOption}
                handleSubmitFilter={handleSubmitFilter}
              />
            </div>
            {activeTab === 'house' && (
              <>
                <div className="my-auto">
                  <PrimaryButton
                    text="新規作成"
                    onClick={onOpenModal}
                    className="icon-button hidden md:inline-flex"
                    iconLeft="icon-button-add"
                  />
                </div>
                <div
                  onClick={onOpenModal}
                  onKeyDown={() => {}}
                  className="absolute bottom-8 right-8 z-10 inline-block cursor-pointer rounded-2xl bg-primary p-4 transition-all duration-200 ease-in hover:scale-[0.98] active:scale-[0.9] active:transition-all active:duration-200 active:ease-in md:hidden"
                >
                  <IconEdit color={'#1F1C00'} />
                </div>
              </>
            )}
          </div>
        )}

        <div
          key={nanoid()}
          role="tabpanel"
          aria-labelledby="profile-tab"
          className={`${activeTab === 'house' && 'h-[calc(100vh-300px)] overflow-x-auto lg:h-[calc(100vh-190px)]'}`}
        >
          <div
            className={`${activeTab === 'house' && houses.length > 0 ? 'min-w-[800px]' : ''} rounded-b-2xl bg-on-error`}
          >
            {activeTab === 'house' && (
              <div className="mt-4">
                {houses.length > 0 ? (
                  <>
                    <div className="houseComponentListColumn h-[28px] 2xl:h-10">
                      {tableHeading.map((e, i) => (
                        <div
                          key={nanoid(5)}
                          className={`${
                            i === 0 ? 'justify-start pl-[80px]' : 'justify-center pl-2'
                          } 2xl:text-text-[11px] label sm flex items-center whitespace-nowrap text-[11px] text-on-surface`}
                        >
                          {i === 1 ? (
                            <React.Fragment>
                              {e}
                              <div className="ml-[8px] h-[18px] w-[18px] rounded-full bg-primary lg:ml-[24px] lg:h-[24px] lg:w-[24px]">
                                <button
                                  className="flex w-[18px] justify-center pt-[1px] lg:w-[24px] lg:pt-[4px]"
                                  onClick={handleSortDateBusiness}
                                  onKeyDown={() => {}}
                                >
                                  <IconArrowDropUp sortDate={sortDate} />
                                </button>
                              </div>
                            </React.Fragment>
                          ) : (
                            e
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="!overflow-scroll-y relative flex-1 md:h-[calc(100vh-253px)] lg:h-[calc(100vh-280px)]">
                      <InfiniteScroll
                        dataLength={houses.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<LoadMore />}
                        height={'calc(100vh - 280px)'}
                      >
                        <ul>
                          {houses.map((houses) => (
                            <ListHouseComponent
                              key={nanoid(5)}
                              data={houses}
                              dataFilter={
                                organizationId
                                  ? {
                                      organizationId: [{ operation: 'in', value: [+organizationId] }],
                                    }
                                  : undefined
                              }
                            />
                          ))}
                        </ul>
                      </InfiniteScroll>
                    </div>
                  </>
                ) : (
                  <div className="text-center text-lg text-on-surface-variant">{MESSAGE_NO_DATA}</div>
                )}
              </div>
            )}
            {activeTab === 'chart' && (
              <div className={`${zoom && s.zoom} overflow-x-auto`}>
                {dataChart.length > 0 && (
                  <div className="flex justify-between">
                    <div className=""></div>
                    <div className="flex flex-wrap gap-4 p-4 lg:flex-nowrap">
                      <div className="flex max-w-[296px] gap-[16px] rounded-lg border border-outline-variant px-[16px] py-[8px]">
                        <DefaultText
                          variant="text-status"
                          className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                        >
                          凡例
                        </DefaultText>
                        <div className="flex gap-1">
                          <IconSowing className="!cursor-default" />
                          <DefaultText
                            variant="text-status"
                            className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                          >
                            播種
                          </DefaultText>
                        </div>
                        <div className="flex gap-1">
                          <IconColonization className="!cursor-default" />
                          <DefaultText
                            variant="text-status"
                            className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                          >
                            定植
                          </DefaultText>
                        </div>
                        <div className="flex gap-1">
                          <IconHarvest className="!cursor-default" />
                          <DefaultText
                            variant="text-status"
                            className="m-auto whitespace-nowrap text-[11px] text-on-surface 2xl:text-[11px]"
                          >
                            収穫
                          </DefaultText>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <DefaultText
                          variant="text-tableBody"
                          className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
                        >
                          GroupBy
                        </DefaultText>
                        <Select
                          className="!min-w-[100px] lg:!min-w-[160px]"
                          data={groupByValues}
                          ref={areaGroupingRef}
                          onChange={handleAreaGroupingChange}
                        />
                      </div>
                      <div className="flex gap-2">
                        <DefaultText
                          variant="text-tableBody"
                          className="m-auto whitespace-nowrap text-[14px] text-on-surface-variant 2xl:text-[14px]"
                        >
                          タイムスケール
                        </DefaultText>
                        <Select
                          className="!min-w-[100px] lg:!min-w-[160px]"
                          data={scaleValues}
                          ref={scaleRef}
                          onChange={handleScaleChange}
                        />
                        {zoom ? (
                          <div
                            className="flex items-center rounded-full border border-on-surface-variant p-3"
                            onClick={handleZoomOut}
                            onKeyDown={() => {}}
                          >
                            <IconZoomOut />
                          </div>
                        ) : (
                          <div
                            className="flex items-center rounded-full border border-on-surface-variant p-3"
                            onClick={handleZoomIn}
                            onKeyDown={() => {}}
                          >
                            <IconZoomIn />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${!zoom && '!h-[calc(100vh-270px)]'}`}>
                  <ChartAll scaleUnit={scale} grouping={isAreaGrouping === 'true'} isAreaGrouping={isAreaGrouping} />
                </div>
              </div>
            )}
            {activeTab === 'maps' && (
              <div className="h-[calc(100vh-165px)]">
                <MapComponent className="h-[calc(100vh-165px)]" markers={markers} zoom={6} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        showCloseIcon={false}
        classNames={{ modal: '!bg-gray-footer !p-6 rounded-2xl !max-w-[640px]' }}
      >
        <form onSubmit={handleSubmit(handleCreateHouses)}>
          <Heading level={4} text="ハウスの新規作成" className="mb-4" />
          <div className="flex flex-col gap-6">
            <div>
              <Label className="label lg mb-2" text="ハウスの名前（必須）" />
              <Controller
                name="title"
                control={control}
                defaultValue=""
                rules={{
                  required: MESSAGE_REQUIRED_HOUSE_NAME,
                }}
                render={({ field }) => (
                  <ForwardedInput
                    {...field}
                    type="text"
                    className={'border-1 h-12 w-full rounded border-on-surface-variant px-4 py-1'}
                    hasError={!!errors?.title?.message}
                  />
                )}
              />
              <ErrorTooltip errorMessage={errors['title']?.message?.toString()} hasError={!!errors?.title?.message} />
            </div>
            <div>
              <Label text="ハウス面積（㎡）" className="label lg mb-2" />
              <Paragraph
                className="regular-text lg mb-2 !tracking-[0px]"
                content="ハウスの面積は後から変更できません。変更したい場合は新規作成してください。"
              />
              <Controller
                name="area"
                control={control}
                defaultValue=""
                rules={{
                  min: {
                    value: 1,
                    message: MESSAGE_VALIDATE_MIN(1),
                  },
                  required: MESSAGE_REQUIRED_DEFAULT,
                }}
                render={({ field }) => (
                  <StepperNumberFormat
                    {...field}
                    className="w-full lg:max-w-[160px]"
                    step={1}
                    onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange)}
                    setValue={setValue}
                    name="area"
                    min={0}
                    hasError={!!errors?.area?.message}
                  />
                )}
              />
              <ErrorTooltip errorMessage={errors['area']?.message?.toString()} hasError={!!errors?.area?.message} />
            </div>
            <div className="flex flex-col">
              <Text className="label lg mb-2" text="画像" />
              <div>
                <div className="flex gap-1">
                  {filesPreview.map((url, index) => {
                    return (
                      <div key={url} className="col-sm-1">
                        <div className="card relative">
                          <img className="h-[88px] w-[88px] rounded-lg object-cover" src={url} alt="" />
                          <button className="absolute right-2 top-2" onClick={() => removeImage(index)}>
                            <RemoveImage />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <label
                  htmlFor="file-upload"
                  className="mt-4 inline-block h-10 w-full cursor-pointer rounded-xl border border-br-gray !bg-gray-footer px-6 py-2.5 text-center text-sm text-on-primary transition-all duration-200 ease-in hover:!bg-black/[0.08] active:scale-[0.98] active:transition-all active:duration-200 active:ease-in lg:w-auto"
                >
                  画像のアップロード
                </label>
                <input id="file-upload" type="file" multiple className="hidden" onChange={changeHandler} />
              </div>
            </div>
            <div className="mt-6 flex justify-end gap-2">
              <PrimaryButton type="button" text="キャンセル" variant="text" onClick={onCancelClick} />
              <PrimaryButton type="submit" text="保存" variant="outlined" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ListHouse;
