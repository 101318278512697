import React from 'react';

type Props = {
  className?: string;
};

const IconSowing: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect
          x="12.5439"
          y="-0.727905"
          width="18"
          height="18"
          rx="2"
          transform="rotate(45 12.5439 -0.727905)"
          fill="#9F9300"
        />
      </svg>
    </>
  );
};

export default IconSowing;
