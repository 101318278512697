import BodyText from '@/components/atoms/AText/BodyText';
import Label from '@/components/atoms/AText/Label';
import { useOutsideClick } from '@/hooks/useClickOutside';
import cn from '@/utils/general';
import { nanoid } from 'nanoid';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { ISheet } from '@/stores/business/model';
import { activeFavouriteSheet, copySheetApi, editNameSheetApi } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import Star from '@/components/icon/Star';
import { setSheetActive } from '@/stores/business/slice';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import { deleteSheetApi } from '@/stores/chart/services';
import { setShowToast } from '@/stores/common';

type MenuListProps = {
  handleListShow: () => void;
  handleEditMode: () => void;
  handleShowModal: () => void;
  item: ISheet;
};

const MenuList = ({ handleListShow, handleEditMode, item, handleShowModal }: MenuListProps) => {
  const refList = useRef(null);
  const dispatch = useDispatch();
  const { businessId } = useParams();
  useOutsideClick(refList, handleListShow);

  const copySheet = () => {
    if (businessId && item.id) {
      dispatch(copySheetApi({ businessId: +businessId, sheetId: item.id }));
    }
  };

  const handleFavourite = () => {
    if (businessId && item.id) {
      dispatch(activeFavouriteSheet({ businessId: +businessId, sheetId: item.id }));
    }
  };

  return (
    <ul
      ref={refList}
      className="w-full items-end rounded bg-surface-at-2 py-2 shadow-elevation-M3-light-2"
      onClick={handleListShow}
    >
      <li
        className="flex h-[40px] w-full items-center gap-2 pl-4 pr-6 hover:bg-black/[0.08]"
        onClick={copySheet}
        onKeyDown={() => {}}
      >
        <BodyText tag="span" size="large">
          コピーの作成
        </BodyText>
      </li>
      <li
        className="flex h-[40px] w-full items-center gap-2 pl-4 pr-6 hover:bg-black/[0.08]"
        onClick={handleEditMode}
        onKeyDown={() => {}}
      >
        <BodyText tag="span" size="large">
          名前の変更
        </BodyText>
      </li>
      <li
        className="flex h-[40px] w-full items-center gap-2 pl-4 pr-6 hover:bg-black/[0.08]"
        onClick={handleFavourite}
        onKeyDown={() => {}}
      >
        <BodyText tag="span" size="large">
          {item.status ? 'スターをはずす' : 'スターをつける'}
        </BodyText>
      </li>
      <li
        className="flex h-[40px] w-full items-center gap-2 pl-4 pr-6 hover:bg-black/[0.08]"
        onClick={handleShowModal}
        onKeyDown={() => {}}
      >
        <BodyText tag="span" size="large" className="text-error">
          削除
        </BodyText>
      </li>
    </ul>
  );
};

type SheetNavItemProps = {
  item: ISheet;
  isActive: boolean;
};

const SheetNavItem = ({ item, isActive }: SheetNavItemProps) => {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [listIsShowed, setListIsShowed] = useState(false);
  const [content, setContent] = useState<string>(item.title);
  const [showDeleteSheet, setShowDeleteSheet] = useState<boolean>(false);
  const { sheetActive, listSheet } = useSelector((state: RootState) => state.business);
  const ref = useRef<HTMLInputElement>(null);
  const [fixedPosition, setFixedPosition] = useState({
    top: 0,
    left: 0,
  });

  const handleEditMode = () => {
    setEditMode(true);
  };
  const handleCloseEditMode = () => {
    setEditMode(false);
  };
  const handleListShow = () => {
    const childRect = document.getElementById(`sheet-${item.id}`)?.getBoundingClientRect();

    setFixedPosition({
      top: childRect?.top ?? 0,
      left: childRect?.left ?? 0,
    });
    setListIsShowed(!listIsShowed);
  };

  const editNameSheetEnter = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
      await editNameSheet();
    }
  };

  const editNameSheet = async () => {
    if (item.id && sheetActive && businessId) {
      await dispatch(
        editNameSheetApi({
          businessId: +businessId,
          id: item.id,
          title: content,
        }),
      );
      setEditMode(false);
    }
  };

  const handleActiveSheet = () => {
    dispatch(setSheetActive(item.id));
  };

  const handleShowModal = () => {
    setShowDeleteSheet(true);
  };

  const handleCloseModal = () => {
    setShowDeleteSheet(false);
  };

  const handleDeleteSheet = () => {
    if (businessId && item.id && listSheet.length > 1) {
      dispatch(deleteSheetApi({ sheetId: item.id, businessId: +businessId, callback: () => handleCloseModal() }));
    } else {
      dispatch(setShowToast({ message: 'このシートを削除することはできません', type: 'error' }));
      handleCloseModal();
    }
  };

  useOutsideClick(ref, () => {
    handleCloseEditMode();
    editNameSheet();
  });
  return (
    <>
      <button
        id={`sheet-${item.id}`}
        type="button"
        className={cn(
          'relative flex w-[160px] flex-row items-center bg-transparent px-2 py-3 text-on-surface-variant',
          editMode ? ' bg-primary py-[6px]' : 'justify-between hover:bg-black/[0.08]',
          isActive && '!bg-primary',
        )}
        onDoubleClick={handleEditMode}
      >
        {!!item.status && <Star />}
        {listIsShowed && !editMode && (
          <div
            style={{
              top: fixedPosition.top - 70,
              left: fixedPosition.left + 10,
            }}
            className="fixed flex w-[200px] -translate-x-2 -translate-y-[calc(50%+26px)] items-end duration-200"
          >
            <MenuList
              handleListShow={handleListShow}
              handleShowModal={handleShowModal}
              handleEditMode={handleEditMode}
              item={item}
            />
          </div>
        )}
        {editMode ? (
          <input
            ref={ref}
            type="text"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => editNameSheetEnter(e)}
            className="h-9 w-full rounded border border-outline px-2 py-1 text-sm font-normal leading-[175%] -tracking-[0.5px] text-on-surface placeholder:text-sm focus:border-on-surface focus:outline-none focus:ring-on-surface focus-visible:outline-none"
          />
        ) : (
          <Label
            tag="span"
            size="large"
            className="block w-full overflow-hidden text-ellipsis whitespace-nowrap text-left  text-on-surface-variant"
          >
            <div onClick={handleActiveSheet} onKeyDown={() => {}}>
              {item.title}
            </div>
          </Label>
        )}
        {!editMode && (
          <span className="flex" onClick={handleListShow}>
            <i className="icon-24px icon-arrow_drop_down h-6 w-6 bg-on-surface-variant" />
          </span>
        )}
        <CommonModal
          open={showDeleteSheet}
          title={'シートの削除'}
          textBtnCancel={'キャンセル'}
          textBtnSubmit={'削除'}
          buttonVariant={'delete'}
          handleClose={handleCloseModal}
          handleClick={handleDeleteSheet}
          boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        >
          <ModalDelete
            contentDelete={
              'シートの名前を削除します。シート内にある損益計画、栽培計画も削除されます。この操作は元に戻せません。'
            }
          />
        </CommonModal>
      </button>
    </>
  );
};

type BusinessFooterProps = {
  data: ISheet[];
};

const BusinessFooter = ({ data }: BusinessFooterProps) => {
  const { sheetActive } = useSelector((state: RootState) => state.business);

  return (
    <>
      <div className="flex overflow-x-auto">
        {data.map((item) => (
          <SheetNavItem key={nanoid(5)} item={item} isActive={item.id === sheetActive} />
        ))}
      </div>
    </>
  );
};

export default BusinessFooter;
