import CommonModal from '@/components/atoms/Modal/CommonModal';
import PublicChip from '@/components/atoms/PublicChip';
import TagsClose from '@/components/atoms/Tags/TagsClose';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useState } from 'react';
import ModalDelete from '../ModalComponent/ModalDelete';
import { defaultFormat } from '@/utils/formatTime';
import { TDocument } from '@/api/types/questionTypes';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteDocument } from '@/stores/document';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { shareType } from '@/types/enum';
import { nanoid } from 'nanoid';

interface ListItemProps {
  document: TDocument;
}

const ListIiteratureComponent: React.FC<ListItemProps> = (props: ListItemProps) => {
  const { document } = props;
  const { organizationId } = useParams();
  const [showModalDeleteDocument, setShowModalDeleteDocument] = useState(false);
  const dispatchListIiterature = useDispatch();

  const handleDeleteDocument = () => {
    handleCloseModalDocument();
    dispatchListIiterature(deleteDocument({ documentId: document.id, organizationId }));
  };

  const handleCloseModalDocument = () => {
    setShowModalDeleteDocument(false);
  };

  return (
    <>
      <Link
        to={replacePathParams(EPath.documentDetail, { organizationId: organizationId ?? '', id: document.id ?? '' })}
      >
        <li
          key={nanoid(5)}
          className="questionComponentListColumn flex min-h-[64px] items-center gap-1 border-b border-outline-variant"
        >
          <div className="whitespace-pre-wrap py-2 pl-[16px]">
            <DefaultText
              variant="text-status"
              className="text-primary-dark truncate-text my-auto break-all text-[11px] text-on-surface-variant 2xl:text-[11px]"
            >
              {document.organization?.title}
            </DefaultText>
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark my-auto break-all py-[4px] text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {document?.title}
            </DefaultText>
            <div className="flex gap-[4px]">
              {document.tagRelation.map((tag) => (
                <TagsClose text={tag?.title} key={tag?.id} />
              ))}
            </div>
          </div>
          <div />
          <div className="flex justify-center">
            <PublicChip variant={document.publish === shareType.public ? 'public' : 'private'} />
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {document?.user?.name}
            </DefaultText>
          </div>
          <div className="flex justify-center">
            <DefaultText
              variant="text-tableBody"
              className="text-primary-dark break-all text-[14px] text-on-surface 2xl:text-[14px]"
            >
              {defaultFormat(document.createdAt)}
            </DefaultText>
          </div>
        </li>
      </Link>
      <CommonModal
        open={showModalDeleteDocument}
        title={'文献の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModalDocument}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
        handleClick={handleDeleteDocument}
      >
        <ModalDelete contentDelete={'文献を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </>
  );
};

export default ListIiteratureComponent;
