import style from './style.module.sass';
import ModalHeader from '@/components/molecules/ModalHeader';
import AllFilter from '@/components/molecules/ModalBody/AllFilterBody';
import FooterGeneral from '@/components/molecules/ModalFooter/FooterGeneral';
import { CONSTANTS } from '@/utils/constants/consts';
import cn from '@/utils/general';
import { IDataFilter, IFilter, ITypeFilter } from '@/types/question';
import { IPagination } from '@/types/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores';

type ModalFilterProps = {
  className?: string;
  handleToggleFilterBlock: () => void;
  handleSubmitFilter: () => void;
  data: ITypeFilter[];
  dataOption: IFilter<IPagination, IDataFilter>;
  setData: (value: ITypeFilter[]) => void;
};
function ModalFilter(props: ModalFilterProps) {
  const { className, handleToggleFilterBlock, data, setData, handleSubmitFilter, dataOption } = props;
  const { width } = useSelector((state: RootState) => state.common);

  const isMobile = width <= CONSTANTS.SIZE_MOBILE;
  const containerMergeClassName = cn(
    `${isMobile ? style.modal_sp : style.modal} z-50 lg:overflow-clip overflow-scroll-modal`,
    className,
  );
  return (
    <div
      className={containerMergeClassName}
      style={{
        overflow: 'visible',
        background:
          'var(--surface-at-3, linear-gradient(0deg, rgba(54, 49, 0, 0.10) 0%, rgba(54, 49, 0, 0.10) 100%), #FDFBF7)',
      }}
    >
      <ModalHeader
        text="フィルター"
        isMobile={isMobile}
        handleToggleFilterBlock={handleToggleFilterBlock}
        handleSubmitFilter={handleSubmitFilter}
      ></ModalHeader>
      <AllFilter listFilter={data} setData={setData} isMobile={isMobile} dataOption={dataOption}></AllFilter>
      {!isMobile && (
        <FooterGeneral
          handleToggleFilterBlock={handleToggleFilterBlock}
          handleSubmitFilter={handleSubmitFilter}
        ></FooterGeneral>
      )}
    </div>
  );
}
export default ModalFilter;
