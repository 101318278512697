import DefaultText from '../../atoms/Text/DefaultText';
import React, { useEffect } from 'react';
import BaseButton from '@/components/atoms/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { useLocation, useNavigate } from 'react-router-dom';
import { activeMember, getInfoInvite } from '@/stores/auth';
import { replacePathParams } from '@/utils/helpers';
import { EPath } from '@/route/route';
import { TITLE_WEB } from '@/utils/constants/consts';

export const InfoUserInvite = () => {
  const authState = useSelector((state: RootState) => state.auth);

  return (
    <>
      <div className="flex">
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          組織:
        </DefaultText>
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          {authState.infoUserInvite?.organizationTitle}
        </DefaultText>
      </div>
      <div className="flex">
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          名前:
        </DefaultText>
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          {authState.infoUserInvite?.name}
        </DefaultText>
      </div>
      <div className="flex">
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          ログインメールアドレス:
        </DefaultText>
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          {authState.infoUserInvite?.email}
        </DefaultText>
      </div>
      <div className="flex pt-6">
        <DefaultText variant="text-link" className="text-sm text-on-surface-2">
          お名前とメールアドレスを確認し、ログインしてください。
        </DefaultText>
      </div>
    </>
  );
};

const Invitation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state: RootState) => state.auth);
  const token = location.pathname.split('/')[location.pathname.split('/').length - 1];

  useEffect(() => {
    if (token) {
      dispatch(getInfoInvite(token));
    }
  }, [token]);

  const handleSubmitEmail = () => {
    const formData = { email: authState.infoUserInvite?.email, token };
    dispatch(activeMember({ ...formData, callback: () => navigate(replacePathParams(EPath.password, {})) }));
  };

  return (
    <div className="container m-auto h-588 max-w-480 bg-white px-6 lg:px-0">
      <div className="flex h-12 w-full pt-20 2xl:pt-40">
        <DefaultText variant="title" className="block h-12 w-full text-center text-[32px] text-on-surface">
          {TITLE_WEB} App
        </DefaultText>
      </div>
      <div className="w-full pt-20">
        <InfoUserInvite />
        <div className="flex pt-6">
          <DefaultText variant="text-link" className="text-sm text-on-surface-2">
            登録されているメールアドレスにワンタイムパスワードを送信します。
          </DefaultText>
        </div>
        <div className="flex">
          <DefaultText variant="text-link" className="text-sm text-on-surface-2">
            次の画面で、ワンタイムパスワードを入力して、ログインしてく獺祭。
          </DefaultText>
        </div>
        <div className="pt-6" onClick={handleSubmitEmail} onKeyDown={() => {}}>
          <BaseButton variant="primary" type="button" className="w-full md:w-[77px]">
            <DefaultText
              variant="length"
              className="text-sm text-on-primary transition-all duration-200 ease-in active:scale-[0.98] active:transition-all active:duration-200 active:ease-in"
            >
              送信
            </DefaultText>
          </BaseButton>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
