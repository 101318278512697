import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.css';
import style from './style.module.sass';
import { IconClose } from '@/components/icon/IconClose/IconClose';

type Props = {
  image?: string | null;
  path?: string | null;
  handleCloseImg?: () => void;
};

const DisplayImage = (props: Props) => {
  const { image, path, handleCloseImg } = props;

  return (
    <div className={style.displayImg}>
      {image ? (
        <img src={image} alt="" />
      ) : (
        <img src={path ? `${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(path)}` : '/media.png'} alt="" />
      )}
      <span className={style.close} onClick={handleCloseImg} onKeyDown={() => {}}>
        <IconClose />
      </span>
    </div>
  );
};

export default DisplayImage;
