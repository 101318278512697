import { ErrorTooltip } from '@/components/atoms/Text/ErrorTooltip';
import { ICircleMultiple } from '@/stores/business/model';
import { getValidateMsg, MESSAGE_VALIDATE_MAX, MESSAGE_VALIDATE_MIN } from '@/utils/constants/messageValidate';
import { handleOnChangeInputNumberFormat } from '@/utils/helpers';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInputRow from '../FormInputRow';
import FormEditWraper from './FormEditWraper';
import StepperNumberFormat from '@/components/atoms/StepperNumberFormat/StepperNumberFormat';

interface Props {
  defaultValue: number;
  min?: number;
  max?: number;
  step: number;
  name: string;
  labelLeft?: string;
  labelRight?: string;
  labelTop?: string;
  onSubmit: (data: ICircleMultiple) => void;
  isDisable: boolean;
}
const FormEdit2 = (props: Props) => {
  const { defaultValue, min, max, step, name, onSubmit, labelRight, labelTop, labelLeft, isDisable = false } = props;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICircleMultiple>();

  useEffect(() => {
    setValue(name, String(defaultValue));
  }, [defaultValue]);

  const rules: {
    validate?: (value: string) => string | undefined;
    min?: { value: string; message: string };
    max?: { value: string; message: string };
  } = {
    validate: (value) => {
      return getValidateMsg(value, step);
    },
  };

  if (min) {
    rules.min = {
      value: String(min),
      message: MESSAGE_VALIDATE_MIN(min), // Custom error message for min
    };
  }

  if (max) {
    rules.max = {
      value: String(max),
      message: MESSAGE_VALIDATE_MAX(max), // Custom error message for min
    };
  }

  return (
    <form
      className="w-[calc(100vw-64px)] max-w-[479px] rounded-2xl bg-gray-background p-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormEditWraper isDisable={isDisable}>
        <div>
          <div className="text-sm font-normal">{labelTop}</div>
          <FormInputRow
            labelRight={labelRight ?? '円/月'}
            labelLeft={labelLeft ?? ''}
            classNames={labelLeft && 'flex-col !items-start sm:flex-row sm:min-w-[410px]'}
          >
            <Controller
              name={name}
              control={control}
              defaultValue=""
              rules={rules}
              render={({ field }) => (
                <StepperNumberFormat
                  {...field}
                  className="regular-text lg text-on-surface"
                  onChange={(e) => handleOnChangeInputNumberFormat(e, field.onChange, true)}
                  setValue={setValue}
                  min={min}
                  max={max}
                  step={step}
                  name={name}
                  isHiddenArrowUpDown={false}
                  isFiledNumberDialog={true}
                  hasError={!!errors?.name?.message}
                />
              )}
            />
          </FormInputRow>
          <ErrorTooltip errorMessage={errors?.[name]?.message ?? ''} hasError={!!errors?.[name]?.message} />
        </div>
      </FormEditWraper>
    </form>
  );
};

export default FormEdit2;
