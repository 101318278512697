import { ReactNode } from 'react';
import Modal from 'react-responsive-modal';
import PrimaryButton from '../PrimaryButton';
import DefaultText from '../Text/DefaultText';

type Props = {
  breadcrumb?: string;
  title?: string;
  open: boolean;
  handleClose: () => void;
  handleClick?: () => void;
  children?: ReactNode;
  titleButton?: string;
  textBtnCancel?: string;
  textBtnSubmit?: string;
  buttonVariant?: 'filled' | 'outlined' | 'text' | 'delete';
  boxClassName?: string;
  buttonHidden?: boolean;
};

const CommonModal = ({
  breadcrumb,
  title,
  open,
  handleClose,
  handleClick,
  children,
  textBtnCancel,
  textBtnSubmit,
  buttonVariant = 'filled',
  boxClassName,
  buttonHidden = false,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      center
      classNames={{ modal: `!bg-gray-footer rounded-2xl ${boxClassName}` }}
    >
      <div className="modal">
        <div className="container">
          <>
            <DefaultText variant="text-status" className="text-[11px] text-on-surface-variant">
              {breadcrumb}
            </DefaultText>
            <DefaultText variant="title-children" className="text-lg text-on-surface">
              {title}
            </DefaultText>
          </>
          {children}
          {!buttonHidden && (
            <div className="flex justify-end gap-2">
              <div
                className="my-auto cursor-pointer rounded-xl px-3 pb-[10px] pt-[9px] transition-all duration-200 ease-in hover:bg-black/[0.08] active:scale-[0.98] active:transition-all active:duration-200 active:ease-in"
                onClick={handleClose}
              >
                <DefaultText variant="length" className="text-sm text-on-primary">
                  {textBtnCancel}
                </DefaultText>
              </div>
              <PrimaryButton text={textBtnSubmit} variant={buttonVariant} type="submit" onClick={handleClick} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
