import React, { useRef, useState } from 'react';
import FormEdit1 from '@/components/molecules/BusinessTable/FormEdit/FormEdit1';
import FormEdit2 from '@/components/molecules/BusinessTable/FormEdit/FormEdit2';
import FormEdit3 from '@/components/molecules/BusinessTable/FormEdit/FormEdit3';
import FormEdit4 from '@/components/molecules/BusinessTable/FormEdit/FormEdit4';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { useOutsideClick } from '@/hooks/useClickOutside';
import { setDialogType } from '@/stores/business/slice';
import {
  updateAssociationFees,
  updateConsultingLaborCosts,
  updateEquipmentRepairCosts,
  updateFertilizerCost,
  updateGroundRent,
  updateInsuranceSupportCosts,
  updateLogisticsCosts,
  updateMaterialCosts,
  updateMiscellaneousExpensesCosts,
  updateMiscellaneousIncome,
  updateOfficePersonnelCosts,
  updateOtherAdministrativeExpenses,
  updateOtherSalesCosts,
  updatePackingMaterialCosts,
  updatePesticidesHygieneCosts,
  updatePromotionalCosts,
  updateSalaryPartTime,
  updateSeedCostPrice,
  updateShippingFee,
  updateSpecificWorkContract,
  updateSubsidyIncome,
  updateToolsCost,
  updateWorkClothingCosts,
  updateWorkContract,
} from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { ICircleMultiple, IDataUpdate } from '@/stores/business/model';

type Formtype = {
  [key: string]: React.ReactNode;
};

const Dialog = () => {
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const { dialogType, dataBusinessPlanResult, sheetActive } = useSelector((state: RootState) => state.business);

  const submitForm = async (name: string, data: ICircleMultiple) => {
    if (businessId && sheetActive) {
      setIsDisable(true);
      const dataUpdate: IDataUpdate = {};
      for (const key in data) {
        dataUpdate[key] = Number(parseFloat(data[key].replace(/,/g, '')));
      }

      switch (name) {
        case 'specificWorkContract':
          await dispatch(updateSpecificWorkContract({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'workContract':
          await dispatch(updateWorkContract({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'miscellaneousIncome':
          await dispatch(updateMiscellaneousIncome({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'subsidyIncome':
          await dispatch(updateSubsidyIncome({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'salaryPartTime':
          await dispatch(updateSalaryPartTime({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'consultingLaborCosts':
          await dispatch(updateConsultingLaborCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'equipmentRepairCosts':
          await dispatch(updateEquipmentRepairCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'fertilizerCost':
          await dispatch(updateFertilizerCost({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'pesticidesHygieneCosts':
          await dispatch(updatePesticidesHygieneCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'toolsCost':
          await dispatch(updateToolsCost({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'materialCosts':
          await dispatch(updateMaterialCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'workClothingCosts':
          await dispatch(updateWorkClothingCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'miscellaneousExpensesCosts':
          await dispatch(
            updateMiscellaneousExpensesCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }),
          );
          break;
        case 'promotionalCosts':
          await dispatch(updatePromotionalCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'packingMaterialCosts':
          await dispatch(updatePackingMaterialCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'logisticsCosts':
          await dispatch(updateLogisticsCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'otherSalesCosts':
          await dispatch(updateOtherSalesCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'shippingFee':
          await dispatch(updateShippingFee({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'officePersonnelCosts':
          await dispatch(updateOfficePersonnelCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'groundRent':
          await dispatch(updateGroundRent({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'insuranceSupportCosts':
          await dispatch(updateInsuranceSupportCosts({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'associationFees':
          await dispatch(updateAssociationFees({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
        case 'otherAdministrativeExpenses':
          await dispatch(
            updateOtherAdministrativeExpenses({ businessId: +businessId, sheetId: sheetActive, dataUpdate }),
          );
          break;
        case 'seedCostPrice':
          await dispatch(updateSeedCostPrice({ businessId: +businessId, sheetId: sheetActive, dataUpdate }));
          break;
      }
      setIsDisable(false);
    }
  };

  const formtype: Formtype = {
    form1: <FormEdit1 />,
    form2: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.specificWorkContract ?? 0}
        min={0}
        max={1000000}
        step={1}
        name="specificWorkContract"
        onSubmit={(data) => submitForm('specificWorkContract', data)}
      />
    ),
    form3: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.workContract ?? 0}
        min={0}
        max={1000000}
        step={1}
        name="workContract"
        onSubmit={(data) => submitForm('workContract', data)}
      />
    ),
    form4: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.miscellaneousIncome ?? 0}
        min={0}
        max={1000000}
        step={1}
        name="miscellaneousIncome"
        onSubmit={(data) => submitForm('miscellaneousIncome', data)}
      />
    ),
    form5: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.subsidyIncome ?? 750000}
        min={0}
        max={3000000}
        step={10000}
        name="subsidyIncome"
        labelRight="円/年"
        onSubmit={(data) => submitForm('subsidyIncome', data)}
      />
    ),
    form6: (
      <FormEdit3
        data={[
          {
            defaultValue: dataBusinessPlanResult.salaryPartTime ?? 1200,
            min: 990,
            max: 3000,
            step: 10,
            name: 'salaryPartTime',
            labelLeft: 'パート時給単価',
            labelRight: '円/時間',
          },
          {
            defaultValue: dataBusinessPlanResult.experience ?? 1,
            min: 0.1,
            max: 5,
            step: 0.1,
            name: 'experience',
            labelLeft: '習熟度係数',
            labelRight: '',
          },
        ]}
      />
    ),
    form7: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.consultingLaborCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="consultingLaborCosts"
        onSubmit={(data) => submitForm('consultingLaborCosts', data)}
      />
    ),
    form8: (
      <FormEdit3
        data={[
          {
            defaultValue: dataBusinessPlanResult.electricityPrice ?? 20,
            min: 0,
            max: 100,
            step: 1,
            name: 'electricityPrice',
            labelLeft: '電気 単価',
            labelRight: '円/kWh',
          },
          {
            defaultValue: dataBusinessPlanResult.heavyOilPrice ?? 100,
            min: 0,
            max: 300,
            step: 1,
            name: 'heavyOilPrice',
            labelLeft: '燃料 単価',
            labelRight: '円/L',
          },
        ]}
      />
    ),
    form9: (
      <FormEdit4
        data={[
          {
            title: '新品',
            data: [
              {
                defaultValue: dataBusinessPlanResult.newBuildings ?? 10000,
                name: 'newBuildings',
                labelLeft: '建物・構築物',
                labelRight: '円',
                min: 0,
                step: 1,
              },
              {
                defaultValue: dataBusinessPlanResult.newMechanicalEquipment ?? 10000,
                name: 'newMechanicalEquipment',
                labelLeft: '機械設備',
                labelRight: '円',
                min: 0,
                step: 1,
              },
            ],
          },
          {
            title: '中古',
            data: [
              {
                defaultValue: dataBusinessPlanResult.usedBuildings ?? 10000,
                name: 'usedBuildings',
                labelLeft: '建物・構築物',
                labelRight: '円',
                min: 0,
                step: 1,
              },
              {
                defaultValue: dataBusinessPlanResult.usedMechanicalEquipment ?? 10000,
                name: 'usedMechanicalEquipment',
                labelLeft: '機械設備',
                labelRight: '円',
                min: 0,
                step: 1,
              },
            ],
          },
        ]}
      />
    ),
    form10: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.equipmentRepairCosts ?? 10000}
        min={0}
        max={10000000}
        step={10000}
        name="equipmentRepairCosts"
        onSubmit={(data) => submitForm('equipmentRepairCosts', data)}
      />
    ),
    form11: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.seedCostPrice ?? 25}
        min={0}
        max={100}
        step={1}
        labelRight="円/株"
        name="seedCostPrice"
        onSubmit={(data) => submitForm('seedCostPrice', data)}
      />
    ),
    form12: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.fertilizerCost ?? 350000}
        min={0}
        max={1000000}
        step={10000}
        name="fertilizerCost"
        labelLeft="10aあたり"
        labelRight="円/年間"
        onSubmit={(data) => submitForm('fertilizerCost', data)}
      />
    ),
    form13: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.pesticidesHygieneCosts ?? 150000}
        min={0}
        max={200000}
        step={10000}
        name="pesticidesHygieneCosts"
        labelLeft="10aあたり"
        onSubmit={(data) => submitForm('pesticidesHygieneCosts', data)}
      />
    ),
    form14: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.toolsCost ?? 10000}
        min={0}
        max={10000000}
        step={10000}
        name="toolsCost"
        onSubmit={(data) => submitForm('toolsCost', data)}
      />
    ),
    form15: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.materialCosts ?? 25}
        min={0}
        max={100}
        step={1}
        labelRight="円/株"
        name="materialCosts"
        onSubmit={(data) => submitForm('materialCosts', data)}
      />
    ),
    form16: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.workClothingCosts ?? 10000}
        min={0}
        max={10000000}
        step={10000}
        name="workClothingCosts"
        onSubmit={(data) => submitForm('workClothingCosts', data)}
      />
    ),
    form17: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.miscellaneousExpensesCosts ?? 10000}
        min={0}
        max={10000000}
        step={10000}
        name="miscellaneousExpensesCosts"
        onSubmit={(data) => submitForm('miscellaneousExpensesCosts', data)}
      />
    ),
    form18: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.promotionalCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="promotionalCosts"
        onSubmit={(data) => submitForm('promotionalCosts', data)}
      />
    ),
    form19: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.packingMaterialCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="packingMaterialCosts"
        onSubmit={(data) => submitForm('packingMaterialCosts', data)}
      />
    ),
    form20: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.logisticsCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="logisticsCosts"
        onSubmit={(data) => submitForm('logisticsCosts', data)}
      />
    ),
    form21: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.otherSalesCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="otherSalesCosts"
        onSubmit={(data) => submitForm('otherSalesCosts', data)}
      />
    ),
    form22: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.shippingFee ?? 0}
        min={0}
        max={100}
        step={1}
        labelTop="商品売上に対しての比率"
        labelRight="%"
        name="shippingFee"
        onSubmit={(data) => submitForm('shippingFee', data)}
      />
    ),
    form23: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.officePersonnelCosts ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="officePersonnelCosts"
        onSubmit={(data) => submitForm('officePersonnelCosts', data)}
      />
    ),
    form24: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.groundRent ?? 0}
        labelRight="円/年"
        labelLeft="10aあたり"
        min={0}
        max={100000000000}
        step={1}
        name="groundRent"
        onSubmit={(data) => submitForm('groundRent', data)}
      />
    ),
    form25: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.insuranceSupportCosts ?? 10000}
        min={0}
        max={10000000}
        step={10000}
        name="insuranceSupportCosts"
        onSubmit={(data) => submitForm('insuranceSupportCosts', data)}
      />
    ),
    form26: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.associationFees ?? 0}
        min={0}
        max={10000000}
        step={1000}
        labelRight="円/年"
        name="associationFees"
        onSubmit={(data) => submitForm('associationFees', data)}
      />
    ),
    form27: (
      <FormEdit4
        data={[
          {
            title: '新品',
            data: [
              {
                defaultValue: dataBusinessPlanResult.newBuildings ?? 10000,
                name: 'newBuildings',
                labelLeft: '建物・構築物',
                labelRight: '円',
                min: 0,
                step: 1,
              },
              {
                defaultValue: dataBusinessPlanResult.newMechanicalEquipment ?? 10000,
                name: 'newMechanicalEquipment',
                labelLeft: '機械設備',
                labelRight: '円',
                min: 0,
                step: 1,
              },
            ],
          },
          {
            title: '中古',
            data: [
              {
                defaultValue: dataBusinessPlanResult.usedBuildings ?? 10000,
                name: 'usedBuildings',
                labelLeft: '建物・構築物',
                labelRight: '円',
                min: 0,
                step: 1,
              },
              {
                defaultValue: dataBusinessPlanResult.usedMechanicalEquipment ?? 10000,
                name: 'usedMechanicalEquipment',
                labelLeft: '機械設備',
                labelRight: '円',
                min: 0,
                step: 1,
              },
            ],
          },
        ]}
      />
    ),
    form28: (
      <FormEdit2
        isDisable={isDisable}
        defaultValue={dataBusinessPlanResult.otherAdministrativeExpenses ?? 0}
        min={0}
        max={100000000000}
        step={1}
        name="otherAdministrativeExpenses"
        onSubmit={(data) => submitForm('otherAdministrativeExpenses', data)}
      />
    ),
  };

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    dispatch(setDialogType(''));
  });

  return <>{dialogType && <div ref={ref}>{formtype[dialogType]}</div>}</>;
};

export default Dialog;
