import { MouseEventHandler } from 'react';
import { OutLinedButton, TextButton } from '../../ButtonIcon';
import style from './style.module.sass';

type FooterGeneralProps = {
  handleToggleFilterBlock: MouseEventHandler<HTMLDivElement>;
  handleSubmitFilter: () => void;
};

function FooterGeneral({ handleToggleFilterBlock, handleSubmitFilter }: FooterGeneralProps) {
  return (
    <div className={style.modal_footer}>
      <div onClick={handleToggleFilterBlock}>
        <TextButton>
          <span className="font-medium text-on-primary">キャンセル</span>
        </TextButton>
      </div>
      <OutLinedButton onClick={handleSubmitFilter}>適用</OutLinedButton>
    </div>
  );
}

export default FooterGeneral;
