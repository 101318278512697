import { Column } from '@silevis/reactgrid';
import { CONSTANTS } from '@/utils/constants/consts';

export function getColumns(groupBy: string, listId: string[], width: number): Column[] {
  if (groupBy === 'year') {
    const data: { columnId: string; width: number }[] = [];
    let yearFocus = listId[0].split('/')[0];
    listId.forEach((yearMonth, index) => {
      const yearCurrent = yearMonth.split('/')[0];
      if (yearFocus !== yearCurrent) {
        data.push({ columnId: String(index), width: 96 });
      }

      yearFocus = yearCurrent;

      data.push({ columnId: yearMonth, width: 96 });

      if (index === listId.length - 1) {
        data.push({ columnId: '', width: 96 });
      }
    });
    return [
      {
        columnId: 'titlesColumn',
        width: width < CONSTANTS.SIZE_MOBILE ? 130 : 200,
      },
      ...data,
    ];
  } else {
    const column: { columnId: string }[] = [];
    listId.forEach((item) => {
      const year = item.split('/')[0];
      if (!column.find((item2) => item2.columnId === year)) {
        column.push({ columnId: year });
      }
    });
    return [
      {
        columnId: 'titlesColumn',
        width: width < CONSTANTS.SIZE_MOBILE ? 130 : 200,
      },
      ...column,
    ];
  }
}
