import PrimaryButton from '@/components/atoms/PrimaryButton';
import DefaultText from '@/components/atoms/Text/DefaultText';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Chart, ICircle } from '@/stores/chart/model';
import { defaultFormat, formatTimeForInputDate } from '@/utils/formatTime';
import { ICircleMultiple, IDataUpdateCircle } from '@/stores/business/model';
import { useDispatch, useSelector } from 'react-redux';
import { changeOrderCircle, updateCircleInfoMultipleBusinessPlan } from '@/stores/business/services';
import { useParams } from 'react-router-dom';
import { RootState } from '@/stores';
import { SortableContext } from '@dnd-kit/sortable';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableItem } from '@/components/molecules/ModalComponent/SortableItem';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import { deleteCirclePlan } from '@/stores/chart/services';
import moment from 'moment/moment';
import { MESSAGE_VALIDATE_DAY } from '@/utils/constants/messageValidate';
import { getDefaultTimeForDate } from '@/utils/helpers';

interface Props {
  areas: Chart[];
  handleClose: () => void;
}

const ModalSeedingWithinArea = (props: Props) => {
  const { areas, handleClose } = props;
  const dispatch = useDispatch();
  const { businessId } = useParams();
  const [areasState, setAreasState] = useState<ICircle[]>(
    areas.map((item) => ({
      id: +item.id,
      name: item.name,
      sowing: item.sowing,
      planting: item.planting,
      startOfHarvest: item.harvest.start,
      harvestFinished: item.harvest.end,
    })),
  );

  const { sheetActive } = useSelector((state: RootState) => state.business);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [circleDelete, setCircleDelete] = useState('');
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICircleMultiple>();

  useEffect(() => {
    areasState.forEach((item) => {
      setValue(`name-${item.id}`, item.name);
      setValue(`sowing-${item.id}`, formatTimeForInputDate(item.sowing));
      setValue(`planting-${item.id}`, formatTimeForInputDate(item.planting));
      setValue(`start_of_harvest-${item.id}`, formatTimeForInputDate(item.startOfHarvest));
      setValue(`harvest_finished-${item.id}`, formatTimeForInputDate(item.harvestFinished));
    });
  }, [areasState]);

  const handleUpdateMultipleCircle = (dataForm: ICircleMultiple) => {
    if (businessId) {
      const data: IDataUpdateCircle[] = [];
      areasState.forEach((item) => {
        data.push({
          id: item.idFake ?? item.id ?? 0,
          title: dataForm[`name-${item.id}`],
          seedDate: defaultFormat(dataForm[`sowing-${item.id}`]),
          transplantDate: defaultFormat(dataForm[`planting-${item.id}`]),
          harvestStartDate: defaultFormat(dataForm[`start_of_harvest-${item.id}`]),
          harvestEndDate: defaultFormat(dataForm[`harvest_finished-${item.id}`]),
        });
      });
      dispatch(
        updateCircleInfoMultipleBusinessPlan({
          businessId: +businessId,
          sheetId: sheetActive,
          areaId: areas[0].area.id,
          dataUpdate: data,
          callback: handleClose,
        }),
      );
    }
  };

  const dragEndEvent = (e: DragEndEvent) => {
    if (e && businessId && e.active.id && e?.over?.id && e.active.id !== e?.over?.id) {
      const areasStateCopy = [...areasState];
      const indexFrom = areasStateCopy.findIndex((item) => item.id == e.active.id);
      const indexTo = areasStateCopy.findIndex((item) => item.id == e?.over?.id);

      const itemMove = areasStateCopy.splice(indexFrom, 1);
      areasStateCopy.splice(indexTo, 0, ...itemMove);

      setAreasState(areasStateCopy);
      dispatch(
        changeOrderCircle({
          businessId: +businessId,
          sheetId: sheetActive,
          idFrom: +e.active.id,
          idTo: +e.over.id,
        }),
      );
    }
  };

  const handleModalDeleteClick = (id: string) => {
    setCircleDelete(id);
    setShowModalDelete(true);
  };

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleDeleteCircle = () => {
    if (sheetActive && businessId) {
      dispatch(
        deleteCirclePlan({
          businessId: +businessId,
          sheetId: sheetActive,
          circlePlanId: +circleDelete,
          callback: () => {
            setShowModalDelete(false);
            setAreasState(areasState.filter((item) => item?.id !== +circleDelete));
          },
        }),
      );
    }
  };

  const addItem = () => {
    const itemAdd: ICircle = {
      id: new Date().getTime(),
      idFake: new Date().getTime(),
      name: '',
      sowing: moment().format('YYYY/MM/DD'),
      planting: moment().format('YYYY/MM/DD'),
      startOfHarvest: moment().format('YYYY/MM/DD'),
      harvestFinished: moment().format('YYYY/MM/DD'),
    };
    setAreasState([...areasState, itemAdd]);

    setTimeout(() => {
      const listCircle = document.getElementById('list-circle');
      if (listCircle) {
        listCircle.scrollTop = listCircle.scrollHeight;
      }
    }, 0);
  };

  const validateCustomRule = (value: string, name: string, id: number) => {
    const dateValue = getDefaultTimeForDate(value);
    const sowing = getDefaultTimeForDate(watch(`sowing-${id}`));
    const planting = getDefaultTimeForDate(watch(`planting-${id}`));
    const startOfHarvest = getDefaultTimeForDate(watch(`start_of_harvest-${id}`));
    if (name.includes('planting')) {
      if (dateValue <= sowing) {
        return MESSAGE_VALIDATE_DAY;
      }
    } else if (name.includes('start_of_harvest')) {
      if (dateValue <= planting || dateValue <= sowing) {
        return MESSAGE_VALIDATE_DAY;
      }
    } else if (name.includes('harvest_finished')) {
      if (dateValue <= sowing || dateValue <= planting || dateValue <= startOfHarvest) {
        return MESSAGE_VALIDATE_DAY;
      }
    }
    return undefined;
  };

  return (
    <div className=" h-[80vh] w-[auto] w-auto">
      <form className="h-full" onSubmit={handleSubmit(handleUpdateMultipleCircle)}>
        <DndContext onDragEnd={dragEndEvent}>
          <div className="flex h-[94%] flex-col py-6">
            <div className="grow overflow-x-auto">
              <div className="ml-3 flex w-[1105px] gap-4 px-8 pb-2">
                <DefaultText variant="length" className="w-[312px] text-sm text-on-surface">
                  名前
                </DefaultText>
                <DefaultText variant="length" className="w-[160px] text-sm text-on-surface">
                  播種
                </DefaultText>
                <DefaultText variant="length" className="w-[160px] text-sm text-on-surface">
                  定植
                </DefaultText>
                <DefaultText variant="length" className="w-[160px] text-sm text-on-surface">
                  収穫開始
                </DefaultText>
                <DefaultText variant="length" className="w-[160px] text-sm text-on-surface">
                  収穫終了
                </DefaultText>
              </div>
              <div id="list-circle" className="h-[calc(100%-30px)] w-fit grow overflow-y-auto">
                <SortableContext items={areas}>
                  {areasState.map((item) => (
                    <SortableItem
                      key={item.id}
                      id={String(item.id)}
                      control={control}
                      errors={errors}
                      validateCustomRule={validateCustomRule}
                      handleModalDeleteClick={handleModalDeleteClick}
                      setValue={setValue}
                    />
                  ))}
                </SortableContext>
              </div>
            </div>

            <div className="pt-2">
              <PrimaryButton text={'播種追加'} variant={'outlined'} type="button" onClick={addItem} />
              <div className="pt-2">
                <DefaultText variant="title-children" className="text-on-surface">
                  {
                    '栽培スケジュールを更新しますが、収益計画にはまだ反映されていません。収益計画に反映させる場合は、「収益計画に反映」というボタンを押下してください。'
                  }
                </DefaultText>
              </div>
            </div>
          </div>
        </DndContext>
        <div className="flex justify-end gap-2">
          <div
            className="my-auto cursor-pointer rounded-xl px-3 pb-[10px] pt-[9px] hover:bg-black/[0.08]"
            onClick={handleClose}
            onKeyDown={() => {}}
          >
            <DefaultText variant="length" className="text-sm text-on-surface">
              キャンセル
            </DefaultText>
          </div>
          <PrimaryButton text="保存" variant="outlined" type="submit" />
        </div>
      </form>
      <CommonModal
        open={showModalDelete}
        title={'播種の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={handleDeleteCircle}
        boxClassName={'!w-[calc(100%-64px)] !max-w-[480px]'}
      >
        <ModalDelete contentDelete={'播種の名前を削除します。この操作は元に戻せません。'} />
      </CommonModal>
    </div>
  );
};

export default ModalSeedingWithinArea;
