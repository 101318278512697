import React, { useEffect, useState } from 'react';
import { useService } from './service';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { Carousel } from 'react-responsive-carousel';

const CarouselConfig = {
  infiniteLoop: true,
  renderArrowNext: (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return hasPrev ? (
      <button
        type="button"
        className="absolute right-4 top-1/2 z-10 m-1 flex -translate-y-1/2 bg-none p-2"
        onClick={clickHandler}
        aria-label={label}
      >
        <i className="icon-24px icon-navigate_next h-6 w-6 bg-white" />
      </button>
    ) : null;
  },
  renderArrowPrev: (clickHandler: () => void, hasPrev: boolean, label: string) => {
    return hasPrev ? (
      <button
        type="button"
        className="absolute left-4 top-1/2 z-10 m-1 flex -translate-y-1/2 bg-none p-2"
        onClick={clickHandler}
        aria-label={label}
      >
        <i className="icon-24px icon-navigate_before h-6 w-6 bg-white" />
      </button>
    ) : null;
  },
  showThumbs: false,
  showIndicators: false,
  swipeable: true,
};

type ImageDetailProps = {
  listImages: string[];
  imgIndex: number;
};

const ImageDetail = ({ listImages, imgIndex }: ImageDetailProps) => {
  const renderCarousel = (arr: string[]) => {
    return arr.map((item) => {
      return (
        <div key={item} className="flex h-full items-center justify-center overflow-hidden">
          <img
            src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(item)}`}
            className="aspect-video max-h-[767px] max-w-[1071px] object-contain object-center"
            alt="img"
            loading="lazy"
          />
        </div>
      );
    });
  };

  return (
    <Carousel selectedItem={imgIndex} {...CarouselConfig} className="h-full w-screen">
      {renderCarousel(listImages)}
    </Carousel>
  );
};

type Props = {
  isShowDefault?: boolean;
  listImagesPath?: string[];
};

const Medias = (props: Props) => {
  const { listImagesPath } = props;
  const { handleOnClick, img, handleClose } = useService({ listImagesPath });
  const [imagesTop, setImagesTop] = useState<Array<string>>([]);
  const [imagesBottom, setImagesBottom] = useState<Array<string>>([]);

  useEffect(() => {
    if (listImagesPath && listImagesPath.length > 0) {
      const selectedFiles: Array<string> = [...listImagesPath];
      const listImagesTop = selectedFiles.splice(0, 2);
      setImagesTop(listImagesTop);
      setImagesBottom(selectedFiles);
    }
  }, [listImagesPath]);

  return (
    <div className="w-full">
      {img !== undefined && (
        <div className="fixed left-0 top-0 z-[999] h-full bg-black">
          <button className="absolute right-4 top-4 z-10 m-2 flex p-2" onClick={handleClose}>
            <i className="icon-24px icon-close h-6 w-6 bg-white" />
          </button>
          <div className="h-full">
            <ImageDetail imgIndex={img} listImages={listImagesPath ?? []} />
          </div>
        </div>
      )}
      <div>
        {listImagesPath && listImagesPath.length > 0 && (
          <div className="flex flex-col gap-[2px] rounded">
            {imagesTop.length > 0 && (
              <div className="flex max-h-[319px] gap-0.5">
                {imagesTop.map((img) => (
                  <div
                    style={{ width: `calc(100% / ${imagesTop.length})` }}
                    key={img}
                    onClick={() => handleOnClick(img)}
                    onKeyDown={() => {}}
                  >
                    <img
                      className="h-full w-full rounded object-cover"
                      data-index={3}
                      src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(img)}`}
                      alt="Img3"
                    />
                  </div>
                ))}
              </div>
            )}
            {imagesBottom.length > 0 && (
              <div className="flex max-h-[212px] gap-0.5 overflow-hidden hover:cursor-pointer">
                {imagesBottom.map((img) => (
                  <div
                    key={img}
                    style={{ width: `calc(100% / ${imagesBottom.length})` }}
                    onClick={() => handleOnClick(img)}
                    onKeyDown={() => {}}
                  >
                    <img
                      className="h-full w-full rounded object-cover"
                      data-index={3}
                      src={`${process.env.REACT_APP_BASE_IMAGE_URL}${encodeURIComponent(img)}`}
                      alt="Img3"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Medias;
