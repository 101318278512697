import { Navigate } from 'react-router-dom';
import { EPath } from './route';
import React, { ReactNode } from 'react';

type TProps = {
  isAuthenticated: boolean;
  children: ReactNode;
};

const PrivateRoute: React.FC<TProps> = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <Navigate to={EPath.login} />;
  }
};

export default PrivateRoute;
