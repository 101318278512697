import PageHeader from '@/components/molecules/PageHeader';
import InformationForm from '@/components/organisms/InformationForm';
import React, { useEffect } from 'react';
import { EPath } from '@/route/route';
import { comparePath, replacePathParams } from '@/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { getHousesDetailAsync } from '@/stores/houses';
import { useLocation, useParams } from 'react-router-dom';

const DetailHouse = () => {
  const { houseId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { houseDetail } = useSelector((state: RootState) => state.houses);
  const { titleHeaderDetail } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (houseId) {
      dispatch(getHousesDetailAsync({ id: +houseId }));
    }
  }, [houseId]);

  const getEPathBack = () => {
    if (location.pathname.includes('organization') && location.pathname.includes('houses')) {
      return replacePathParams(EPath.organizationHouseList, {
        organizationId: houseDetail?.organizationId ?? '',
        houseId: houseId ?? '',
      });
    } else if (
      comparePath(window.location.pathname, EPath.editHousesDetailAll) ||
      location.pathname.includes('organization')
    ) {
      return replacePathParams(EPath.organizationHouse, {
        organizationId: houseDetail?.organizationId ?? '',
        houseId: houseId ?? '',
      });
    } else {
      return replacePathParams(EPath.houses, {});
    }
  };

  return (
    <>
      <div className="border-b border-b-[#E8E2D0] px-2 py-1 lg:px-8 lg:py-2">
        <PageHeader isAdmin={false} title={`${titleHeaderDetail}の情報`} ePathBack={getEPathBack()} />
      </div>
      <div className="container m-auto max-w-[800px] p-8">
        <InformationForm />
      </div>
    </>
  );
};

export default DetailHouse;
