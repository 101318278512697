import Breadcrumb, { TBreadcrumb } from '@/components/atoms/Breadcrumb/Breadcrumb';
import CommonModal from '@/components/atoms/Modal/CommonModal';
import DefaultText from '@/components/atoms/Text/DefaultText';
import { IconMenu } from '@/components/icon/IconMenu/IconMenu';
import IconMore from '@/components/icon/IconMore/IconMore';
import ModalDelete from '@/components/molecules/ModalComponent/ModalDelete';
import { EPath } from '@/route/route';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DropdownSetting from '../../components/molecules/Dropdown/DropdownSetting';
import { checkShowMore, titleMapping } from './header.Config';
import style from './style.module.sass';
import { useOutsideClick } from '@/hooks/useClickOutside';
import DropdownSettingHouses from '@/components/molecules/Dropdown/DropdownSetting/DropdownSettingHouses';
import { comparePath, getTitleHeader, replacePathParams } from '@/utils/helpers';
import DropdownSettingListHouses from '@/components/molecules/Dropdown/DropdownSetting/DropdownSettingListHouses';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/stores';
import { deleteOrganizationsAsync } from '@/stores/organization';
import DropdownEditBusiness from '@/components/molecules/Dropdown/DropdownEdit/DropdownEditBusiness';
import { deleteBP, getInfoBP } from '@/stores/business/services';
import ModalBusinessPlan from '@/components/molecules/ModalComponent/ModalBusinessPlan';
import { CONSTANTS } from '@/utils/constants/consts';

type HeaderProps = {
  onClick: () => void;
};

const Header: React.FC<HeaderProps> = ({ onClick }: HeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organizationInfo } = useSelector((state: RootState) => state.organization);
  const { houseDetail } = useSelector((state: RootState) => state.houses);
  const { businessDetail } = useSelector((state: RootState) => state.business);
  const { user } = useSelector((state: RootState) => state.auth);
  const [showSetting, setShowSetting] = useState(false);
  const location = useLocation();
  const { organizationId = '', houseId = '', businessId = '' } = useParams();
  const [showIconMore, setShowIconMore] = useState<boolean>(true);
  const [showModalBusinessPlan, setShowModalBusinessPlan] = useState(false);
  const [showModalDeleteBusinessPlan, setShowModalDeleteBusinessPlan] = useState(false);
  const { width } = useSelector((state: RootState) => state.common);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const { titleHeader, titleHeaderDetail } = useSelector((state: RootState) => state.common);

  const refModal = useRef<HTMLDivElement>(null);

  useOutsideClick(refModal, () => {
    setShowSetting(false);
  });

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleModalDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handleModalDeleteBusinessPlanClick = () => {
    setShowModalDeleteBusinessPlan(true);
  };

  useEffect(() => {
    const path = location.pathname;
    generateTitle(path);
    setShowIconMore(checkShowMore(path));
  }, [location.pathname]);

  const showSettingBox = () => {
    setShowSetting(!showSetting);
  };

  const generateTitle = (path: string): string => {
    for (const key in EPath) {
      if (Object.prototype.hasOwnProperty.call(EPath, key)) {
        const routePath = EPath[key as keyof typeof EPath];

        if (path === routePath) {
          return getTitleFromKey(key);
        }

        if (routePath.includes('/:') && doesPathMatchRoute(path, routePath)) {
          if (key === 'organizationDetail' && path.startsWith('/organization/list-house')) {
            return getTitleFromKey('listHouse');
          }

          return getTitleFromKey(key);
        }
      }
    }
    return '';
  };

  const doesPathMatchRoute = (path: string, routePath: string): boolean => {
    const pathSegments = path.split('/');
    const routeSegments = routePath.split('/');

    if (pathSegments.length !== routeSegments.length) {
      return false;
    }

    for (let i = 0; i < routeSegments.length; i++) {
      if (routeSegments[i].startsWith(':')) {
        continue;
      }

      if (routeSegments[i] !== pathSegments[i]) {
        return false;
      }
    }

    return true;
  };

  const getTitleFromKey = (key: string): string => {
    return titleMapping[key] || '';
  };

  const doesPathMatchAnyRoute = (path: string, routePaths: string[]): boolean => {
    for (const routePath of routePaths) {
      if (doesPathMatchRoute(path, routePath)) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteOrganization = () => {
    if (!organizationId) return;
    const data = {
      id: organizationId ? +organizationId : 0,
    };
    dispatch(
      deleteOrganizationsAsync({
        ...data,
        callback: () => {
          navigate(EPath.documents);
          setShowModalDelete(false);
        },
      }),
    );
  };

  const handleDeleteBusinessPlan = () => {
    dispatch(
      deleteBP({
        id: +businessId,
        callback: () =>
          navigate(replacePathParams(EPath.organizationHouse, { organizationId: +organizationId, houseId: +houseId })),
      }),
    );
    handleCloseModalBusinessPlan();
  };

  const handleCloseModalBusinessPlan = () => {
    setShowModalBusinessPlan(false);
    setShowModalDeleteBusinessPlan(false);
  };

  const handleOpenModalBusinessPlansClick = () => {
    setShowModalBusinessPlan(true);
  };

  useEffect(() => {
    if (comparePath(window.location.pathname, EPath.organizationHouseBusiness)) {
      dispatch(getInfoBP(+businessId));
    }
  }, [businessId]);

  const isDetailRoute = doesPathMatchAnyRoute(location.pathname, [
    EPath.questionDetail,
    EPath.feedbackDetail,
    EPath.documentDetail,
  ]);

  const isDetailRouteWithTitle = doesPathMatchAnyRoute(location.pathname, [
    EPath.houseDetail,
    EPath.business,
    EPath.organizationHouse,
  ]);

  const isDetailBusinessPlanRoute = doesPathMatchAnyRoute(location.pathname, [EPath.organizationHouseBusiness]);

  const isQuestionRoute = doesPathMatchAnyRoute(location.pathname, [EPath.questionDetail]);
  const isIntructionRoute = doesPathMatchAnyRoute(location.pathname, [EPath.feedbackDetail]);

  const isLiteratureRoute = doesPathMatchAnyRoute(location.pathname, [EPath.documentDetail]);

  const BREADCRUMBS_QUESTION: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: replacePathParams(EPath.organization, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: '質問',
      to: EPath.questions,
    },
  ];

  const BREADCRUMBS_FEEDBACK: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: replacePathParams(EPath.organization, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: '指導',
      to: EPath.feedbacks,
    },
  ];
  const BREADCRUMBS_DOCUMENT: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: replacePathParams(EPath.organization, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: '文献',
      to: EPath.documents,
    },
  ];

  const BREADCRUMBS_BUSINESS_PLAN: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: replacePathParams(EPath.organization, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: houseDetail?.title ?? '',
      to: replacePathParams(EPath.organizationHouseList, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: titleHeaderDetail,
      to: '#',
    },
  ];

  const BREADCRUMBS_HOUSE: TBreadcrumb[] = [
    {
      name: titleHeader,
      to: replacePathParams(EPath.organization, {
        organizationId: organizationId ?? '',
      }),
    },
    {
      name: titleHeaderDetail,
      to: '#',
    },
  ];

  const handleShowOrganizationTitle = () => {
    const organizationPaths = [
      EPath.feedbacks,
      EPath.documents,
      EPath.questions,
      EPath.houses,
      EPath.organizationHouseList,
      EPath.organizationQuestion,
      EPath.organizationDocument,
      EPath.organizationFeedback,
      EPath.organization,
    ];
    return organizationPaths.some((path) => comparePath(window.location.pathname, path));
  };

  const getValueTitleHeader = () => {
    if (comparePath(window.location.pathname, EPath.notification)) return '通知';
    else if (comparePath(window.location.pathname, EPath.account)) return 'アカウント';
    else if (handleShowOrganizationTitle()) return getTitleHeader(titleHeader);
    else return titleHeaderDetail;
  };

  const handleShowIconSideBar = () => {
    const paths = [
      EPath.questionDetail,
      EPath.questionDetailAll,
      EPath.feedbackDetail,
      EPath.feedbackDetailAll,
      EPath.documentDetail,
      EPath.documentDetailAll,
    ];
    return paths.some((path) => comparePath(window.location.pathname, path));
  };

  return (
    <div>
      {isDetailRouteWithTitle && width > CONSTANTS.SIZE_MOBILE && (
        <div className="border-b border-outline-variant px-8 py-[7px]">
          <Breadcrumb data={BREADCRUMBS_HOUSE} className="h-[18px]" />
        </div>
      )}
      {isDetailBusinessPlanRoute && width > CONSTANTS.SIZE_MOBILE && (
        <div className="border-b border-outline-variant px-8 py-[7px]">
          <Breadcrumb data={BREADCRUMBS_BUSINESS_PLAN} className="h-[18px]" />
        </div>
      )}
      {(!handleShowIconSideBar() || width > CONSTANTS.SIZE_MOBILE) && (
        <div
          className={`${style.header_box} lg:!flex lg:border-b lg:border-outline-variant ${
            isDetailRoute ? '!h-8' : ''
          }`}
        >
          <div className={style.titlePage}>
            <span className={style.menu_sp} onClick={onClick} onKeyDown={() => {}}>
              <IconMenu />
            </span>
            {isDetailRoute && width > CONSTANTS.SIZE_MOBILE ? (
              <div className="hidden lg:block">
                {isQuestionRoute && <Breadcrumb data={BREADCRUMBS_QUESTION} className="h-[18px]" />}
                {isIntructionRoute && <Breadcrumb data={BREADCRUMBS_FEEDBACK} className="h-[18px]" />}
                {isLiteratureRoute && <Breadcrumb data={BREADCRUMBS_DOCUMENT} className="h-[18px]" />}
              </div>
            ) : (
              <DefaultText
                variant="title-page"
                className="truncate-text my-[6px] text-[18px] text-on-surface lg:text-[24px]"
              >
                {getValueTitleHeader()}
              </DefaultText>
            )}
          </div>
          <div className={`${style.setting_icon} my-auto`}>
            {showIconMore && (
              <span className={style.icon} onClick={showSettingBox}>
                <IconMore />
              </span>
            )}
            <div>
              {showSetting && comparePath(window.location.pathname, EPath.organization) && (
                <div ref={refModal}>
                  <DropdownSetting
                    modalModalDeleteClick={handleModalDeleteClick}
                    organizationId={Number(organizationId)}
                    isHidenDeleteIcon={user?.id !== organizationInfo?.owner.members.id}
                  />
                </div>
              )}
              {showSetting && comparePath(window.location.pathname, EPath.organizationHouse) && (
                <div ref={refModal}>
                  <DropdownSettingHouses
                    modalModalDeleteClick={handleModalDeleteClick}
                    moveFormPath={replacePathParams(EPath.houseDetail, {
                      organizationId: Number(organizationId),
                      houseId: Number(houseId),
                    })}
                  />
                </div>
              )}
              {showSetting && comparePath(window.location.pathname, EPath.organizationHouseList) && (
                <div ref={refModal}>
                  <DropdownSettingListHouses
                    modalModalDeleteClick={handleModalDeleteClick}
                    organizationId={Number(organizationId)}
                  />
                </div>
              )}
              {showSetting && comparePath(window.location.pathname, EPath.housesDetailAll) && (
                <div ref={refModal}>
                  <DropdownSettingHouses
                    modalModalDeleteClick={handleModalDeleteClick}
                    moveFormPath={replacePathParams(EPath.editHousesDetailAll, {
                      houseId: Number(houseId),
                    })}
                  />
                </div>
              )}
              {showSetting && comparePath(window.location.pathname, EPath.organizationHouseBusiness) && (
                <div ref={refModal}>
                  <DropdownEditBusiness
                    modalModalBusinessPlanClick={handleOpenModalBusinessPlansClick}
                    modalModalDeleteClick={handleModalDeleteBusinessPlanClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <CommonModal
        open={showModalDelete}
        title={'組織の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModal}
        handleClick={handleDeleteOrganization}
        boxClassName={'!w-[calc(100vw-64px)] !max-w-[480px]'}
      >
        <ModalDelete
          contentDelete={
            '組織の名前を削除します。組織内にある全てのデータが削除され、ほか組織に公開した記事なども見れなくなります。この操作は元に戻せません。'
          }
        />
      </CommonModal>
      <CommonModal
        open={showModalDeleteBusinessPlan}
        title={'事業計画の削除'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'削除'}
        buttonVariant={'delete'}
        handleClose={handleCloseModalBusinessPlan}
        handleClick={handleDeleteBusinessPlan}
        boxClassName={'w-[calc(100vw-64px)] max-w-[480px]'}
      >
        <ModalDelete
          contentDelete={
            '事業計画の名前を削除します。事業計画内にあるすべてのデータ、損益計画、栽培計画も削除されます。この操作は元に戻せません。'
          }
        />
      </CommonModal>
      <CommonModal
        open={showModalBusinessPlan}
        title={'事業計画'}
        textBtnCancel={'キャンセル'}
        textBtnSubmit={'保存'}
        buttonVariant={'outlined'}
        handleClose={handleCloseModalBusinessPlan}
        boxClassName={'w-[calc(100vw-64px)] max-w-[640px]'}
        buttonHidden={true}
      >
        <ModalBusinessPlan
          name={businessDetail?.title ?? ''}
          id={+businessId}
          handleClose={handleCloseModalBusinessPlan}
        />
      </CommonModal>
    </div>
  );
};

export default Header;
