import DefaultText from '@/components/atoms/Text/DefaultText';

type Props = {
  contentDelete: string;
};
const ModalDelete = ({ contentDelete }: Props) => {
  return (
    <>
      <div className="space-y-4 py-6">
        <div className="space-y-2">
          <DefaultText variant="title-children" className="text-on-surface">
            {contentDelete}
          </DefaultText>
        </div>
      </div>
    </>
  );
};

export default ModalDelete;
